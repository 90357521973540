export const APP_NAME = "Weighzy";
export const CONTACT_EMAIL = "help@weighzy.com";

// Public paths
export const PUBLIC_PATHS = ["/", "/register", "/demo", "/contact"];

// Languages
export const DEFAULT_LANGUAGE = "en";
export const LANGUAGES = [
  "en",
  "da",
  "de",
  "es",
  "fi",
  "fr",
  "it",
  "nl",
  "no",
  "pt",
  "sv",
];
export const STABLE_LANGUAGES = ["en"];

// Data Management.
export const DATABASE_PROVIDER = "Firestore";
export const DATABASE_USERS = "Users";
export const DATABASE_USER_DATA = "UserData";

// Color and Theming.
export const LIGHT_THEME = "light";
export const DARK_THEME = "dark";

export const BASE_LVL_TWO_GRAY = "#DBDBDB"; // Most of the app.
export const BASE_LVL_TWO_GRAY_WITH_OPACITY = "#999999"; // used for modal dimming.
export const BASE_LVL_THREE_GRAY = "#D0D0D0"; // Raised portion inside cardContent.
export const BASE_LVL_FOUR_GRAY = "#C7C7C7"; // Super raised locations, currently only custom calorie input.

export const BASE_WHITE = "#FFFFFF";
export const BASE_WHITE_DULLED = "#c9c5c5";
export const BASE_GRAY = "hsl(0, 0%, 60%)"; // Scale third value for light->dark, divide by count, assign -delta down the line.
export const BASE_DARK_GRAY = "#40444a";
export const BASE_BLACK = "#000000";
export const BASE_LIGHT_GRAY = "#BCBCBC";

export const BASE_LVL_ONE_DARK_WITH_OPACITY = "#121212";
export const BASE_LVL_ONE_DARK = "#1e1e1e";
export const BASE_LVL_TWO_DARK = "#252526";
export const BASE_LVL_THREE_DARK = "#2d2d30";
export const BASE_LVL_FOUR_DARK = "#3e3e42";

export const BASE_BLUE = "#255295";
export const BASE_GREEN = "#2e8d35";
export const BASE_RED = "#8d2e30";
export const BASE_PINK = "#DF1077";
export const BASE_YELLOW = "#F3B800";
export const BASE_ORANGE = "#da6900";
export const BASE_AQUA = "#2e8d8a";
export const BASE_PURPLE = "#6614b3"; // '#64297F';
export const BASE_OLD_PURPLE = "#5B2E8D";

export const THEME_GRAYSCALE = "greyscale";
export const THEME_COLOR = "colored";

export const BASE_ACCENT = BASE_PURPLE;
export const BASE_SELECTABLE_LIGHT = BASE_DARK_GRAY;
export const BASE_SELECTABLE_DARK = BASE_BLACK;

// Spacing
export const TABLE_ARROW_SIZE = 35;
export const NUMBER_OF_CARDS = 4;
export const IN_AD_HEIGHT_MEDIUM = 250; // BannerAdSize.MEDIUM_RECTANGLE, // Interactive Advertising Bureau (IAB) medium rectangle ad size (300x250 density-independent pixels).
export const IN_AD_HEIGHT_SMALL = 100; // BannerAdSize.LARGE_BANNER // Large banner ad size (320x100 density-independent pixels).

// Domain constants
export const AGE_MIN = 18; // Tentative until further investigation.
export const AGE_MAX = 128; // Oldest person alive.
export const HEIGHT_FEET_MIN = 1; // Shortest person ever.
export const HEIGHT_FEET_MAX = 9; // Tallest person ever.
export const WEIGHT_MIN = 70; // Lightest person ever. LBS
export const WEIGHT_MAX = 500; // Heaviest person ever. LBS

export const MILLISECONDS_IN_A_DAY = 86400000;
export const CALORIES_IN_LB = 3500;

export const REST_MIN = 0; // SECONDS
export const REST_MAX = 150; // SECONDS

export const PROG_MIN = 1;
export const PROG_MAX = 20;
export const PROG_DISTANCE_MIN = 0.25;
export const PROG_DISTANCE_MAX = 43;
export const PROG_DURATION_MIN = 5;
export const PROG_DURATION_MAX = 1800;

export const MIN_LIFT = 0; // LBS
export const MAX_LIFT = 1100; // LBS
export const MIN_DISTANCE = 0;
export const MAX_DISTANCE = 43;
export const MIN_DURATION = 0;
export const MAX_DURATION = 3600;

export const KILOGRAMS = "KGS";
export const POUNDS = "LBS";
export const STONES = "STS";

export const POUNDS_TO_KILOGRAMS = 0.45359237;
export const POUNDS_TO_STONES = 0.0714286;
export const KILOGRAMS_TO_POUNDS = 2.2046226218;
export const KILOGRAMS_TO_STONES = 0.157473;
export const STONES_TO_POUNDS = 14;
export const STONES_TO_KILOGRAMS = 6.35029318;

export const KILOMETERS = "KM";
export const MILES = "M";

export const MILES_TO_KILOMETERS = 1.60934;
export const KILOMETERS_TO_MILES = 0.621371;

// Text lengths
export const CARD_CONTENT_TITLE_STRING = 50;
export const INFO_BANNER_MAX_STRING = 35;
export const QUESTION_MAX_STRING = 35;
export const SETTING_MAX_STRING = 12;

export const LARGE_BTN_MAX_STRING = 8;
export const SMALL_BTN_MAX_STRING = 7;

export const TABLE_ROW_ITEM = 11;
export const TABLE_ROW_HEADER = 9;
export const TABLE_DOUBLE_ROW_HEADER = 20;

export const ARROW_SYM = "➝";
