import React from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import {
  QUESTION_MAX_STRING,
  LARGE_BTN_MAX_STRING,
} from "../../../Supports/Constants";
import { shortenText } from "../../../Supports/Functions";
import Icon from "@mdi/react";
import { mdiKeyboardReturn } from "@mdi/js";

export default function FastInfo(props) {
  const { t } = useTranslation();
  const item = props.modalSettings.itemInfo;
  const goal = `${item.goal}${t("hour-short")}`;
  const startDateObj = new Date(item.startDatetime);
  const endDateObj = new Date(item.endDatetime);

  const sections = [
    { section: "goal", value: goal, tooltip: null },
    {
      section: "duration-full",
      value:
        item.formattedDuration === "0s"
          ? t("no-information-available-short")
          : item.formattedDuration,
      tooltip: null,
    },
    {
      section: "status",
      value: item.successful
        ? t("completed")
        : item.status === "cancelled"
        ? t("cancelled")
        : t("ended-early"),
      tooltip: null,
    },
    {
      section: "start",
      value: `${startDateObj.toLocaleTimeString()} ${item.startDate}`,
      tooltip: null,
    },
    {
      section: "end",
      value: `${endDateObj.toLocaleTimeString()} ${item.endDate}`,
      tooltip: null,
    },
  ];

  const genRow = (sectioni18n, itemValue, tooltipi18n) => {
    return (
      <div className="sectionItemRow" key={sectioni18n}>
        <div className="card-text-small">{`${shortenText(
          t(sectioni18n),
          QUESTION_MAX_STRING,
          true,
          1
        )}:`}</div>
        <div className="card-text-small">{itemValue}</div>
      </div>
    );
  };

  const dismissWithoutInputFunc = () => {
    props.modalSettings.setSelectedFast(null);
    props.setModalVisible(false);
  };

  return (
    <Modal
      isOpen={props.modalVisible}
      onRequestClose={dismissWithoutInputFunc}
      animationType="none"
      appElement={document.getElementById("root") || undefined}
    >
      <div className="modal-header-text-small">{`${goal} ${t("fast")}`}</div>
      <div>
        <div className="modal-header-text-small">{t("information")}</div>
        <div>{sections.map((s) => genRow(s.section, s.value, s.tooltip))}</div>
      </div>
      <div className="button-container nowrap noTopMargin noBottomMargin">
        <button
          className="large-rectangle danger"
          onClick={dismissWithoutInputFunc}
        >
          <Icon size={1} path={mdiKeyboardReturn} />
          <div className="card-button-text">
            {shortenText(t("close"), LARGE_BTN_MAX_STRING, true, 0, true, true)}
          </div>
        </button>
      </div>
    </Modal>
  );
}
