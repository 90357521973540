import React from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { LARGE_BTN_MAX_STRING } from "../../../Supports/Constants";
import { shortenText } from "../../../Supports/Functions";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiKeyboardReturn, mdiLogout } from "@mdi/js";

// Component modal confirming if user truly wants to logout of their session.
export default function LogoutConfirmModal(props) {
  const auth = getAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dismissWithoutInputFunc = () => props.setModalVisible(null);
  const logOut = () => {
    auth
      .signOut()
      .then(() => {
        props.setModalVisible(null);
        navigate("/");
      })
      .catch((error) => {
        // No user signed in.
        props.setModalVisible(null);
        navigate("/");
      });
  };

  return (
    <Modal
      isOpen={props.modalVisible}
      onRequestClose={dismissWithoutInputFunc}
      animationType="none"
      appElement={document.getElementById("root") || undefined}
    >
      <div className="modal-header-text-small">{t("m-lo-head")}</div>
      <div className="button-container nowrap noTopMargin noBottomMargin">
        <button
          className="large-rectangle accent"
          onClick={dismissWithoutInputFunc}
        >
          <Icon size={1} path={mdiKeyboardReturn} />
          <div className="card-button-text">
            {shortenText(
              t("cancel"),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </div>
        </button>
        <button className="large-rectangle danger" onClick={logOut}>
          <Icon size={1} path={mdiLogout} />
          <div className="card-button-text">
            {shortenText(
              t("log-out"),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </div>
        </button>
      </div>
    </Modal>
  );
}
