/* eslint-disable no-empty-pattern */
import React from "react";
import { useTranslation } from "react-i18next";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Icon from "@mdi/react";
import { mdiBook } from "@mdi/js";
import {
  BASE_ACCENT,
  BASE_GREEN,
  BASE_PINK,
  BASE_SELECTABLE_DARK,
  BASE_SELECTABLE_LIGHT,
} from "../../../Supports/Constants";
import { shortenText } from "../../../Supports/Functions";
import FurtherReadingModal from "../../Modals/Fast/FurtherReadingModal";
import "./FastCard.css";

// This component generates a custom timer element with a circular tracker and an internal countdown,
// external pre-and-post milestones which displays current targets,
// as well as interactive tracker pressables mapping the timeline.
export default function FastBlocker(props) {
  const { t } = useTranslation();
  const vd = props.visualizationData;

  const MAX_DAYS =
    vd.panel === "view" && vd.fastGoal
      ? vd.fastGoal / 24 + (vd.fastGoal > 12 ? 1 : 0)
      : 7 * 3;

  const dayNames = [
    t("days-sun-full"),
    t("days-mon-full"),
    t("days-tue-full"),
    t("days-wed-full"),
    t("days-thu-full"),
    t("days-fri-full"),
    t("days-sat-full"),
  ];

  const dayNamesShort = dayNames.map((d) =>
    shortenText(d, 3, false, 0, false, false)
  );

  const frozen = vd.fastStatus !== "active" && vd.fastStatus !== "inactive";

  const WEEK_LENGTH = dayNamesShort.length;

  const genDayHeaders = () => {
    const headers = [];
    const today = new Date();

    for (let i = 0; i < Math.min(WEEK_LENGTH, MAX_DAYS); i++) {
      const dayOfWeekShort = dayNamesShort[(today.getDay() + i) % WEEK_LENGTH];

      headers.push(
        <div
          key={`dayheader-${dayOfWeekShort}`}
          className="blockTimelineDayHeader blockTimelineItem"
        >
          {dayOfWeekShort}
        </div>
      );
    }

    return headers;
  };

  const genButtons = (buttons) => {
    const collection = [];
    buttons.forEach((btnInfo, n) => {
      const btn = (
        <button
          className={`blockTimelineItemText blockTimelineItem`}
          style={{ backgroundColor: btnInfo.color }}
          key={btnInfo.dayCount}
          onClick={() => {
            if (
              vd.panel !== "start" ||
              vd.fastStatus === "active" ||
              vd.interactionFunc === "undefined"
            ) {
            } else {
              vd.setGoalHasChanged(true);
              vd.interactionFunc(btnInfo);
            }
          }}
        >
          {btnInfo.displayText}
        </button>
      );
      collection.push(btn);
    });

    return collection;
  };

  const genTimeline = () => {
    const today = new Date();

    const dayHolder = [];
    for (let index = 0; index < MAX_DAYS; index++) {
      const todayDay = today.getDate();
      const newDate = new Date(today).setDate(todayDay + index);
      const finalDate = new Date(newDate);
      const finalDateFormatted = [
        finalDate.getFullYear(),
        finalDate.getMonth() + 1,
        finalDate.getDate(),
      ].join("-");

      // Overwrite 0 to be 12, smallest, otherwise 24h inc.
      const fastCount = index === 0 ? 12 : index * 24;

      const genColor = () => {
        if (vd.currentFastDuration > 0 && fastCount < vd.currentFastDuration) {
          return BASE_GREEN;
        } else if (vd.panel !== "view" && vd.fastGoal >= fastCount) {
          return BASE_ACCENT;
        } else if (
          vd.panel !== "view" &&
          vd.fastDaysFromWeightGoal >= fastCount
        ) {
          return BASE_PINK;
        }
        return vd.isDarkTheme ? BASE_SELECTABLE_DARK : BASE_SELECTABLE_LIGHT;
      };

      dayHolder.push({
        date: finalDate,
        dateFormatted: finalDateFormatted,
        // dayOfWeek: dayNames[(today.getDay() + index) % WEEK_LENGTH],
        // dayOfWeekShort: dayNamesShort[(today.getDay() + index) % WEEK_LENGTH],
        dayCount: index,
        fastHoursCount: fastCount,
        value: fastCount,
        btnName: fastCount + t("hour-short"),
        color: genColor(),
        displayText: fastCount + t("hour-short"),
      });
    }

    return (
      <div className="blockTimelineContainer">
        <div className="blockTimelineHeader">{genDayHeaders()}</div>
        <div
          className={`${
            vd.panel === "view" ? "blockTimelineViewer" : "blockTimeline"
          }`}
        >
          {genButtons(dayHolder)}
        </div>
      </div>
    );
  };

  // left or right.
  const genMilestones = (side) => {
    const MILESTONE_DIFF = 12;
    const hoursIntoFast = vd.currentFastDuration;
    const milestoneCount =
      side === "left"
        ? hoursIntoFast - (hoursIntoFast % MILESTONE_DIFF)
        : hoursIntoFast - (hoursIntoFast % MILESTONE_DIFF) + MILESTONE_DIFF;
    const fastGoalDuration = vd.currentFast.fastGoalDuration;
    const hours = Math.floor(fastGoalDuration / 3600);

    let specialNote = "";
    switch (milestoneCount) {
      case 0:
        specialNote = t("fast-stage-1");
        break;
      case 12:
        specialNote = t("fast-stage-2");
        break;
      case 24:
        specialNote = t("fast-stage-3");
        break;
      case 48:
        specialNote = t("fast-stage-4");
        break;
      case 72:
        specialNote = t("fast-stage-5");
        break;
      default:
        specialNote = t("fast-stage-1");
        break;
    }

    if (milestoneCount > 72) {
      specialNote = t("fast-stage-6");
    }

    specialNote = shortenText(specialNote, 10, false, 0, true, false);

    if (milestoneCount === hours) {
      specialNote += `\n(${t("end")})`;
    }

    const text =
      vd.fastStatus === "active"
        ? `${specialNote}`
        : side === "left"
        ? t("c-ftt-ready-to-start-newline")
        : t("c-ftt-start-fast-newline");

    return (
      <div className={`milestoneContainer`}>
        <div className="milestoneHeaderText">{`${
          side === "left" ? t("active") : t("next")
        }\n${t("milestone")}`}</div>
        <div className="milestoneHeaderHourText">{`${milestoneCount}${t(
          "hour-short"
        )}`}</div>
        <div className="milestoneText">{text}</div>
      </div>
    );
  };

  const genClock = () => {
    const isReady = () => vd.fastStart === null;
    const isComplete = () => vd.fastStatus === "complete";
    const isCancelled = () => vd.fastStatus === "cancelled";
    // const isCountdown = () => !isReady() && !isComplete() && !isCancelled();

    const genSizes = (isMainView) => {
      return {
        font: 18,
        size: 100,
        strokeWidth: 12,
      };
    };

    const sizes = genSizes(vd.panel === "view");
    const remState = (vd.endTarget - Date.now()) / 1000;

    return (
      <div className={`${vd.panel === "view" ? "" : "clock-start"}`}>
        <CountdownCircleTimer
          key={vd.restartTimer}
          isPlaying={!frozen}
          size={sizes.size}
          strokeWidth={sizes.strokeWidth}
          trailColor={BASE_GREEN}
          colors={BASE_ACCENT}
          strokeLinecap={"butt"}
          duration={(vd.endTarget - vd.fastStart) / 1000}
          initialRemainingTime={remState < 0 ? 0 : remState}
          updateInterval={1}
        >
          {({}) => {
            const rem = (vd.endTarget - Date.now()) / 1000;
            let text = "";

            if (isNaN(rem) || isReady()) {
              text = shortenText(t("ready"), 8, true, 0, false, false, 3);
            } else if (isComplete()) {
              text = shortenText(t("ended"), 8, true, 0, false, false, 3);
            } else if (isCancelled()) {
              text = shortenText(t("cancelled"), 8, true, 0, false, false, 3);
            } else {
              if (rem < 0) {
                text += "+\n";
              }

              const hours = Math.floor(Math.abs(rem / 3600));
              const minutes = Math.floor(Math.abs((rem % 3600) / 60));
              const seconds = Math.floor(Math.abs(rem % 60));

              if (hours <= 0 && minutes <= 0) {
                text += `${seconds}${t("second-short")}`;
              } else if (hours <= 0) {
                text += `${minutes}${t("minute-short")}\n${seconds}${t(
                  "second-short"
                )}`;
              } else {
                text += `${hours}${t("hour-short")}\n${minutes}${t(
                  "minute-short"
                )}\n${seconds}${t("second-short")}`;
              }
            }
            return (
              <div
                className="innerCircleFont"
                style={{
                  fontSize: sizes.font,
                  lineHeight: sizes.lineHeight,
                }}
              >
                {text}
              </div>
            );
          }}
        </CountdownCircleTimer>
      </div>
    );
  };

  const genReadMoreButton = (visible = true) => {
    return visible ? (
      <button
        onClick={() => visible && vd.setModalVisible("FT_FURTHER_READING")}
        className={`further-reading-button cardButtonSmallerTable ${
          visible && "cardButtonSelected"
        }`}
      >
        <Icon path={mdiBook} size={1} />
      </button>
    ) : (
      <div style={{ width: "6%" }}></div>
    );
  };

  return (
    <div
      className={`fastBlockContainer ${
        vd.panel === "view" ? "fastBlockContainerColumn" : ""
      }`}
    >
      <div className="contentContainer">
        {vd.panel === "view" && genMilestones("left")}
        {vd.panel === "view" && genReadMoreButton(false)}
        {genClock()}
        {vd.panel === "view" && genReadMoreButton()}
        {vd.panel === "view" && genMilestones("right")}
      </div>
      {genTimeline()}
      {vd.modalVisible === "FT_FURTHER_READING" && (
        <FurtherReadingModal
          key={"customInputModal-FT_FURTHER_READING"}
          modalVisible={vd.modalVisible === "FT_FURTHER_READING"}
          setModalVisible={vd.setModalVisible}
        />
      )}
    </div>
  );
}
