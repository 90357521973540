import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import {
  APP_NAME,
  BASE_ACCENT,
  BASE_DARK_GRAY,
  LARGE_BTN_MAX_STRING,
  QUESTION_MAX_STRING,
} from "../../../Supports/Constants";
import { getTodayDate, shortenText } from "../../../Supports/Functions";
import Icon from "@mdi/react";
import { mdiContentSave, mdiKeyboardReturn } from "@mdi/js";
import { ToggleButtonGroup } from "@mui/material";
import MuiToggleButton from "@mui/material/ToggleButton";
import { styled } from "@mui/material/styles";
import { InfoTooltip } from "../../Supports/InfoTooltip";
import { DataContext } from "../../../Supports/Contexts";
import { getAuth } from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";

export default function CheckupModal(props) {
  const { t } = useTranslation();
  const Data = useContext(DataContext);

  const [todayLog, setTodayLog] = useState(null);
  const [originalState, setOriginalState] = useState(null);
  const [warning, setWarning] = useState(null);
  const [checkup, setCheckup] = useState(null);

  useEffect(() => {
    const today = getTodayDate();

    if (
      Data.weightLog &&
      Data.weightLog[`${today.year}-${today.month}-${today.day}`]
    ) {
      const inTodayLog =
        Data.weightLog[`${today.year}-${today.month}-${today.day}`];
      setTodayLog(inTodayLog);
      setCheckup(inTodayLog?.checkup);
      !originalState && setOriginalState(inTodayLog?.checkup);
    }
  }, [Data.loadingWeightLog, Data.weightLog, originalState]);

  const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: BASE_ACCENT,
    },
    "&:not(.Mui-selected)": {
      color: "white",
      backgroundColor: BASE_DARK_GRAY,
    },
  }));

  if (!props.userData || !props.userData.body.age || !checkup) {
    return <></>;
  }

  const hasStateChanged = (diff) => {
    const keys = Object.keys(diff);

    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      if (originalState[key] !== diff[key]) {
        return true;
      }
    }
    return false;
  };

  const updateState = (value) => {
    setCheckup({ ...checkup, ...value });
    setWarning(
      hasStateChanged({ ...checkup, ...value })
        ? t("warning-changes-saved")
        : ""
    );
  };

  const dismissWithoutInputFunc = () => {
    setWarning(null);
    updateState(originalState);
    props.setModalVisible(false);
  };

  const saveChanges = async () => {
    const today = getTodayDate();
    todayLog.checkup = checkup;

    await setDoc(
      doc(
        getFirestore(),
        `UserData/${getAuth().currentUser.uid}/WeightLog`,
        `${today.year}-${today.month}-${today.day}`
      ),
      todayLog
    ).then(() => {
      setWarning(null);
      setOriginalState(checkup);
      props.setModalVisible(false);
    });
  };

  return (
    <Modal
      isOpen={props.modalVisible}
      onRequestClose={dismissWithoutInputFunc}
      animationType="none"
      appElement={document.getElementById("root") || undefined}
    >
      <div className="modal-header-text-small">{t("m-cu-title")}</div>
      <div className="card-header-text-small-centered">
        {t("m-cu-s1-head", { varApp: APP_NAME })}
      </div>
      <div className="container-centered">
        <div className="card-text-small centered">
          {t("m-cu-s1-body-p1", { varApp: APP_NAME })}
        </div>
        <div className="card-text-small centered">
          {t("m-cu-s1-body-p2", { varApp: APP_NAME })}
        </div>
      </div>

      <div className="card-header-text-small-centered">{t("m-cu-s2-head")}</div>
      <div className="sectionItemRow">
        <div className="card-text-small">
          {shortenText(t("m-cu-s2-body-q1"), QUESTION_MAX_STRING, true, 1)}
        </div>
        <div className="endRow">
          <ToggleButtonGroup
            className="row-content"
            value={checkup.poorSleep}
            exclusive
            onChange={(_, bool) => updateState({ poorSleep: bool })}
            aria-label="poor sleep"
          >
            <ToggleButton
              className="large-rectangle-no-color"
              value={false}
              aria-label="female"
            >
              {t("not-yes")}
            </ToggleButton>
            <ToggleButton
              className="large-rectangle-no-color"
              value={true}
              aria-label="male"
            >
              {t("yes")}
            </ToggleButton>
          </ToggleButtonGroup>
          <InfoTooltip text={t("m-cu-s2-body-q1-info")} />
        </div>
      </div>
      <div className="sectionItemRow">
        <div className="card-text-small">
          {shortenText(t("m-cu-s2-body-q2"), QUESTION_MAX_STRING, true, 1)}
        </div>
        <div className="endRow">
          <ToggleButtonGroup
            className="row-content"
            value={checkup.irregularFluids}
            exclusive
            onChange={(_, bool) => updateState({ irregularFluids: bool })}
            aria-label="poor sleep"
          >
            <ToggleButton
              className="large-rectangle-no-color"
              value={false}
              aria-label="female"
            >
              {t("not-yes")}
            </ToggleButton>
            <ToggleButton
              className="large-rectangle-no-color"
              value={true}
              aria-label="male"
            >
              {t("yes")}
            </ToggleButton>
          </ToggleButtonGroup>
          <InfoTooltip text={t("m-cu-s2-body-q2-info")} />
        </div>
      </div>
      <div className="sectionItemRow">
        <div className="card-text-small">
          {shortenText(t("m-cu-s2-body-q3"), QUESTION_MAX_STRING, true, 1)}
        </div>
        <div className="endRow">
          <ToggleButtonGroup
            className="row-content"
            value={checkup.sick}
            exclusive
            onChange={(_, bool) => updateState({ sick: bool })}
            aria-label="poor sleep"
          >
            <ToggleButton
              className="large-rectangle-no-color"
              value={false}
              aria-label="female"
            >
              {t("not-yes")}
            </ToggleButton>
            <ToggleButton
              className="large-rectangle-no-color"
              value={true}
              aria-label="male"
            >
              {t("yes")}
            </ToggleButton>
          </ToggleButtonGroup>
          <InfoTooltip text={t("m-cu-s2-body-q3-info")} />
        </div>
      </div>
      <div className="sectionItemRow">
        <div className="card-text-small">
          {shortenText(t("m-cu-s2-body-q4"), QUESTION_MAX_STRING, true, 1)}
        </div>
        <div className="endRow">
          <ToggleButtonGroup
            className="row-content"
            value={checkup.irregularWeighin}
            exclusive
            onChange={(_, bool) => updateState({ irregularWeighin: bool })}
            aria-label="poor sleep"
          >
            <ToggleButton
              className="large-rectangle-no-color"
              value={false}
              aria-label="female"
            >
              {t("not-yes")}
            </ToggleButton>
            <ToggleButton
              className="large-rectangle-no-color"
              value={true}
              aria-label="male"
            >
              {t("yes")}
            </ToggleButton>
          </ToggleButtonGroup>
          <InfoTooltip text={t("m-cu-s2-body-q4-info")} />
        </div>
      </div>

      {warning && <div className="warningText">{warning}</div>}
      <div className="button-container nowrap noTopMargin noBottomMargin">
        <button
          className="large-rectangle danger"
          onClick={dismissWithoutInputFunc}
        >
          <Icon path={mdiKeyboardReturn} size={1} />
          <div className="card-button-text ">
            {shortenText(t("close"), LARGE_BTN_MAX_STRING, true, 0, true, true)}
          </div>
        </button>
        <button className="large-rectangle new" onClick={saveChanges}>
          <Icon path={mdiContentSave} size={1} />
          <div className="card-button-text">
            {shortenText(t("save"), LARGE_BTN_MAX_STRING, true, 0, true, true)}
          </div>
        </button>
      </div>
    </Modal>
  );
}
