import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { LARGE_BTN_MAX_STRING } from "../../Supports/Constants";
import {
  deleteAccount,
  deleteCollection,
  shortenText,
  updateVal,
} from "../../Supports/Functions";
import { DarkModeToggle } from "../Supports/DarkModeToggle";
import { DataContext, ModalContext } from "../../Supports/Contexts";
import LogoutConfirmModal from "../Modals/General/LogoutConfirmModal";
import CheckupModal from "../Modals/General/CheckupModal";
import weighzy from "../../Assets/WeighzyFullHeader.png";
import "./Header.css";
import DropDownModal from "../Modals/General/DropDownModal";
import GuideModal from "../Modals/Guide/GuideModal";
import WelcomeModal from "../Modals/Guide/WelcomeModal";
import ConfirmModal from "../Modals/General/ConfirmModal";
import { BASE_INIT_STATE } from "../../Supports/Templates";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { collection, getDocs, getFirestore } from "firebase/firestore";

export default function Header({ logout = false, main = false, data = null }) {
  const modalContext = useContext(ModalContext);
  const Data = useContext(DataContext);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = getAuth();

  const onLogoutPress = () => modalContext.setModal("LOGOUT_CONFIRM_MODAL");
  const onSettingsPress = () => modalContext.setModal("DROPDOWN_SETTINGS");
  const onAboutPress = () => modalContext.setModal("DROPDOWN_ABOUT");
  const onCheckupPress = () => modalContext.setModal("DAILY_CHECKUP");

  const deleteCollections = async () => {
    await deleteCollection(
      getFirestore(),
      `UserData/${auth.currentUser.uid}/WeightLog/`
    );
    await deleteCollection(
      getFirestore(),
      `UserData/${auth.currentUser.uid}/CalorieLog/`
    );
    await deleteCollection(
      getFirestore(),
      `UserData/${auth.currentUser.uid}/FoodItems/`
    );
    await deleteCollection(
      getFirestore(),
      `UserData/${auth.currentUser.uid}/PhysicalItems/`
    );
    await deleteCollection(
      getFirestore(),
      `UserData/${auth.currentUser.uid}/FastLog/`
    );

    const plansSnapshot = await getDocs(
      collection(
        getFirestore(),
        `UserData/${auth.currentUser.uid}/WorkoutPlans/`
      )
    );

    const plansPromises = [];
    plansSnapshot.forEach(async (planDoc) => {
      const plan = planDoc.data();

      plansPromises.push(
        getDocs(
          collection(
            getFirestore(),
            `UserData/${auth.currentUser.uid}/WorkoutPlans/${plan.id}/PlanItems`
          )
        ).then((exercisesSnapsnot) => {
          if (!exercisesSnapsnot) {
            return;
          }

          const exercisePromises = [];
          exercisesSnapsnot.forEach(async (exerciseDoc) => {
            const exercise = exerciseDoc.data();
            const target = (exercise.order - 1).toString();

            exercisePromises.push(
              deleteCollection(
                getFirestore(),
                `UserData/${auth.currentUser.uid}/WorkoutPlans/${plan.id}/PlanItems/${target}/ItemHistory`
              )
            );
          });

          return Promise.all(exercisePromises);
        })
      );

      await deleteCollection(
        getFirestore(),
        `UserData/${auth.currentUser.uid}/WorkoutPlans/${plan.id}/PlanItems`
      );

      await deleteCollection(
        getFirestore(),
        `UserData/${auth.currentUser.uid}/WorkoutPlans/${plan.id}/PlanHistory`
      );
    });

    await Promise.all(plansPromises);

    await deleteCollection(
      getFirestore(),
      `UserData/${auth.currentUser.uid}/WorkoutPlans/`
    );
  };

  const deleteDataAction = async () => {
    const newUserData = { ...BASE_INIT_STATE, id: auth.currentUser.uid };

    await deleteCollections();
    await updateVal(newUserData, auth.currentUser.uid);

    modalContext.setModal(false);
    navigate("/basicinfo", { state: { isReset: true } });

    await Data.setReloadWorkoutPlans(true);
  };

  const deleteAccountAction = async () => {
    await deleteCollections().then(Data.setReloadWorkoutPlans(true));
    await deleteAccount(auth.currentUser.uid);
    modalContext.setModal(false);
    navigate("/");
  };

  const logoutButton = (
    <button className="wide-rectangle" onClick={onLogoutPress}>
      {shortenText(t("log-out"), LARGE_BTN_MAX_STRING, true, 0, true, true)}
    </button>
  );

  const settingsButton = (
    <button className="wide-rectangle" onClick={onSettingsPress}>
      {shortenText(t("dd-l"), LARGE_BTN_MAX_STRING, true, 0, true, true)}
    </button>
  );

  const aboutButton = (
    <button className="wide-rectangle" onClick={onAboutPress}>
      {shortenText(t("dd-r"), LARGE_BTN_MAX_STRING, true, 0, true, true)}
    </button>
  );

  const checkupButton = (
    <button className="wide-rectangle" onClick={onCheckupPress}>
      {shortenText(t("m-cu-title"), LARGE_BTN_MAX_STRING, true, 0, true, true)}
    </button>
  );

  const authedAndMainHeader = (
    <>
      <header className="header-container">
        <div className="header-title-container">
          <img src={weighzy} alt={"Weighzy"} />
          <DarkModeToggle />
        </div>
        <div className="header-button-container">
          {logoutButton}
          {settingsButton}
          {aboutButton}
          {checkupButton}
        </div>
      </header>
      <LogoutConfirmModal
        modalVisible={modalContext.modal === "LOGOUT_CONFIRM_MODAL"}
        setModalVisible={modalContext.setModal}
      />
      <DropDownModal
        userData={data?.userData}
        setUserData={data?.setUserData}
        dropDownModalVisible={modalContext.modal}
        setDropDownModalVisible={modalContext.setModal}
      />
      {modalContext.modal === "DELETE_ACCOUNT_CONFIRM" && (
        <ConfirmModal
          key={"ConfirmModal-DELETE_ACCOUNT_CONFIRM"}
          name={"ConfirmModal-DELETE_ACCOUNT_CONFIRM"}
          modalVisible={modalContext.modal === "DELETE_ACCOUNT_CONFIRM"}
          setModalVisible={modalContext.setModal}
          func={deleteAccountAction}
          nofunc={() => modalContext.setModal("DROPDOWN_SETTINGS")}
          question={`${t("m-dd-s-delete-a-body-p1")} ${t(
            "m-dd-s-delete-a-body-p2",
            {
              btnText: t("m-dd-s-delete-a-btn-l"),
            }
          )}`}
          yes={"m-dd-s-delete-a-btn-l"}
          no={"cancel"}
        />
      )}
      {modalContext.modal === "RESET_ACCOUNT_CONFIRM" && (
        <ConfirmModal
          key={"ConfirmModal-RESET_ACCOUNT_CONFIRM"}
          name={"ConfirmModal-RESET_ACCOUNT_CONFIRM"}
          modalVisible={modalContext.modal === "RESET_ACCOUNT_CONFIRM"}
          setModalVisible={modalContext.setModal}
          func={deleteDataAction}
          nofunc={() => modalContext.setModal("DROPDOWN_SETTINGS")}
          question={`${t("m-dd-s-reset-a-body-p1")}\n\n${t(
            "m-dd-s-reset-a-body-p2",
            {
              btnText: t("m-dd-s-reset-a-btn-l"),
            }
          )}`}
          yes={"m-dd-s-reset-a-btn-l"}
          no={"cancel"}
        />
      )}
      <GuideModal
        key={"GuideModal"}
        name={"GuideModal"}
        modalVisible={"GuideModal" === modalContext.modal}
        setModalVisible={modalContext.setModal}
        userData={data?.userData}
      />
      <WelcomeModal
        key={"WelcomeModal"}
        name={"WelcomeModal"}
        modalVisible={"WelcomeModal" === modalContext.modal}
        setModalVisible={modalContext.setModal}
        userData={data?.userData}
      />
      <CheckupModal
        modalVisible={modalContext.modal === "DAILY_CHECKUP"}
        setModalVisible={modalContext.setModal}
        userData={data?.userData}
        setUserData={data?.setUserData}
      />
    </>
  );

  const authedHeader = (
    <>
      <header>
        {logoutButton}
        <img src={weighzy} alt={"Weighzy"} />
        <DarkModeToggle />
      </header>
      <LogoutConfirmModal
        modalVisible={modalContext.modal === "LOGOUT_CONFIRM_MODAL"}
        setModalVisible={modalContext.setModal}
      />
    </>
  );

  const outsideHeader = (
    <header>
      <div className="spacer" />
      <img src={weighzy} alt={"Weighzy"} />
      <DarkModeToggle />
    </header>
  );

  return logout && main && data
    ? authedAndMainHeader
    : logout
    ? authedHeader
    : outsideHeader;
}
