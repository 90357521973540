import { useContext } from "react";
import { ThemeContext } from "../../Supports/Contexts";
import "./Section.css";

export default function Section({ header, children, growHeight = false }) {
  const theme = useContext(ThemeContext);
  return (
    <div
      className={`section ${theme.theme}${growHeight ? " grow-height" : ""}`}
    >
      {header && <div className="header">{header}</div>}
      {children}
    </div>
  );
}
