/* eslint-disable no-mixed-operators */
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  BASE_BLUE,
  BASE_GRAY,
  CARD_CONTENT_TITLE_STRING,
  SMALL_BTN_MAX_STRING,
  THEME_COLOR,
  THEME_GRAYSCALE,
} from "../../../Supports/Constants";
import { scaleTheme, shortenText } from "../../../Supports/Functions";
import "./CardContent.css";
import { ModalContext } from "../../../Supports/Contexts";

// The primary purpose of this component is to manage the designated "parts" of a card section.
// Intentions are to provide repeatible parts to a "visualization" top, a "context" middle, and an "interaction" bottom.
// Where the visualization part is allowed to handle its own custom design and logic.
// And the context and interaction are provided means to have combinations of dynamic buttons and text placements which
// React to the needs of the visualization parts.
export default function CardContent(props) {
  const { t } = useTranslation();
  const modalContext = useContext(ModalContext);

  let interactionsWeightDelta = props.interactions
    ? props.interactions.selector
    : null;

  const generateInteractionButtons = (areDisabled = false) => {
    switch (props.interactions.format) {
      case "butterfly-weight":
        return _generateButterflyFormat(
          props.interactions,
          props.customInteractionFuncs,
          props.interactionFunc,
          areDisabled
        );
      case "butterfly-goal":
        return _generateButterflyFormat(
          props.interactions,
          props.customInteractionFuncs,
          props.interactionFunc,
          areDisabled
        );
      case "standard":
        return _generateStandardFormat(
          props.interactions,
          props.customInteractionFuncs,
          props.interactionFunc,
          areDisabled
        );
      default:
        return _generateStandardFormat(
          props.interactions,
          props.customInteractionFuncs,
          props.interactionFunc,
          areDisabled
        );
    }
  };

  const _generateGenericButtons = (
    interactions,
    functions,
    defaultFunction,
    areDisabled = false
  ) => {
    const scaleByValue = true; // Else by Index.
    const subset = [];

    const buttonsHolder =
      typeof interactions.buttons === "function"
        ? interactions.buttons(interactions.step)
        : interactions.buttons;
    const len = buttonsHolder.length;

    const largestVal =
      scaleByValue &&
      Math.max(...buttonsHolder.map((o) => !isNaN(o.btnName) && o.btnName));

    for (let index = 0; index < len; index++) {
      let internalDisable = false;
      let internalHidden = false;
      let color;
      const btnColor = buttonsHolder[index].btnColor;
      const btnName = buttonsHolder[index].btnName;
      const name = typeof btnName === "function" ? btnName(props) : btnName;

      if (typeof btnColor === "function") {
        const location = buttonsHolder[index].enabledCheck;
        const hiddenCheck = buttonsHolder[index].hiddenCheck;
        const checkFunc = (func, left, right, expectation, type) => {
          if (type === "bool") {
            return {
              result: func(right === expectation),
              condition: right === expectation,
            };
          } else if (type === ">") {
            return {
              result: func(left > right === expectation),
              condition: left > right === expectation,
            };
          } else if (type === "<") {
            return {
              result: func(left < right === expectation),
              condition: left < right === expectation,
            };
          } else if (type === "===") {
            return {
              result: func((left === right) === expectation),
              condition: (left === right) === expectation,
            };
          } else {
            // !==
            return {
              result: func((left !== right) === expectation),
              condition: (left !== right) === expectation,
            };
          }
        };

        const checkLogic = (
          inBtnColor,
          check,
          expectation,
          checkType,
          inKey
        ) => {
          if (typeof check[inKey] === "function") {
            if (props[inKey] === null) {
              return false;
            } else {
              return checkFunc(
                inBtnColor,
                props[inKey],
                check[inKey](props[inKey]),
                expectation,
                checkType
              ).condition;
            }
          } else {
            return checkFunc(
              inBtnColor,
              props[inKey],
              check[inKey],
              expectation,
              checkType
            ).condition;
          }
        };

        if (hiddenCheck) {
          if (Array.isArray(hiddenCheck.check)) {
            let key;
            let rollingCheck = 0;
            for (let j = 0; j < hiddenCheck.check.length; j++) {
              key = Object.keys(hiddenCheck.check[j])[0];
              const check = hiddenCheck.check[j];
              const checkType = hiddenCheck.checkType[j];
              const expectation = hiddenCheck.expectation[j];

              const passed = checkLogic(
                btnColor,
                check,
                expectation,
                checkType,
                key
              );
              if (passed) {
                rollingCheck++;
              }
            }

            internalHidden = hiddenCheck.neverHide
              ? false
              : rollingCheck !== hiddenCheck.check.length;
          } else {
            const key = Object.keys(hiddenCheck.check)[0];

            internalHidden = hiddenCheck.neverHide
              ? false
              : checkLogic(
                  btnColor,
                  hiddenCheck.check,
                  hiddenCheck.expectation,
                  hiddenCheck.checkType,
                  key
                );
          }
        }

        if (location) {
          if (Array.isArray(location.check)) {
            let key;
            let rollingCheck = 0;
            for (let j = 0; j < location.check.length; j++) {
              key = Object.keys(location.check[j])[0];
              const check = location.check[j];
              const checkType = location.checkType[j];
              const expectation = location.expectation[j];
              const passed = checkLogic(
                btnColor,
                check,
                expectation,
                checkType,
                key
              );
              if (passed) {
                rollingCheck++;
              }
            }

            internalDisable = location.neverDisable
              ? false
              : rollingCheck !== location.check.length;
            color = btnColor(rollingCheck !== location.check.length);
          } else {
            const key = Object.keys(location.check)[0];

            internalDisable = location.neverDisable
              ? false
              : checkLogic(
                  btnColor,
                  location.check,
                  location.expectation,
                  location.checkType,
                  key
                );
            color = btnColor(
              checkLogic(
                btnColor,
                location.check,
                location.expectation,
                location.checkType,
                key
              )
            );
          }
        } else {
          color = btnColor();
        }
      } else if (
        interactions.theme === "undefined" ||
        (interactions.theme === THEME_GRAYSCALE &&
          (btnColor === BASE_GRAY || btnColor === "undefined"))
      ) {
        color = scaleTheme(THEME_GRAYSCALE, BASE_GRAY, len + 1, index + 1);
      } else if (
        btnColor === interactions.themeColor ||
        btnColor === "undefined"
      ) {
        color = scaleTheme(
          THEME_COLOR,
          interactions.themeColor,
          scaleByValue ? largestVal : len + 1,
          scaleByValue ? buttonsHolder[index].btnName : index + 1
        );
      } else {
        color = btnColor;
      }

      if (!internalHidden) {
        subset.push({
          btnName: name,
          value: buttonsHolder[index].value,
          btnColor: color,
          funcKey: buttonsHolder[index].funcKey,
          modalSettings: buttonsHolder[index].modalSettings,
          modal: buttonsHolder[index].modal,
          internalDisable: internalDisable,
        });
      }
    }

    const buttons = subset.map((btn) => {
      let funcToUse =
        functions && functions[btn.funcKey]
          ? functions[btn.funcKey]
          : defaultFunction
          ? defaultFunction
          : functions;

      // Can be an empty object, thus !== undefined. if(btn.modalSettings) === false if it is an empty obj.
      // All modal injections must have a modalSettings defined in its specification.
      let modalFunc = null;
      if (btn.modalSettings !== undefined) {
        modalFunc = funcToUse;
        funcToUse = () => {
          modalContext.setModal(btn.funcKey);
        };
      }

      return (
        <div
          key={"button" + btn.btnName + "-" + btn.funcKey ? btn.funcKey : ""}
        >
          <button
            className="card-content-button card-button-text"
            style={{
              backgroundColor: btn.btnColor ? btn.btnColor : BASE_GRAY,
            }}
            onClick={
              areDisabled ||
              props.disabledLogic ||
              btn.internalDisable ||
              !funcToUse
                ? null
                : funcToUse.bind(this, btn)
            }
          >
            {shortenText(
              t(
                typeof btn.btnName === "string"
                  ? btn.btnName.toLowerCase()
                  : btn.btnName
              ),
              SMALL_BTN_MAX_STRING,
              false
            )}
          </button>
          {btn.modal &&
            btn.modal({
              btn: btn,
              modalFunc: modalFunc,
              setModalVisible: modalContext.setModal,
              modalVisible: modalContext.modal,
              props: props,
              _generateGenericButtons: _generateGenericButtons,
            })}
        </div>
      );
    });

    return (
      // FUTURE: Assign unique id to container level.
      <div
        key={`button-container-${buttons.length}-first-${buttons[0].key}`}
        className="button-container noTopMargin noBottomMargin"
      >
        {buttons}
      </div>
    );
  };

  const generateContextInteractionButtons = (areDisabled = false) => {
    return _generateGenericButtons(
      props.contextInteractions,
      props.customContextFuncs,
      props.contextFunc,
      areDisabled
    );
  };

  const __generateButtonRange = (isPositive, spread, numOfButtons) => {
    const subset = [];
    for (let index = 1; index < numOfButtons + 1; index++) {
      const val = isPositive
        ? interactionsWeightDelta + index * spread
        : interactionsWeightDelta - index * spread;

      try {
        subset.push({
          btnName: val?.toFixed(1),
          value: val,
          btnColor: scaleTheme(THEME_GRAYSCALE, BASE_GRAY, numOfButtons, index),
        });
      } catch (e) {}
    }
    return subset;
  };

  const __generateSubset = (
    interactions,
    functions,
    defaultFunction,
    part,
    subset,
    areDisabled = false
  ) => {
    const buttons = subset.map((btn, index) => {
      let funcToUse =
        functions && functions[btn.funcKey]
          ? functions[btn.funcKey]
          : defaultFunction
          ? defaultFunction
          : functions;

      // Can be an empty object, thus !== undefined. if(btn.modalSettings) === false if it is an empty obj.
      // All modal injections must have a modalSettings defined in its specification.
      let modalFunc = null;

      if (btn.modalSettings !== undefined) {
        modalFunc = funcToUse;
        funcToUse = () => {
          modalContext.setModal(btn.funcKey);
        };
      }

      return (
        <>
          <button
            className="card-content-button-butterfly card-button-text"
            style={{
              backgroundColor: btn.btnColor ? btn.btnColor : BASE_GRAY,
            }}
            key={"pressable-" + btn.btnName + "-" + index}
            onClick={
              !areDisabled && funcToUse ? funcToUse.bind(this, btn) : null
            }
          >
            {shortenText(
              t(
                typeof btn.btnName === "string"
                  ? btn.btnName.toLowerCase()
                  : btn.btnName
              ),
              SMALL_BTN_MAX_STRING,
              false,
              0,
              true,
              true
            )}
          </button>
          {btn.modal &&
            btn.modal({
              btn: btn,
              modalFunc: modalFunc,
              setModalVisible: modalContext.setModal,
              modalVisible: modalContext.modal,
              props: props,
              _generateGenericButtons: _generateGenericButtons,
            })}
        </>
      );
    });

    return buttons;
  };

  const _generateButterflyFormat = (
    interactions,
    functions,
    defaultFunction,
    areDisabled = false
  ) => {
    const spread = interactions.step;

    interactionsWeightDelta =
      interactionsWeightDelta === 0
        ? interactions.defaultDelta
        : interactionsWeightDelta;

    const left = __generateButtonRange(false, spread, 6);

    interactions.buttons.center[0].current = interactionsWeightDelta;
    const center = [
      {
        btnName: interactionsWeightDelta,
        value: interactionsWeightDelta,
        btnColor: BASE_BLUE,
      },
    ].concat(interactions.buttons.center);

    const right = __generateButtonRange(true, spread, 6);

    return (
      <div className="butterfly-container">
        <div className="interaction-subset-left">
          {__generateSubset(
            interactions,
            functions,
            defaultFunction,
            "left",
            left,
            areDisabled
          )}
        </div>
        <div className="interaction-subset-center">
          {__generateSubset(
            interactions,
            functions,
            defaultFunction,
            "center",
            center,
            areDisabled
          )}
        </div>
        <div className="interaction-subset-right">
          {__generateSubset(
            interactions,
            functions,
            defaultFunction,
            "right",
            right,
            areDisabled
          )}
        </div>
      </div>
    );
  };

  const _generateStandardFormat = (
    interactions,
    functions,
    defaultFunction,
    areDisabled = false
  ) => {
    return _generateGenericButtons(
      interactions,
      functions,
      defaultFunction,
      areDisabled
    );
  };

  const generateContext = () => {
    const content = [];
    // if there is a disabled check and its asking for the context to be disabled.
    if (
      props.contextDisabledCheck &&
      props.contextDisabledCheck.check() === "removeButtons"
    ) {
      // if there is any additional text content, whether instead of, or after buttons.
      if (props.contextInfo) {
        content.push(
          <div key="contextText" className="card-text-small">
            {props.contextInfo}
          </div>
        );
      }
    } else if (
      props.contextDisabledCheck &&
      props.contextDisabledCheck.check()
    ) {
      content.push(
        <div key="disabledMessage" className="empty-text">
          {props.contextDisabledCheck.disabledMessage}
        </div>
      );
    } else {
      // There is no disabled check (therefore won't ever be disabled) or there is one, but it is enabled

      // if there are context buttons
      if (props.contextInteractions && props.contextInteractions.buttons) {
        content.push(
          generateContextInteractionButtons(
            props.contextDisabledCheck &&
              props.contextDisabledCheck.check() === "disableButtons"
          )
        );
      }
      // if there is any additional text content, whether instead of, or after buttons.
      if (props.contextInfo) {
        content.push(
          <div key="contextText" className="empty-text">
            {props.contextInfo}
          </div>
        );
      }
    }

    return (
      <div className="context-container">
        <div className="card-header-text-small-centered">
          {shortenText(
            typeof props.contextTitle === "function"
              ? props.contextTitle(props.latestItem)
              : props.contextTitle,
            CARD_CONTENT_TITLE_STRING
          )}
        </div>
        <div className="context-section">{content}</div>
      </div>
    );
  };

  const generateInteraction = () => {
    return (
      <div className="interaction-container">
        <div className="card-header-text-small-centered">
          {shortenText(
            typeof props.interactionTitle === "function"
              ? props.interactionTitle(props.latestItem)
              : props.interactionTitle,
            CARD_CONTENT_TITLE_STRING
          )}
        </div>
        <div>
          {props.interactionsDisabledCheck &&
          props.interactionsDisabledCheck.check() &&
          props.interactionsDisabledCheck.check() === true ? (
            <div className="empty-text">
              {props.interactionsDisabledCheck.disabledMessage}
            </div>
          ) : (
            generateInteractionButtons(
              props.interactionsDisabledCheck &&
                props.interactionsDisabledCheck.check() === "disableButtons"
            )
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="card-container container" onClick={() => {}}>
      <div className="visualization-container">
        {props.isVisualizationActive && props.visualizationType(props)}
      </div>
      {props.isContextActive && generateContext()}
      {props.interactions &&
        props.interactions.buttons &&
        generateInteraction()}
    </div>
  );
}
