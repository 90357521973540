import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import {
  BASE_ACCENT,
  BASE_GRAY,
  TABLE_DOUBLE_ROW_HEADER,
  TABLE_ROW_ITEM,
} from "../../../Supports/Constants";
import { shortenText } from "../../../Supports/Functions";
import FastInfo from "../../Modals/Fast/FastInfo";
import "./FastCard.css";

// Simple table-like display of fast stats.
export default function FastStats(props) {
  const { t } = useTranslation();
  const vd = props.visualizationData;

  const [page, setPage] = useState(0);
  const [selectedFast, setSelectedFast] = useState(null);

  const MAX_NUM_OF_LOGS = 4;
  const dataKeys = Object.keys(vd.fastLog).sort().reverse(); // Sort keys to be in date order, reversed.
  const logItems = dataKeys.map((key) => vd.fastLog[key]);

  const backPage = page > 0;
  const forwardPage =
    page <
    Math.floor(logItems.length / MAX_NUM_OF_LOGS) -
      (logItems.length % MAX_NUM_OF_LOGS === 0 ? 1 : 0);

  const formatDuration = (duration) => {
    if (isNaN(duration)) {
      return t("no-information-available-short");
    }
    const hdur = Math.floor(Math.abs(duration / 3600));
    const mdur = Math.floor(Math.abs((duration % 3600) / 60));
    const sdur = Math.floor(Math.abs(duration % 60));

    return hdur <= 0 && mdur <= 0
      ? `${sdur}${t("second-short")}`
      : hdur <= 0
      ? `${mdur}${t("minute-short")} ${sdur}${t("second-short")}`
      : `${hdur}${t("hour-short")} ${mdur}${t("minute-short")} ${sdur}${t(
          "second-short"
        )}`;
  };

  const statsRow = [];
  statsRow.push(
    <div key="statsHeader" className="logTableHeaderRow">
      <div className="logHeaderText column25">
        {shortenText(t("total-fasts"), 8, true, 0, true, true)}
      </div>
      <div className="logHeaderText column25">
        {shortenText(t("success-rate"), 8, true, 0, true, true)}
      </div>
      <div className="logHeaderText column25">
        {shortenText(t("average-duration"), 8, true, 0, true, true)}
      </div>
      <div className="logHeaderText column25">
        {shortenText(t("longest-fast"), 8, true, 0, true, true)}
      </div>
    </div>
  );

  const rowItemStyle = `logItemText selectedRowText column25`;

  statsRow.push(
    <div key="statsItems" className="logTableRow preppedRow">
      <div className={rowItemStyle}>
        {vd.stats?.total ? vd.stats.total : t("no-information-available-short")}
      </div>
      <div className={rowItemStyle}>
        {isNaN(vd.stats?.successRate) && vd.stats.total
          ? Math.floor((vd.stats.successes / vd.stats.total) * 100) + "%"
          : t("no-information-available-short")}
      </div>
      <div className={rowItemStyle}>
        {vd.stats?.avgDuration
          ? formatDuration(vd.stats.avgDuration)
          : t("no-information-available-short")}
      </div>
      <div className={rowItemStyle}>
        {vd.stats?.longest
          ? formatDuration(vd.stats.longest)
          : t("no-information-available-short")}
      </div>
    </div>
  );

  const tableFastsRowHeader = (
    <div key="tableHeader" className="logTableHeaderRow">
      <div className="logHeaderText column20">
        {shortenText(t("status"), TABLE_DOUBLE_ROW_HEADER, true, 0, true, true)}
      </div>
      <div className="logHeaderText column20">
        {shortenText(t("goal"), TABLE_DOUBLE_ROW_HEADER, true, 0, true, true)}
      </div>
      <div className="logHeaderText column20">
        {shortenText(
          t("duration-full"),
          TABLE_DOUBLE_ROW_HEADER,
          true,
          0,
          true,
          true
        )}
      </div>
      <div className="logHeaderText column20">
        {shortenText(t("start"), TABLE_DOUBLE_ROW_HEADER, true, 0, true, true)}
      </div>
      <div className="logHeaderText column20">
        {shortenText(t("end"), TABLE_DOUBLE_ROW_HEADER, true, 0, true, true)}
      </div>
    </div>
  );

  const generateTableItems = () => {
    const rows = [];
    for (
      let index = page * MAX_NUM_OF_LOGS;
      index < logItems.length &&
      index - page * MAX_NUM_OF_LOGS < MAX_NUM_OF_LOGS;
      index++
    ) {
      const logItem = logItems[index];

      const rowItemStyle = `logItemText column20 ${
        index === selectedFast ? "selectedRowText" : "selectableRowText"
      }`;

      const row = (
        <div
          key={`tableRow-${index}`}
          className={`logTableRow ${
            index === selectedFast ? "selectedRow" : "selectableRow"
          }`}
          onClick={() => {
            setSelectedFast(index);
            vd.setModalVisible("FT_FAST_INFO");
          }}
        >
          <div className={rowItemStyle}>
            {shortenText(
              logItem.successful
                ? t("completed")
                : logItem.status === "cancelled"
                ? t("cancelled")
                : t("ended-early"),
              TABLE_ROW_ITEM
            )}
          </div>
          <div className={rowItemStyle}>{`${logItem.goal}${t(
            "hour-short"
          )}`}</div>
          <div className={rowItemStyle}>
            {logItem.formattedDuration === "0s"
              ? t("no-information-available-short")
              : logItem.formattedDuration}
          </div>
          <div className={rowItemStyle}>{logItem.startDate}</div>
          <div className={rowItemStyle}>{logItem.endDate}</div>
        </div>
      );

      rows.push(row);
    }
    return rows;
  };

  const emptyStub = (
    <div key={"tableRow-stub"} className="logTableRowStub">
      <div className="logItemTextStub">{t("c-ftt-r-s2-body-stub")}</div>
    </div>
  );

  return (
    <div className="statsContainer">
      <div className="logTable">{statsRow}</div>
      <div className="TableArrowHeader">
        <button
          className="glyph-button"
          onClick={() => backPage && setPage(page - 1)}
        >
          <Icon
            path={mdiChevronLeft}
            size={1}
            style={{
              color: backPage ? BASE_ACCENT : BASE_GRAY,
            }}
          />
        </button>
        <div className="card-header-text-small-centered">
          {t("c-ftt-r-s2-head")}
        </div>
        <button
          className="glyph-button"
          onClick={() => forwardPage && setPage(page + 1)}
        >
          <Icon
            path={mdiChevronRight}
            size={1}
            style={{
              color: forwardPage ? BASE_ACCENT : BASE_GRAY,
            }}
          />
        </button>
      </div>
      <div className="logTable">
        {tableFastsRowHeader}
        {logItems?.length > 0 ? generateTableItems() : emptyStub}
      </div>

      {vd.modalVisible === "FT_FAST_INFO" && (
        <FastInfo
          key={"FT_FAST_INFO"}
          modalVisible={vd.modalVisible === "FT_FAST_INFO"}
          setModalVisible={vd.setModalVisible}
          isDarkTheme={vd.isDarkTheme}
          modalSettings={{
            itemInfo: logItems[selectedFast],
            setSelectedFast: setSelectedFast,
          }}
        />
      )}
    </div>
  );
}
