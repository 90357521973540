import React, { useCallback, useMemo } from "react";

import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";

import ChartTooltip from "../Workout/StatChartTooltip";
import {
  BASE_ACCENT,
  BASE_BLACK,
  BASE_GREEN,
  BASE_RED,
  BASE_WHITE,
  BASE_WHITE_DULLED,
} from "../../../Supports/Constants";
import {
  compareISODatetimes,
  converterDistances,
  converterUserWeight,
  getDate,
  getEarlierDate,
  getTodayDate,
} from "../../../Supports/Functions";
import { useTranslation } from "react-i18next";

export default function StatChart(props) {
  const vd = props.visualizationData;
  const { t } = useTranslation();

  const MAX_DATAPOINTS = 15; // The maximum number of datapoints displayed in the chart at onetime.
  // If the number of datapoints in the range is larger then the MAX_DATAPOINTS, then slim down set at regular intervals
  // to get general feel of changes over that span, attempting to maintain value to viewer while not overloading visuals.

  const blackList = [
    "trendLine-stats",
    // 'trendLine-prediction',
    // 'trendLine-goal',
  ];

  const DOMAIN_PADDING = 2;

  const filterRange = (
    preFilteredData,
    range,
    maxNumOfItems = MAX_DATAPOINTS
  ) => {
    let rangeBack;
    switch (range) {
      case "2W":
        rangeBack = 14;
        break;
      case "1M":
        rangeBack = 30; // Note: Some months are 29, 30, 31 days. Though we aren't saying just current month. Generic month.
        break;
      case "6M":
        rangeBack = 30 * 6; // Note: Some months are 29, 30, 31 days. "...". Generic 6 months.
        break;
      case "1Y":
        rangeBack = 365;
        break;
      case "3Y":
        rangeBack = 365 * 3;
        break;
      case "ALL":
        rangeBack = null;
        break;
      default:
        rangeBack = 14;
        break;
    }

    if (!rangeBack) {
      return preFilteredData;
    }

    const backDate = getEarlierDate(getTodayDate().json, rangeBack - 1);

    const finalData = preFilteredData
      .map((d, i) => {
        d.index = i;
        return d;
      })
      .filter((d) => compareISODatetimes(d.datestamp, backDate.ios));
    const savedToday = finalData.shift();

    if (finalData.length <= maxNumOfItems) {
      finalData.push(savedToday);
      return finalData;
    }

    let cullSpread = Math.ceil(finalData.length / maxNumOfItems);
    const culledData = [savedToday];
    let count = 0;

    if (cullSpread < 3) {
      // when the spread hits a poor rounding at 2, give it a bump down to a tighter cull to produce more datapoints.
      cullSpread = 1;
    }

    for (let i = 0; i < finalData.length; i++) {
      if (count < cullSpread) {
        count++;
      } else {
        finalData[i].date !== savedToday.date && culledData.push(finalData[i]);
        count = 0;
      }
    }

    return culledData.sort((a, b) =>
      compareISODatetimes(a.datetime, b.datetime)
    );
  };

  const buildStatTimeline = useCallback(() => {
    const stats = [];

    // Pad range around goal line for minimum and maximum.
    let maxStat = 0;
    let minStat = 1000;

    const filtered = filterRange(vd.history, vd.range);

    if (filtered.length === 0 || filtered === null) {
      return <></>;
    }

    for (let index = 0; index < filtered.length; index++) {
      const stat = filtered[index];
      const value =
        vd.statContext === "SET"
          ? stat.weight
          : vd.statContext === "DISTANCE"
          ? stat.distanceCompleted
          : stat.durationCompleted;

      const convertedValue =
        vd.statContext === "DISTANCE"
          ? converterDistances(stat.unit, value, vd.displayUnit)
          : vd.statContext === "SET"
          ? converterUserWeight(stat.unit, value, vd.displayUnit)
          : value;

      if (maxStat < convertedValue) {
        maxStat = convertedValue;
      }

      if (minStat > convertedValue) {
        minStat = convertedValue;
      }

      if (!isNaN(convertedValue)) {
        let lastStat = null;
        if (stats.index > 0) {
          const lastStatItem = vd.history[stats.index - 1] || null;
          const lastStatValue =
            vd.statContext === "SET"
              ? lastStatItem.weight
              : vd.statContext === "DISTANCE"
              ? lastStatItem.distanceCompleted
              : lastStatItem.durationCompleted;
          const lastConvertedValue =
            vd.statContext === "DISTANCE"
              ? converterDistances(
                  lastStatItem.unit,
                  lastStatValue,
                  vd.displayUnit
                )
              : vd.statContext === "SET"
              ? converterUserWeight(
                  lastStatItem.unit,
                  lastStatValue,
                  vd.displayUnit
                )
              : lastStatValue;
          lastStat = lastConvertedValue;
        }

        let nextStat;

        if (stat.status === "UP") {
          nextStat =
            convertedValue +
            vd.settings[
              vd.statContext === "DISTANCE"
                ? "progressionRateDistance"
                : vd.statContext === "SET"
                ? "progressionRateSet"
                : "progressionRateDuration"
            ];
        } else if (stat.status === "DOWN") {
          nextStat =
            convertedValue -
            vd.settings[
              vd.statContext === "DISTANCE"
                ? "progressionRateDistance"
                : vd.statContext === "SET"
                ? "progressionRateSet"
                : "progressionRateDuration"
            ];
        } else {
          nextStat = convertedValue;
        }

        stats.push({
          key: `stat-${index}`,
          y: parseFloat(
            convertedValue.toFixed(vd.statContext === "DISTANCE" ? 2 : 0)
          ),
          x: getDate(stat.datestamp).date,
          isDarkTheme: vd.isDarkTheme,
          labelStore: ` `, // There needs to be something inserted, empty character.
          lastStat: lastStat || null,
          nextStat: nextStat.toFixed(vd.statContext === "DISTANCE" ? 2 : 0),
          unit: vd.displayUnit,
          statContext: vd.statContext,
        });
      }
    }

    return {
      stats: stats,
      minStat: minStat,
      maxStat: maxStat,
    };
  }, [
    vd.displayUnit,
    vd.history,
    vd.isDarkTheme,
    vd.range,
    vd.settings,
    vd.statContext,
  ]);

  const visibleDataPoints = useMemo(() => {
    return buildStatTimeline();
  }, [buildStatTimeline]);

  if (!vd.history?.length) {
    return <></>;
  }

  return (
    <div className="chart">
      <VictoryChart
        containerComponent={
          <VictoryVoronoiContainer
            labels={({ datum }) => `${datum.labelStore}`}
            voronoiDimension="x"
            voronoiBlacklist={blackList}
            labelComponent={
              <VictoryTooltip flyoutComponent={<ChartTooltip />} />
            }
          />
        }
        padding={{
          top: 10,
          bottom: 25,
          left: 30,
          right: 20,
        }}
        minDomain={{
          y: Math.max(0, visibleDataPoints.minStat - DOMAIN_PADDING),
        }}
        maxDomain={{ y: visibleDataPoints.maxStat + DOMAIN_PADDING }}
        height={150}
      >
        <VictoryAxis
          style={{
            axis: { stroke: vd.isDarkTheme ? BASE_WHITE_DULLED : BASE_BLACK },
          }}
          tickFormat={(tf) => {
            try {
              const day = tf.split("/")[0];
              const month = tf.split("/")[1];
              // const year = tf.split('/')[2].slice(-2);
              return `${day}\n${month}`;
            } catch (error) {
              return "";
            }
          }}
          tickLabelComponent={
            <VictoryLabel
              angle={0}
              dy={-5}
              dx={1}
              textAnchor={"middle"}
              style={{
                fill: vd.isDarkTheme ? BASE_WHITE : BASE_BLACK,
                fontSize: 10,
                fontFamily: "Oswald-Regular",
              }}
            />
          }
        />
        <VictoryAxis
          style={{
            axis: { stroke: vd.isDarkTheme ? BASE_WHITE_DULLED : BASE_BLACK },
          }}
          dependentAxis
          tickFormat={(tf) =>
            `${tf.toFixed(
              vd.statContext === "SET"
                ? 0
                : vd.statContext === "DURATION"
                ? 0
                : 1
            )} ${t(vd.displayUnit)}`
          }
          tickCount={3}
          tickLabelComponent={
            <VictoryLabel
              dx={8}
              textAnchor={"end"}
              style={{
                fill: vd.isDarkTheme ? BASE_WHITE : BASE_BLACK,
                fontSize: 10,
                fontFamily: "Oswald-Regular",
              }}
            />
          }
        />
        {visibleDataPoints.stats && (
          <VictoryLine
            name="trendLine-stats"
            data={visibleDataPoints.stats}
            style={{
              data: {
                stroke: vd.isDarkTheme ? BASE_WHITE : BASE_BLACK,
                strokeWidth: vd.isDarkTheme ? 1 : 2,
                strokeDasharray: "1 2 1 2",
              },
            }}
          />
        )}

        <VictoryScatter
          data={visibleDataPoints.stats}
          size={({ datum }) =>
            datum.type === "user" ? 4 : vd.isDarkTheme ? 3 : 2
          }
          style={{
            data: {
              fill: ({ datum }) => {
                switch (datum.goalMet) {
                  case true:
                    return BASE_GREEN;
                  case false:
                    return BASE_RED;
                  default:
                    return BASE_ACCENT;
                }
              },
              stroke: vd.isDarkTheme ? BASE_WHITE : BASE_BLACK,
              strokeWidth: vd.isDarkTheme ? 1 : 2,
            },
          }}
        />
      </VictoryChart>
    </div>
  );
}
