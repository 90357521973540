/* eslint-disable no-sequences */
import React, { useCallback, useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import {
  ARROW_SYM,
  BASE_ACCENT,
  BASE_GRAY,
  BASE_LVL_ONE_DARK,
  BASE_WHITE,
  LARGE_BTN_MAX_STRING,
  POUNDS,
  QUESTION_MAX_STRING,
} from "../../../Supports/Constants";
import {
  compareISODatetimes,
  converterUserWeight,
  generateDurationProgressionRates,
  getTodayDate,
  shortenText,
} from "../../../Supports/Functions";
import {
  generateCalorieAnnotations,
  generatePhysicalAnnotations,
  generateScalarEntries,
  generateSortingEntries,
} from "../../../Supports/Templates";
import Icon from "@mdi/react";
import {
  mdiCalendarCursor,
  mdiCalendarRefresh,
  mdiChevronLeft,
  mdiChevronRight,
  mdiCloseThick,
  mdiDelete,
  mdiFood,
  mdiInformationOutline,
  mdiKeyboardReturn,
  mdiPercent,
  mdiPlus,
  mdiRunFast,
} from "@mdi/js";
import UnstyledSelectBasic from "../../../Components/Supports/UnstyledSelectBasic";
import "./CalorieModal.css";
import { InfoTooltip } from "../../Supports/InfoTooltip";
import { DataContext } from "../../../Supports/Contexts";

// This component handles the modal for adding to a custom calorie entry.
// Supports simple custom calories, as well as searching and prepping pre-made meal and activity entries.
// The user must open the manager to adjust entries.
export default function CalorieAddModal(props) {
  const { t } = useTranslation();
  const Data = useContext(DataContext);

  const type = props.modalSettings.unitType;
  const isFood = props.modalSettings.isFood;
  const activeItems = isFood ? Data.foodItems : Data.physicalItems;

  const basePresetFoods = props.foods.foods;
  const basePresetExercises = props.exercises.exercises;

  const [internalVisible, setInternalVisible] = useState(props.modalVisible);

  const ALL = 0;
  const MEALS_ONLY = 1;
  const [searchType, setSearchType] = useState(ALL); // 0 All, 1: Only Meals.

  const [toBeAddedPage, setToBeAddedPage] = useState(0);
  const [toBeAdded, setToBeAdded] = useState([]);

  const [selectedToBeAdded, setSelectedToBeAdded] = useState(null);
  const [selectedSearched, setSelectedSearched] = useState(null);

  const [baseSearchResults, setBaseSearchResults] = useState(() => {
    if (!activeItems) {
      return [];
    } else if (activeItems && Array.isArray(activeItems)) {
      return activeItems;
    } else if (activeItems && Object.keys(activeItems).length > 0) {
      return Object.values(activeItems);
    }
  });
  const [usedSearchResults, setUsedSearchResults] = useState(baseSearchResults);

  const [searchResultsPage, setSearchResultsPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortedBy, setSortedBy] = useState("recent");
  const [scalar, setScalar] = useState("Quantity not specified");

  const DEFAULT_DURATION = isFood ? null : 1800;
  const [duration, setDuration] = useState(DEFAULT_DURATION);

  const MAX_ITEMS_PER_PAGE = 6;

  const defaultSortingFunction = {
    name: "recent",
    nameTranslated: t("recent"),
    func: (a, b) => -compareISODatetimes(a.lastUsed, b.lastUsed),
  };

  const sortingFunctions = [
    defaultSortingFunction,
    {
      name: "newest",
      nameTranslated: t("newest"),
      func: (a, b) => -compareISODatetimes(a.datetime, b.datetime),
    },
    {
      name: "oldest",
      nameTranslated: t("oldest"),
      func: (a, b) => compareISODatetimes(a.datetime, b.datetime),
    },
    {
      name: `A ${ARROW_SYM} Z`, // ⮕
      nameTranslated: t(`A ${ARROW_SYM} Z`),
      func: (a, b) => a.name.localeCompare(b.name),
    },
    {
      name: `Z ${ARROW_SYM} A`,
      nameTranslated: t(`Z ${ARROW_SYM} A`),
      func: (a, b) => -1 * a.name.localeCompare(b.name),
    },
  ];

  const sortingFunctionsList = generateSortingEntries(sortingFunctions);

  const processFoodPreset = (fi, ind, startIndex, lookup) => {
    const sortedScalars = fi.s.sort((a, b) => {
      return b.g - a.g;
    });

    const caloriesPer100g = fi.n.find((n) => n.i === 1008).v;
    const caloriesPer1g = caloriesPer100g / 100;
    const minCalories = Math.round(
      sortedScalars[sortedScalars.length - 1]?.g * caloriesPer1g ||
        caloriesPer100g
    );
    const maxCalories = Math.round(
      sortedScalars[0]?.g * caloriesPer1g || caloriesPer100g
    );

    return {
      id: startIndex + ind,
      isPreset: true,
      name: fi.d,
      calories: caloriesPer100g,
      nutrients: fi.n,
      type: "foodItem",
      servingUnit: "100g", // Default.
      minCalories: minCalories,
      maxCalories: maxCalories,
      scalars: sortedScalars,
      lookup: lookup || null,
      tokensTriggered: fi.tokensTriggered,
    };
  };

  const calculateCaloriesExpended = useCallback(
    (exercise, durationInSeconds) => {
      const getMETCalc = (bodyLbs, metVal, seconds) => {
        return (bodyLbs / 2.20462) * metVal * 0.0175 * (seconds / 60);
      };

      const todayLog = props.currentWeight;
      const userWeightInLBs = converterUserWeight(
        todayLog.unit,
        todayLog.value,
        POUNDS
      );

      switch (exercise.category) {
        case "crossfit":
          return getMETCalc(userWeightInLBs, 5.6, durationInSeconds);
        case "stretching":
          return getMETCalc(userWeightInLBs, 2.3, durationInSeconds);
        case "plyometrics":
          return getMETCalc(userWeightInLBs, 8, durationInSeconds);
        case "cardio":
          return getMETCalc(userWeightInLBs, 8, durationInSeconds); // FUTURE distinguish between different cardios.
        case "strength":
          return getMETCalc(userWeightInLBs, 6, durationInSeconds);
        default:
          return getMETCalc(userWeightInLBs, 3.5, durationInSeconds);
      }
    },
    [props.currentWeight]
  );

  const processExercisePreset = useCallback(
    (ei, ind, startIndex, lookup) => {
      const caloriesPerMinute = Math.round(calculateCaloriesExpended(ei, 60));

      const baseAnnotes = generatePhysicalAnnotations();

      switch (ei.category) {
        case "crossfit":
          baseAnnotes.CARDIO = true;
          baseAnnotes.LIFT = true;
          break;
        case "stretching":
          baseAnnotes.PHYSIO = true;
          break;
        case "plyometrics":
        case "cardio":
          baseAnnotes.CARDIO = true;
          break;
        default:
          baseAnnotes.LIFT = true;
          break;
      }

      if (ei.name.toLowerCase().includes("walk")) {
        baseAnnotes.WALK = true;
      }

      return {
        ...ei,
        ...{
          lookup: ei.lookup || null,
          id: startIndex + ind,
          isPreset: true,
          calories: caloriesPerMinute,
          caloriesPerHalfHour: caloriesPerMinute * 30,
          type: "physicalItem",
          annotations: baseAnnotes,
          tokensTriggered: ei.tokensTriggered,
        },
      };
    },
    [calculateCaloriesExpended]
  );

  useEffect(() => {
    if (!internalVisible && props.modalVisible) {
      setInternalVisible(props.modalVisible);

      let tempBase;

      if (!activeItems) {
        tempBase = [];
      } else if (activeItems && Array.isArray(activeItems)) {
        tempBase = activeItems;
      } else if (activeItems && Object.keys(activeItems).length > 0) {
        tempBase = Object.values(activeItems);
      }

      const recentList =
        props.userData.calories.savedItems[isFood ? "food" : "physical"].recent;
      if (recentList.length > 0 && searchType === ALL) {
        const recents = recentList
          .map((li, i) => {
            return li.isPreset
              ? isFood
                ? processFoodPreset(basePresetFoods[li.lookup], i, 0, li.lookup)
                : processExercisePreset(
                    basePresetExercises[li.lookup],
                    i,
                    0,
                    li.lookup
                  )
              : tempBase?.find(
                  (ele) => ele.name === li.lookup || ele.key === li.lookup
                );
          })
          .filter((item) => item !== undefined);
        setUsedSearchResults(recents);
      } else {
        const customs = activeItems
          ? Object.keys(activeItems)
              .reduce(
                (res, key, ind) => (
                  (res[ind] = { ...activeItems[key], ...{ id: ind } }), res
                ),
                []
              )
              .filter((item) => item !== undefined)
          : [];
        setUsedSearchResults(customs);
      }
    }

    // to force a correct "recent" sort on load.
    if (usedSearchResults && (sortedBy === "recent" || sortedBy === null)) {
      Object.keys(usedSearchResults).forEach((k) => {
        try {
          usedSearchResults[k].lastUsed = activeItems[k].lastUsed;
        } catch (e) {} // undefined when a delete in manager happens
      });
    }

    // to stop search filtering from resetting immediately back to full list.
    if (props.hasNewCalorieItems && activeItems) {
      const customs = Object.keys(activeItems).reduce(
        (res, key, ind) => (
          (res[ind] = { ...activeItems[key], ...{ id: ind } }), res
        ),
        []
      );
      setBaseSearchResults(customs);
      props.setHasNewCalorieItems(false);
    }
  }, [
    activeItems,
    basePresetExercises,
    basePresetFoods,
    internalVisible,
    isFood,
    processExercisePreset,
    props,
    searchType,
    sortedBy,
    usedSearchResults,
  ]);

  const clear = () => {
    setToBeAdded([]);
    setScalar("Quantity not specified");
    setDuration(DEFAULT_DURATION);
    setSelectedSearched(null);
    setSearchQuery(""); // if this is done, when it reloads, the applied filter is still there on the set, yet the cue to why is not.
  };

  const save = () => {
    const preppedCollection = Object.values(toBeAdded).map((ci) => {
      if (!ci.isPreset || ci.type === "physicalItem") {
        return ci;
      }

      let nutrientsFormatted = ci.nutrients;

      if (isNaN(ci.nutrients.protein)) {
        nutrientsFormatted = {
          protein: ci.nutrients.find((n) => n.i === 1003)?.v || 0,
          carbs: ci.nutrients.find((n) => n.i === 1005)?.v || 0,
          fat: ci.nutrients.find((n) => n.i === 1004)?.v || 0,
          sugar: ci.nutrients.find((n) => n.i === 2000)?.v || 0,
          fiber: ci.nutrients.find((n) => n.i === 1079)?.v || 0,
          alcohol: ci.nutrients.find((n) => n.i === 1018)?.v || 0,
        };
      }
      const nutrientsScaled = nutrientsFormatted;
      // if (ci.isPreset) {
      //   nutrientsScaled = {
      //     carbs: Math.round(
      //       (nutrientsFormatted.carbs / 100) *
      //         (ci.scalars?.find((s) => s.n === ci.servingUnit).g || 1)
      //     ),
      //     protein: Math.round(
      //       (nutrientsFormatted.protein / 100) *
      //         (ci.scalars?.find((s) => s.n === ci.servingUnit).g || 1)
      //     ),
      //     fat: Math.round(
      //       (nutrientsFormatted.fat / 100) *
      //         (ci.scalars?.find((s) => s.n === ci.servingUnit).g || 1)
      //     ),
      //     sugar: Math.round(
      //       (nutrientsFormatted.sugar / 100) *
      //         (ci.scalars?.find((s) => s.n === ci.servingUnit).g || 1)
      //     ),
      //   };
      // }

      const MIN_G = 20;
      return {
        ...ci,
        ...{
          scalars: ci.scalars,
          nutrients: nutrientsScaled,
          annotations: {
            ...generateCalorieAnnotations(),
            ...{
              CARB: nutrientsFormatted.carbs >= MIN_G,
              PROT: nutrientsFormatted.protein >= MIN_G,
              FAT: nutrientsFormatted.fat >= MIN_G,
              SUGAR: nutrientsFormatted.sugar >= MIN_G,
            },
          },
        },
      };
    });

    props.func(preppedCollection);
    clear();
    setInternalVisible(false);
    props.setModalVisible(!props.modalVisible);
  };

  const dismissWithoutInputFunc = () => {
    clear();
    setInternalVisible(false);
    props.setModalVisible(false);
  };

  const generateItemTable = (
    tableID,
    itemsArr,
    tableHeader,
    page,
    setPage,
    actionTarget,
    setActionTarget,
    emptyStub,
    selectedTarget,
    setSelectedTarget,
    sortable = false
  ) => {
    let items = itemsArr;

    if (!items) {
      items = [];
    } else if (!Array.isArray(items)) {
      items = Object.keys(items).reduce(
        (res, key, ind) => (
          (res[ind] = { ...items[key], ...{ id: ind } }), res
        ),
        []
      );
    }

    items = items.filter((it) => it);

    const backPage = page > 0;
    const forwardPage =
      page <
      Math.floor(items.length / MAX_ITEMS_PER_PAGE) -
        (items.length % MAX_ITEMS_PER_PAGE === 0 ? 1 : 0);

    const header = (
      <div className="TableArrowHeader">
        <button
          className="glyph-button"
          onClick={() => backPage && setPage(page - 1)}
        >
          <Icon
            path={mdiChevronLeft}
            size={1}
            style={{
              color: backPage
                ? props.isDarkTheme
                  ? BASE_WHITE
                  : BASE_ACCENT
                : props.isDarkTheme
                ? BASE_LVL_ONE_DARK
                : BASE_GRAY,
            }}
          />
        </button>
        <div className="card-header-text-small-centered">
          {shortenText(tableHeader, 16, true, 0, true, true)}
        </div>
        <button
          className="glyph-button"
          onClick={() => forwardPage && setPage(page + 1)}
        >
          <Icon
            path={mdiChevronRight}
            size={1}
            style={{
              color: forwardPage
                ? props.isDarkTheme
                  ? BASE_WHITE
                  : BASE_ACCENT
                : props.isDarkTheme
                ? BASE_LVL_ONE_DARK
                : BASE_GRAY,
            }}
          />
        </button>
      </div>
    );

    const tableRows = [];

    if (items.length <= 0) {
      return (
        <div className="logTable">
          {header}
          <div className="logTableRowStub stub unselectedRow">
            <div className="logItemText logItemTextStubPlan">{emptyStub}</div>
          </div>
        </div>
      );
    }

    if (sortable && searchType === MEALS_ONLY && items.length > 0) {
      const sortFunc =
        sortingFunctions.filter((sf) => sf.name === sortedBy)[0]?.func ||
        defaultSortingFunction.func;

      items.sort(sortFunc);
    }

    tableRows.push(
      <div className="logTableHeaderRow" key="tableHeader">
        <div className="logHeaderText column70">{t("name")}</div>
        <div className="logHeaderText column30">{t("calories")}</div>
      </div>
    );

    const canBeMultiAdded = tableID === "searchResults";

    for (
      let index = page * MAX_ITEMS_PER_PAGE;
      index < items.length &&
      index - page * MAX_ITEMS_PER_PAGE < MAX_ITEMS_PER_PAGE;
      index++
    ) {
      const item = items[index];
      const row = (
        <button
          className={`logTableRow ${
            selectedTarget &&
            (item.isPreset
              ? selectedTarget.id === item.name
              : selectedTarget.id === item.key)
              ? "selectedRow"
              : "unselectedRow"
          }`}
          key={`tableRow-${index}`}
          onClick={() => {
            const prom = new Promise((res, rej) => {
              const newSel =
                selectedTarget &&
                (item.isPreset
                  ? selectedTarget.id === item.name
                  : selectedTarget.id === item.key)
                  ? null
                  : {
                      ...item,
                      ...{ id: item.isPreset ? item.name : item.key },
                    };
              setSelectedTarget(newSel);

              res(newSel);
            });

            prom.then((newSel) => {
              tableID === "searchResults" &&
                newSel?.scalars &&
                isFood &&
                setScalar(newSel.scalars[0].n);
            });
          }}
        >
          <div
            className={`logItemText column70 ${
              selectedTarget &&
              (item.isPreset
                ? selectedTarget.id === item.name
                : selectedTarget.id === item.key)
                ? "selectedRowText"
                : "unselectedRowText"
            }`}
          >{`${shortenText(
            item?.name || t("unnamed"),
            40,
            true,
            0,
            false,
            false
          )}`}</div>
          <div
            className={`logItemText column30 ${
              selectedTarget &&
              (item.isPreset
                ? selectedTarget.id === item.name
                : selectedTarget.id === item.key)
                ? "selectedRowText"
                : "unselectedRowText"
            }`}
          >
            {tableID === "toBeAdded"
              ? `${item.calories}kcal`
              : item.minCalories && item.maxCalories
              ? `${item.minCalories} — ${item.maxCalories}`
              : `${
                  item.isPreset && !isFood
                    ? item.caloriesPerHalfHour
                    : item.calories
                }${t("kcal")}/${
                  isFood
                    ? item.servingUnit === "Meal"
                      ? t(item.servingUnit)[0].toUpperCase() +
                        t(item.servingUnit).slice(1)
                      : item.servingUnit
                    : item.isPreset
                    ? `30${t("minute-short")}`
                    : t("activity")
                }`}
          </div>
        </button>
      );
      tableRows.push(row);
    }

    const hasSelected = selectedTarget;

    let scalarList = [];
    let durationList = [];
    if (isFood && hasSelected) {
      scalarList = generateScalarEntries(selectedTarget.scalars);
    } else if (!isFood && hasSelected) {
      durationList = generateDurationProgressionRates(t, 30, 18000, 30);
    }

    return (
      <div className="logTable">
        {header}
        {tableRows}
        {
          <div className="popUpSections">
            <div className="actionNameContainer">
              <div className="warningText">
                {shortenText(
                  selectedTarget?.name || t("nothing-selected"),
                  100,
                  true,
                  0
                )}
              </div>
            </div>
            {tableID === "searchResults" &&
              hasSelected &&
              hasSelected.isPreset && (
                <div className="sectionItemRow">
                  <div className="card-text-small">{`${shortenText(
                    isFood ? t("serving") : t("duration-full"),
                    QUESTION_MAX_STRING,
                    true,
                    1
                  )}:`}</div>
                  <div className="card-text-small">{`${
                    selectedTarget.isPreset
                      ? isFood
                        ? Math.round(
                            (selectedTarget.calories / 100) *
                              selectedTarget.scalars.find((s) => s.n === scalar)
                                ?.g
                          )
                        : Math.round((selectedTarget.calories / 60) * duration)
                      : selectedTarget.calories
                  }${t("kcal")}`}</div>
                  <div className="endRow">
                    <div className="scalerButton">
                      <UnstyledSelectBasic
                        controlled
                        options={isFood ? scalarList : durationList}
                        defaultOption={isFood ? scalar : duration}
                        onChange={(_, v) =>
                          isFood ? setScalar(v) : setDuration(v)
                        }
                      />
                    </div>
                  </div>
                </div>
              )}

            <div className="sectionItemRow">
              <div className="card-text-small">{`${shortenText(
                t("actions-i18n"),
                QUESTION_MAX_STRING,
                true,
                1
              )}:`}</div>
              <div className="endRow">
                <div className="button-container nowrap noTopMargin noBottomMargin">
                  <button
                    className={`row-glyph-button ${
                      !hasSelected
                        ? "disabled"
                        : !canBeMultiAdded
                        ? "danger"
                        : "new"
                    }`}
                    onClick={() => {
                      if (hasSelected) {
                        if (tableID === "toBeAdded") {
                          setActionTarget(
                            actionTarget.filter((inItem) =>
                              selectedTarget.isPreset
                                ? selectedTarget.id !== inItem.name
                                : selectedTarget.id !== inItem.key
                            )
                          );

                          setSelectedTarget(null);
                        } else {
                          const newId = actionTarget.length;

                          let newItem;

                          if (isFood) {
                            newItem = {
                              ...selectedTarget,
                              ...{
                                id: newId,
                                calories: selectedTarget.isPreset
                                  ? Math.round(
                                      (selectedTarget.calories / 100) *
                                        (selectedTarget.scalars?.find(
                                          (s) => s.n === scalar
                                        ).g || 1)
                                    )
                                  : selectedTarget.calories,
                                nutrients: isNaN(
                                  selectedTarget.nutrients.protein
                                )
                                  ? {
                                      protein: parseInt(
                                        selectedTarget.nutrients
                                          .find((n) => n.i === 1003)
                                          ?.v.toFixed(0) || 0
                                      ),
                                      carbs: parseInt(
                                        selectedTarget.nutrients
                                          .find((n) => n.i === 1005)
                                          ?.v.toFixed(0) || 0
                                      ),
                                      fat: parseInt(
                                        selectedTarget.nutrients
                                          .find((n) => n.i === 1004)
                                          ?.v.toFixed(0) || 0
                                      ),
                                      sugar: parseInt(
                                        selectedTarget.nutrients
                                          .find((n) => n.i === 2000)
                                          ?.v.toFixed(0) || 0
                                      ),
                                      fiber: parseInt(
                                        selectedTarget.nutrients
                                          .find((n) => n.i === 1079)
                                          ?.v.toFixed(0) || 0
                                      ),
                                      alcohol: parseInt(
                                        selectedTarget.nutrients
                                          .find((n) => n.i === 1018)
                                          ?.v.toFixed(0) || 0
                                      ),
                                    }
                                  : selectedTarget.nutrients,
                                servingUnit: selectedTarget.isPreset
                                  ? scalar
                                  : selectedTarget.servingUnit,
                                servingSize: selectedTarget.isPreset
                                  ? 1
                                  : selectedTarget.servingSize,
                              },
                            };
                          } else {
                            newItem = {
                              ...selectedTarget,
                              ...{
                                id: newId,
                                calories: selectedTarget.isPreset
                                  ? Math.round(
                                      (selectedTarget.calories / 60) * duration
                                    )
                                  : selectedTarget.calories,
                              },
                            };
                          }

                          setActionTarget(actionTarget.concat(newItem));
                        }
                      }
                    }}
                  >
                    <Icon
                      path={!canBeMultiAdded ? mdiDelete : mdiPlus}
                      size={1}
                    />
                  </button>
                  {tableID !== "toBeAdded" && (
                    <button
                      className={`row-glyph-button ${
                        !hasSelected ? "disabled" : "special"
                      }`}
                      onClick={() => {
                        if (hasSelected) {
                          setInternalVisible(false);
                          const newId = actionTarget.length;

                          let newItem;

                          if (isFood) {
                            newItem = {
                              ...selectedTarget,
                              ...{
                                id: newId,
                                calories: selectedTarget.isPreset
                                  ? Math.round(
                                      (selectedTarget.calories / 100) *
                                        (selectedTarget.scalars?.find(
                                          (s) => s.n === scalar
                                        ).g || 1)
                                    )
                                  : selectedTarget.calories,
                                servingUnit: selectedTarget.isPreset
                                  ? scalar
                                  : selectedTarget.servingUnit,
                              },
                            };
                          } else {
                            newItem = {
                              ...selectedTarget,
                              ...{
                                id: newId,
                                calories: selectedTarget.isPreset
                                  ? Math.round(
                                      (selectedTarget.calories / 60) * duration
                                    )
                                  : selectedTarget.calories,
                              },
                            };
                          }

                          props.setPercentItemTarget({
                            item: newItem,
                            callback: (item) => {
                              setActionTarget(actionTarget.concat(item));
                            },
                          });
                          props.setModalVisible("CT_MULTI_ITEM");
                        }
                      }}
                    >
                      <Icon path={mdiCloseThick} size={1} />
                    </button>
                  )}
                  {tableID !== "toBeAdded" && (
                    <button
                      className={`row-glyph-button ${
                        !hasSelected ? "disabled" : "warning"
                      }`}
                      onClick={() => {
                        if (hasSelected) {
                          setInternalVisible(false);
                          const newId = actionTarget.length;

                          let newItem;

                          if (isFood) {
                            newItem = {
                              ...selectedTarget,
                              ...{
                                id: newId,
                                calories: selectedTarget.isPreset
                                  ? Math.round(
                                      (selectedTarget.calories / 100) *
                                        (selectedTarget.scalars?.find(
                                          (s) => s.n === scalar
                                        ).g || 1)
                                    )
                                  : selectedTarget.calories,
                                servingUnit: selectedTarget.isPreset
                                  ? scalar
                                  : selectedTarget.servingUnit,
                              },
                            };
                          } else {
                            newItem = {
                              ...selectedTarget,
                              ...{
                                id: newId,
                                calories: selectedTarget.isPreset
                                  ? Math.round(
                                      (selectedTarget.calories / 60) * duration
                                    )
                                  : selectedTarget.calories,
                              },
                            };
                          }

                          props.setPercentItemTarget({
                            item: newItem,
                            callback: (item) => {
                              setActionTarget(actionTarget.concat(item));
                            },
                          });
                          props.setModalVisible("CT_PERCENT_ITEM");
                        }
                      }}
                    >
                      <Icon path={mdiPercent} size={1} />
                    </button>
                  )}
                  <button
                    className={`row-glyph-button ${
                      !hasSelected ? "disabled" : "accent"
                    }`}
                    onClick={() => {
                      if (hasSelected) {
                        setInternalVisible(false);

                        let newItem;
                        if (isFood) {
                          newItem = selectedTarget.isPreset
                            ? {
                                ...selectedTarget,
                                ...{
                                  nutrients: isNaN(
                                    selectedTarget.nutrients.protein
                                  )
                                    ? {
                                        protein: parseInt(
                                          selectedTarget.nutrients
                                            .find((n) => n.i === 1003)
                                            ?.v.toFixed(0) || 0
                                        ),
                                        carbs: parseInt(
                                          selectedTarget.nutrients
                                            .find((n) => n.i === 1005)
                                            ?.v.toFixed(0) || 0
                                        ),
                                        fat: parseInt(
                                          selectedTarget.nutrients
                                            .find((n) => n.i === 1004)
                                            ?.v.toFixed(0) || 0
                                        ),
                                        sugar: parseInt(
                                          selectedTarget.nutrients
                                            .find((n) => n.i === 2000)
                                            ?.v.toFixed(0) || 0
                                        ),
                                        fiber: parseInt(
                                          selectedTarget.nutrients
                                            .find((n) => n.i === 1079)
                                            ?.v.toFixed(0) || 0
                                        ),
                                        alcohol: parseInt(
                                          selectedTarget.nutrients
                                            .find((n) => n.i === 1018)
                                            ?.v.toFixed(0) || 0
                                        ),
                                      }
                                    : selectedTarget.nutrients,
                                  servingUnit: selectedTarget.isPreset
                                    ? scalar
                                    : selectedTarget.servingUnit,
                                  servingSize: selectedTarget?.servingSize || 1,
                                },
                              }
                            : selectedTarget;
                        } else {
                          newItem = selectedTarget;
                          newItem.origin = "CUSTOM_PHYSICAL_CALORIES";
                        }

                        props.setItemManagementTarget(newItem);
                        props.setModalVisible(
                          isFood || !newItem?.instructions
                            ? "CT_INFO_ADD"
                            : "CT_ACTIVE_INFO"
                        );
                        setSelectedTarget(null);
                        setSearchQuery("");
                      }
                    }}
                  >
                    <Icon path={mdiInformationOutline} size={1} />
                  </button>
                  <button
                    className={`row-glyph-button  ${
                      !hasSelected ? "disabled" : "info"
                    }`}
                    onClick={() => {
                      if (hasSelected) {
                        setInternalVisible(false);

                        let newItem;
                        if (isFood) {
                          newItem = {
                            ...selectedTarget,
                            ...{
                              nutrients: isNaN(selectedTarget.nutrients.protein)
                                ? {
                                    protein: parseInt(
                                      selectedTarget.nutrients
                                        .find((n) => n.i === 1003)
                                        ?.v.toFixed(0) || 0
                                    ),
                                    carbs: parseInt(
                                      selectedTarget.nutrients
                                        .find((n) => n.i === 1005)
                                        ?.v.toFixed(0) || 0
                                    ),
                                    fat: parseInt(
                                      selectedTarget.nutrients
                                        .find((n) => n.i === 1004)
                                        ?.v.toFixed(0) || 0
                                    ),
                                    sugar: parseInt(
                                      selectedTarget.nutrients
                                        .find((n) => n.i === 2000)
                                        ?.v.toFixed(0) || 0
                                    ),
                                    fiber: parseInt(
                                      selectedTarget.nutrients
                                        .find((n) => n.i === 1079)
                                        ?.v.toFixed(0) || 0
                                    ),
                                    alcohol: parseInt(
                                      selectedTarget.nutrients
                                        .find((n) => n.i === 1018)
                                        ?.v.toFixed(0) || 0
                                    ),
                                  }
                                : selectedTarget.nutrients,
                              servingUnit: selectedTarget.isPreset
                                ? scalar
                                : selectedTarget.servingUnit,
                              servingSize: selectedTarget?.servingSize || 1,
                            },
                          };
                        } else {
                          newItem = selectedTarget;
                        }

                        props.setItemManagementTarget(newItem);
                        props.setModalVisible(
                          isFood
                            ? "CT_ITEM_MANAGER_MEAL"
                            : "CT_ITEM_MANAGER_ACTIVITY"
                        );
                        setSelectedTarget(null);
                        setSearchQuery("");
                      }
                    }}
                  >
                    <Icon path={isFood ? mdiFood : mdiRunFast} size={1} />
                  </button>
                </div>
                <InfoTooltip text={t("info-actions")} />
              </div>
            </div>
          </div>
        }
      </div>
    );
  };

  // FUTURE: Improve search tokenization, perhaps use offtheshelf
  const updateSearchItems = (text, typeOverload) => {
    setSelectedSearched(null);
    setSearchResultsPage(0);
    setSearchQuery(text);

    text = text.trim();
    const stlc = text.toLowerCase();
    const stlcTokens = stlc.split(" ");

    const searchController = !isNaN(typeOverload) ? typeOverload : searchType;

    if (text === "" || text === undefined || text === null) {
      const recentList = isFood
        ? props.recentFoodItems
        : props.recentPhysicalItems;
      if (recentList && searchController === ALL) {
        const recents = recentList
          .map((li, i) => {
            return li.isPreset
              ? isFood
                ? processFoodPreset(basePresetFoods[li.lookup], i, 0, li.lookup)
                : processExercisePreset(
                    basePresetExercises[li.lookup],
                    i,
                    0,
                    li.lookup
                  )
              : baseSearchResults?.find((ele) => ele.name === li.lookup);
          })
          .filter((item) => item !== undefined);

        setUsedSearchResults(recents);
      } else {
        const customs = activeItems
          ? Object.keys(activeItems)
              .reduce(
                (res, key, ind) => (
                  (res[ind] = { ...activeItems[key], ...{ id: ind } }), res
                ),
                []
              )
              .filter((item) => item !== undefined)
          : [];

        setUsedSearchResults(customs);
      }
    } else {
      const customs = activeItems
        ? Object.keys(activeItems)
            .filter((k) => activeItems[k].name.toLowerCase().includes(stlc))
            .reduce(
              (res, key, ind) => (
                (res[ind] = { ...activeItems[key], ...{ id: ind } }), res
              ),
              []
            )
        : [];

      if (searchController === ALL) {
        let presets;

        if (isFood) {
          presets = basePresetFoods
            .map((be, i) => {
              const triggeredTokens = be.tokens.filter((token) =>
                stlcTokens.some((inputToken) =>
                  token.toLowerCase().includes(inputToken)
                )
              );

              return {
                ...be,
                ...{ lookup: i, tokensTriggered: triggeredTokens.length },
              };
            })
            .filter(
              (f) =>
                f.d.toLowerCase().includes(stlc) ||
                (f.tokens &&
                  stlcTokens &&
                  f.tokens.some((token) =>
                    stlcTokens.includes(token.toLowerCase())
                  ))
            )
            .map((f, i) => processFoodPreset(f, i, customs.length, f.lookup));
        } else {
          presets = basePresetExercises
            .map((be, i) => {
              const triggeredTokens = be.tokens.filter((token) =>
                stlcTokens.some((inputToken) =>
                  token.toLowerCase().includes(inputToken)
                )
              );

              return {
                ...be,
                ...{ lookup: i, tokensTriggered: triggeredTokens.length },
              };
            })
            .filter((staticData) => {
              return (
                staticData.name.toLowerCase().includes(stlc) ||
                (staticData.tokens &&
                  stlcTokens &&
                  staticData.tokens.some((token) =>
                    stlcTokens.includes(token.toLowerCase())
                  )) ||
                (staticData.equipment &&
                  staticData.equipment.toLowerCase().includes(stlc)) ||
                (staticData.category &&
                  staticData.category.toLowerCase().includes(stlc)) ||
                (staticData.mechanic &&
                  staticData.mechanic.toLowerCase().includes(stlc).length >
                    0) ||
                (
                  staticData.primaryMuscles &&
                  staticData.primaryMuscles.filter((m) =>
                    m.toLowerCase().includes(stlc)
                  )
                ).length > 0 ||
                (
                  staticData.secondaryMuscles &&
                  staticData.secondaryMuscles.filter((m) =>
                    m.toLowerCase().includes(stlc)
                  )
                ).length > 0
              );
            })
            .map((e, i) =>
              processExercisePreset(e, i, customs.length, e.lookup)
            );
        }

        setUsedSearchResults(
          customs.concat(
            presets.sort((a, b) => {
              if (
                !isNaN(a.tokensTriggered) &&
                !isNaN(b.tokensTriggered) &&
                a.tokensTriggered !== b.tokensTriggered
              ) {
                return b.tokensTriggered - a.tokensTriggered;
              }
              return a.name.length - b.name.length;
            })
          )
        );
      } else {
        setUsedSearchResults(customs);
      }
    }

    // setSelectedSearched(usedSearchResults[0]);
  };

  const generateSearchBar = () => {
    return (
      <div className="sectionItemRow">
        <div className="button-container nowrap noTopMargin noBottomMargin">
          <button
            className={`small-rectangle ${
              searchType === ALL ? "accent" : "disabled"
            }`}
            onClick={() => {
              setSearchType(ALL);
              updateSearchItems(searchQuery, ALL);
            }}
          >
            <div className="card-button-text">{t("all")}</div>
          </button>
          <button
            className={`small-rectangle ${
              searchType === MEALS_ONLY ? "accent" : "disabled"
            }`}
            onClick={() => {
              setSearchType(MEALS_ONLY);
              updateSearchItems(searchQuery, MEALS_ONLY);
            }}
          >
            <div className="card-button-text">
              {isFood ? t("meals") : t("plans")}
            </div>
          </button>
        </div>
        <input
          type="search"
          onChange={(e) => updateSearchItems(e.target.value)}
          placeholder={
            isFood ? t("stub-search-food") : t("stub-search-activities")
          }
          className="input-wide"
        />
        <div className="endRow">
          <InfoTooltip
            text={
              isFood ? t("info-searching-food") : t("info-searching-activities")
            }
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      isOpen={internalVisible}
      onRequestClose={dismissWithoutInputFunc}
      animationType="none"
      appElement={document.getElementById("root") || undefined}
    >
      <div className="card-header-text-small-centered">{`${t("add")} ${t(
        type
      )}`}</div>
      <div className="customCalorieContainer">
        {generateSearchBar()}

        {searchType === MEALS_ONLY && (
          <div className="sectionItemRow">
            <div className="card-text-small">{`${t("sort")}:`}</div>
            <div className="endRow">
              <div className="sortButton">
                <UnstyledSelectBasic
                  options={sortingFunctionsList}
                  defaultOption={sortedBy}
                  onChange={(_, v) => setSortedBy(v)}
                />
              </div>
            </div>
          </div>
        )}

        {generateItemTable(
          "searchResults",
          usedSearchResults,
          `${usedSearchResults ? Object.keys(usedSearchResults).length : 0} ${
            isFood ? t("header-foods-found") : t("activities-found")
          }`,
          searchResultsPage,
          setSearchResultsPage,
          toBeAdded,
          setToBeAdded,
          isFood ? t("stub-search-food") : t("stub-search-activities"),
          selectedSearched,
          setSelectedSearched,
          searchType !== ALL
        )}
      </div>
      <div className="customCalorieContainer">
        <div className="dateHeader">
          <button
            className="glyph-button glyph-button-text-row"
            onClick={() => {
              const today = getTodayDate();
              if (today.date !== props.activeDate.date) {
                props.changeDate(today);
              }
            }}
          >
            <div className="card-header-text-smaller navLabel">
              {shortenText(
                t("c-clt-vis-backtotoday"),
                LARGE_BTN_MAX_STRING,
                true,
                0,
                true,
                true
              )}
            </div>
            <Icon path={mdiCalendarRefresh} size={1} />
          </button>

          <div className="innerActiveDate">
            <button
              className="glyph-button"
              onClick={() => props.changeDate(props.previousDate)}
            >
              <Icon path={mdiChevronLeft} size={1} />
            </button>

            <div className="card-header-text-small-centered currentDateText">
              {props.activeDate.date}
            </div>
            <button
              className="glyph-button"
              onClick={() => props.changeDate(props.nextDate)}
            >
              <Icon path={mdiChevronRight} size={1} />
            </button>
          </div>
          <button
            className="glyph-button glyph-button-text-row"
            onClick={() => {
              const promise = new Promise((res) => {
                props.setModalVisible(false);
                setInternalVisible(false);
                setTimeout(() => {
                  res();
                }, 0);
              });

              promise.then(() => {
                props.setReturnFromCalToAdd(
                  isFood ? "CUSTOM_FOOD_CALORIES" : "CUSTOM_PHYSICAL_CALORIES"
                );
                props.setModalVisible("CT_CALENDAR");
              });
            }}
          >
            <div className="card-header-text-smaller navLabel">
              {shortenText(
                t("c-clt-vis-selectadate"),
                LARGE_BTN_MAX_STRING,
                true,
                0,
                true,
                true
              )}
            </div>
            <Icon path={mdiCalendarCursor} size={1} />
          </button>
        </div>
        {generateItemTable(
          "toBeAdded",
          toBeAdded,
          `${
            Object.keys(toBeAdded).length > 0
              ? Object.keys(toBeAdded).length
              : ""
          } ${
            isFood ? t("header-foods-to-be-added") : t("activities-to-be-added")
          }`,
          toBeAddedPage,
          setToBeAddedPage,
          toBeAdded,
          setToBeAdded,
          isFood
            ? t("stub-empty-to-be-added-foods")
            : t("stub-empty-to-be-added-activities"),
          selectedToBeAdded,
          setSelectedToBeAdded
        )}
      </div>
      <div className="button-container nowrap noTopMargin noBottomMargin">
        <button
          className="large-rectangle danger"
          onClick={dismissWithoutInputFunc}
        >
          <Icon path={mdiKeyboardReturn} size={1} />
          <div className="card-button-text">
            {shortenText(t("close"), LARGE_BTN_MAX_STRING, true, 0, true, true)}
          </div>
        </button>
        <button
          className={`large-rectangle ${
            toBeAdded.length <= 0 ? "disabled" : "new"
          }`}
          onClick={() => toBeAdded.length > 0 && save()}
        >
          <Icon path={mdiPlus} size={1} />
          <div className="card-button-text">
            {shortenText(
              t("add-selected-to-log"),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </div>
        </button>
        <button
          className="large-rectangle info"
          onClick={() => {
            setInternalVisible(false);
            props.setModalVisible(
              isFood ? "CT_ITEM_MANAGER_MEAL" : "CT_ITEM_MANAGER_ACTIVITY"
            );
          }}
        >
          <Icon path={isFood ? mdiFood : mdiRunFast} size={1} />
          <div className="card-button-text">
            {shortenText(
              t("open-manager"),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </div>
        </button>
      </div>
    </Modal>
  );
}
