/* eslint-disable no-sequences */
import React, { useCallback, useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import {
  BASE_ACCENT,
  BASE_GRAY,
  BASE_SELECTABLE_LIGHT,
  LARGE_BTN_MAX_STRING,
  POUNDS,
  QUESTION_MAX_STRING,
} from "../../../Supports/Constants";
import {
  DEFAULT_REPEAT_SETTING,
  converterUserWeight,
  generateDurationProgressionRates,
  generateNumberRange,
  getTodayDate,
  shortenText,
  updateVal,
} from "../../../Supports/Functions";
import {
  generateCalorieAnnotations,
  generatePhysicalAnnotations,
  generateRepeatOptions,
  generateScalarEntries,
  generateServingSizeOptions,
  generateServingUnitOptions,
} from "../../../Supports/Templates";
import Icon from "@mdi/react";
import {
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
  mdiChevronLeft,
  mdiChevronRight,
  mdiCloseThick,
  mdiContentSave,
  mdiDelete,
  mdiInformationOutline,
  mdiKeyboard,
  mdiKeyboardReturn,
  mdiPercent,
  mdiPlaylistPlus,
  mdiPlus,
  mdiViewList,
} from "@mdi/js";
import Slider from "@mui/material/Slider";
import UnstyledSelectBasic from "../../../Components/Supports/UnstyledSelectBasic";
import "./CalorieModal.css";
import { InfoTooltip } from "../../Supports/InfoTooltip";
import { deleteDoc, doc, getFirestore, setDoc } from "firebase/firestore";
import { DataContext } from "../../../Supports/Contexts";

// This component specified the modal that handles meal and activity management for the user.
export default function CalorieManagementModal(props) {
  const { t } = useTranslation();
  const Data = useContext(DataContext);

  const userData = props.userData; // The base user data reference for the application.
  const [modalData, setModalData] = useState(props.modalData); // If a modal is being pre-loaded/injected with data -- it gets it from here.

  const savedManagerState = props.savedManagerState;
  const setSavedManagerState = props.setSavedManagerState;

  const isFood = props.modalSettings.isFood;
  const savedItems = userData.calories.savedItems;
  const itemManagementTarget = props.itemManagementTarget;

  const basePresetFoods = props.foods.foods;
  const basePresetExercises = props.exercises.exercises;

  const activeItems = isFood ? props.savedFoodItems : props.savedPhysicalItems;
  const recentList = isFood
    ? savedItems.food.recent
    : savedItems.physical.recent;

  const MEAL = "Meal";
  const GRAM = "g";
  const ML = "ml";
  const TSP = "tsp";

  const DEFAULT_SERVING_SIZE_G = 100;
  const DEFAULT_SERVING_SIZE_ML = 100;
  const DEFAULT_SERVING_SIZE_TSP = 100;

  const DEFAULT_SERVING_SIZE_MEAL = 1;
  const DEFAULT_SERVING_UNIT =
    modalData && modalData.servingUnit ? modalData.servingUnit : MEAL; // Meal / g / ml
  const DEFAULT_SERVING_SIZE =
    modalData && modalData.servingSize
      ? modalData.servingSize
      : DEFAULT_SERVING_SIZE_MEAL;

  const [min, setMin] = useState(
    savedManagerState?.min
      ? savedManagerState.min
      : itemManagementTarget?.dependencies
      ? itemManagementTarget.dependencies
          .map((d) => d.calories)
          .reduce((acc, cur) => acc + cur, 0)
      : props.modalSettings.minVal
  );
  const [max, setMax] = useState(props.modalSettings.maxVal);
  const current = props.current || min;
  // const color = props.modalSettings.color;

  const [itemsPage, setItemsPage] = useState(0);
  const secretPlaceholderName = "whereswaldosecretname";
  const [itemGraph] = useState(props.itemGraph);

  const [workingItemGraph, setWorkingItemGraph] = useState(
    savedManagerState?.workingItemGraph
      ? savedManagerState?.workingItemGraph
      : itemGraph
  );

  const [dependencies, setDependencies] = useState(
    savedManagerState?.dependencies
      ? savedManagerState?.dependencies
      : itemManagementTarget?.dependencies
      ? itemManagementTarget.dependencies
      : []
  );
  const [priorItems] = useState(activeItems);

  const [showWarning, setShowWarning] = useState(modalData?.isScannedFood);

  const generateItemsList = () => {
    const list = Object.keys(priorItems);
    const its = [];
    for (let index = 0; index < list.length; index++) {
      const item = priorItems[list[index]];
      its.push({
        value: item.name,
        text: item.name ? `${item.name.substr(0, 16)}` : t("no-name"),
        key: item.key,
      });
    }
    return its;
  };

  const preloadTarget = modalData?.plan || itemManagementTarget || null;

  const [priorItem, setPriorItem] = useState(preloadTarget?.key);

  const [sliderVal, setSliderVal] = useState(
    savedManagerState?.sliderVal
      ? savedManagerState.sliderVal
      : priorItems &&
        preloadTarget?.key &&
        priorItems[preloadTarget?.key]?.calories
      ? priorItems[preloadTarget?.key].calories
      : isFood && modalData?.isScannedFood
      ? modalData?.calories || 0
      : current || 0
  );
  const [tempSliderVal, setTempSliderVal] = useState(sliderVal);
  const [showInputVisual, setShowInputVisual] = useState(false);

  const [newItemKey, setNewItemKey] = useState(
    savedManagerState?.newItemKey || preloadTarget?.key || null
  );

  const [newItemName, setNewItemName] = useState(
    savedManagerState?.newItemName
      ? savedManagerState.newItemName
      : preloadTarget
      ? preloadTarget.name
      : isFood && modalData?.isScannedFood
      ? modalData?.name
      : ""
  );

  const isScannedYetUnknownName =
    isFood && modalData?.isScannedFood ? modalData?.name === undefined : false;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const EMPTY_NUTRIENTS = isFood
    ? {
        sugar: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
        alcohol: 0,
        fiber: 0,
      }
    : null;

  const [nutrients, setNutrients] = useState(
    savedManagerState?.nutrients
      ? savedManagerState.nutrients
      : !isFood
      ? null
      : modalData && modalData.nutrients
      ? modalData.nutrients
      : priorItems &&
        preloadTarget?.key &&
        priorItems[preloadTarget?.key]?.nutrients
      ? priorItems[preloadTarget?.key].nutrients
      : preloadTarget?.nutrients
      ? preloadTarget.nutrients
      : EMPTY_NUTRIENTS
  );

  const [annotations, setAnnotations] = useState(
    savedManagerState?.annotations
      ? savedManagerState.annotations
      : preloadTarget?.types && Object.keys(preloadTarget?.types).length > 0
      ? preloadTarget?.types
      : priorItems &&
        preloadTarget?.key &&
        priorItems[preloadTarget?.key]?.annotations
      ? priorItems[preloadTarget?.key].annotations
      : isFood
      ? generateCalorieAnnotations()
      : generatePhysicalAnnotations()
  );

  const addDependencyEntry = useCallback(
    (v) => {
      const vData = v;

      workingItemGraph.addNode(
        newItemKey ? newItemKey : secretPlaceholderName,
        vData.dependencies
      );

      const vDataCalories = vData.calories;

      const incNutrients = !isFood
        ? null
        : isNaN(vData?.nutrients.protein)
        ? {
            protein: vData?.nutrients.find((n) => n.i === 1003)?.v || 0,
            carbs: vData?.nutrients.find((n) => n.i === 1005)?.v || 0,
            fat: vData?.nutrients.find((n) => n.i === 1004)?.v || 0,
            sugar: vData?.nutrients.find((n) => n.i === 2000)?.v || 0,
            fiber: vData?.nutrients.find((n) => n.i === 1079)?.v || 0,
            alcohol: vData?.nutrients.find((n) => n.i === 1018)?.v || 0,
          }
        : vData?.nutrients;

      const scaledNutrients = !isFood
        ? null
        : {
            carbs: Math.round(
              (incNutrients.carbs / 100) *
                (vData.scalars?.find((s) => s.n === vData.servingUnit).g || 1)
            ),
            protein: Math.round(
              (incNutrients.protein / 100) *
                (vData.scalars?.find((s) => s.n === vData.servingUnit).g || 1)
            ),
            fat: Math.round(
              (incNutrients.fat / 100) *
                (vData.scalars?.find((s) => s.n === vData.servingUnit).g || 1)
            ),
            sugar: Math.round(
              (incNutrients.sugar / 100) *
                (vData.scalars?.find((s) => s.n === vData.servingUnit).g || 1)
            ),
            fiber: Math.round(
              (incNutrients.fiber / 100) *
                (vData.scalars?.find((s) => s.n === vData.servingUnit).g || 1)
            ),
            alcohol: Math.round(
              (incNutrients.alcohol / 100) *
                (vData.scalars?.find((s) => s.n === vData.servingUnit).g || 1)
            ),
          };

      // FUTURE: Support input strat that can handle non-rounded values, so we don't have to force a round to load into the dropdown.
      const newNutrients = isFood
        ? {
            carbs: nutrients.carbs + Math.round(scaledNutrients.carbs),
            fat: nutrients.fat + Math.round(scaledNutrients.fat),
            alcohol: nutrients.alcohol + Math.round(scaledNutrients.alcohol),
            fiber: nutrients.fiber + Math.round(scaledNutrients.fiber),
            sugar: nutrients.sugar + Math.round(scaledNutrients.sugar),
            protein: nutrients.protein + Math.round(scaledNutrients.protein),
          }
        : null;

      let inAnnotes = vData.annotations;

      if (!inAnnotes) {
        const MIN_G = 20;
        if (isFood) {
          inAnnotes = {
            ...generateCalorieAnnotations(),
            ...{
              CARB: incNutrients.carbs >= MIN_G,
              PROT: incNutrients.protein >= MIN_G,
              FAT: incNutrients.fat >= MIN_G,
              SUGAR: incNutrients.sugar >= MIN_G,
            },
          };
        } else {
          inAnnotes = generatePhysicalAnnotations();
        }
      } else {
        const keys = Object.keys(annotations);
        keys.forEach((k) => {
          inAnnotes[k] ||= annotations[k];
        });
      }

      vData.annotations = inAnnotes;

      setAnnotations(inAnnotes);
      setNutrients(newNutrients);
      setMin(Math.min(props.modalSettings.maxVal, min + vDataCalories));
      setSliderVal(
        Math.min(props.modalSettings.maxVal, sliderVal + vDataCalories)
      );
      setDependencies(
        dependencies.length <= 0 ? [vData] : dependencies.concat(vData)
      );
    },
    [
      annotations,
      dependencies,
      isFood,
      min,
      newItemKey,
      nutrients?.alcohol,
      nutrients?.carbs,
      nutrients?.fat,
      nutrients?.fiber,
      nutrients?.protein,
      nutrients?.sugar,
      props.modalSettings.maxVal,
      sliderVal,
      workingItemGraph,
    ]
  );

  const clearSavedState = useCallback(() => {
    setSavedManagerState(null);
  }, [setSavedManagerState]);

  useEffect(() => {
    if (props.modalItemStore) {
      if (props.savedManagerState) {
        setMin(props.savedManagerState.min);
        setWorkingItemGraph(props.savedManagerState.workingItemGraph);
        setDependencies(props.savedManagerState.dependencies);
        setSliderVal(props.savedManagerState.sliderVal);
        setNewItemKey(props.savedManagerState.newItemKey);
        setNewItemName(props.savedManagerState.newItemName);
        setNutrients(props.savedManagerState.nutrients);
        setAnnotations(props.savedManagerState.annotations);
        setRepeatSetting(props.savedManagerState.repeatSetting);
        setServingSize(props.savedManagerState.servingSize);
        setServingUnit(props.savedManagerState.servingUnit);
        setView(props.savedManagerState.view);
        setSearchQuery(props.savedManagerState.searchQuery);
        setDuration(props.savedManagerState.duration);
        setScalar(props.savedManagerState.scalar);
        setSearchType(props.savedManagerState.searchType);
        setSelectedSearched(props.savedManagerState.selectedSearched);
        setUsedSearchResults(props.savedManagerState.usedSearchResults);
        setSearchResultsPage(props.savedManagerState.searchResultsPage);
      }

      clearSavedState();
      addDependencyEntry(props.modalItemStore);
      props.setModalItemStore(null);
    }
  }, [addDependencyEntry, clearSavedState, props]);

  useEffect(() => {
    if (
      props.modalData?.barCode &&
      isFood &&
      props.modalData?.isScannedFood &&
      props.modalData.isSuccessfulScan
    ) {
      setNutrients({
        // FUTURE: Add other like alcohol and fiber
        EMPTY_NUTRIENTS,
        ...{
          carbs: Math.round(props.modalData.carbs.carbohydrates100g) || 0,
          protein: Math.round(props.modalData.proteins.proteins100g) || 0,
          fat: Math.round(props.modalData.fats.fat100g) || 0,
          sugar: Math.round(props.modalData.sugars.sugars100g) || 0,
        },
      });
    }
  }, [
    EMPTY_NUTRIENTS,
    isFood,
    props.modalData?.barCode,
    props.modalData?.carbs.carbohydrates100g,
    props.modalData?.fats.fat100g,
    props.modalData?.isScannedFood,
    props.modalData?.isSuccessfulScan,
    props.modalData?.proteins.proteins100g,
    props.modalData?.sugars.sugars100g,
  ]);

  const [repeatSetting, setRepeatSetting] = useState(
    savedManagerState?.repeatSetting
      ? savedManagerState.repeatSetting
      : preloadTarget?.schedule
      ? preloadTarget.schedule
      : priorItems &&
        preloadTarget?.key &&
        priorItems[preloadTarget?.key]?.repeatSetting
      ? priorItems[preloadTarget?.key].repeatSetting
      : DEFAULT_REPEAT_SETTING
  );
  const [servingSize, setServingSize] = useState(
    savedManagerState?.servingSize ||
      preloadTarget?.servingSize ||
      DEFAULT_SERVING_SIZE
  );
  const [servingUnit, setServingUnit] = useState(
    savedManagerState?.servingUnit ||
      preloadTarget?.servingUnit ||
      DEFAULT_SERVING_UNIT
  );

  const [showSave, setShowSave] = useState(true); //   isFood

  const DEFAULT_DURATION = isFood ? null : 1800;
  const [duration, setDuration] = useState(
    savedManagerState?.duration || DEFAULT_DURATION
  );

  const incRateLarge = 10;
  const incRateSmall = 1;

  const MAX_ITEMS_PER_PAGE = 5;
  const backPage = itemsPage > 0;
  const forwardPage =
    itemsPage <
    Math.floor(dependencies.length / MAX_ITEMS_PER_PAGE) -
      (dependencies.length % MAX_ITEMS_PER_PAGE === 0 ? 1 : 0);

  const [view, setView] = useState(savedManagerState?.view || 0);

  const nutrientsNumberRange = generateNumberRange(
    0,
    servingSize === DEFAULT_SERVING_SIZE_G ? 100 : 500,
    1,
    GRAM,
    false,
    false
  );

  const ALL = 0;
  const MEALS_ONLY = 1;
  const [searchType, setSearchType] = useState(
    savedManagerState?.searchType || ALL
  ); // 0 All, 1: Only Meals.

  const [selectedSearched, setSelectedSearched] = useState(
    savedManagerState?.selectedSearched
      ? savedManagerState.selectedSearched
      : null
  );

  const [baseSearchResults] = useState(activeItems);
  const [usedSearchResults, setUsedSearchResults] = useState(
    savedManagerState?.usedSearchResults
      ? savedManagerState.usedSearchResults
      : activeItems &&
          Object.keys(activeItems)
            .reduce(
              (res, key, ind) => (
                (res[ind] = { ...activeItems[key], ...{ id: ind } }), res
              ),
              []
            )
            .filter(
              (k) =>
                !workingItemGraph.willMakeDependencyCycle(newItemKey, k.key) &&
                newItemKey !== k.key
            )
  );
  const [searchResultsPage, setSearchResultsPage] = useState(
    savedManagerState?.searchResultsPage || 0
  );
  const [searchQuery, setSearchQuery] = useState(
    savedManagerState?.searchQuery || ""
  );
  const [scalar, setScalar] = useState(
    savedManagerState?.scalar || "Quantity not specified"
  );

  const saveState = () => {
    setSavedManagerState({
      min: min,
      itemGraph: itemGraph,
      workingItemGraph: workingItemGraph,
      dependencies: dependencies,
      sliderVal: sliderVal,
      newItemKey: newItemKey,
      newItemName: newItemName,
      nutrients: nutrients,
      annotations: annotations,
      repeatSetting: repeatSetting,
      servingSize: servingSize,
      servingUnit: servingUnit,
      view: view,
      searchQuery: searchQuery,
      duration: duration,
      scalar: scalar,
      searchType: searchType,
      selectedSearched: selectedSearched,
      usedSearchResults: usedSearchResults,
      searchResultsPage: searchResultsPage,
    });
  };

  const removeLoadedEntry = () => {
    const adjustChainDown = (key, calDelta) => {
      if (!key) {
        return;
      }

      const dependers = itemGraph.getDependers(key) || [];
      Object.keys(dependers)?.forEach((d) => adjustChainDown(d, calDelta));
      const curTarget = priorItems[key];

      if (!curTarget) {
        return;
      }

      const numOfRemovedDependency =
        curTarget.dependencies?.map((d) => d.key === newItemKey).length || 0;

      curTarget.calories -= calDelta * numOfRemovedDependency;
      curTarget.dependencies =
        curTarget.dependencies?.filter((d) => d !== newItemKey) || [];

      const newAnnotes = curTarget.annotations;
      Object.keys(newAnnotes)?.forEach((k) => {
        // If it was true in the removed dependency, then remove.
        // But if it wasn't true for the dependency, trust the existing.
        if (curTarget.annotations[k]) {
          newAnnotes[k] = false;
        }
      });

      curTarget.annotations = newAnnotes;

      priorItems[key] = curTarget;
    };

    // For each remaining savedItem entry -- reduce its calories by the diff, go down its dependers chain until full corrected,
    // dumb remove annotes, and remove from graph and listed dependencies.
    adjustChainDown(newItemKey, priorItems[newItemKey].calories);

    const removedItemDeps = priorItems[newItemKey].dependencies || [];

    // removed this items presence in its dependencies' dependers list. Required for the CalorieInfo modal.
    removedItemDeps.forEach((d) => {
      if (priorItems[d]) {
        priorItems[d].dependers = priorItems[d].dependers.filter(
          (n) => n !== newItemKey
        );
      }
    });

    itemGraph.removeNode(newItemKey);
    const newRecentList = recentList.filter((rl) => newItemKey !== rl.lookup);

    delete priorItems[newItemKey];
    newEntry();

    const newStore = {
      graph: JSON.parse(JSON.stringify(itemGraph)),
      recent: newRecentList,
    };

    return deleteDoc(
      doc(
        getFirestore(),
        `UserData/${userData.id}/${isFood ? "Food" : "Physical"}Items`,
        newItemKey
      )
    )
      .then(
        updateVal(
          {
            [`calories.savedItems.${isFood ? "food" : "physical"}`]: newStore,
          },
          userData.id
        )
      )
      .then(Data.setReloadCalorieItems(true))
      .then(props.setHasNewCalorieItems(true));
  };

  const genItem = () => {
    const dateObj = getTodayDate();
    let tempKey =
      newItemName &&
      newItemName !== "" &&
      newItemName.trim().replace(/\W/g, "") !== ""
        ? newItemName.trim().replace(/\W/g, "")
        : t("unknown-name"); // Removes extra whitespace, and clears our any non letter characters, worst case its something like "..." which converts to unknown-name, then filled out next few lines.

    if (itemGraph.isNode(tempKey)) {
      tempKey = `${tempKey}-${itemGraph.getNumberOfNodes()}`;
    }
    const MIN_G = 20;

    let newAnnotes;

    if (isFood) {
      newAnnotes = {
        ...annotations,
        ...{
          CARB: nutrients.carbs >= MIN_G,
          PROT: nutrients.protein >= MIN_G,
          FAT: nutrients.fat >= MIN_G,
          SUGAR: nutrients.sugar >= MIN_G,
        },
      };
    } else {
      newAnnotes = annotations;
    }

    const dependenciesHold =
      dependencies.map((d) => {
        return {
          ...d,
          ...{
            dependencies: d.dependencies || null,
            defenders: d.defenders || null,
          },
        };
      }) || null;

    const prepped = {
      name:
        newItemName && newItemName !== ""
          ? newItemName.trim()
          : t("unknown-name"),
      type: isFood ? "foodItem" : "physicalItem",
      calories: sliderVal,
      key: tempKey,
      repeatSetting: repeatSetting,
      annotations: newAnnotes,
      datetime: dateObj.json,
      lastUsed: dateObj.json,
      date: dateObj.date,
      dependencies: dependenciesHold,
      dependers: [],
      servingSize: servingSize,
      servingUnit: servingUnit,
    };

    if (isFood) {
      prepped.nutrients = nutrients;
    }

    return prepped;
  };

  const saveNewEntry = () => {
    const newItem = genItem();
    priorItems[newItem.key] = newItem;

    itemGraph.addNode(
      newItem.key,
      dependencies.map((d) => d.key || d.name)
    );
    itemGraph.removeNode("");

    dependencies?.forEach((d) => {
      if (priorItems[d]) {
        const targetDependers = priorItems[d].dependers;
        targetDependers.push(newItem.key);
        priorItems[d].dependers = [...new Set(targetDependers)];
      }
    });

    setWorkingItemGraph(itemGraph); // fresh graph state

    return updateVal(
      {
        [`calories.savedItems.${isFood ? "food" : "physical"}.graph`]:
          JSON.parse(JSON.stringify(itemGraph)),
      },
      userData.id
    )
      .then(
        setDoc(
          doc(
            getFirestore(),
            `UserData/${userData.id}/${isFood ? "Food" : "Physical"}Items`,
            newItem.key
          ),
          newItem
        )
      )
      .then(loadInPriorItem(newItem.key))
      .then(Data.setReloadCalorieItems(true))
      .then(props.setHasNewCalorieItems(true));
  };

  const updateEntry = () => {
    const adjustCalorieChain = (inCur, delta) => {
      const deps = itemGraph.getDependers(inCur);
      Object.keys(deps)?.forEach((d) => adjustCalorieChain(d, delta));

      if (priorItems[inCur]) {
        const numOfDependencies =
          priorItems[inCur]?.dependencies?.map((d) => d.key === inCur).length ||
          0;

        priorItems[inCur].calories += delta * numOfDependencies;
      }
    };

    adjustCalorieChain(newItemKey, sliderVal - priorItems[newItemKey].calories);

    itemGraph.updateDependencies(
      newItemKey,
      dependencies.map((d) => d.key || d.name)
    );

    const newDependers = itemGraph.getDependers(newItemKey);
    setWorkingItemGraph(itemGraph); // fresh graph state

    let newItem;

    const dependenciesHold =
      dependencies.map((d) => {
        return {
          ...d,
          ...{
            dependencies: d.dependencies || null,
            defenders: d.defenders || null,
          },
        };
      }) || null;

    const defendersHold = newDependers
      ? newDependers.map((d) => {
          return {
            ...d,
            ...{
              dependencies: d.dependencies || null,
              defenders: d.defenders || null,
            },
          };
        })
      : null;

    const dateObj = getTodayDate();

    if (isFood) {
      const MIN_G = 20;

      newItem = {
        ...priorItems[newItemKey],
        ...{
          name: newItemName.trim(),
          type: isFood ? "foodItem" : "physicalItem",
          annotations: {
            ...annotations,
            ...{
              CARB: nutrients.carbs >= MIN_G,
              PROT: nutrients.protein >= MIN_G,
              FAT: nutrients.fat >= MIN_G,
              SUGAR: nutrients.sugar >= MIN_G,
            },
          },
          nutrients: nutrients,
          calories: sliderVal,
          repeatSetting: repeatSetting,
          datetime: dateObj.json,
          lastUsed: dateObj.json,
          date: dateObj.date,
          dependencies: dependenciesHold,
          dependers: defendersHold,
          servingSize: servingSize,
          servingUnit: servingUnit,
        },
      };
    } else {
      newItem = {
        ...priorItems[newItemKey],
        ...{
          name: newItemName,
          type: isFood ? "foodItem" : "physicalItem",
          annotations: annotations,
          calories: sliderVal,
          repeatSetting: repeatSetting,
          datetime: dateObj.json,
          lastUsed: dateObj.json,
          date: dateObj.date,
          dependencies: dependenciesHold,
          dependers: defendersHold,
        },
      };
    }

    priorItems[newItemKey] = newItem;
    clearSavedState();

    return updateVal(
      {
        [`calories.savedItems.${isFood ? "food" : "physical"}.graph`]:
          JSON.parse(JSON.stringify(itemGraph)),
      },
      userData.id
    )
      .then(
        setDoc(
          doc(
            getFirestore(),
            `UserData/${userData.id}/${isFood ? "Food" : "Physical"}Items`,
            newItemKey
          ),
          priorItems[newItemKey]
        )
      )
      .then(Data.setReloadCalorieItems(true))
      .then(props.setHasNewCalorieItems(true));
  };

  const loadInPriorItem = (key) => {
    const item = priorItems[key];

    if (item) {
      const itemDep = item?.dependencies || [];

      let caloriesFromDeps = 0;
      if (itemDep.length > 0) {
        caloriesFromDeps = item.dependencies
          .map((d) => d.calories)
          .reduce((acc, cur) => acc + cur, 0);
      }

      setMin(props.modalSettings.minVal + caloriesFromDeps);
      setMax(Math.max(props.modalSettings.maxVal, item.calories));
      setPriorItem(item.key);
      setNutrients((prev) => {
        return {
          ...prev,
          ...item.nutrients,
        };
      });
      setSliderVal(item.calories);
      setNewItemKey(item.key);
      setNewItemName(item.name);
      setAnnotations(item.annotations);
      setRepeatSetting(item.repeatSetting);
      setDependencies(item?.dependencies || []);

      setServingSize(isFood ? item?.servingSize || DEFAULT_SERVING_SIZE : null);
      setServingUnit(isFood ? item?.servingUnit || DEFAULT_SERVING_UNIT : null);
      setShowWarning(false);
      setShowSave(true);

      clearSavedState();
      updateSearchItems();

      setUsedSearchResults(
        activeItems
          ? Object.keys(activeItems)
              .reduce(
                (res, key, ind) => (
                  (res[ind] = { ...activeItems[key], ...{ id: ind } }), res
                ),
                []
              )
              .filter((k) => {
                return (
                  !workingItemGraph.willMakeDependencyCycle(item.name, k.key) &&
                  item.name !== k.key
                );
              })
          : []
      );
    }
  };

  const clear = () => {
    setModalData(null);
    setSliderVal(current);
    setShowInputVisual(false);
    setNewItemKey(null);
    setNewItemName(null);
    setPriorItem(t("none-selected"));
    setMin(props.modalSettings.minVal);
    setDependencies([]);
    setWorkingItemGraph(itemGraph);
    setNutrients(EMPTY_NUTRIENTS);

    setAnnotations(
      isFood ? generateCalorieAnnotations() : generatePhysicalAnnotations()
    );
    setRepeatSetting(DEFAULT_REPEAT_SETTING);
    setServingSize(DEFAULT_SERVING_SIZE_MEAL);
    setServingUnit(MEAL);
    setShowSave(false);
    setShowWarning(false);

    setSelectedSearched(null);
    setSearchQuery(""); // if this is done, when it reloads, the applied filter is still there on the set, yet the cue to why is not.
    setScalar([]);
    setDuration(DEFAULT_DURATION);
    clearSavedState();
    props.setItemManagementTarget(null);
  };

  const dismissWithoutInputFunc = () => {
    clear();
    props.setHasNewCalorieItems(true); // force a refresh at the MealAdd

    const promise = new Promise((res) => {
      props.setModalVisible(false);

      setTimeout(() => {
        res();
      }, 0);
    });

    promise.then(() => {
      props.setModalVisible(
        isFood ? "CUSTOM_FOOD_CALORIES" : "CUSTOM_PHYSICAL_CALORIES"
      );
    });
  };

  // const openCamera = () => {
  //   const promise = new Promise((res) => {
  //     props.setModalVisible(false);

  //     setTimeout(() => {
  //       res();
  //     }, 0);
  //   });

  //   promise.then(() => {
  //     props.setModalVisible("CUSTOM_FOOD_CAMERA");
  //   });
  // };

  const saveAnnotations = (name, bool = undefined) => {
    setAnnotations({
      ...annotations,
      ...{ [name]: bool === undefined ? !annotations[name] : bool },
    });
  };

  const _generateSimpleAnnotations = (annotes) => {
    const buttons = [];
    const names = Object.keys(annotes);
    for (let i = 0; i < names.length; i++) {
      const name = names[i];

      const buttonStructure = (
        <button
          className="annotation-item"
          style={{
            backgroundColor: annotations[name]
              ? BASE_ACCENT
              : BASE_SELECTABLE_LIGHT,
          }}
          key={name}
          onClick={() => saveAnnotations(name)}
        >
          <div className="card-button-text">{t(name.toLowerCase())}</div>
        </button>
      );

      buttons.push(buttonStructure);
    }

    return buttons;
  };

  const newEntry = () => {
    setPriorItem(t("none-selected"));
    setMin(props.modalSettings.minVal);
    setSliderVal(0);
    setAnnotations(
      isFood ? generateCalorieAnnotations() : generatePhysicalAnnotations()
    );
    setRepeatSetting(DEFAULT_REPEAT_SETTING);
    setServingSize(isFood ? DEFAULT_SERVING_SIZE_MEAL : null);
    setServingUnit(isFood ? MEAL : null);
    setNewItemKey(null);
    setNewItemName("");
    setWorkingItemGraph(itemGraph); // fresh graph state
    setDependencies([]);
    setShowWarning(false);
    setNutrients(isFood ? EMPTY_NUTRIENTS : null);
    workingItemGraph.addNode(secretPlaceholderName);
    setUsedSearchResults(
      activeItems
        ? Object.keys(activeItems).reduce(
            (res, key, ind) => (
              (res[ind] = { ...activeItems[key], ...{ id: ind } }), res
            ),
            []
          )
        : []
    );
  };

  const removeDependencyEntry = (index) => {
    const dep = dependencies[index];

    dependencies.splice(index, 1);

    // Check if it was the last of its kind to be removed. If so, do final cuts.
    // Otherwise there were more then one so these
    // elements should not yet be disconnected from the system.
    if (dependencies.map((d) => d === dep.name).length <= 0) {
      workingItemGraph.removeNode(dep.isPreset ? dep.name : dep.key);

      const keys = Object.keys(annotations);
      const newAnnotes = annotations;

      keys.forEach((k) => {
        // If it was true in the removed dependency, then remove.
        // But if it wasn't true for the dependency, trust the existing.
        if (dep.annotations[k]) {
          newAnnotes[k] = false;
        }
      });

      setAnnotations(newAnnotes);
    }

    if (isFood) {
      const incNutrients = isNaN(dep.nutrients.protein)
        ? {
            protein: dep.nutrients.find((n) => n.i === 1003)?.v || 0,
            carbs: dep.nutrients.find((n) => n.i === 1005)?.v || 0,
            fat: dep.nutrients.find((n) => n.i === 1004)?.v || 0,
            sugar: dep.nutrients.find((n) => n.i === 2000)?.v || 0,
            fiber: dep.nutrients.find((n) => n.i === 1079)?.v || 0,
            alcohol: dep.nutrients.find((n) => n.i === 1018)?.v || 0,
          }
        : dep.nutrients;

      const scaledNutrients = {
        carbs: Math.round(
          (incNutrients.carbs / 100) *
            (dep.scalars?.find((s) => s.n === dep.servingUnit).g || 1)
        ),
        protein: Math.round(
          (incNutrients.protein / 100) *
            (dep.scalars?.find((s) => s.n === dep.servingUnit).g || 1)
        ),
        fat: Math.round(
          (incNutrients.fat / 100) *
            (dep.scalars?.find((s) => s.n === dep.servingUnit).g || 1)
        ),
        sugar: Math.round(
          (incNutrients.sugar / 100) *
            (dep.scalars?.find((s) => s.n === dep.servingUnit).g || 1)
        ),
        fiber: Math.round(
          (incNutrients.fiber / 100) *
            (dep.scalars?.find((s) => s.n === dep.servingUnit).g || 1)
        ),
        alcohol: Math.round(
          (incNutrients.alcohol / 100) *
            (dep.scalars?.find((s) => s.n === dep.servingUnit).g || 1)
        ),
      };

      // FUTURE: When removing, won't remove set annotations, even if possibly dropped out. Hard to fix becuase we don't know the size of the full meal, so not sure if rate holds.
      const newNutrients = {
        carbs: Math.max(nutrients.carbs - scaledNutrients.carbs, 0),
        fat: Math.max(nutrients.fat - scaledNutrients.fat, 0),
        alcohol: Math.max(nutrients.alcohol - scaledNutrients.alcohol, 0),
        fiber: Math.max(nutrients.fiber - scaledNutrients.fiber, 0),
        sugar: Math.max(nutrients.sugar - scaledNutrients.sugar, 0),
        protein: Math.max(nutrients.protein - scaledNutrients.protein, 0),
      };

      setNutrients(newNutrients);
    }

    setMin(min - dep.calories);
    setSliderVal(sliderVal - dep.calories);
  };

  const generateItemTableView = () => {
    const tableRows = [];

    if (dependencies.length <= 0) {
      return (
        <div className="logTableRowStub logItemTextStub logItemTextStubPlan">
          {`${t("optional")}: ${t("add-saved-entries")}`}
        </div>
      );
    }

    const headerStyle = `logHeaderText column20`;
    const headerLargeStyle = `logHeaderText column60`;

    tableRows.push(
      <div className="logTableHeaderRow raiseTable" key="tableHeader">
        <div className={headerStyle}>{t("calories")}</div>
        <div className={headerLargeStyle}>{t("name")}</div>
        <div className={headerStyle}>{t("remove")}</div>
      </div>
    );

    for (
      let index = itemsPage * MAX_ITEMS_PER_PAGE;
      index < dependencies.length &&
      index - itemsPage * MAX_ITEMS_PER_PAGE < MAX_ITEMS_PER_PAGE;
      index++
    ) {
      const rowItemStyle = `logItemText column60 unselectedRowText`;
      const rowItemStyleSmall = `logItemText column20 unselectedRowText`;

      const dependency = dependencies[index];
      const row = (
        <div className="logTableRow selectableRow" key={`tableRow-${index}`}>
          <div className={rowItemStyleSmall}>{`${dependency.calories}${t(
            "kcal"
          )}`}</div>
          <div className={rowItemStyle}>{`${
            dependency?.name
              .replace(/(.{30})..+/, "$1...")
              .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                letter.toUpperCase()
              ) || t("unnamed")
          }`}</div>
          <button
            className={`${rowItemStyleSmall} row-glyph-button danger`}
            onClick={() => {
              removeDependencyEntry(index);
            }}
          >
            <Icon path={mdiDelete} size={1} />
          </button>
        </div>
      );
      tableRows.push(row);
    }

    return tableRows;
  };

  const generateItemTable = (
    tableID,
    itemsArr,
    tableHeader,
    page,
    setPage,
    actionTarget,
    setActionTarget,
    emptyStub,
    selectedTarget,
    setSelectedTarget
  ) => {
    const items = itemsArr;

    const backPage = page > 0;
    const forwardPage =
      page <
      Math.floor(items.length / MAX_ITEMS_PER_PAGE) -
        (items.length % MAX_ITEMS_PER_PAGE === 0 ? 1 : 0);

    const header = (
      <div className="TableArrowHeader">
        <button
          className="glyph-button"
          onClick={() => backPage && setPage(page - 1)}
        >
          <Icon
            path={mdiChevronLeft}
            size={1}
            style={{
              color: backPage ? BASE_ACCENT : BASE_GRAY,
            }}
          />
        </button>
        <div className="card-header-text-small-centered">
          {shortenText(tableHeader, 16, true, 0, true, true)}
        </div>
        <button
          className="glyph-button"
          onClick={() => forwardPage && setPage(page + 1)}
        >
          <Icon
            path={mdiChevronRight}
            size={1}
            style={{
              color: backPage ? BASE_ACCENT : BASE_GRAY,
            }}
          />
        </button>
      </div>
    );

    const tableRows = [];

    if (items.length <= 0) {
      return (
        <div className="logTable">
          {header}
          <div className="logTableRowStub selectableRow">
            <div className="logItemText logItemTextStubPlan">{emptyStub}</div>
          </div>
        </div>
      );
    }

    tableRows.push(
      <div className="logTableHeaderRow" key="tableHeader">
        <div className="logHeaderText column70">{t("name")}</div>
        <div className="logHeaderText column30">{t("calories")}</div>
      </div>
    );

    const canBeMultiAdded = tableID === "searchResults";

    for (
      let index = page * MAX_ITEMS_PER_PAGE;
      index < items.length &&
      index - page * MAX_ITEMS_PER_PAGE < MAX_ITEMS_PER_PAGE;
      index++
    ) {
      const item = items[index];
      const row = (
        <button
          className={`logTableRow ${
            selectedTarget && selectedTarget.id === item?.id
              ? "selectedRow"
              : "selectableRow"
          }`}
          key={`tableRow-${index}`}
          onClick={() => {
            const prom = new Promise((res, rej) => {
              const newSel =
                selectedTarget && selectedTarget.id === item?.id
                  ? null
                  : { ...item, ...{ id: item?.id } };
              setSelectedTarget(newSel);

              res(newSel);
            });

            prom.then((newSel) => {
              tableID === "searchResults" &&
                newSel?.scalars &&
                isFood &&
                setScalar(newSel.scalars[0].n);
            });
          }}
        >
          <div
            className={`logItemText column70 ${
              selectedTarget && selectedTarget.id === item?.id
                ? "selectedRowText"
                : "unselectedRowText"
            }`}
          >{`${shortenText(
            item?.name || t("unnamed"),
            37,
            true,
            0,
            false,
            false
          )}`}</div>
          <div
            className={`logItemText column30 ${
              selectedTarget && selectedTarget.id === item?.id
                ? "selectedRowText"
                : "unselectedRowText"
            }`}
          >
            {tableID === "toBeAdded"
              ? `${item.calories}${t("kcal")}`
              : item.minCalories && item.maxCalories
              ? `${item.minCalories} — ${item.maxCalories}`
              : `${
                  item.isPreset && !isFood
                    ? item.caloriesPerHalfHour
                    : item.calories
                }${t("kcal")}/${
                  isFood
                    ? item.servingUnit === "Meal"
                      ? t(item.servingUnit)[0].toUpperCase() +
                        t(item.servingUnit).slice(1)
                      : item.servingUnit
                    : item.isPreset
                    ? `30${t("minute-short")}`
                    : t("activity")
                }`}
          </div>
        </button>
      );
      tableRows.push(row);
    }

    const hasSelected = selectedTarget;

    let scalarList = [];
    let durationList = [];
    if (isFood && hasSelected) {
      scalarList = generateScalarEntries(selectedTarget.scalars);
    } else if (!isFood && hasSelected) {
      durationList = generateDurationProgressionRates(t, 30, 18000, 30);
    }

    return (
      <div className="logTable">
        {header}
        {tableRows}
        {
          <>
            <div className="actionNameContainer">
              <div className="warningText">
                {shortenText(
                  selectedTarget?.name || t("nothing-selected"),
                  200,
                  true,
                  0
                )}
              </div>
            </div>

            {hasSelected && hasSelected.isPreset && (
              <div className="sectionItemRow column100">
                <div className="card-text-small">{`${shortenText(
                  isFood ? t("serving") : t("duration-full"),
                  QUESTION_MAX_STRING,
                  true,
                  1
                )}:`}</div>
                <div className="card-text-small">{`${
                  selectedTarget.isPreset
                    ? isFood
                      ? Math.round(
                          (selectedTarget.calories / 100) *
                            selectedTarget.scalars.find((s) => s.n === scalar)
                              ?.g
                        )
                      : Math.round((selectedTarget.calories / 60) * duration)
                    : selectedTarget.calories
                }${t("kcal")}`}</div>
                <div className="endRow">
                  <div className="scalerButton">
                    <UnstyledSelectBasic
                      options={isFood ? scalarList : durationList}
                      defaultOption={isFood ? scalar : duration}
                      onChange={(_, v) =>
                        isFood ? setScalar(v) : setDuration(v)
                      }
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="sectionItemRow column100">
              <div className="card-text-small">{`${shortenText(
                t("actions-i18n"),
                QUESTION_MAX_STRING,
                true,
                1
              )}:`}</div>
              <div className="endRow">
                <div className="button-container nowrap noTopMargin noBottomMargin">
                  <button
                    className={`row-glyph-button ${
                      !hasSelected
                        ? "disabled"
                        : !canBeMultiAdded
                        ? "danger"
                        : "new"
                    }`}
                    onClick={() => {
                      if (hasSelected) {
                        const newId = actionTarget.length;

                        let newItem;

                        if (isFood) {
                          newItem = {
                            ...selectedTarget,
                            ...{
                              id: newId,
                              calories: selectedTarget.isPreset
                                ? Math.round(
                                    (selectedTarget.calories / 100) *
                                      (selectedTarget.scalars?.find(
                                        (s) => s.n === scalar
                                      ).g || 1)
                                  )
                                : selectedTarget.calories,
                              servingUnit: selectedTarget.isPreset
                                ? scalar
                                : selectedTarget.servingUnit,
                              servingSize: 1,
                            },
                          };
                        } else {
                          newItem = {
                            ...selectedTarget,
                            ...{
                              id: newId,
                              calories: selectedTarget.isPreset
                                ? Math.round(
                                    (selectedTarget.calories / 60) * duration
                                  )
                                : selectedTarget.calories,
                            },
                          };
                        }

                        setActionTarget(newItem);
                      }
                    }}
                  >
                    <Icon
                      path={!canBeMultiAdded ? mdiDelete : mdiPlus}
                      size={1}
                    />
                  </button>
                  <button
                    className={`row-glyph-button ${
                      !hasSelected ? "disabled" : "special"
                    }`}
                    onClick={() => {
                      if (hasSelected) {
                        const newId = actionTarget.length;
                        props.setItemManagementTarget(genItem());

                        let newItem;

                        if (isFood) {
                          newItem = {
                            ...selectedTarget,
                            ...{
                              id: newId,
                              calories: selectedTarget.isPreset
                                ? Math.round(
                                    (selectedTarget.calories / 100) *
                                      (selectedTarget.scalars?.find(
                                        (s) => s.n === scalar
                                      ).g || 1)
                                  )
                                : selectedTarget.calories,
                              servingUnit: selectedTarget.isPreset
                                ? scalar
                                : selectedTarget.servingUnit,
                            },
                          };
                        } else {
                          newItem = {
                            ...selectedTarget,
                            ...{
                              id: newId,
                              calories: selectedTarget.isPreset
                                ? Math.round(
                                    (selectedTarget.calories / 60) * duration
                                  )
                                : selectedTarget.calories,
                            },
                          };
                        }

                        saveState();
                        props.setPercentItemTarget({
                          item: newItem,
                          callback: () => {},
                          origin: isFood
                            ? "CT_ITEM_MANAGER_MEAL"
                            : "CT_ITEM_MANAGER_ACTIVITY",
                        });

                        props.setModalVisible("CT_MULTI_ITEM");
                      }
                    }}
                  >
                    <Icon path={mdiCloseThick} size={1} />
                  </button>
                  <button
                    className={`row-glyph-button ${
                      !hasSelected ? "disabled" : "warning"
                    }`}
                    onClick={() => {
                      if (hasSelected) {
                        const newId = actionTarget.length;
                        props.setItemManagementTarget(genItem());

                        let newItem;

                        if (isFood) {
                          newItem = {
                            ...selectedTarget,
                            ...{
                              id: newId,
                              calories: selectedTarget.isPreset
                                ? Math.round(
                                    (selectedTarget.calories / 100) *
                                      (selectedTarget.scalars?.find(
                                        (s) => s.n === scalar
                                      ).g || 1)
                                  )
                                : selectedTarget.calories,
                              servingUnit: selectedTarget.isPreset
                                ? scalar
                                : selectedTarget.servingUnit,
                            },
                          };
                        } else {
                          newItem = {
                            ...selectedTarget,
                            ...{
                              id: newId,
                              calories: selectedTarget.isPreset
                                ? Math.round(
                                    (selectedTarget.calories / 60) * duration
                                  )
                                : selectedTarget.calories,
                            },
                          };
                        }

                        saveState();
                        props.setPercentItemTarget({
                          item: newItem,
                          callback: () => {},
                          origin: isFood
                            ? "CT_ITEM_MANAGER_MEAL"
                            : "CT_ITEM_MANAGER_ACTIVITY",
                        });

                        props.setModalVisible("CT_PERCENT_ITEM");
                      }
                    }}
                  >
                    <Icon path={mdiPercent} size={1} />
                  </button>
                  <button
                    className={`row-glyph-button ${
                      !hasSelected ? "disabled" : "accent"
                    }`}
                    onClick={() => {
                      if (hasSelected) {
                        let newItem;
                        if (isFood) {
                          newItem = isNaN(selectedTarget.nutrients.protein)
                            ? {
                                ...selectedTarget,
                                ...{
                                  nutrients: {
                                    protein: Math.round(
                                      selectedTarget.nutrients.find(
                                        (n) => n.i === 1003
                                      )?.v || 0
                                    ),
                                    carbs: Math.round(
                                      selectedTarget.nutrients.find(
                                        (n) => n.i === 1005
                                      )?.v || 0
                                    ),
                                    fat: Math.round(
                                      selectedTarget.nutrients.find(
                                        (n) => n.i === 1004
                                      )?.v || 0
                                    ),
                                    sugar: Math.round(
                                      selectedTarget.nutrients.find(
                                        (n) => n.i === 2000
                                      )?.v || 0
                                    ),
                                    fiber: Math.round(
                                      selectedTarget.nutrients.find(
                                        (n) => n.i === 1079
                                      )?.v || 0
                                    ),
                                    alcohol: Math.round(
                                      selectedTarget.nutrients.find(
                                        (n) => n.i === 1018
                                      )?.v || 0
                                    ),
                                  },
                                  servingUnit: selectedTarget.isPreset
                                    ? scalar
                                    : selectedTarget.servingUnit,
                                  servingSize: 1,
                                },
                              }
                            : selectedTarget;
                        } else {
                          newItem = {
                            ...selectedTarget,
                            ...{
                              origin: isFood
                                ? "CT_ITEM_MANAGER_MEAL"
                                : "CT_ITEM_MANAGER_ACTIVITY",
                            },
                          };
                        }

                        saveState();
                        props.setItemManagementTarget(newItem);

                        props.setModalVisible(
                          isFood ? "CT_INFO_MANAGE" : "CT_ACTIVE_INFO"
                        );
                      }
                    }}
                  >
                    <Icon path={mdiInformationOutline} size={1} />
                  </button>
                </div>
                <InfoTooltip text={t("info-actions")} />
              </div>
            </div>
          </>
        }
      </div>
    );
  };

  const calculateCaloriesExpended = (exercise, durationInSeconds) => {
    const getMETCalc = (bodyLbs, metVal, seconds) => {
      return (bodyLbs / 2.20462) * metVal * 0.0175 * (seconds / 60);
    };

    const today = getTodayDate();
    const todayLog = userData.weight.log[today.year][today.month][today.day];
    const userWeightInLBs = converterUserWeight(
      todayLog.unit,
      todayLog.value,
      POUNDS
    );

    switch (exercise.category) {
      case "crossfit":
        return getMETCalc(userWeightInLBs, 5.6, durationInSeconds);
      case "stretching":
        return getMETCalc(userWeightInLBs, 2.3, durationInSeconds);
      case "plyometrics":
        return getMETCalc(userWeightInLBs, 8, durationInSeconds);
      case "cardio":
        return getMETCalc(userWeightInLBs, 8, durationInSeconds); // FUTURE distinguish between different cardios.
      case "strength":
        return getMETCalc(userWeightInLBs, 6, durationInSeconds);
      default:
        return getMETCalc(userWeightInLBs, 3.5, durationInSeconds);
    }
  };

  // FUTURE: Improve search tokenization, perhaps use offtheshelf
  const updateSearchItems = (text = searchQuery, typeOverload = undefined) => {
    setSelectedSearched(null);
    setSearchResultsPage(0);
    setSearchQuery(text);

    text = text.trim();
    const stlc = text.toLowerCase();
    const stlcTokens = stlc.split(" ");

    const searchController = typeOverload ? typeOverload : searchType;

    const prom = new Promise((res, _) => {
      if (text === "" || !text || !text.replace(/\s/g, "").length) {
        const customs = Object.keys(baseSearchResults)
          .reduce(
            (res, key, ind) => (
              (res[ind] = { ...baseSearchResults[key], ...{ id: ind } }), res
            ),
            []
          )
          .filter(
            (k) =>
              !workingItemGraph.willMakeDependencyCycle(newItemKey, k.key) &&
              newItemKey !== k.key
          );

        setUsedSearchResults(customs);
      } else {
        const customs = Object.keys(baseSearchResults)
          .filter((k) =>
            baseSearchResults[k].name.toLowerCase().includes(text.toLowerCase())
          )
          .reduce(
            (res, key, ind) => (
              (res[ind] = { ...baseSearchResults[key], ...{ id: ind } }), res
            ),
            []
          )
          .filter(
            (k) =>
              !workingItemGraph.willMakeDependencyCycle(newItemKey, k.key) &&
              newItemKey !== k.key
          );

        if (searchController === ALL) {
          let presets;

          if (isFood) {
            presets = basePresetFoods
              .map((be, i) => {
                const triggeredTokens = be.tokens.filter((token) =>
                  stlcTokens.some((inputToken) =>
                    token.toLowerCase().includes(inputToken)
                  )
                );

                return {
                  ...be,
                  ...{ lookup: i, tokensTriggered: triggeredTokens.length },
                };
              })
              .filter(
                (f) =>
                  f.d.toLowerCase().includes(text.toLowerCase()) ||
                  (f.tokens &&
                    stlcTokens &&
                    f.tokens.some((token) =>
                      stlcTokens.includes(token.toLowerCase())
                    ))
              )
              .map((f, i) => {
                const sortedScalars = f.s.sort((a, b) => {
                  return b.g - a.g;
                });

                const caloriesPer100g = f.n.find((n) => n.i === 1008).v;
                const caloriesPer1g = caloriesPer100g / 100;
                const minCalories = Math.round(
                  sortedScalars[sortedScalars.length - 1]?.g * caloriesPer1g ||
                    caloriesPer100g
                );
                const maxCalories = Math.round(
                  sortedScalars[0]?.g * caloriesPer1g || caloriesPer100g
                );

                return {
                  id: customs.length + i,
                  isPreset: true,
                  name: f.d,
                  calories: caloriesPer100g,
                  nutrients: f.n,
                  type: "foodItem",
                  servingUnit: "100g", // Default.
                  minCalories: minCalories,
                  maxCalories: maxCalories,
                  scalars: sortedScalars,
                  tokensTriggered: f.tokensTriggered,
                };
              });
          } else {
            presets = basePresetExercises
              .map((be, i) => {
                const triggeredTokens = be.tokens.filter((token) =>
                  stlcTokens.some((inputToken) =>
                    token.toLowerCase().includes(inputToken)
                  )
                );

                return {
                  ...be,
                  ...{ lookup: i, tokensTriggered: triggeredTokens.length },
                };
              })
              .filter((staticData) => {
                const stlc = text.toLowerCase();

                return (
                  staticData.name.toLowerCase().includes(stlc) ||
                  (staticData.tokens &&
                    stlcTokens &&
                    staticData.tokens.some((token) =>
                      stlcTokens.includes(token.toLowerCase())
                    )) ||
                  (staticData.equipment &&
                    staticData.equipment.toLowerCase().includes(stlc)) ||
                  (staticData.category &&
                    staticData.category.toLowerCase().includes(stlc)) ||
                  (staticData.mechanic &&
                    staticData.mechanic.toLowerCase().includes(stlc).length >
                      0) ||
                  (
                    staticData.primaryMuscles &&
                    staticData.primaryMuscles.filter((m) =>
                      m.toLowerCase().includes(stlc)
                    )
                  ).length > 0 ||
                  (
                    staticData.secondaryMuscles &&
                    staticData.secondaryMuscles.filter((m) =>
                      m.toLowerCase().includes(stlc)
                    )
                  ).length > 0
                );
              })
              .map((exercise, i) => {
                const caloriesPerMinute = Math.round(
                  calculateCaloriesExpended(exercise, 60)
                );

                const baseAnnotes = generatePhysicalAnnotations();

                switch (exercise.category) {
                  case "crossfit":
                    baseAnnotes.CARDIO = true;
                    baseAnnotes.LIFT = true;
                    break;
                  case "stretching":
                    baseAnnotes.PHYSIO = true;
                    break;
                  case "plyometrics":
                  case "cardio":
                    baseAnnotes.CARDIO = true;
                    break;
                  default:
                    baseAnnotes.LIFT = true;
                    break;
                }

                if (exercise.name.toLowerCase().includes("walk")) {
                  baseAnnotes.WALK = true;
                }

                return {
                  ...exercise,
                  ...{
                    id: customs.length + i,
                    isPreset: true,
                    calories: caloriesPerMinute,
                    caloriesPerHalfHour: caloriesPerMinute * 30,
                    type: "physicalItem",
                    annotations: baseAnnotes,
                    tokensTriggered: exercise.tokensTriggered,
                  },
                };
              });
          }

          setUsedSearchResults(
            customs.concat(
              presets.sort((a, b) => {
                if (
                  !isNaN(a.tokensTriggered) &&
                  !isNaN(b.tokensTriggered) &&
                  a.tokensTriggered !== b.tokensTriggered
                ) {
                  return b.tokensTriggered - a.tokensTriggered;
                }
                return a.name.length - b.name.length;
              })
            )
          );
        } else {
          setUsedSearchResults(customs);
        }
      }

      res();
    });

    prom.then();
  };

  const generateSearchBar = () => {
    return (
      <div className="sectionItemRow">
        <div className="button-container nowrap noTopMargin noBottomMargin">
          <button
            className={`small-rectangle ${
              searchType === ALL ? "accent" : "disabled"
            }`}
            onClick={() => {
              setSearchType(ALL);
              updateSearchItems(searchQuery, ALL);
            }}
          >
            <div className="card-button-text">{t("all")}</div>
          </button>
          <button
            className={`small-rectangle ${
              searchType === MEALS_ONLY ? "accent" : "disabled"
            }`}
            onClick={() => {
              setSearchType(MEALS_ONLY);
              updateSearchItems(searchQuery, MEALS_ONLY);
            }}
          >
            <div className="card-button-text">{t("created")}</div>
          </button>
        </div>
        <input
          type="search"
          onChange={(e) => updateSearchItems(e.target.value)}
          placeholder={
            isFood ? t("stub-search-food") : t("stub-search-activities")
          }
          className="input-wide"
        />
        <div className="endRow">
          <InfoTooltip
            text={t(
              isFood ? t("info-searching-food") : t("info-searching-activities")
            )}
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      isOpen={props.modalVisible}
      onRequestClose={dismissWithoutInputFunc}
      animationType="none"
      appElement={document.getElementById("root") || undefined}
    >
      <div className="card-header-text-small-centered">
        {isFood ? t("manage-meals") : t("manage-activities")}
      </div>
      <div className="customCalorieContainer">
        <div className="button-container nowrap noBottomMargin">
          <button
            className="large-rectangle new"
            onClick={() => {
              newEntry();
              setShowSave(true);
            }}
          >
            <Icon path={mdiPlus} size={1} />
            <div className="card-button-text">
              {shortenText(
                isFood ? t("create-meal") : t("create-activity"),
                LARGE_BTN_MAX_STRING,
                true,
                0,
                true,
                true
              )}
            </div>
          </button>
          {/* {isFood && (
            <button className="large-rectangle special" onClick={openCamera}>
              <Icon path={mdiBarcode} size={1} />
              <div className="card-button-text">
                {shortenText(
                  t("camera"),
                  LARGE_BTN_MAX_STRING,
                  true,
                  0,
                  true,
                  true
                )}
              </div>
            </button>
          )} */}
          {Object.keys(priorItems).length > 0 && (
            <UnstyledSelectBasic
              options={generateItemsList()}
              defaultOption={priorItem}
              onChange={(_, v) => loadInPriorItem(v)}
            />
          )}
        </div>

        {showSave && (
          <div className="cardSectionPartContainer">
            <div className="inputContainer">
              {showWarning && (
                <div className="warningText customWarningText card-header-text-small">
                  {t("from-scanner-warning")}
                </div>
              )}
              <div className="calorieRangeSelector">
                <div className="sliderRangeText">
                  <div className="card-text-small">{`${min}${t("kcal")}`}</div>
                  <div className="card-text-small">
                    {sliderVal + `${t("kcal")}`}
                  </div>
                  <div className="card-text-small">{`${max}${t("kcal")}`}</div>
                </div>
                <Slider
                  aria-label="input"
                  value={sliderVal}
                  onChange={(event) => {
                    setTempSliderVal(Math.floor(event.target.value));
                    setSliderVal(Math.floor(event.target.value));
                  }}
                  min={min}
                  max={max}
                />
                <div className="sliderGroup">
                  <button
                    className="glyph-button"
                    onClick={() => {
                      if (sliderVal >= min + incRateLarge) {
                        setSliderVal(sliderVal - incRateLarge);
                      }
                    }}
                  >
                    <Icon
                      path={mdiChevronLeft}
                      size={1}
                      style={{
                        color:
                          sliderVal >= min + incRateLarge
                            ? BASE_ACCENT
                            : BASE_GRAY,
                      }}
                    />
                  </button>
                  <button
                    className="glyph-button"
                    onClick={() => {
                      if (sliderVal > min) {
                        setSliderVal(
                          Math.round((sliderVal - incRateSmall) * 100) / 100
                        );
                      }
                    }}
                  >
                    <Icon
                      path={mdiChevronDoubleLeft}
                      size={1}
                      style={{
                        color: sliderVal > min ? BASE_ACCENT : BASE_GRAY,
                      }}
                    />
                  </button>
                  <button
                    className="glyph-button"
                    onClick={() => setShowInputVisual(!showInputVisual)}
                  >
                    <Icon path={mdiKeyboard} size={1} />
                  </button>
                  {showInputVisual && (
                    <input
                      className="input-smallest cardTextSmallRaised"
                      type="number"
                      placeholder={`${tempSliderVal}`}
                      autoFocus={true}
                      onChange={(event) => {
                        const val = parseFloat(event.target.value);
                        if (val < max && val > min) {
                          setSliderVal(val);
                        } else if (val >= max) {
                          setSliderVal(max);
                        } else if (val <= min) {
                          setSliderVal(min);
                        }
                      }}
                    />
                  )}
                  <button
                    className="glyph-button"
                    onClick={() => {
                      if (sliderVal + incRateSmall <= max) {
                        setSliderVal(
                          Math.round((sliderVal + incRateSmall) * 100) / 100
                        );
                      }
                    }}
                  >
                    <Icon
                      path={mdiChevronDoubleRight}
                      size={1}
                      style={{
                        color:
                          sliderVal + incRateSmall <= max
                            ? BASE_ACCENT
                            : BASE_GRAY,
                      }}
                    />
                  </button>
                  <button
                    className="glyph-button"
                    onClick={() => {
                      if (sliderVal + incRateLarge <= max) {
                        setSliderVal(sliderVal + incRateLarge);
                      }
                    }}
                  >
                    <Icon
                      path={mdiChevronRight}
                      size={1}
                      style={{
                        color:
                          sliderVal + incRateLarge <= max
                            ? BASE_ACCENT
                            : BASE_GRAY,
                      }}
                    />
                  </button>
                </div>
              </div>

              <div className="button-container nowrap noTopMargin noBottomMargin">
                <button
                  className={`large-rectangle ${
                    view === 0 ? "accent" : "disabled"
                  }`}
                  onClick={() => setView(0)}
                >
                  <Icon path={mdiInformationOutline} size={1} />
                  <div className="card-button-text">
                    {shortenText(
                      isFood ? t("meal-info") : t("plan-info"),
                      LARGE_BTN_MAX_STRING,
                      true,
                      0,
                      true,
                      true
                    )}
                  </div>
                </button>
                <button
                  className={`large-rectangle ${
                    view === 1 ? "accent" : "disabled"
                  }`}
                  onClick={() => setView(1)}
                >
                  <Icon path={mdiViewList} size={1} />
                  <div className="card-button-text">
                    {shortenText(
                      `${t("contains")} ${dependencies.length} ${t(
                        isFood ? "foods" : "activities"
                      )}`,
                      LARGE_BTN_MAX_STRING,
                      true,
                      0,
                      true,
                      true
                    )}
                  </div>
                </button>
                <button
                  className={`large-rectangle ${
                    view === 2 ? "accent" : "disabled"
                  }`}
                  onClick={() => setView(2)}
                >
                  <Icon path={mdiPlaylistPlus} size={1} />
                  <div className="card-button-text">
                    {shortenText(
                      isFood ? t("add-food") : t("add-activity"),
                      LARGE_BTN_MAX_STRING,
                      true,
                      0,
                      true,
                      true
                    )}
                  </div>
                </button>
              </div>
              <div className="viewContainer">
                {view === 0 && (
                  <>
                    <div className="sectionItemRow">
                      <div className="card-text-small">{`${shortenText(
                        t("name"),
                        QUESTION_MAX_STRING,
                        true,
                        1
                      )}:`}</div>
                      <div className="endRow">
                        <input
                          className="input-wide"
                          placeholder={
                            isScannedYetUnknownName
                              ? t("unknown-name")
                              : t("name")
                          }
                          onChange={(e) => setNewItemName(e.target.value)}
                          value={newItemName}
                        />
                        <InfoTooltip text={t("m-cc-q1-info")} />
                      </div>
                    </div>
                    <div className="sectionItemRow">
                      <div className="card-text-small">
                        {`${shortenText(
                          t("repeat-settings"),
                          QUESTION_MAX_STRING,
                          true,
                          1
                        )}:`}
                      </div>
                      <div className="endRow">
                        <UnstyledSelectBasic
                          controlled
                          options={generateRepeatOptions(t)}
                          defaultOption={repeatSetting}
                          onChange={(_, v) => setRepeatSetting(v)}
                        />
                        <InfoTooltip text={t("m-cc-q3-info")} />
                      </div>
                    </div>
                    {isFood && (
                      <div className="sectionItemRow">
                        <div className="card-text-small">{`${shortenText(
                          t("serving-unit"),
                          QUESTION_MAX_STRING,
                          true,
                          1
                        )}:`}</div>
                        <div className="endRow">
                          <UnstyledSelectBasic
                            options={generateServingUnitOptions(
                              servingUnit.toUpperCase() ===
                                MEAL.toUpperCase() ||
                                servingUnit === TSP ||
                                servingUnit === GRAM ||
                                servingUnit === ML
                                ? undefined
                                : servingUnit,
                              t
                            )}
                            defaultOption={
                              servingUnit.toUpperCase() === MEAL.toUpperCase()
                                ? t(servingUnit)[0].toUpperCase() +
                                  t(servingUnit).slice(1)
                                : servingUnit
                            }
                            onChange={(e) => {
                              const v = e.target.textContent.toLowerCase();
                              setServingUnit(
                                v === t(GRAM)
                                  ? GRAM
                                  : v === t(ML)
                                  ? ML
                                  : v === t(TSP)
                                  ? TSP
                                  : MEAL
                              );
                              setServingSize(
                                v === t(GRAM)
                                  ? DEFAULT_SERVING_SIZE_G
                                  : v === t(ML)
                                  ? DEFAULT_SERVING_SIZE_ML
                                  : v === t(TSP)
                                  ? DEFAULT_SERVING_SIZE_TSP
                                  : DEFAULT_SERVING_SIZE_MEAL
                              );
                            }}
                          />
                          <InfoTooltip text={t("serving-unit-info")} />
                        </div>
                      </div>
                    )}
                    {isFood && (
                      <div className="sectionItemRow">
                        <div className="card-text-small">{`${shortenText(
                          t("serving-size"),
                          QUESTION_MAX_STRING,
                          true,
                          1
                        )}:`}</div>
                        <div className="endRow">
                          <UnstyledSelectBasic
                            options={generateServingSizeOptions(
                              t,
                              servingUnit === MEAL ||
                                servingUnit === TSP ||
                                servingUnit === GRAM ||
                                servingUnit === ML
                                ? servingUnit
                                : "",
                              modalData ? modalData.servingSize : undefined,
                              servingUnit !== MEAL &&
                                Array.from(
                                  { length: 100 },
                                  (_, i) => i + 1
                                ).reverse()
                            )}
                            defaultOption={servingSize}
                            onChange={(_, v) => {
                              const oldServingSize = servingSize;
                              setSliderVal(
                                Math.round((sliderVal * v) / oldServingSize)
                              ); // convert existing calorie slider info to scale.
                              setServingSize(v);
                            }}
                          />
                          <InfoTooltip text={t("serving-size-info")} />
                        </div>
                      </div>
                    )}
                    {isFood && (
                      <>
                        <div className="sectionItemRow">
                          <div className="card-text-small">{`${shortenText(
                            t("carbohydrates"),
                            QUESTION_MAX_STRING,
                            true,
                            1
                          )}:`}</div>
                          <div className="endRow">
                            <UnstyledSelectBasic
                              controlled
                              options={nutrientsNumberRange}
                              defaultOption={nutrients.carbs}
                              onChange={(_, v) => {
                                setNutrients({
                                  ...nutrients,
                                  ...{ carbs: v },
                                });
                              }}
                            />
                            <InfoTooltip text={t("info-carbs")} />
                          </div>
                        </div>
                        <div className="sectionItemRow">
                          <div className="card-text-small">{`${shortenText(
                            t("fat"),
                            QUESTION_MAX_STRING,
                            true,
                            1
                          )}:`}</div>
                          <div className="endRow">
                            <UnstyledSelectBasic
                              controlled
                              options={nutrientsNumberRange}
                              defaultOption={nutrients.fat}
                              onChange={(_, v) => {
                                setNutrients({
                                  ...nutrients,
                                  ...{ fat: v },
                                });
                              }}
                            />
                            <InfoTooltip text={t("info-fat")} />
                          </div>
                        </div>
                        <div className="sectionItemRow">
                          <div className="card-text-small">{`${shortenText(
                            t("protein"),
                            QUESTION_MAX_STRING,
                            true,
                            1
                          )}:`}</div>
                          <div className="endRow">
                            <UnstyledSelectBasic
                              controlled
                              options={nutrientsNumberRange}
                              defaultOption={nutrients.protein}
                              onChange={(_, v) => {
                                setNutrients({
                                  ...nutrients,
                                  ...{ protein: v },
                                });
                              }}
                            />
                            <InfoTooltip text={t("info-protein")} />
                          </div>
                        </div>
                        <div className="sectionItemRow">
                          <div className="card-text-small">{`${shortenText(
                            t("sugar"),
                            QUESTION_MAX_STRING,
                            true,
                            1
                          )}:`}</div>
                          <div className="endRow">
                            <UnstyledSelectBasic
                              controlled
                              options={nutrientsNumberRange}
                              defaultOption={nutrients.sugar}
                              onChange={(_, v) => {
                                setNutrients({
                                  ...nutrients,
                                  ...{ sugar: v },
                                });
                              }}
                            />
                            <InfoTooltip text={t("info-sugar")} />
                          </div>
                        </div>
                      </>
                    )}
                    {!isFood && (
                      <div className="sectionItemRow">
                        <div className="card-text-small">{`${t(
                          "annotations"
                        )}:`}</div>
                        <div className="endRow">
                          <div className="annotations">
                            {_generateSimpleAnnotations(annotations)}
                          </div>
                          <InfoTooltip text={t("m-cc-q2-info")} />
                        </div>
                      </div>
                    )}
                  </>
                )}
                {view === 1 && (
                  <>
                    <div className="TableArrowHeader">
                      <button
                        className="glyph-button"
                        onClick={() => backPage && setItemsPage(itemsPage - 1)}
                      >
                        <Icon
                          path={mdiChevronLeft}
                          size={1}
                          style={{
                            color: backPage ? BASE_ACCENT : BASE_GRAY,
                          }}
                        />
                      </button>
                      <div className="card-header-text-small-centered">
                        {`${t("contains-i18n")}: ${dependencies.length}`}
                      </div>
                      <button
                        className="glyph-button"
                        onClick={() =>
                          forwardPage && setItemsPage(itemsPage + 1)
                        }
                      >
                        <Icon
                          path={mdiChevronRight}
                          size={1}
                          style={{
                            color: forwardPage ? BASE_ACCENT : BASE_GRAY,
                          }}
                        />
                      </button>
                    </div>
                    {generateItemTableView()}
                  </>
                )}
                {view === 2 && (
                  <>
                    {generateSearchBar()}
                    {generateItemTable(
                      "searchResults",
                      usedSearchResults,
                      `${Object.keys(usedSearchResults).length} ${
                        isFood ? t("header-foods-found") : t("activities-found")
                      }`,
                      searchResultsPage,
                      setSearchResultsPage,
                      dependencies,
                      addDependencyEntry,
                      isFood
                        ? t("stub-search-food")
                        : t("stub-search-activities"),
                      selectedSearched,
                      setSelectedSearched,
                      searchType !== ALL
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="button-container nowrap noTopMargin noBottomMargin">
        <button
          className="large-rectangle info"
          onClick={dismissWithoutInputFunc}
        >
          <Icon path={mdiKeyboardReturn} size={1} />
          <div className="card-button-text">
            {shortenText(
              t("back-i18n"),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </div>
        </button>
        {newItemKey !== null && (
          <>
            <button className="large-rectangle" onClick={updateEntry}>
              <Icon path={mdiContentSave} size={1} />
              <div className="card-button-text">
                {shortenText(
                  t("update-meal"),
                  LARGE_BTN_MAX_STRING,
                  true,
                  0,
                  true,
                  true
                )}
              </div>
            </button>
            <button
              className="large-rectangle danger"
              onClick={removeLoadedEntry}
            >
              <Icon path={mdiDelete} size={1} />
              <div className="card-button-text">
                {shortenText(
                  isFood ? t("delete-meal") : t("delete-activity"),
                  LARGE_BTN_MAX_STRING,
                  true,
                  0,
                  true,
                  true
                )}
              </div>
            </button>
          </>
        )}

        {newItemKey === null && (
          <>
            <button
              className={`large-rectangle ${
                !(
                  newItemName !== null &&
                  newItemName !== t("unknown-name") &&
                  newItemName !== ""
                )
                  ? "disabled"
                  : "new"
              }`}
              onClick={() => {
                if (
                  newItemName !== null &&
                  newItemName !== t("unknown-name") &&
                  newItemName !== ""
                ) {
                  saveNewEntry();
                }
              }}
            >
              <Icon path={mdiContentSave} size={1} />
              <div className="card-button-text">
                {shortenText(
                  isFood ? t("save-meal") : t("save-activity"),
                  LARGE_BTN_MAX_STRING,
                  true,
                  0,
                  true,
                  true
                )}
              </div>
            </button>
          </>
        )}
      </div>
    </Modal>
  );
}
