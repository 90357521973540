import React from "react";
import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
// import { GoogleSignin } from '@react-native-google-signin/google-signin';
// import DeviceInfo from 'react-native-device-info';
// import mobileAds from 'react-native-google-mobile-ads';
import App from "../App";

export default function FirebaseWrapper(props) {
  // Google sign-up registration.
  // GoogleSignin.configure({
  //   webClientId:
  //     "582632124808-rq6hrlcomm4ohqegtl471lkgrvj5j663.apps.googleusercontent.com",
  //   offlineAccess: true,
  //   // iosClientId: '<FROM DEVELOPER CONSOLE>', // [iOS] if you want to specify the client ID of type iOS (otherwise, it is taken from GoogleService-Info.plist)
  //   scopes: [
  //     // 'https://www.googleapis.com/auth/fitness.activity.read',
  //     // 'https://www.googleapis.com/auth/fitness.activity.write',
  //     "https://www.googleapis.com/auth/fitness.body.read",
  //     "https://www.googleapis.com/auth/fitness.body.write",
  //     // 'https://www.googleapis.com/auth/fitness.nutrition.read',
  //     // 'https://www.googleapis.com/auth/fitness.nutrition.write',
  //     // 'https://www.googleapis.com/auth/fitness.sleep.read',
  //     // 'https://www.googleapis.com/auth/fitness.sleep.write',
  //   ],
  // });

  // mobileAds().setRequestConfiguration({
  //   // Update all future requests suitable for parental guidance
  //   // maxAdContentRating: MaxAdContentRating.PG,

  //   // Indicates that you want your content treated as child-directed for purposes of COPPA.
  //   // tagForChildDirectedTreatment: true,

  //   // Indicates that you want the ad request to be handled in a
  //   // manner suitable for users under the age of consent.
  //   // tagForUnderAgeOfConsent: true,

  //   // An array of test device IDs to allow.
  //   testDeviceIdentifiers: testDeviceIdentifiersArr,
  // });

  const firebaseConfig = {
    apiKey: "AIzaSyDL0i_ZU2KKHZbxpzFP3odyBbn9vA9vVt0",
    authDomain: "weighter-b67fd.firebaseapp.com",
    // databaseURL: "https://your-database-name.firebaseio.com",
    projectId: "weighter-b67fd",
    // storageBucket: "your-project-id-1234.appspot.com",
    // messagingSenderId: "12345-insert-yourse",
    // appId: "insert yours: 1:1234:web:ee873bd1234c0deb7eba61ce",
    // For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
    // measurementId: "G-MEASUREMENT_ID",
  };

  const app = initializeApp(firebaseConfig); // Initialize Firebase
  const auth = getAuth(app); // Initialize Firebase Authentication and get a reference to the service
  const firestore = getFirestore(app); // Initialize Cloud Firestore and get a reference to the service

  // Dev environment variable check and setup.
  if (process.env.NODE_ENV !== "production") {
    connectAuthEmulator(auth, "http://127.0.0.1:9099");
    connectFirestoreEmulator(firestore, "127.0.0.1", 8080);
  }

  return <App />;
}
