import React from "react";
import "../../../Supports/Constants";
import { shortenText } from "../../../Supports/Functions";
import "./Card.css";
import Section from "../Section";

// The primary purpose of this component is to structure the base card,
// and handle the animation logic for various collapsing height calculations.
export default function Card(props) {
  const header = props.header;
  const id = props.id;
  const info = props.info;
  const leftButtonTitle = props.leftButtonTitle;
  const rightButtonTitle = props.rightButtonTitle;
  const sections = props.children;
  const activeCardStates = props.activeCardStates;
  const setActiveCardStates = props.setActiveCardStates;

  const validStates = new Set([0, 1, 2]);
  if (!validStates.has(activeCardStates[id])) {
    activeCardStates[id] = 1; // Add default if not yet used.
  }

  if (!sections) {
    return <></>;
  }

  return (
    <Section>
      <div className="card-header">
        <div className={`${activeCardStates[id] === 0 ? "show-active" : ""}`}>
          <button
            className={"large-square"}
            onClick={() =>
              setActiveCardStates({ ...activeCardStates, [id]: 0 })
            }
          >
            {shortenText(leftButtonTitle, 5, false, 0, false, false, true, 3)}
          </button>
        </div>
        <div
          className={`card-header-center-box ${
            activeCardStates[id] === 1 ? "show-active" : ""
          }`}
          onClick={() => setActiveCardStates({ ...activeCardStates, [id]: 1 })}
        >
          <div className="card-header-title">{header}</div>
          <div className="card-header-info">{info}</div>
        </div>
        <div className={`${activeCardStates[id] === 2 ? "show-active" : ""}`}>
          <button
            className="large-square"
            onClick={() =>
              setActiveCardStates({ ...activeCardStates, [id]: 2 })
            }
          >
            {shortenText(rightButtonTitle, 5, false, 0, false, false, true, 3)}
          </button>
        </div>
      </div>
      <>{sections[activeCardStates[id]]}</>
    </Section>
  );
}
