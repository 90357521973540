import React, { useState } from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { LARGE_BTN_MAX_STRING } from "../../../Supports/Constants";
import { getDate, shortenText } from "../../../Supports/Functions";
import Icon from "@mdi/react";
import {
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
  mdiChevronLeft,
  mdiChevronRight,
  mdiKeyboardReturn,
  mdiTimerOff,
} from "@mdi/js";
import Slider from "@mui/material/Slider";

// Component to handle the confirmation of a fast end. Allows for slider/arrow adjustments to finesse.
export default function EndFastModal(props) {
  const { t } = useTranslation();
  const min = 0;
  const today = getDate(null, false);
  const completed = (props.endTarget - today.time) / 1000;
  const max = props.currentFast.fastGoalDuration - completed;
  const [sliderVal, setSliderVal] = useState(max);
  const [firstOpenedTrigger, setFirstOpenedTrigger] = useState(true);

  if (isNaN(completed)) {
    return <></>;
  }

  if (firstOpenedTrigger && props.modalVisible) {
    setSliderVal(max);
    setFirstOpenedTrigger(false);
  }

  const translateDurationToHM = (dur) => {
    const sc = t("second-short");
    const mn = t("minute-short");
    const hr = t("hour-short");

    const remH = Math.floor(Math.abs(dur / 3600));
    const remM = Math.floor(Math.abs((dur % 3600) / 60));
    const remS = Math.floor(Math.abs(dur % 60));
    const remFormatted =
      remH <= 0 && remM <= 0
        ? `${remS}${sc}`
        : remH <= 0
        ? `${remM}${mn} ${remS}${sc}`
        : `${remH}${hr} ${remM}${mn} ${remS}${sc}`;

    return remFormatted;
  };

  const shiftTime = (isHour, isUpshift, delta = 1) => {
    const multiplier = 3600 * (isHour ? 1 : 1 / 60);
    const totalDelta = delta * multiplier;
    const newVal = sliderVal + (isUpshift ? totalDelta : -totalDelta);
    setSliderVal(newVal < 0 ? 0 : newVal > max ? max : newVal);
  };

  const dismissWithInputFunc = () => {
    props.setModalVisible(false);
    setSliderVal(max);
    setFirstOpenedTrigger(true);
    props.func(sliderVal);
  };

  const dismissWithoutInputFunc = () => {
    props.setModalVisible(false);
    setSliderVal(max);
    setFirstOpenedTrigger(true);
  };

  return (
    <Modal
      isOpen={props.modalVisible}
      onRequestClose={dismissWithoutInputFunc}
      animationType="none"
      appElement={document.getElementById("root") || undefined}
    >
      <div className="modal-header-text-small">
        {t("m-ftt-end-title", {
          varLength: translateDurationToHM(sliderVal),
        })}
      </div>
      <div className="sliderRangeText">
        <div className="card-text-small">{`${min}${t("hour-short")}`}</div>
        <div className="sliderValuePairing card-text-small">
          {translateDurationToHM(sliderVal)}
        </div>
        <div className="card-text-small">{`${translateDurationToHM(max)}`}</div>
      </div>
      <Slider
        aria-label="input"
        value={sliderVal}
        onChange={(event) => setSliderVal(Math.floor(event.target.value))}
        min={min}
        max={max}
      />
      <div className="sliderGroup">
        <button
          className="glyph-button"
          onClick={() => sliderVal > min && shiftTime(true, false)}
        >
          <Icon size={1} path={mdiChevronLeft} />
        </button>
        <button
          className="glyph-button"
          onClick={() => sliderVal > min && shiftTime(false, false)}
        >
          <Icon size={1} path={mdiChevronDoubleLeft} />
        </button>
        <button
          className="glyph-button"
          onClick={() => sliderVal < max && shiftTime(false, true)}
        >
          <Icon size={1} path={mdiChevronDoubleRight} />
        </button>
        <button
          className="glyph-button"
          onClick={() => sliderVal < max && shiftTime(true, true)}
        >
          <Icon size={1} path={mdiChevronRight} />
        </button>
      </div>
      <div className="button-container nowrap noTopMargin noBottomMargin">
        <button
          className="large-rectangle danger"
          onClick={dismissWithoutInputFunc}
        >
          <Icon size={1} path={mdiKeyboardReturn} />
          <div className="card-button-text">
            {shortenText(
              t("cancel"),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </div>
        </button>
        <button
          className="large-rectangle accent"
          onClick={dismissWithInputFunc}
        >
          <Icon size={1} path={mdiTimerOff} />
          <div className="card-button-text">
            {shortenText(
              t("m-ftt-end-btn-end"),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </div>
        </button>
      </div>
    </Modal>
  );
}
