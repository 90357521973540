import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import {
  APP_NAME,
  BASE_ACCENT,
  BASE_DARK_GRAY,
  CONTACT_EMAIL,
  DARK_THEME,
  DEFAULT_LANGUAGE,
  KILOGRAMS,
  LARGE_BTN_MAX_STRING,
  LIGHT_THEME,
  POUNDS,
  QUESTION_MAX_STRING,
  STABLE_LANGUAGES,
  STONES,
} from "../../../Supports/Constants";
import {
  converterUserWeight,
  generateAges,
  generateHeights,
  getBMR,
  getTodayDate,
  heightStringToObject,
  objectToHeightString,
  shortenText,
  updateVal,
} from "../../../Supports/Functions";
import { generateLanguages } from "../../../Supports/Templates";

import Icon from "@mdi/react";
import {
  mdiAutorenew,
  mdiBook,
  mdiContentSave,
  mdiDelete,
  mdiEmail,
  mdiKeyboardReturn,
} from "@mdi/js";
import i18n from "../../../i18n/config";
import UnstyledSelectBasic from "../../../Components/Supports/UnstyledSelectBasic";
import { ToggleButtonGroup } from "@mui/material";
import MuiToggleButton from "@mui/material/ToggleButton";
import { styled } from "@mui/material/styles";
import "./DropDownModal.css";
import { InfoTooltip } from "../../Supports/InfoTooltip";
import { DataContext } from "../../../Supports/Contexts";

// Component to handle the dropdown functionality for both settings and about sections.
export default function DropDownModal(props) {
  const { t } = useTranslation();
  const Data = useContext(DataContext);
  const [warning, setWarning] = useState("");

  const today = getTodayDate();

  const body = props.userData.body;
  const weightUnit = props.userData.weight.settings.unit;
  const age = body.age?.value;
  const sex = body.sex;
  const language = props.userData.settings.language || DEFAULT_LANGUAGE;
  const theme = props.isDarkTheme ? DARK_THEME : LIGHT_THEME;
  const height = objectToHeightString(body.height);
  const weightLog = Data.weightLog;

  const [weight, setWeight] = useState(null);

  useEffect(() => {
    !Data.loading &&
      setWeight(weightLog[`${today.year}-${today.month}-${today.day}`]);
  }, [weightLog, Data.loading, today.year, today.month, today.day]);

  const noteSettings = props.userData.settings?.notifications;
  const noteNoCal = noteSettings?.noCal || true;
  const noteNoWeight = noteSettings?.noWeight || true;
  const noteFastEnd = noteSettings?.fastEnd || true;

  const birthday = body.birthday || null;

  const [ddSettings, setDDSettings] = useState({
    age: age || null,
    sex: sex || null,
    height: height || null,
    language: language || null,
    theme: theme || false,
    weightUnit: weightUnit || null,
    noteNoCal: noteNoCal || true,
    noteNoWeight: noteNoWeight || true,
    noteFastEnd: noteFastEnd || true,
  });

  const originalState = useRef({
    age: age,
    sex: sex,
    height: height,
    language: language,
    theme: theme,
    weightUnit: weightUnit,
    noteNoCal: noteNoCal,
    noteNoWeight: noteNoWeight,
    noteFastEnd: noteFastEnd,
  });

  if (!props.userData || !props.userData.body.age || Data.loadingWeightLog) {
    return <></>;
  }

  const hasStateChanged = (diff) => {
    const keys = Object.keys(diff);

    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      if (originalState.current[key] !== diff[key]) {
        return true;
      }
    }
    return false;
  };

  const updateState = (value) => {
    setDDSettings({ ...ddSettings, ...value });

    let warning = "";

    if (
      value.language &&
      STABLE_LANGUAGES.findIndex((l) => l === value.language) === -1
    ) {
      warning = t("m-dd-s-unstable-language-warning");
    }

    warning += hasStateChanged({ ...ddSettings, ...value })
      ? `${warning !== "" ? "\n\n" : ""}${t("warning-changes-saved")}`
      : "";

    setWarning(warning);
  };

  const setUser = (values) => {
    const now = new Date();
    const today = getTodayDate();

    const newBMR = getBMR({
      age: values.age || age,
      sex: values.sex || sex,
      height: values.height || height,
      weight: converterUserWeight(weight.unit, weight.value, POUNDS),
    });

    i18n.changeLanguage(ddSettings.language);

    props.userData.settings = {
      theme: ddSettings.theme,
      language: ddSettings.language,
      notifications: {
        noCal: ddSettings.noteNoCal,
        noWeight: ddSettings.noteNoWeight,
        fastEnd: ddSettings.noteFastEnd,
      },
    };

    props.userData.body = {
      height: values.height || height,
      sex: values.sex || sex,
      birthday: values.birthday || birthday,
      age: values.birthday
        ? {
            value: Math.floor(now - values.birthday / 31557600000), // FUTURE: will this break it? unsure if values.birthday is the correct expectation
            datetime: values.birthday,
            date: today.date, // FUTURE: get datetime of birthday injected when basicinfo.
          }
        : { value: values.age, date: today.date, datetime: today.json },
      basalMetabolicRate: newBMR,
    };

    props.userData.weight.settings = {
      ...props.userData.weight.settings,
      ...{ unit: ddSettings.weightUnit },
    };

    return updateVal(
      {
        body: props.userData.body,
      },
      props.userData.id
    )
      .then(
        updateVal(
          {
            "weight.settings": props.userData.weight.settings,
          },
          props.userData.id
        )
      )
      .then(
        updateVal(
          {
            settings: props.userData.settings,
          },
          props.userData.id
        )
      );
  };

  const saveChanges = () => {
    originalState.current = ddSettings;
    setWarning("");
    props.setDropDownModalVisible(false);

    setUser({
      age: ddSettings.age,
      sex: ddSettings.sex,
      height: heightStringToObject(ddSettings.height),
      weightUnit: ddSettings.weightUnit,
      language: ddSettings.language,
      theme: ddSettings.theme,
    }).catch((error) => {});
  };

  const dismissWithoutInputFunc = () => {
    setWarning("");
    props.setDropDownModalVisible(false);
  };

  const ages = generateAges();
  const heights = generateHeights();

  const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: BASE_ACCENT,
    },
    "&:not(.Mui-selected)": {
      color: "white",
      backgroundColor: BASE_DARK_GRAY,
    },
  }));

  const settingSections = [
    {
      header: t("m-dd-s1-head"),
      content: (
        <>
          <div className="sectionItemRow">
            <div className="card-text-small">{`${shortenText(
              t("age"),
              QUESTION_MAX_STRING,
              true,
              1
            )}:`}</div>
            <div className="endRow">
              <UnstyledSelectBasic
                options={ages}
                defaultOption={ddSettings.age}
                onChange={(_, age) => updateState({ age: age })}
              />
              <InfoTooltip text={t("age-info")} />
            </div>
          </div>
          <div className="sectionItemRow">
            <label className="card-text-small">{`${shortenText(
              t("sex"),
              QUESTION_MAX_STRING,
              true,
              1
            )}:`}</label>
            <div className="endRow">
              <ToggleButtonGroup
                className="row-content"
                value={ddSettings.sex === "f" ? "female" : "male"}
                exclusive
                onChange={(_, newSex) =>
                  updateState({ sex: newSex === "female" ? "f" : "m" })
                }
                aria-label="sex"
              >
                <ToggleButton
                  className="large-rectangle-no-color"
                  value="female"
                  aria-label="female"
                >
                  {t("female")}
                </ToggleButton>
                <ToggleButton
                  className="large-rectangle-no-color"
                  value="male"
                  aria-label="male"
                >
                  {t("male")}
                </ToggleButton>
              </ToggleButtonGroup>
              <InfoTooltip text={t("sex-info")} />
            </div>
          </div>
          <div className="sectionItemRow">
            <div className="card-text-small">{`${shortenText(
              t("height"),
              QUESTION_MAX_STRING,
              true,
              1
            )}:`}</div>
            <div className="endRow">
              <UnstyledSelectBasic
                options={heights}
                defaultOption={ddSettings.height}
                onChange={(_, height) => updateState({ height: height })}
              />
              <InfoTooltip text={t("height-info")} />
            </div>
          </div>
          <div className="sectionItemRow">
            <div className="card-text-small">{`${shortenText(
              t("weight-unit"),
              QUESTION_MAX_STRING,
              true,
              1
            )}:`}</div>
            <div className="endRow">
              <ToggleButtonGroup
                className="row-content"
                value={ddSettings.weightUnit}
                exclusive
                onChange={(_, newWeightUnit) => {
                  updateState({ weightUnit: newWeightUnit });
                }}
                aria-label="weight unit"
              >
                <ToggleButton
                  className="large-rectangle-no-color"
                  value={POUNDS}
                  aria-label={POUNDS}
                >
                  {POUNDS}
                </ToggleButton>
                <ToggleButton
                  className="large-rectangle-no-color"
                  value={KILOGRAMS}
                  aria-label={KILOGRAMS}
                >
                  {KILOGRAMS}
                </ToggleButton>
                <ToggleButton
                  className="large-rectangle-no-color"
                  value={STONES}
                  aria-label={STONES}
                >
                  {STONES}
                </ToggleButton>
              </ToggleButtonGroup>
              <InfoTooltip text={t("weight-unit-info")} />
            </div>
          </div>
        </>
      ),
    },
    {
      header: t("appearance"),
      content: (
        <>
          <div className="sectionItemRow">
            <div className="card-text-small">{`${shortenText(
              t("language"),
              QUESTION_MAX_STRING,
              true,
              1
            )}:`}</div>
            <div className="endRow">
              <UnstyledSelectBasic
                options={generateLanguages(t)}
                defaultOption={ddSettings.language}
                onChange={(_, newLanguage) => {
                  i18n.changeLanguage(newLanguage);
                  updateState({ language: newLanguage });
                }}
              />
              <InfoTooltip text={t("language-info", { varApp: APP_NAME })} />
            </div>
          </div>
        </>
      ),
    },
    {
      header: t("m-dd-s2-head"),
      content: (
        <>
          <div className="sectionItemRow">
            <div className="card-text-small">{`${shortenText(
              t("m-dd-s-reset"),
              QUESTION_MAX_STRING,
              true,
              1
            )}:`}</div>
            <div className="endRow">
              <div className="button-container nowrap noTopMargin noBottomMargin flex-end full-width">
                <button
                  className="large-rectangle warning"
                  onClick={() =>
                    props.setDropDownModalVisible("RESET_ACCOUNT_CONFIRM")
                  }
                >
                  <Icon path={mdiAutorenew} size={1} />
                  <div className="card-button-text">
                    {shortenText(
                      t("reset"),
                      LARGE_BTN_MAX_STRING,
                      true,
                      0,
                      true,
                      true
                    )}
                  </div>
                </button>
              </div>
              <InfoTooltip text={t("m-dd-s-s5-info")} />
            </div>
          </div>
          <div className="sectionItemRow">
            <div className="card-text-small">{`${shortenText(
              t("m-dd-s-delete"),
              QUESTION_MAX_STRING,
              true,
              1
            )}:`}</div>
            <div className="endRow">
              <div className="button-container nowrap noTopMargin noBottomMargin flex-end full-width">
                <button
                  className="large-rectangle danger"
                  onClick={() =>
                    props.setDropDownModalVisible("DELETE_ACCOUNT_CONFIRM")
                  }
                >
                  <Icon path={mdiDelete} size={1} />
                  <div className="card-button-text">
                    {shortenText(
                      t("delete"),
                      LARGE_BTN_MAX_STRING,
                      true,
                      0,
                      true,
                      true
                    )}
                  </div>
                </button>
              </div>
              <InfoTooltip text={t("m-dd-s-s6-info")} />
            </div>
          </div>
        </>
      ),
    },
  ];

  const aboutSections = [
    {
      header: t("m-dd-a-title", { varApp: APP_NAME }),
      content: (
        <div className="principles-section">
          <label className="principles-text">
            <label className="principles-bold-text">
              {t("m-dd-a-s1-head")}
            </label>
            {t("m-dd-a-s1-body")}
          </label>
          <label className="principles-text">
            <label className="principles-bold-text">
              {t("m-dd-a-s2-head")}
            </label>
            {t("m-dd-a-s2-body")}
          </label>
          <label className="principles-text">
            <label className="principles-bold-text">
              {t("m-dd-a-s3-head")}
            </label>
            {t("m-dd-a-s3-body")}
          </label>
          <label className="principles-text">
            <label className="principles-bold-text">
              {t("m-dd-a-s4-head")}
            </label>
            {t("m-dd-a-s4-body")}
          </label>
          <label className="principles-text">
            <label className="principles-bold-text">
              {t("m-dd-a-s5-head")}
            </label>
            {t("m-dd-a-s5-body")}
          </label>
        </div>
      ),
    },
    {
      header: t("dd-ng-hc"),
      content: (
        <div className="principles-section">
          <label className="principles-text">
            <label className="principles-bold-text">{t("m-s1-h") + " "}</label>
            {t("m-s1-ss1-s")}
          </label>
          <label className="principles-text">
            <label className="principles-bold-text">
              {t("m-dd-a-fb-head")}
            </label>
            {t("m-dd-a-fb-body")}
          </label>
          <div className="button-container nowrap noTopMargin noBottomMargin flex-end full-width">
            <button
              className="large-rectangle accent"
              onClick={() => props.setDropDownModalVisible("GuideModal")}
            >
              <Icon path={mdiBook} size={1} />
              <div className="card-button-text">
                {shortenText(
                  t("v-g"),
                  LARGE_BTN_MAX_STRING,
                  true,
                  0,
                  true,
                  true
                )}
              </div>
            </button>
            <button
              className="large-rectangle new"
              onClick={() =>
                window.open(
                  `mailto:${CONTACT_EMAIL}?subject=${APP_NAME} ${t(
                    "m-dd-a-fb-title"
                  )}`
                )
              }
            >
              <Icon path={mdiEmail} size={1} />
              <div className="card-button-text">
                {shortenText(
                  t("m-dd-a-fb-btn"),
                  LARGE_BTN_MAX_STRING,
                  true,
                  0,
                  true,
                  true
                )}
              </div>
            </button>
          </div>
        </div>
      ),
    },
  ];

  const sections =
    props.dropDownModalVisible === "DROPDOWN_SETTINGS"
      ? settingSections
      : aboutSections;

  const buildSections = () => {
    const col = [];
    sections.forEach((section, i) => {
      col.push(
        <div key={"section" + i}>
          <div className="card-header-text-small-centered">
            {section.header}
          </div>
          {section.content}
        </div>
      );
    });
    return col;
  };

  return (
    <Modal
      isOpen={
        props.dropDownModalVisible === "DROPDOWN_ABOUT" ||
        props.dropDownModalVisible === "DROPDOWN_SETTINGS"
      }
      onRequestClose={dismissWithoutInputFunc}
      animationType="none"
      appElement={document.getElementById("root") || undefined}
    >
      {sections.length > 0 && buildSections()}
      {warning !== "" && <div className="warningText">{t(warning)}</div>}
      <div className="button-container nowrap noTopMargin noBottomMargin">
        <button
          className="large-rectangle accent"
          onClick={dismissWithoutInputFunc}
        >
          <Icon path={mdiKeyboardReturn} size={1} />
          <div className="card-button-text">
            {shortenText(t("close"), LARGE_BTN_MAX_STRING, true, 0, true, true)}
          </div>
        </button>
        {props.dropDownModalVisible === "DROPDOWN_SETTINGS" && (
          <button className="large-rectangle new" onClick={saveChanges}>
            <Icon path={mdiContentSave} size={1} />
            <div className="card-button-text">
              {shortenText(
                t("save"),
                LARGE_BTN_MAX_STRING,
                true,
                0,
                true,
                true
              )}
            </div>
          </button>
        )}
      </div>
    </Modal>
  );
}
