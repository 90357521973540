import React, {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import CalorieBar from "./CalorieBar";
import {
  CALORIE_CONTEXT_BUTTONS,
  CT_FOOD_ANNOTATIONS,
  CT_FOOD_BUTTONS,
  CT_PHYSICAL_ANNOTATIONS,
  CT_PHYSICAL_BUTTONS,
} from "../../../Supports/Buttons";
import Card from "../../Structural/Card/Card";
import CardContent from "../../Structural/Card/CardContent";
import {
  CALORIES_IN_LB,
  INFO_BANNER_MAX_STRING,
} from "../../../Supports/Constants";
import {
  areDatetimesOnDifferentDates,
  compareISODatetimes,
  DEFAULT_REPEAT_SETTING,
  getEarlierDate,
  getLaterDate,
  shortenText,
  updateVal,
} from "../../../Supports/Functions";
import Dependency from "../../../Supports/dependency";
import {
  generateCalorieAnnotations,
  generateCalorieCurrentItem,
  generatePhysicalAnnotations,
} from "../../../Supports/Templates";
import foodsData from "../../../Assets/datasets/foods.json";
import exercisesData from "../../../Assets/datasets/exercises.json";
import {
  DataContext,
  ModalContext,
  ThemeContext,
} from "../../../Supports/Contexts";
import { doc, getFirestore, setDoc } from "firebase/firestore";

export default function CalorieCard(props) {
  const { t } = useTranslation();
  const Data = useContext(DataContext);
  const theme = useContext(ThemeContext);
  const modalContext = useContext(ModalContext);
  const isDarkTheme = theme.theme !== "light";

  const weightLog = Data.weightLog;
  const calorieLog = Data.calorieLog;

  const [foods] = useState({
    types: foodsData.types,
    foods: foodsData.foods.map((f) => {
      f.tokens = f.d.replace(/[&/\\#,+()$~%.'":*?<>{}-]/g, " ").split(" ");
      return f;
    }),
  });
  const [exercises] = useState({
    exercises: exercisesData.exercises.map((e) => {
      e.tokens = e.name.replace(/[&/\\#,+()$~%.'":*?<>{}-]/g, " ").split(" ");
      return e;
    }),
  });

  const activeDate = props.activeDate;
  const setActiveDate = props.setActiveDate;
  const userData = props.userData;
  const plans = props.userData.workout.plans;
  const body = userData.body;
  const fastStatus = userData.fast.current.status;
  const calories = userData.calories;

  const language = props.userData.settings.language;
  const bmr = body.basalMetabolicRate;
  const savedItems = calories.savedItems;

  const [savedFoodItems, setSavedFoodItems] = useState([]);
  const [savedPhysicalItems, setSavedPhysicalItems] = useState([]);

  useEffect(() => {
    !Data.loadingFoodItems &&
      Data.foodItems &&
      setSavedFoodItems(Data.foodItems);
  }, [Data.loadingFoodItems, Data.foodItems]);

  useEffect(() => {
    !Data.loadingPhysicalItems &&
      Data.physicalItems &&
      setSavedPhysicalItems(Data.physicalItems);
  }, [Data.loadingPhysicalItems, Data.physicalItems]);

  const itemGraph = useRef(new Dependency(savedItems.food.graph)).current;
  const itemActivityGraph = useRef(
    new Dependency(savedItems.physical.graph)
  ).current;

  const [currentWeight, setCurrentWeight] = useState(null);
  const [current, setCurrent] = useState(null);
  const [usedItems, setUsedItems] = useState([]);

  const foodItems = useMemo(() => current?.foodItems ?? [], [current]);
  const physicalItems = useMemo(() => current?.physicalItems ?? [], [current]);

  const caloriesIn = current?.foodItems
    ? current?.foodItems.reduce(
        (accumulator, item) => accumulator + item.calories,
        0
      )
    : 0;

  const caloriesOut = current?.physicalItems
    ? current?.physicalItems.reduce(
        (accumulator, item) => accumulator + item.calories,
        0
      )
    : 0;

  const changeViewedItemLogic = useCallback(
    (values) => {
      if (!values) {
        return null;
      }

      const internalFood = values.foodItems
        ? values.foodItems
        : foodItems && foodItems[0]?.date === activeDate.date
        ? foodItems
        : [];
      const internalPhysical = values.physicalItems
        ? values.physicalItems
        : physicalItems && physicalItems[0]?.date === activeDate.date
        ? physicalItems
        : [];

      const calorieCardState = props.activeCardStates.calorie;

      if (!values.collection) {
        if (
          internalFood &&
          internalFood[values.index] &&
          (calorieCardState === 0 || calorieCardState === 1)
        ) {
          const item = internalFood[values.index];
          item.index = item.index || values.index;
          return item;
        } else if (
          internalPhysical &&
          internalPhysical[internalPhysical.length - 1 - values.index] &&
          (calorieCardState === 2 || calorieCardState === 1)
        ) {
          const item =
            internalPhysical[internalPhysical.length - 1 - values.index];
          item.index = item.index || values.index;
          return item;
        } else {
          return null;
        }
      } else {
        return values.collection === "foodItem"
          ? internalFood[values.index]
          : internalPhysical[values.index];
      }
    },
    [activeDate.date, foodItems, physicalItems, props.activeCardStates]
  );

  const [latestItem, setLatestItem] = useState(null);

  const changeViewedItem = useCallback(
    (values) => {
      setLatestItem(changeViewedItemLogic(values));
    },
    [setLatestItem, changeViewedItemLogic]
  );

  const addItem = useCallback(
    (type, items, isGeneric = false) => {
      const isFood = type === "foodItem";
      const store = isFood ? foodItems : physicalItems;
      const typeName = isFood ? "foodItem" : "physicalItem";
      const descriptorName = isFood ? "Food" : "Physical";
      const annotationSet = isFood
        ? generateCalorieAnnotations()
        : generatePhysicalAnnotations();
      const recentList = savedItems[isFood ? "food" : "physical"].recent;

      let preppedItems = items;

      if (!Array.isArray(items)) {
        preppedItems = [items];
      } // make sure preppedItems is an array by this point.

      setUsedItems([]);
      for (let index = 0; index < preppedItems.length; index++) {
        const item = preppedItems[index];
        const newKey = item.key
          ? item.key
          : `${descriptorName} ${store.length}`;
        const newItem = {
          index: store.length || 0,
          name: item.name ? item.name : `${descriptorName} ${store.length + 1}`,
          type: typeName,
          calories: item.calories,
          isPreset: item?.isPreset || false,
          nutrients: item?.nutrients || [],
          key: newKey,
          repeatSetting: item.repeatSetting
            ? item.repeatSetting
            : DEFAULT_REPEAT_SETTING,
          annotations: item.annotations ? item.annotations : annotationSet,
          dependencies: item?.dependencies || [],
          dependers: item?.dependers || [],
          datetime: activeDate.json,
          date: activeDate.date,
          servingSize: item.servingSize || 1,
          servingUnit: item.servingUnit || "Meal",
          scalars: item.scalars || null,
        };

        store.push(newItem);

        const tempCurrent = current;

        if (isFood) {
          tempCurrent.caloriesIn += item.calories;
        } else {
          tempCurrent.caloriesOut += item.calories;
        }

        calorieLog[`${activeDate.year}-${activeDate.month}-${activeDate.day}`] =
          tempCurrent;

        // Update most recent usage in savedItems.X for each.
        const target = isFood
          ? savedFoodItems[newKey]
          : savedPhysicalItems[newKey];

        if (target) {
          target.lastUsed = activeDate.json;

          usedItems.push({
            key: newKey,
            store: isFood ? "food" : "physical",
            item: target,
          });
        }

        if (!isGeneric) {
          const indx = recentList.findIndex((li) => li.name === newItem.name);
          indx !== -1 && recentList.splice(indx, 1); // Remove old position, if used before.
          item.name &&
            recentList.unshift({
              name: newItem.name,
              isPreset: newItem.isPreset,
              lookup: item?.lookup || newItem.key,
            }); // If not a generic, put to most recent.
        }
      }

      current[isFood ? "foodItems" : "physicalItems"] = store;

      return updateVal(
        {
          [`calories.savedItems.${isFood ? "food" : "physical"}.recent`]:
            recentList.slice(0, 19), // only 20 most recent items.
        },
        userData.id
      )
        .then(
          changeViewedItem({ collection: typeName, index: store.length - 1 })
        )
        .then(
          isFood &&
            fastStatus === "active" &&
            props.setModalVisible("END_FAST_FROM_FOOD")
        )
        .then(
          setDoc(
            doc(
              getFirestore(),
              `UserData/${userData.id}/CalorieLog`,
              `${activeDate.year}-${activeDate.month}-${activeDate.day}`
            ),
            calorieLog[
              `${activeDate.year}-${activeDate.month}-${activeDate.day}`
            ]
          )
        )
        .then(() => {
          usedItems.forEach((ui) => {
            return setDoc(
              doc(
                getFirestore(),
                `UserData/${userData.id}/${isFood ? "Food" : "Physical"}Items`,
                ui.key
              ),
              ui.item
            );
          });
        });
    },
    [
      activeDate.date,
      activeDate.day,
      activeDate.json,
      activeDate.month,
      activeDate.year,
      calorieLog,
      changeViewedItem,
      current,
      fastStatus,
      foodItems,
      physicalItems,
      props,
      savedFoodItems,
      savedItems,
      savedPhysicalItems,
      usedItems,
      userData.id,
    ]
  );

  useEffect(() => {
    !Data.loadingWeightLog &&
      setCurrentWeight(
        weightLog[`${activeDate.year}-${activeDate.month}-${activeDate.day}`]
      );

    Data.setSyncWeightLog(false);
  }, [
    weightLog,
    Data.loadingWeightLog,
    Data.syncWeightLog,
    activeDate.year,
    activeDate.month,
    activeDate.day,
    activeDate,
    Data,
  ]);

  useEffect(() => {
    if (!Data.loadingCalorieLog && !current) {
      const inCurr =
        calorieLog[
          `${activeDate.year}-${activeDate.month}-${activeDate.day}`
        ] ??
        generateCalorieCurrentItem(
          activeDate,
          bmr,
          savedItems,
          addItem,
          calorieLog
        );
      setCurrent(inCurr);
    }

    current &&
      (current.foodItems || current.physicalItems) &&
      setLatestItem(
        changeViewedItemLogic({
          collection: null,
          index: 0,
          foodItems: current.foodItems,
          physicalItems: current.physicalItems,
        })
      );
  }, [
    calorieLog,
    Data.loadingCalorieLog,
    activeDate.year,
    activeDate.month,
    activeDate.day,
    activeDate,
    bmr,
    savedItems,
    addItem,
    changeViewedItemLogic,
    current,
  ]);

  const [savedManagerState, setSavedManagerState] = useState(null);
  const [modalItemStore, setModalItemStore] = useState(null);
  const [hasNewCalorieItems, setHasNewCalorieItems] = useState(true);
  const [returnFromCalToAdd, setReturnFromCalToAdd] = useState(false);
  const [itemManagementTarget, setItemManagementTarget] = useState(null);
  const [percentItemTarget, setPercentItemTarget] = useState(null);

  const changeDate = (date) => {
    setActiveDate(date);

    let dayLog = calorieLog[`${date.year}-${date.month}-${date.day}`];

    if (dayLog) {
      setCurrent(dayLog);
    } else {
      const genDay = generateCalorieCurrentItem(
        date,
        bmr,
        savedItems,
        addItem,
        calorieLog
      );
      setCurrent(genDay);
      dayLog = genDay;
    }

    changeViewedItem({
      collection: null,
      index: 0,
      foodItems: dayLog?.foodItems,
      physicalItems: dayLog?.physicalItems,
    });
  };

  useImperativeHandle(props.homeRef, () => ({
    changeDate,
  }));

  const goalPace = currentWeight?.pace?.value || 0;
  const paceGoalCalsPerWeek = (goalPace * CALORIES_IN_LB) / 7;
  let annotations = null;

  const generateInfo = () => {
    const display = !isNaN(
      Math.abs(bmr + paceGoalCalsPerWeek - (caloriesIn - caloriesOut))
    )
      ? `${caloriesIn} ${t("of")} ${bmr + paceGoalCalsPerWeek + caloriesOut} ${
          caloriesOut > 0 ? `(+${caloriesOut}) ` : ""
        }| ${Math.abs(
          bmr + paceGoalCalsPerWeek - (caloriesIn - caloriesOut)
        )}${t("kcal")} ${shortenText(
          t(
            bmr + paceGoalCalsPerWeek - (caloriesIn - caloriesOut) > 0
              ? "left"
              : "over"
          ),
          10
        )}`
      : "";

    return shortenText(
      display,
      INFO_BANNER_MAX_STRING,
      true,
      0,
      false,
      false,
      false,
      Math.ceil(INFO_BANNER_MAX_STRING / 3),
      3,
      true,
      false // stops capitalizing 'of'.
    );
  };

  const stub = (
    <Card
      name="calorie"
      expandedCardHeight={props.expandedCardHeight}
      id="calorie"
      header={t("ct-clt")}
      info={generateInfo()}
      leftButtonTitle={t("cb-clt-l")}
      rightButtonTitle={t("cb-clt-r")}
      isExpanded={props.isExpanded}
      setExpanded={props.setExpanded}
      setActiveCardStates={props.setActiveCardStates}
      activeCardStates={props.activeCardStates}
    />
  );

  if (
    !userData ||
    !props.userData.body.age ||
    !current ||
    !calorieLog ||
    Data.loadingCalorieLog ||
    Data.loadingWeightLog ||
    Data.loadingSavedFoodItems ||
    Data.loadingSavedPhysicalItems
  ) {
    return stub;
  }

  if (
    latestItem &&
    !isNaN(latestItem.index) &&
    latestItem.date === activeDate.date
  ) {
    if (
      latestItem.type === "foodItem" &&
      foodItems &&
      foodItems[latestItem.index]
    ) {
      annotations = foodItems[latestItem.index].annotations;
    } else if (
      latestItem.type === "physicalItem" &&
      physicalItems &&
      physicalItems[latestItem.index]
    ) {
      annotations = physicalItems[latestItem.index].annotations;
    } else {
      annotations = null;
    }
  }

  const endFastFromFood = (res) => res && modalContext.setModal("ENDFAST");

  const removeItemAtIndex = (type, itemIndex) => {
    if (!type || itemIndex === null) {
      return;
    }
    try {
      if (type === "all") {
        type = latestItem.type;
      }
      const store = type === "foodItem" ? foodItems : physicalItems;
      const typeName = type === "foodItem" ? "foodItem" : "physicalItem";
      const otherStore = type === "foodItem" ? physicalItems : foodItems;
      const otherType = type === "foodItem" ? "physicalItem" : "foodItem";

      if (store && store.length > 0) {
        // Remove item from index;
        const removedItemArr = store.splice(itemIndex, 1);
        const removedItem = removedItemArr[0];
        const tempCurrent = current;
        tempCurrent[type === "foodItem" ? "caloriesIn" : "caloriesOut"] -=
          removedItem.calories;

        // rebuild the index incase there was spamming involved.
        for (let index = 0; index < store.length; index++) {
          store[index].index = index;
        }

        calorieLog[`${activeDate.year}-${activeDate.month}-${activeDate.day}`] =
          tempCurrent;

        // If the removed one was the last in the index, move the cursor down.
        let nextIndex = itemIndex; // stay in place, but shift down index from before.

        // but if in-place is now too far down, move to latest in store save.
        if (store.length - 1 < itemIndex) {
          nextIndex = store.length - 1;
        }

        // There's remaining items in the same store
        if (nextIndex >= 0) {
          changeViewedItem({ collection: typeName, index: nextIndex });
        } else if (otherStore.length > 0) {
          // if there's none in the store save, try the other store save.
          changeViewedItem({
            collection: otherType,
            index: otherStore.length - 1,
          });
        } else {
          // if there's none in the store save, return null, therefore selecting none.
          changeViewedItem(null);
        }

        return setDoc(
          doc(
            getFirestore(),
            `UserData/${userData.id}/CalorieLog`,
            `${activeDate.year}-${activeDate.month}-${activeDate.day}`
          ),
          calorieLog[`${activeDate.year}-${activeDate.month}-${activeDate.day}`]
        );
      }
    } catch (e) {
      // spamming the buttons
    }
  };

  const undoLast = (type) => {
    const store = type === "foodItem" ? foodItems : physicalItems;

    removeItemAtIndex(type, store.length - 1);
  };

  const selectViewedItem = () => {
    const nonSelectedText = t("c-clt-ctx-no-calories");
    if (!latestItem) {
      return nonSelectedText;
    }
  };

  const updateAnnotations = (btn) => {
    const store = latestItem.type === "foodItem" ? foodItems : physicalItems;
    if (store.length === 0) {
      return;
    }

    const storeName =
      latestItem.type === "foodItem" ? "foodItems" : "physicalItems";

    store[latestItem.index].annotations[btn.value] =
      !store[latestItem.index].annotations[btn.value];

    calorieLog[`${activeDate.year}-${activeDate.month}-${activeDate.day}`][
      storeName
    ] = store;

    props.setUserData({ ...userData, calories: calories });

    setDoc(
      doc(
        getFirestore(),
        `UserData/${userData.id}/CalorieLog`,
        `${activeDate.year}-${activeDate.month}-${activeDate.day}`
      ),
      calorieLog[`${activeDate.year}-${activeDate.month}-${activeDate.day}`]
    );
  };

  const hasEarlierItem = (both = true) => {
    if (latestItem === null || latestItem === undefined) {
      return false;
    }
    const targetIndex = latestItem.index - 1;
    const targetStore =
      latestItem.type === "foodItem" ? foodItems : physicalItems;

    // if starting from physical start, and moving left to food end.
    if (both && targetIndex < 0 && latestItem.type !== "foodItem") {
      return foodItems[foodItems.length - 1];
    }
    return targetStore[targetIndex];
  };

  const hasLaterItem = (both = true) => {
    if (latestItem === null || latestItem === undefined) {
      return false;
    }
    const targetIndex = latestItem.index + 1;
    const targetStore =
      latestItem.type === "foodItem" ? foodItems : physicalItems;

    // if starting from food end, and moving right to physical start.
    if (
      both &&
      targetIndex > targetStore.length - 1 &&
      latestItem.type === "foodItem" &&
      physicalItems
    ) {
      return physicalItems[0];
    }
    return targetStore[targetIndex];
  };

  const visType = (internalProps) => {
    return <CalorieBar visualizationData={internalProps.visualizationData} />;
  };

  const openGenericCustomCalorie = (trigger, data) => {
    props.setModalData(data);
    props.setModalVisible(trigger);
  };

  return (
    <Card
      name="calorie"
      id="calorie"
      header={t("ct-clt")}
      info={generateInfo()}
      leftButtonTitle={t("cb-clt-l")}
      rightButtonTitle={t("cb-clt-r")}
      setActiveCardStates={props.setActiveCardStates}
      activeCardStates={props.activeCardStates}
      externalTriggers={(params) => {
        switch (params.trigger) {
          case "CT_GENERIC_CUSTOM_FOR_WORKOUT_END":
            openGenericCustomCalorie(params.trigger, params.data);
            break;
          case "CUSTOM_FOOD_CALORIES":
            openGenericCustomCalorie(params.trigger, params.data);
            break;
          default:
            break;
        }
      }}
    >
      <CardContent
        foods={foods}
        exercises={exercises}
        userData={userData}
        itemGraph={itemGraph}
        hasNewCalorieItems={hasNewCalorieItems}
        setHasNewCalorieItems={setHasNewCalorieItems}
        savedManagerState={savedManagerState}
        setSavedManagerState={setSavedManagerState}
        isContextActive={true}
        interactionTitle={(item = null) => {
          return item && item.name && item.type === "foodItem"
            ? shortenText(`${t("description")}: ${item.name}`, 42)
            : t("description");
        }}
        interactions={CT_FOOD_ANNOTATIONS} // required annotation buttons.
        latestItem={latestItem} // required buttons
        interactionsDisabledCheck={{
          check: () => {
            return (
              foodItems.length <= 0 ||
              !latestItem ||
              latestItem.type !== "foodItem"
            );
          },
          disabledMessage: t("c-clt-default-l-stub"),
        }}
        annotations={
          latestItem && latestItem.type === "foodItem"
            ? latestItem.annotations
            : null
        }
        interactionFunc={updateAnnotations}
        isVisualizationActive={true}
        visualizationType={visType}
        visualizationData={{
          name: "FOOD",
          bmr: bmr,
          foods: foods,
          exercises: exercises,
          calorieLog: calorieLog,
          caloriesIn: caloriesIn,
          caloriesOut: caloriesOut,
          foodItems: foodItems,
          physicalItems: physicalItems,
          savedItems: savedItems,
          savedFoodItems: savedFoodItems,
          goalPace: goalPace,
          activeDate: activeDate,
          itemGraph: itemGraph,
          userData: userData,
          itemManagementTarget: itemManagementTarget,
          setItemManagementTarget: setItemManagementTarget,
          percentItemTarget: percentItemTarget,
          setPercentItemTarget: setPercentItemTarget,
          today: activeDate,
          setActiveDate: setActiveDate,
          hasEarlierItem: () => hasEarlierItem(false),
          hasLaterItem: () => hasLaterItem(false),
          previousDate: getEarlierDate(activeDate.json),
          nextDate: getLaterDate(activeDate.json),
          hasEarlierDate: calorieLog[getEarlierDate(activeDate.json)],
          hasLaterDate: calorieLog[getLaterDate(activeDate.json)],
          dayChangeAllowed: true,
          latestItem: latestItem,
          externalDisplayFunc: (b) => changeViewedItem(b),
          interactiveVisualization: true,
          changeDate: changeDate,
          setModalVisible: props.setModalVisible,
          modalVisible: props.modalVisible,
          // modalData: modalContext.modalData,
          hasNewCalorieItems: hasNewCalorieItems,
          setHasNewCalorieItems: setHasNewCalorieItems,
          endFastFromFood: endFastFromFood,
          returnFromCalToAdd: returnFromCalToAdd,
          setReturnFromCalToAdd: setReturnFromCalToAdd,
          savedManagerState: savedManagerState,
          setSavedManagerState: setSavedManagerState,
          modalItemStore: modalItemStore,
          setModalItemStore: setModalItemStore,
          isDarkTheme: isDarkTheme,
          language: language,
        }}
        contextTitle={t("c-clt-ctx-l-head")}
        contextInteractions={CT_FOOD_BUTTONS}
        customContextFuncs={{
          UNDO: (btn) =>
            removeItemAtIndex("foodItem", latestItem ? latestItem.index : null),
          CUSTOM_FOOD_CALORIES: (v) => addItem("foodItem", v),
          CUSTOM_FOOD_CAMERA: (v) =>
            openGenericCustomCalorie(v.trigger, v.triggerData),
          CUSTOM_GENERIC_CALORIES: (v) =>
            addItem("foodItem", { calories: v }, true),
        }}
        contextFunc={(btn) =>
          addItem("foodItem", { calories: btn.btnName }, true)
        }
        setModalVisible={props.setModalVisible}
        modalVisible={props.modalVisible}
        setItemManagementTarget={setItemManagementTarget}
        setPercentItemTarget={setPercentItemTarget}
        activeDate={activeDate}
        changeDate={changeDate}
        previousDate={getEarlierDate(activeDate.json)}
        nextDate={getLaterDate(activeDate.json)}
        setReturnFromCalToAdd={setReturnFromCalToAdd}
        fastStatus={fastStatus}
        setActiveCardStates={props.setActiveCardStates}
        activeCardStates={props.activeCardStates}
        savedFoodItems={savedFoodItems}
        savedPhysicalItems={savedPhysicalItems}
      />
      <CardContent
        itemGraph={itemGraph}
        foods={foods}
        exercises={exercises}
        setItemManagementTarget={setItemManagementTarget}
        contextTitle={(item = null) => {
          return item && item.name && latestItem.date === activeDate.date
            ? `${item.calories}${t("kcal")}: ${shortenText(item.name, 35)}`
            : t("c-clt-ctx-selected-entry");
        }}
        isContextActive={true}
        contextInteractions={CALORIE_CONTEXT_BUTTONS}
        contextInfo={selectViewedItem()}
        customContextFuncs={{
          CT_CNTX_UNDO: () =>
            removeItemAtIndex("all", latestItem ? latestItem.index : null),
          CT_CNTX_INFO: () => props.setModalVisible("CT_CNTX_INFO"),
          CT_CNTX_MORE: () => addItem(latestItem.type, latestItem, true),
          CT_CNTX_REDUCE: () => props.setModalVisible("CT_PERCENT_ITEM"), // callback functionality inserted as backup in the modal specs.
        }}
        externalDisplayFunc={(b) => changeViewedItem(b)}
        isVisualizationActive={true}
        visualizationType={visType}
        visualizationData={{
          name: "BOTH",
          bmr: bmr,
          foods: foods,
          exercises: exercises,
          calorieLog: calorieLog,
          caloriesIn: caloriesIn,
          caloriesOut: caloriesOut,
          foodItems: foodItems,
          savedItems: savedItems,
          itemGraph: itemGraph,
          userData: userData,
          itemManagementTarget: itemManagementTarget,
          setItemManagementTarget: setItemManagementTarget,
          physicalItems: physicalItems,
          goalPace: goalPace,
          activeDate: activeDate,
          today: activeDate,
          setActiveDate: setActiveDate,
          hasEarlierItem: () => hasEarlierItem(),
          hasLaterItem: () => hasLaterItem(),
          previousDate: getEarlierDate(activeDate.json),
          nextDate: getLaterDate(activeDate.json),
          hasEarlierDate: calorieLog[getEarlierDate(activeDate.json)],
          hasLaterDate: calorieLog[getLaterDate(activeDate.json)],
          dayChangeAllowed: true,
          latestItem: latestItem,
          externalDisplayFunc: (b) => changeViewedItem(b),
          interactiveVisualization: true,
          changeDate: changeDate,
          setModalVisible: props.setModalVisible,
          modalVisible: props.modalVisible,
          // modalData: modalContext.modalData,
          removeItemAtIndex: removeItemAtIndex,
          addItem: addItem,
          returnFromCalToAdd: returnFromCalToAdd,
          setReturnFromCalToAdd: setReturnFromCalToAdd,
          modalItemStore: modalItemStore,
          setModalItemStore: setModalItemStore,
          isDarkTheme: isDarkTheme,
          language: language,
        }}
        interactionTitle={(item = null) =>
          item && item.name
            ? shortenText(`${t("description")}: ${item.name}`, 42)
            : t("c-clt-ctx-entry-description")
        }
        latestItem={latestItem} // required buttons
        contextDisabledCheck={{
          check: () => {
            return latestItem?.date !== activeDate.date;
          },
          disabledMessage: t("c-clt-default-m-stub"),
        }}
        interactionsDisabledCheck={{
          check: () => {
            const active = activeDate.json;
            const todayDate = activeDate.json;
            if (
              compareISODatetimes(active, todayDate) < 0 &&
              areDatetimesOnDifferentDates(active, todayDate)
            ) {
              return "disableButtons";
            }
            return !latestItem;
          },
          disabledMessage: t("c-clt-default-m-stub"),
        }}
        annotations={annotations} // required annotation buttons.
        interactions={
          latestItem && latestItem.date === activeDate.date
            ? latestItem.type === "foodItem"
              ? CT_FOOD_ANNOTATIONS
              : CT_PHYSICAL_ANNOTATIONS
            : null
        }
        interactionFunc={updateAnnotations}
        setReturnFromCalToAdd={setReturnFromCalToAdd}
      />
      <CardContent
        userData={userData}
        foods={foods}
        exercises={exercises}
        setItemManagementTarget={setItemManagementTarget}
        setPercentItemTarget={setPercentItemTarget}
        savedManagerState={savedManagerState}
        setSavedManagerState={setSavedManagerState}
        // modalData={modalContext.modalData}
        itemGraph={itemActivityGraph}
        hasNewCalorieItems={hasNewCalorieItems}
        setHasNewCalorieItems={setHasNewCalorieItems}
        isContextActive={true}
        interactionTitle={(item = null) =>
          shortenText(
            `${
              item && item.name && item.type !== "foodItem"
                ? shortenText(`${t("description")}: ${item.name}`, 42)
                : t("description")
            }`,
            55,
            true,
            0,
            true,
            true
          )
        }
        interactionsDisabledCheck={{
          check: () => {
            const active = activeDate.json;
            const todayDate = activeDate.json;
            if (
              compareISODatetimes(active, todayDate) < 0 &&
              areDatetimesOnDifferentDates(active, todayDate)
            ) {
              return "disableButtons";
            }

            return (
              (physicalItems && physicalItems.length <= 0) ||
              !latestItem ||
              latestItem.type !== "physicalItem"
            );
          },
          disabledMessage: t("c-clt-default-r-stub"),
        }}
        latestItem={latestItem} // required buttons
        annotations={
          latestItem && latestItem.type === "physicalItem"
            ? latestItem.annotations || null
            : null
        } // required annotation buttons.
        interactions={CT_PHYSICAL_ANNOTATIONS}
        interactionFunc={updateAnnotations}
        isVisualizationActive={true}
        visualizationType={visType}
        visualizationData={{
          name: "PHY",
          bmr: bmr,
          foods: foods,
          exercises: exercises,
          calorieLog: calorieLog,
          caloriesIn: caloriesIn,
          caloriesOut: caloriesOut,
          foodItems: foodItems,
          physicalItems: physicalItems,
          savedPhysicalItems: savedPhysicalItems,
          goalPace: goalPace,
          activeDate: activeDate,
          savedItems: savedItems,
          today: activeDate,
          itemGraph: itemActivityGraph,
          itemManagementTarget: itemManagementTarget,
          setItemManagementTarget: setItemManagementTarget,
          percentItemTarget: percentItemTarget,
          setPercentItemTarget: setPercentItemTarget,
          userData: userData,
          setActiveDate: setActiveDate,
          hasEarlierItem: () => hasEarlierItem(false),
          hasLaterItem: () => hasLaterItem(false),
          previousDate: getEarlierDate(activeDate.json),
          nextDate: getLaterDate(activeDate.json),
          hasEarlierDate: calorieLog[getEarlierDate(activeDate.json)],
          hasLaterDate: calorieLog[getLaterDate(activeDate.json)],
          dayChangeAllowed: true,
          latestItem: latestItem,
          externalDisplayFunc: (b) => changeViewedItem(b),
          interactiveVisualization: true,
          changeDate: changeDate,
          setModalVisible: props.setModalVisible,
          modalVisible: props.modalVisible,
          // modalData: modalContext.modalData,
          hasNewCalorieItems: hasNewCalorieItems,
          setHasNewCalorieItems: setHasNewCalorieItems,
          returnFromCalToAdd: returnFromCalToAdd,
          setReturnFromCalToAdd: setReturnFromCalToAdd,
          savedManagerState: savedManagerState,
          setSavedManagerState: setSavedManagerState,
          modalItemStore: modalItemStore,
          setModalItemStore: setModalItemStore,
          plans: plans,
          customGenericFunc: (v) => addItem("physicalItem", { calories: v }),
          isDarkTheme: isDarkTheme,
          language: language,
        }}
        contextTitle={t("c-clt-ctx-r-head")}
        customContextFuncs={{
          UNDO: () => undoLast("physicalItem"),
          CUSTOM_PHYSICAL_CALORIES: (v) => addItem("physicalItem", v),
          CUSTOM_GENERIC_PHYSICAL_CALORIES: (v) =>
            addItem("physicalItem", { calories: v }, true),
        }}
        contextInteractions={CT_PHYSICAL_BUTTONS}
        contextFunc={(btn) =>
          addItem("physicalItem", { calories: btn.btnName }, true)
        }
        setModalVisible={props.setModalVisible}
        modalVisible={props.modalVisible}
        activeDate={activeDate}
        changeDate={changeDate}
        previousDate={getEarlierDate(activeDate.json)}
        nextDate={getLaterDate(activeDate.json)}
        setReturnFromCalToAdd={setReturnFromCalToAdd}
        savedFoodItems={savedFoodItems}
        savedPhysicalItems={savedPhysicalItems}
        currentWeight={currentWeight}
      />
    </Card>
  );
}
