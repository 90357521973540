import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Footer from "../Components/Structural/Footer";
import Header from "../Components/Structural/Header";
import Section from "../Components/Structural/Section";
import Mast from "../Components/Structural/Mast";
import {
  APP_NAME,
  LARGE_BTN_MAX_STRING,
  QUESTION_MAX_STRING,
} from "../Supports/Constants";
import { shortenText } from "../Supports/Functions";
import { BASE_INIT_STATE } from "../Supports/Templates";
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
// import { GoogleLogin } from "@react-oauth/google";
import GoogleButton from "react-google-button";
import appleSignIn from "../Assets/appleid_button@2x.png";

export default function Login({ setUserFunc, handleAuthErrors }) {
  const auth = getAuth();
  const firestore = getFirestore();
  const providerGoogle = new GoogleAuthProvider();
  providerGoogle.setCustomParameters({
    prompt: "select_account ",
  });
  const signInWithGooglePopup = () => signInWithPopup(auth, providerGoogle);

  const providerApple = new OAuthProvider("apple.com");
  providerApple.addScope("email");

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [warning, setWarning] = useState("");
  const [forgotTrigger, setForgetTrigger] = useState(true);

  const passwordResetEnabled = true;

  useEffect(() => {
    setForgetTrigger(false);
  }, [setForgetTrigger]);

  const onForgotPasswordPress = () => {
    return (
      auth
        .sendPasswordResetEmail(email) // NOTE: Only works if connected to live firebase platform. Not supported in emulator.
        // .sendPasswordResetEmail(email, actionCodeSettings) // NOTE: Only works if connected to live firebase platform. Not supported in emulator.
        .then(() => setWarning(t("account-warning-check-email")))
        .catch((_) =>
          setWarning(t("account-warning-no-reset", { varApp: APP_NAME }))
        )
    );
  };

  const updateEmail = (event) => setEmail(event.target.value);
  const updatePassword = (event) => setPassword(event.target.value);

  const validateEmailPassword = () => {
    if (!email && !password) {
      setWarning(t("account-warning-no-email-or-password"));
      return;
    } else if (!email) {
      setWarning(t("account-warning-no-email"));
      return;
    } else if (!password) {
      setWarning(t("account-warning-no-password"));
      return;
    }

    login(email, password);
  };

  const buildUser = (response) => {
    const uid = response.user.uid;
    const data = {
      id: uid,
      email: response.user.email,
      userAgreement: {
        hasAgreedToUserAgreement: false,
        date: null,
      },
    };

    // Create reference to firebase login info document.
    const usersRef = collection(firestore, "Users");

    setDoc(doc(usersRef, uid), data)
      .then(() => {
        const userData = { ...BASE_INIT_STATE, id: uid };

        // Create reference to firebase user data document collection.
        const userDataRef = collection(firestore, "UserData");
        setDoc(doc(userDataRef, uid), userData).then(() => {
          setUserFunc(data);
          navigate("/useragreement");
        });
      })
      .catch((error) => setWarning(handleAuthErrors(error)));
  };

  const login = (_email, _password) => {
    signInWithEmailAndPassword(auth, _email, _password)
      .then((response) => {
        if (
          process.env.NODE_ENV === "production" &&
          !auth.currentUser.emailVerified
        ) {
          setWarning(t("account-warning-check-email-for-verification"));
          return;
        }

        const uid = response.user.uid;
        getDoc(doc(firestore, "Users", uid))
          .then((firestoreDocument) => {
            if (!firestoreDocument || !firestoreDocument.exists()) {
              if (auth.currentUser.emailVerified) {
                buildUser(response);
              } else {
                setWarning(t("account-warning-no-account"));
                return;
              }
            } else {
              const grabbedData = firestoreDocument.data();
              setUserFunc(grabbedData);
              setWarning("");

              const successPath = () =>
                navigate(
                  grabbedData?.userAgreement?.hasAgreedToUserAgreement
                    ? "/main"
                    : "/useragreement"
                );

              successPath();
            }
          })
          .catch((error) => setWarning(handleAuthErrors(error)));
      })
      .catch((error) => {
        setWarning(handleAuthErrors(error));
        setForgetTrigger(error.code === "auth/wrong-password");
      });
  };

  const loginThirdParty = (response) => {
    const uid = response.user.uid;
    return getDoc(doc(firestore, "Users", uid))
      .then((firestoreDocument) => {
        if (!firestoreDocument.exists()) {
          const data = {
            id: uid,
            email: response.user.email,
          };
          setUserFunc(data);

          const usersRef = collection(firestore, "Users");
          setDoc(doc(usersRef, uid), data)
            .then(() => {
              const userDataRef = collection(firestore, "UserData");
              setDoc(doc(userDataRef, uid), {
                ...BASE_INIT_STATE,
                id: uid,
              }).then(() => {
                setWarning("");
                navigate("/useragreement");
              });
            })
            .catch((error) => setWarning(handleAuthErrors(error)));
        } else {
          const grabbedData = firestoreDocument.data();
          setWarning("");
          setUserFunc(grabbedData);

          const successPath = () =>
            navigate(
              grabbedData?.userAgreement?.hasAgreedToUserAgreement
                ? "/main"
                : "/useragreement"
            );

          successPath();

          // const IS_EMULATOR = false;
          // const EMULATOR_SETTINGS = {
          //   debugGeography: AdsConsentDebugGeography.EEA,
          //   testDesbviceIdentifiers: ["EMULATOR"],
          // };

          // AdsConsent.requestInfoUpdate(
          //   IS_EMULATOR ? EMULATOR_SETTINGS : {}
          // ).then((consentInfo) => {
          //   if (
          //     consentInfo.status === AdsConsentStatus.OBTAINED ||
          //     consentInfo.status === AdsConsentStatus.NOT_REQUIRED
          //   ) {
          //     successPath();
          //   } else if (
          //     consentInfo.isConsentFormAvailable &&
          //     consentInfo.status === AdsConsentStatus.REQUIRED
          //   ) {
          //     AdsConsent.showForm().then((res) => {
          //       res.status === AdsConsentStatus.OBTAINED
          //         ? successPath()
          //         : navigation.navigate("Login");
          //     });
          //   } else {
          //     navigation.navigate("Login");
          //   }
          // });
        }
      })
      .catch((error) => setWarning(handleAuthErrors(error)));
  };

  const logAppleUser = (credential) => {
    signInWithPopup(auth, providerApple)
      .then(loginThirdParty)
      .catch((error) => setWarning(handleAuthErrors(error)));
  };

  const logGoogleUser = () => {
    signInWithGooglePopup()
      .then(loginThirdParty)
      .catch((error) => setWarning(handleAuthErrors(error)));
  };

  const goToRegistration = () => navigate("/register");

  // const responseMessage = (response) => {
  //   internalSignInWithCredential(response.credential);
  // };

  // const errorMessage = (error) => {};

  const EmailPassword = (
    <div className="section-content">
      <div className="row-content space-between">
        <label className="large-label">{`${shortenText(
          t("email"),
          QUESTION_MAX_STRING,
          true,
          1
        )}:`}</label>
        <input
          id="email"
          type="email"
          placeholder={t("email")}
          onChange={updateEmail}
        />
      </div>
      <div className="row-content space-between">
        <label className="large-label">{`${shortenText(
          t("password"),
          QUESTION_MAX_STRING,
          true,
          1
        )}:`}</label>
        <input
          id="password"
          type="password"
          placeholder={t("password")}
          onChange={updatePassword}
        />
      </div>
      {warning && (
        <div className="row-content">
          <div className="warningText">{warning}</div>
        </div>
      )}
      <div className="row-content">
        <button className="large-rectangle" onClick={validateEmailPassword}>
          {shortenText(t("log-in"), LARGE_BTN_MAX_STRING, true, 0, true, true)}
        </button>
        {forgotTrigger && (
          <button
            className="large-rectangle warning"
            onClick={() => passwordResetEnabled && onForgotPasswordPress()}
          >
            {shortenText(
              t("login-btn-reset-password"),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </button>
        )}
      </div>
    </div>
  );

  const Register = (
    <div className="section-content">
      <div className="row-content">
        <button className="large-rectangle" onClick={goToRegistration}>
          {shortenText(t("sign-up"), LARGE_BTN_MAX_STRING, true, 0, true, true)}
        </button>
      </div>
      <div className="row-content">
        <label className="header">
          {"... or use an alternate login option!"}
        </label>
      </div>
      <div className="row-content">
        <button className="apple-sign-in" onClick={logAppleUser}>
          <img alt="Sign in with Apple" src={appleSignIn} />
        </button>
        {/* <GoogleLogin
          auto_select
          onSuccess={responseMessage}
          onError={errorMessage}
        /> */}
        <div className="google-sign-in">
          <GoogleButton onClick={logGoogleUser} />
        </div>
      </div>
    </div>
  );

  return (
    <div className="page-wrapper">
      <Header />
      <main className="menu-page">
        <Section header={t("mast-reasons-title", { varApp: APP_NAME })}>
          <Mast />
        </Section>
        <Section header={t("login-s2-head")}>{EmailPassword}</Section>
        <Section header={t("login-s3-head")}>{Register}</Section>
      </main>
      <Footer />
    </div>
  );
}
