import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryStack,
} from "victory";
import {
  BASE_ACCENT,
  BASE_BLACK,
  BASE_GRAY,
  BASE_LVL_THREE_DARK,
  BASE_PINK,
  BASE_WHITE,
  CALORIES_IN_LB,
  LARGE_BTN_MAX_STRING,
  THEME_COLOR,
} from "../../../Supports/Constants";
import {
  compareISODatetimes,
  getDate,
  getTodayDate,
  scaleTheme,
  scaleValue,
  shortenText,
} from "../../../Supports/Functions";
import { generateAnnotationGlyphString } from "../../../Supports/Templates";
import CalendarModal from "../../Modals/Calories/CalendarModal";
import CalorieInfo from "../../Modals/Calories/CalorieInfo";
import CalorieManagementModal from "../../Modals/Calories/CalorieManagementModal";
import ConfirmModal from "../../Modals/General/ConfirmModal";
import CustomInputModal from "../../Modals/General/CustomInputModal";
import ViewExerciseModal from "../../Modals/Workout/ViewExerciseModal";
import "./CalorieCard.css";
import {
  mdiCalendarCursor,
  mdiCalendarRefresh,
  mdiChevronLeft,
  mdiChevronRight,
} from "@mdi/js";

export default function CalorieBar(props) {
  const { t } = useTranslation();
  const vd = props.visualizationData;

  const bmr = vd.bmr || 0;
  const goalPace = vd.goalPace || 0;

  const ANNOTES_ON = false;
  // const activeDateLog = vd.calorieLog[vd.activeDate.date];
  const BAR_HEIGHT = 15;
  const STROKE_WIDTH_SELECTED = 2;
  const STROKE_WIDTH = 0.5;
  const PADDING_BOTTOM = 10;
  const foodItems = useMemo(() => vd.foodItems || [], [vd.foodItems]);
  const physicalItems = useMemo(
    () => vd.physicalItems || [],
    [vd.physicalItems]
  );

  const caloriesIn = vd.caloriesIn || 0;
  const caloriesOut = vd.caloriesOut || 0;
  const paceGoalCalsPerWeek = (goalPace * CALORIES_IN_LB) / 7;
  const goal = bmr + paceGoalCalsPerWeek + caloriesOut;
  const bmrThird = Math.floor(bmr / 3);
  const bmrTwoThirds = Math.floor((bmr * 2) / 3);
  const plusphy = bmr + caloriesOut;
  const maxDomain = Math.max(
    goal,
    plusphy,
    caloriesIn,
    bmr + paceGoalCalsPerWeek,
    bmr
  );

  const leeway = maxDomain * 0.07;
  const domainTicks = useMemo(
    () => [
      0, // lowest
      bmrThird, // a third of bmr
      bmrTwoThirds, // two thirds of bmr
      goal,
      bmr,
      plusphy, // +PHY
      maxDomain, // largest
    ],
    [bmr, bmrThird, bmrTwoThirds, goal, maxDomain, plusphy]
  );

  const domainProp = useMemo(
    () =>
      maxDomain === null || maxDomain === "undefined" || maxDomain === "NaN"
        ? null
        : [0, maxDomain],
    [maxDomain]
  );

  // The first step of the pressable interaction phases.
  // Typically setup to "clean the slate" before activity on the press out.
  const onPressInFunc = useCallback(() => {
    if (!vd.interactiveVisualization) {
      return null;
    }

    return [
      {
        childName: [...foodItems, ...physicalItems].map(
          (i) => i.type + "-" + i.index
        ),
        mutation: () => undefined,
      },
    ];
  }, [foodItems, physicalItems, vd.interactiveVisualization]);

  // The primary pressable trigger point.
  const onPressOutFunc = useCallback(() => {
    if (!vd.interactiveVisualization) {
      return null;
    }

    return [
      {
        mutation: ({ datum, style }) => {
          if (!vd.externalDisplayFunc) {
            return null;
          }

          const styleObj = {
            style: {
              fill: style?.fill,
              stroke: BASE_BLACK,
              strokeWidth: STROKE_WIDTH_SELECTED,
            },
          };

          vd.externalDisplayFunc({
            collection: datum.type,
            index: datum.typeIndex,
          });

          return styleObj;
        },
      },
    ];
  }, [vd]);

  const generateSection = useCallback(
    (sectionItems, consume = 0) => {
      const internalCollection = [];
      for (let ind = 0; ind < sectionItems.length; ind++) {
        const item = sectionItems[ind];
        if (consume > 0 && item.calories <= consume) {
          // don't add this bar, consume it, and pass to next bar for consume check.
          consume -= item.calories;
        } else {
          // FIXED: Did not seem to allow for costume x/y thruputs to be handled on
          // live-refresh of data, have to convert data object to x/y, then pass in data.

          const obj = {
            datetime: item.datetime,
            x: 1,
            y: item.calories - consume,
            type: item.type,
            typeIndex: ind,
            barIndex: `${item.type}-${ind}`,
            annotations: item.annotations,
            name: item.name,
            key: item.key + item.index,
          };
          consume = 0;

          internalCollection.push(
            <VictoryBar
              key={obj.barIndex}
              name={obj.barIndex}
              barWidth={BAR_HEIGHT}
              data={[obj]}
              labels={({ datum }) => {
                if (
                  !ANNOTES_ON ||
                  !datum.annotations ||
                  datum.datetime === "background" ||
                  vd.latestItem?.key + vd.latestItem?.index !==
                    item.key + item.index
                ) {
                  return;
                }
                const zeroPrefix = datum.y === 0 ? "0️⃣" : "";
                const annoteKeys = Object.keys(datum.annotations);

                if (!annoteKeys) {
                  return `${datum.y}`;
                }

                return (
                  zeroPrefix + generateAnnotationGlyphString(datum.annotations)
                );
              }}
              labelComponent={
                <VictoryLabel
                  style={{
                    fill: vd.isDarkTheme ? BASE_WHITE : BASE_BLACK,
                    fontSize: 11,
                    fontFamily: "Oswald-Regular",
                  }}
                  textAnchor="middle"
                  y={BAR_HEIGHT - 3}
                  dx={({ datum }) => {
                    const endBarPosition = scaleValue(
                      datum.y,
                      0,
                      maxDomain,
                      0,
                      400
                    );
                    return -(endBarPosition * 2);
                  }}
                />
              }
              style={{
                data: {
                  fill: ({ datum }) => {
                    return scaleTheme(
                      THEME_COLOR,
                      datum.type === "foodItem" ? BASE_ACCENT : BASE_PINK,
                      1000,
                      datum.y
                    );
                  },
                  stroke: BASE_BLACK,
                  strokeWidth: ({ datum }) => {
                    return vd.latestItem?.key + vd.latestItem?.index ===
                      item.key + item.index
                      ? STROKE_WIDTH_SELECTED
                      : STROKE_WIDTH;
                  },
                },
              }}
            />
          );
        }
      }
      return internalCollection;
    },
    [ANNOTES_ON, maxDomain, vd.isDarkTheme, vd.latestItem]
  );

  // Generates the bar parts and fills the remainder with the "background" of the bar.
  const barGenerator = useCallback(() => {
    const col = [];
    const totalItems = [];
    let yCalc = maxDomain;
    let midPoint = maxDomain;

    if (vd.name === "FOOD" || vd.name === "BOTH") {
      totalItems.push(...foodItems);
      yCalc -= caloriesIn;
      midPoint += caloriesIn;
    }
    if (vd.name === "PHY" || vd.name === "BOTH") {
      totalItems.push(...physicalItems);
      yCalc -= caloriesOut;
      midPoint += caloriesOut;
    }

    (vd.name === "FOOD" || vd.name === "BOTH") &&
      col.push(...generateSection(foodItems));

    let consume = 0;
    if (vd.name === "BOTH" && caloriesIn > maxDomain - caloriesOut) {
      consume = caloriesOut + caloriesIn - maxDomain;
    }

    if (yCalc > 0) {
      const obj = {
        datetime: "background",
        midPoint: midPoint,
        x:
          totalItems.length > 0
            ? totalItems[totalItems.length - 1].date
            : "emptyChart",
        y: yCalc,
      };

      // Background bar for non-used calories.
      col.push(
        <VictoryBar
          name={"bar-background"}
          key={"bar-background"}
          barWidth={BAR_HEIGHT}
          data={[obj]}
          style={{
            labels: { fill: BASE_BLACK },
            data: {
              fill: vd.isDarkTheme ? BASE_LVL_THREE_DARK : BASE_WHITE,
            },
          }}
        />
      );
    }

    (vd.name === "PHY" || vd.name === "BOTH") &&
      col.push(...generateSection(physicalItems, consume));

    return col;
  }, [
    caloriesIn,
    caloriesOut,
    foodItems,
    generateSection,
    maxDomain,
    physicalItems,
    vd.isDarkTheme,
    vd.name,
  ]);

  // Generates the victorychart context which is then used to contain the custom bar design itself.
  // Stacks, axises, etc.
  const genCalorieBar = useCallback(() => {
    return (
      <VictoryChart
        domainPadding={0}
        domain={domainProp}
        padding={{
          top: 0,
          bottom: 10 + PADDING_BOTTOM,
          left: 10,
          right: 10,
        }}
        horizontal
        height={BAR_HEIGHT + 25 + PADDING_BOTTOM - 6}
      >
        <VictoryStack
          externalEventMutations={[
            {
              childName: [...foodItems, ...physicalItems].map(
                (i) => i.type + "-" + i.index
              ),
              target: ["data"],
              eventKey: "all",
              mutation: () => ({ style: undefined }),
              callback: undefined,
            },
          ]}
          events={[
            {
              childName: [...foodItems, ...physicalItems].map(
                (i) => i.type + "-" + i.index
              ),
              target: "data",
              eventHandlers: {
                onMouseDown: onPressInFunc,
                onMouseUp: onPressOutFunc,
                // onPressIn: onPressInFunc,
                // onPressOut: onPressOutFunc,
              },
            },
          ]}
        >
          {barGenerator()}
        </VictoryStack>
        <VictoryAxis
          dependentAxis
          tickFormat={(x) => {
            if (x !== goal && x >= goal - leeway && x <= goal + leeway) {
              return "";
            } else if (x === 0) {
              return 0;
            } else if (x === goal) {
              return t("c-vis-timeline-goal");
            } else if (x === bmr) {
              return t("c-vis-timeline-bmr");
            } else if (x === caloriesIn + caloriesOut && caloriesOut > 0) {
              return `+${caloriesOut} (${t("c-vis-timeline-phy")})`;
            } else if (x === caloriesIn && caloriesIn > bmr) {
              return t("c-vis-timeline-cal");
            } else if (x === plusphy) {
              return `+${t("c-vis-timeline-phy")}`;
            }
            return `${x}`;
          }}
          orientation="top"
          offsetY={21 + BAR_HEIGHT - 5}
          tickValues={domainTicks}
          tickLabelComponent={
            <VictoryLabel
              dy={5}
              dx={(d) => {
                const delta = 400 - d.x;

                if (delta < 12) {
                  return -delta;
                }
                return 0;
              }}
              angle={0}
              textAnchor={"middle"}
              style={{
                fill: vd.isDarkTheme ? BASE_WHITE : BASE_BLACK,
                fontSize: 10,
                fontFamily: "Oswald-Regular",
              }}
            />
          }
          style={{
            axis: { stroke: "none" },
            ticks: {
              stroke: vd.isDarkTheme ? BASE_WHITE : BASE_BLACK,
              size: 14,
              strokeWidth: 1,
              strokeDasharray: "4,2",
            },
          }}
        />
      </VictoryChart>
    );
  }, [
    barGenerator,
    bmr,
    caloriesIn,
    caloriesOut,
    domainProp,
    domainTicks,
    foodItems,
    goal,
    leeway,
    onPressInFunc,
    onPressOutFunc,
    physicalItems,
    plusphy,
    t,
    vd.isDarkTheme,
  ]);

  const visibleCalorieBar = useMemo(() => {
    return genCalorieBar();
  }, [genCalorieBar]);

  // General structure of visualization space. Handles the back and forward button logic and display.
  return (
    <>
      <div className="calorieBar">
        <div className="dateHeader">
          {vd.dayChangeAllowed && (
            <button
              className="today glyph-button glyph-button-text-row"
              onClick={() => {
                const today = getTodayDate();
                if (today.date !== vd.activeDate.date) {
                  vd.changeDate(today);
                }
              }}
            >
              <div className="navLabel card-header-text-smaller">
                {shortenText(
                  t("c-clt-vis-backtotoday"),
                  LARGE_BTN_MAX_STRING,
                  true,
                  0,
                  true,
                  true
                )}
              </div>
              <Icon size={1} path={mdiCalendarRefresh} />
            </button>
          )}
          <div className="innerActiveDate">
            {vd.dayChangeAllowed && (
              <button
                className="glyph-button"
                onClick={() => {
                  const today = getTodayDate();
                  const lastYearDate = new Date(
                    today.year - 1,
                    today.month - 1,
                    today.day
                  );
                  if (
                    compareISODatetimes(vd.previousDate.ios, lastYearDate) > 0
                  ) {
                    vd.changeDate(vd.previousDate);
                  }
                }}
              >
                <Icon size={1} path={mdiChevronLeft} />
              </button>
            )}
            <div className="card-header-text-small-centered currentDateText">
              {vd.activeDate.date}
            </div>
            {vd.dayChangeAllowed && (
              <button
                className="glyph-button"
                onClick={() => {
                  vd.changeDate(vd.nextDate);
                }}
              >
                <Icon size={1} path={mdiChevronRight} />
              </button>
            )}
          </div>
          {vd.dayChangeAllowed && (
            <button
              className="today glyph-button glyph-button-text-row"
              onClick={() => {
                vd.setModalVisible("CT_CALENDAR");
              }}
            >
              <div className="navLabel card-header-text-smaller">
                {shortenText(
                  t("c-clt-vis-selectadate"),
                  LARGE_BTN_MAX_STRING,
                  true,
                  0,
                  true,
                  true
                )}
              </div>
              <Icon size={1} path={mdiCalendarCursor} />
            </button>
          )}
        </div>
        <div className="barContainer">
          <button
            className="barArrows barArrowLeft glyph-button"
            onClick={() => {
              const earlier = vd.hasEarlierItem();
              if (earlier) {
                vd.externalDisplayFunc({
                  index: earlier.index,
                  collection: earlier.type,
                });
              }
            }}
          >
            <Icon size={1.5} path={mdiChevronLeft} />
          </button>
          {visibleCalorieBar}
          <button
            className="barArrows barArrowRight glyph-button"
            onClick={() => {
              const later = vd.hasLaterItem();
              if (later) {
                vd.externalDisplayFunc({
                  index: later.index,
                  collection: later.type,
                });
              }
            }}
          >
            <Icon size={1.5} path={mdiChevronRight} />
          </button>
        </div>
      </div>

      {vd.modalVisible === "CT_CALENDAR" && (
        <CalendarModal
          key={"CT_CALENDAR"}
          modalVisible={vd.modalVisible === "CT_CALENDAR"}
          setModalVisible={vd.setModalVisible}
          func={(date) => {
            if (date) {
              const oldDate = new Date(date);
              const newDate = new Date();
              newDate.setTime(oldDate.getTime() + 60 * 12 * 60000);
              vd.changeDate(getDate(newDate.toISOString()));
            }
          }}
          question={"m-clt-cal-title"}
          yes={"select"}
          no={"cancel"}
          calorieLog={vd.calorieLog}
          activeDate={vd.activeDate}
          returnFromCalToAdd={vd.returnFromCalToAdd}
          setReturnFromCalToAdd={vd.setReturnFromCalToAdd}
          language={vd.language}
        />
      )}

      {vd.modalVisible === "CT_CNTX_INFO" && (
        <CalorieInfo
          key={"CT_CNTX_INFO"}
          modalVisible={vd.modalVisible === "CT_CNTX_INFO"}
          setModalVisible={vd.setModalVisible}
          modalSettings={{
            itemInfo: vd.latestItem,
            savedItems: vd.savedItems,
            itemGraph: vd.itemGraph,
          }}
          foods={vd.foods}
          exercises={vd.exercises}
        />
      )}

      {vd.modalVisible === "CT_MULTI_ITEM" && (
        <CustomInputModal
          key={"customInputModal-CT_MULTI_ITEM"}
          modalVisible={vd.modalVisible === "CT_MULTI_ITEM"}
          setModalVisible={vd.setModalVisible}
          func={(multiplier) => {
            const target = vd.percentItemTarget?.item || vd.latestItem;
            const newItem = { ...target };

            const backupFunc = (item) => {
              vd.removeItemAtIndex(
                "all",
                vd.latestItem ? vd.latestItem.index : null
              );
              vd.addItem(item.type, item);
            };
            const targetFunc = vd.percentItemTarget?.callback || backupFunc;

            newItem.calories = parseInt(
              (newItem.calories * multiplier).toFixed(0)
            );

            newItem.dependencies = newItem.dependencies?.map((d) => {
              return {
                ...d,
                ...{
                  calories: parseInt((d.calories * multiplier).toFixed(0)),
                },
              };
            });

            if (newItem.type === "foodItem") {
              if (isNaN(newItem.nutrients.protein)) {
                newItem.nutrients = {
                  protein: parseInt(
                    (
                      (newItem.nutrients.find((n) => n.i === 1003)?.v || 0) *
                      multiplier
                    ).toFixed(0)
                  ),
                  carbs: parseInt(
                    (
                      (newItem.nutrients.find((n) => n.i === 1005)?.v || 0) *
                      multiplier
                    ).toFixed(0)
                  ),
                  fat: parseInt(
                    (
                      (newItem.nutrients.find((n) => n.i === 1004)?.v || 0) *
                      multiplier
                    ).toFixed(0)
                  ),
                  sugar: parseInt(
                    (
                      (newItem.nutrients.find((n) => n.i === 2000)?.v || 0) *
                      multiplier
                    ).toFixed(0)
                  ),
                  fiber: parseInt(
                    (
                      (newItem.nutrients.find((n) => n.i === 1079)?.v || 0) *
                      multiplier
                    ).toFixed(0)
                  ),
                  alcohol: parseInt(
                    (
                      (newItem.nutrients.find((n) => n.i === 1018)?.v || 0) *
                      multiplier
                    ).toFixed(0)
                  ),
                };
              } else {
                const keys = Object.keys(newItem.nutrients);
                const newNutrients = {};
                for (let index = 0; index < keys.length; index++) {
                  const key = keys[index];
                  newNutrients[key] = parseInt(
                    (newItem.nutrients[key] * multiplier).toFixed(0)
                  );
                }
                newItem.nutrients = newNutrients;
              }
              newItem.servingSize = parseInt(
                (
                  (newItem.servingSize ? newItem.servingSize : 1) * multiplier
                ).toFixed(0)
              );
            }

            vd.setModalItemStore(newItem);
            targetFunc(newItem);
          }}
          cancelFunc={(props) => {}}
          modalSettings={{
            percentItemTarget: vd.percentItemTarget,
            maxVal: 50,
            minVal: 1,
            unitType: "x",
            color: BASE_GRAY,
            colorScale: true,
            starting: 1,
          }}
          current={100}
        />
      )}

      {vd.modalVisible === "CT_PERCENT_ITEM" && (
        <CustomInputModal
          key={"customInputModal-CT_PERCENT_ITEM"}
          modalVisible={vd.modalVisible === "CT_PERCENT_ITEM"}
          setModalVisible={vd.setModalVisible}
          func={(outOfOneHundred) => {
            const target = vd.percentItemTarget?.item || vd.latestItem;
            const newItem = { ...target };

            const backupFunc = (item) => {
              vd.removeItemAtIndex(
                "all",
                vd.latestItem ? vd.latestItem.index : null
              );
              vd.addItem(item.type, item, true);
            };
            const targetFunc = vd.percentItemTarget?.callback || backupFunc;

            newItem.calories = parseInt(
              ((newItem.calories * outOfOneHundred) / 100).toFixed(0)
            );

            newItem.dependencies = newItem.dependencies?.map((d) => {
              return {
                ...d,
                ...{
                  calories: parseInt(
                    ((d.calories * outOfOneHundred) / 100).toFixed(0)
                  ),
                },
              };
            });

            if (newItem.type === "foodItem") {
              if (isNaN(newItem.nutrients.protein)) {
                newItem.nutrients = {
                  protein: parseInt(
                    (
                      ((newItem.nutrients.find((n) => n.i === 1003)?.v || 0) *
                        outOfOneHundred) /
                      100
                    ).toFixed(0)
                  ),
                  carbs: parseInt(
                    (
                      ((newItem.nutrients.find((n) => n.i === 1005)?.v || 0) *
                        outOfOneHundred) /
                      100
                    ).toFixed(0)
                  ),
                  fat: parseInt(
                    (
                      ((newItem.nutrients.find((n) => n.i === 1004)?.v || 0) *
                        outOfOneHundred) /
                      100
                    ).toFixed(0)
                  ),
                  sugar: parseInt(
                    (
                      ((newItem.nutrients.find((n) => n.i === 2000)?.v || 0) *
                        outOfOneHundred) /
                      100
                    ).toFixed(0)
                  ),
                  fiber: parseInt(
                    (
                      ((newItem.nutrients.find((n) => n.i === 1079)?.v || 0) *
                        outOfOneHundred) /
                      100
                    ).toFixed(0)
                  ),
                  alcohol: parseInt(
                    (
                      ((newItem.nutrients.find((n) => n.i === 1018)?.v || 0) *
                        outOfOneHundred) /
                      100
                    ).toFixed(0)
                  ),
                };
              } else {
                const keys = Object.keys(newItem.nutrients);
                const newNutrients = {};
                for (let index = 0; index < keys.length; index++) {
                  const key = keys[index];

                  newNutrients[key] = parseInt(
                    ((newItem.nutrients[key] * outOfOneHundred) / 100).toFixed(
                      0
                    )
                  );
                }
                newItem.nutrients = newNutrients;
              }
              newItem.servingSize = parseInt(
                (
                  ((newItem.servingSize ? newItem.servingSize : 1) *
                    outOfOneHundred) /
                  100
                ).toFixed(0)
              );
            }

            vd.setModalItemStore(newItem);
            targetFunc(newItem);
          }}
          cancelFunc={(props) => {}}
          modalSettings={{
            percentItemTarget: vd.percentItemTarget,
            maxVal: 100,
            minVal: 1,
            unitType: "%",
            color: BASE_GRAY,
            colorScale: true,
            starting: 100,
          }}
          current={100}
        />
      )}

      {vd.modalVisible === "CT_ITEM_MANAGER_MEAL" && (
        <CalorieManagementModal
          key={"CT_ITEM_MANAGER_MEAL"}
          modalVisible={vd.modalVisible === "CT_ITEM_MANAGER_MEAL"}
          setModalVisible={vd.setModalVisible}
          func={vd.modalFunc}
          userData={vd.userData}
          modalData={vd.modalData}
          itemGraph={vd.itemGraph}
          itemManagementTarget={vd.itemManagementTarget}
          setItemManagementTarget={vd.setItemManagementTarget}
          setPercentItemTarget={vd.setPercentItemTarget}
          hasNewCalorieItems={vd.hasNewCalorieItems}
          setHasNewCalorieItems={vd.setHasNewCalorieItems}
          savedManagerState={vd.savedManagerState}
          setSavedManagerState={vd.setSavedManagerState}
          setModalItemStore={vd.setModalItemStore}
          modalItemStore={vd.modalItemStore}
          modalSettings={{
            unitType: "calories",
            isFood: true,
            minVal: 0,
            maxVal: 4000, // Michael Phelps' Meal.
            color: BASE_ACCENT,
            colorScale: true,
          }}
          foods={vd.foods}
          exercises={vd.exercises}
          savedFoodItems={vd.savedFoodItems}
        />
      )}

      {vd.modalVisible === "CT_ITEM_MANAGER_ACTIVITY" && (
        <CalorieManagementModal
          key={"CT_ITEM_MANAGER_ACTIVITY"}
          modalVisible={vd.modalVisible === "CT_ITEM_MANAGER_ACTIVITY"}
          setModalVisible={vd.setModalVisible}
          func={vd.modalFunc}
          userData={vd.userData}
          modalData={vd.modalData}
          itemGraph={vd.itemGraph}
          itemManagementTarget={vd.itemManagementTarget}
          setPercentItemTarget={vd.setPercentItemTarget}
          setItemManagementTarget={vd.setItemManagementTarget}
          hasNewCalorieItems={vd.hasNewCalorieItems}
          setHasNewCalorieItems={vd.setHasNewCalorieItems}
          savedManagerState={vd.savedManagerState}
          setSavedManagerState={vd.setSavedManagerState}
          setModalItemStore={vd.setModalItemStore}
          modalItemStore={vd.modalItemStore}
          modalSettings={{
            unitType: "calories",
            isFood: false,
            minVal: 0,
            maxVal: 2000, // Marathon calories.
            color: BASE_GRAY,
            colorScale: true,
          }}
          foods={vd.foods}
          exercises={vd.exercises}
          savedPhysicalItems={vd.savedPhysicalItems}
        />
      )}

      {/* {vd.modalVisible === "CT_GENERIC_CUSTOM_FOR_WORKOUT_END" && (
        <CustomInputModal
          key={"customInputModal-CT_GENERIC_CUSTOM_FOR_WORKOUT_END"}
          modalVisible={vd.modalVisible === "CT_GENERIC_CUSTOM_FOR_WORKOUT_END"}
          setModalVisible={vd.setModalVisible}
          modalData={vd.modalData}
          func={vd.customGenericFunc}
          modalSettings={{
            isFood: false,
            unitType: "kcal",
            header: "calories",
            minVal: 0,
            maxVal: 2000, // marathon
            color: BASE_ACCENT,
            colorScale: true,
          }}
          // current={0}
        />
      )} */}

      {vd.modalVisible === "CT_INFO_ADD" && (
        <CalorieInfo
          key={"CT_INFO_ADD"}
          modalVisible={vd.modalVisible === "CT_INFO_ADD"}
          setModalVisible={vd.setModalVisible}
          modalSettings={{
            itemInfo: vd.itemManagementTarget,
            savedItems: vd.savedItems,
            itemGraph: vd.itemGraph,
            returnTarget:
              vd.itemManagementTarget.type === "foodItem"
                ? "CUSTOM_FOOD_CALORIES"
                : "CUSTOM_PHYSICAL_CALORIES",
          }}
          foods={vd.foods}
          exercises={vd.exercises}
        />
      )}

      {vd.modalVisible === "CT_INFO_MANAGE" && (
        <CalorieInfo
          key={"CT_INFO_MANAGE"}
          modalVisible={vd.modalVisible === "CT_INFO_MANAGE"}
          setModalVisible={vd.setModalVisible}
          modalSettings={{
            itemInfo: vd.itemManagementTarget,
            savedItems: vd.savedItems,
            itemGraph: vd.itemGraph,
            returnTarget:
              vd.itemManagementTarget.type === "foodItem"
                ? "CT_ITEM_MANAGER_MEAL"
                : "CT_ITEM_MANAGER_ACTIVITY",
          }}
          foods={vd.foods}
          exercises={vd.exercises}
        />
      )}

      {vd.modalVisible === "END_FAST_FROM_FOOD" && (
        <ConfirmModal
          key={"ConfirmModal-END_FAST_FROM_FOOD"}
          name={"ct-end-fast-name"}
          modalVisible={vd.modalVisible === "END_FAST_FROM_FOOD"}
          setModalVisible={vd.setModalVisible}
          func={vd.endFastFromFood}
          funcValue={true}
          question={"ct-end-fast-q"}
          yes={"ct-end-fast-y"}
          no={"ct-end-fast-n"}
        />
      )}

      {vd.modalVisible === "CT_ACTIVE_INFO" && (
        <ViewExerciseModal
          key={"ViewExerciseModal-CT_ACTIVE_INFO"}
          modalVisible={vd.modalVisible === "CT_ACTIVE_INFO"}
          setModalVisible={vd.setModalVisible}
          modalSettings={{
            plans: vd.plans,
            prebuiltExerciseData: vd.exercises,
            viewedExercise: vd.itemManagementTarget,
            setViewedExercise: vd.setItemManagementTarget,
            planModalType: vd.planModalType,
            // setPlanModalType: vd.setPlanModalType,
          }}
        />
      )}
    </>
  );
}
