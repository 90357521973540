import React from "react";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import {
  BASE_ACCENT,
  BASE_GRAY,
  TABLE_ROW_HEADER,
} from "../../../Supports/Constants";
import { shortenText } from "../../../Supports/Functions";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import ViewExerciseModal from "../../Modals/Workout/ViewExerciseModal";

export default function WorkoutPlans(props) {
  const { t } = useTranslation();
  const vd = props.visualizationData;

  if (!vd.plans) {
    return <></>;
  }

  const MAX_PLANS_PER_PAGE = 3;
  const planKeys = Object.keys(vd.plans);
  const backPage = vd.cache.page > 0;
  const forwardPage =
    vd.cache.page <
    Math.floor(planKeys.length / MAX_PLANS_PER_PAGE) -
      (planKeys.length % MAX_PLANS_PER_PAGE === 0 ? 1 : 0);

  const tableRows = [];

  const formatDuration = (dur) => {
    const hdur = Math.floor(dur / 3600);
    const mdur = Math.floor((dur % 3600) / 60);
    const sdur = Math.floor(dur % 60);
    return hdur <= 0 && mdur <= 0
      ? `${sdur}${t("second-short")}`
      : hdur <= 0
      ? `${mdur}${t("minute-short")} ${sdur}${t("second-short")}`
      : `${hdur}${t("hour-short")} ${mdur}${t("minute-short")}`;
  };

  const headerStyle = "logHeaderText column25";

  tableRows.push(
    <div className="logTableHeaderRow" key="tableHeader">
      <div className={headerStyle}>
        {shortenText(t("name"), TABLE_ROW_HEADER, false, 0, true, true)}
      </div>
      <div className={headerStyle}>
        {shortenText(t("exercises"), TABLE_ROW_HEADER, false, 0, true, true)}
      </div>
      <div className={headerStyle}>
        {shortenText(
          t("duration-full"),
          TABLE_ROW_HEADER,
          false,
          0,
          true,
          true
        )}
      </div>
      <div className={headerStyle}>
        {shortenText(t("prior"), TABLE_ROW_HEADER, false, 0, true, true)}
      </div>
      {/* <div className="" style={headerStyle}>
        {shortenText(t('next'), TABLE_ROW_HEADER, false, 0, true, true)}
      </div> */}
    </div>
  );

  for (
    let index = vd.cache.page * MAX_PLANS_PER_PAGE;
    index < planKeys.length &&
    index - vd.cache.page * MAX_PLANS_PER_PAGE < MAX_PLANS_PER_PAGE;
    index++
  ) {
    const planItem = vd.plans[planKeys[index]];

    const rowItemStyle = `logItemText column25 ${
      vd.cache.selectedPlan === planKeys[index]
        ? "selectedRowText"
        : "selectableRowText"
    }`;

    const selectableRowStyle = `logTableRow ${
      vd.cache.selectedPlan === planKeys[index]
        ? "selectedRow"
        : "selectableRow"
    }`;

    const onClickRow = () => {
      vd.updateCache({
        selectedPlan:
          vd.cache.selectedPlan === planKeys[index] ? null : planKeys[index],
        selectedPlanHasExercises:
          vd.cache.selectedPlan === planKeys[index]
            ? false
            : vd.planSizes[planKeys[index]] > 0,
      });
    };

    const row = (
      <button
        key={`tableRow-${index}`}
        onClick={onClickRow}
        className={selectableRowStyle}
      >
        <div className={rowItemStyle}>{`${
          (planItem && planItem.name.replace(/(.{14})..+/, "$1...")) ||
          t("no-information-available-short")
        }`}</div>
        <div className={rowItemStyle}>{`${
          planItem && !Number.isNaN(vd.planSizes[planKeys[index]])
            ? vd.planSizes[planKeys[index]] || 0
            : t("no-information-available-short")
        }`}</div>
        <div className={rowItemStyle}>
          {(planItem &&
            planItem.duration &&
            formatDuration(planItem.duration)) ||
            t("no-information-available-short")}
        </div>
        <div className={rowItemStyle}>
          {(planItem && planItem.lastDate?.date) ||
            t("no-information-available-short")}
        </div>
        {/* <div className="" style={rowItemStyle}>
          {(planItem && planItem.nextDate?.date) || t('no-information-available-short')}
        </div> */}
      </button>
    );
    tableRows.push(row);
  }

  if (tableRows.length === 1) {
    const stub = (
      <div className="logTableRowStub unselectedRow" key={`tableRow-stub`}>
        <div className="logItemTextStub unselectedRowText">
          {t("c-wot-l-vis-stub")}
        </div>
      </div>
    );

    tableRows.push(stub);
  }

  return (
    <div className="plansContainer">
      <div className="TableArrowHeader">
        <button
          className="glyph-button"
          onClick={() =>
            backPage &&
            vd.updateCache({
              page: vd.cache.page - 1,
            })
          }
        >
          <Icon
            path={mdiChevronLeft}
            size={1}
            style={{
              color: backPage ? BASE_ACCENT : BASE_GRAY,
            }}
          />
        </button>
        <div className="card-header-text-small-centered tableCycleLabel">
          {t("c-wot-l-vis-head")}
        </div>
        <button
          className="glyph-button"
          onClick={() =>
            forwardPage &&
            vd.updateCache({
              page: vd.cache.page + 1,
            })
          }
        >
          <Icon
            path={mdiChevronRight}
            size={1}
            style={{
              color: forwardPage ? BASE_ACCENT : BASE_GRAY,
            }}
          />
        </button>
      </div>
      <div className="logTable">{tableRows}</div>
      {vd.modalVisible === "WO_CNTX_INFO-INACTIVE" && (
        <ViewExerciseModal
          key={"ViewExerciseModal-WO_CNTX_INFO-INACTIVE"}
          modalVisible={vd.modalVisible === "WO_CNTX_INFO-INACTIVE"}
          setModalVisible={vd.setModalVisible}
          modalSettings={{
            plans: vd.plans,
            prebuiltExerciseData: vd.prebuiltExerciseData,
            viewedExercise: vd.viewedExercise,
            setViewedExercise: vd.setViewedExercise,
            planModalType: vd.planModalType,
            setPlanModalType: vd.setPlanModalType,
          }}
        />
      )}
    </div>
  );
}
