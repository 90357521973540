import {
  CALORIES_IN_LB,
  KILOMETERS,
  LIGHT_THEME,
  LANGUAGES,
  POUNDS,
  MILES,
} from "./Constants";
import {
  DEFAULT_REPEAT_SETTING,
  getTodayDate,
  REPEAT_OPTIONS,
} from "./Functions";

// Template generator functions and object structures used in the data and displays of the application.

// Assorted
export const generateRepeatOptions = (translater) => {
  const repeatOptionsObj = REPEAT_OPTIONS;
  const repeatOptions = Object.keys(repeatOptionsObj);
  const options = [];

  for (let index = 0; index < repeatOptions.length; index++) {
    const opt = repeatOptions[index];
    options.push({ value: opt, text: translater(opt) });
  }

  return options;
};

export const generateServingSizeOptions = (
  translater,
  unit,
  customSize = undefined,
  customRange = undefined
) => {
  const options = [];

  const servingSizeOptions = customRange
    ? customRange
    : unit === "Meal"
    ? [0.25, 0.5, 0.75, 1]
    : [1, 100];

  customSize &&
    servingSizeOptions.indexOf(customSize) === -1 &&
    servingSizeOptions.push(customSize);
  servingSizeOptions.reverse();

  for (let index = 0; index < servingSizeOptions.length; index++) {
    const opt = servingSizeOptions[index];
    options.push({
      value: opt,
      text: `${opt}${
        unit === "Meal"
          ? " " +
            translater("meal")[0].toUpperCase() +
            translater("meal").substring(1)
          : unit
      }`,
    });
  }

  return options;
};

export const generateServingUnitOptions = (
  extra = undefined,
  translator = null
) => {
  const options = [];

  const servingUnitOptions = ["meal", "g", "ml", "tsp"];

  extra && servingUnitOptions.unshift(extra);

  for (let index = 0; index < servingUnitOptions.length; index++) {
    const opt = servingUnitOptions[index];
    const translated = translator ? translator(opt) : opt;
    const converted =
      opt === "meal" || extra
        ? translated[0].toUpperCase() + translated.slice(1)
        : translated;

    options.push({
      value: converted,
      text: converted,
    });
  }

  return options;
};

export const generateLanguages = (translater) => {
  const langs = LANGUAGES;
  const options = [];

  for (let index = 0; index < langs.length; index++) {
    const opt = langs[index];
    options.push({ value: opt, text: translater(opt) });
  }

  return options;
};

export const generateItemEntries = (arrOfEntries) => {
  const entries = [];

  for (let index = 0; index < arrOfEntries.length; index++) {
    const opt = arrOfEntries[index];

    entries.push({
      value: opt.name,
      text: `${opt.name} - ${opt.calories} kcal.`,
      key: opt.key,
    });
  }

  return entries;
};

export const generateSortingEntries = (arrOfEntries) => {
  const entries = [];

  for (let index = 0; index < arrOfEntries.length; index++) {
    const opt = arrOfEntries[index];
    entries.push({
      value: opt.name,
      text: opt.nameTranslated,
    });
  }

  return entries;
};

export const generateScalarEntries = (arrOfEntries) => {
  const entries = [];
  if (!arrOfEntries || arrOfEntries.length <= 0) {
    return [];
  }

  for (let index = 0; index < arrOfEntries.length; index++) {
    const opt = arrOfEntries[index];
    entries.push({
      value: opt.n,
      text: opt.n,
    });
  }

  return entries;
};

// Calorie Tracker
export const DEFAULT_CALORIE_ANNOTATIONS = {
  MEDS: false,
  PROT: false,
  FAT: false,
  CARB: false,
  SUGAR: false,
};

export const DEFAULT_PHYSICAL_ANNOTATIONS = {
  SPORT: false,
  LIFT: false,
  CARDIO: false,
  PHYSIO: false,
  WALK: false,
};

export const generateCalorieAnnotations = () =>
  Object.assign({}, DEFAULT_CALORIE_ANNOTATIONS);

export const generatePhysicalAnnotations = () =>
  Object.assign({}, DEFAULT_PHYSICAL_ANNOTATIONS);

export const DEFAULT_ANNOTATIONS_GLYPHS = {
  MEDS: "💊",
  PROT: "🥩",
  FAT: "🥓",
  CARB: "🍞",
  SUGAR: "🍬",
  SPORT: "🏒",
  LIFT: "🏋🏽‍♀️",
  CARDIO: "🏊🏻‍♂️",
  PHYSIO: "👩🏾‍⚕️",
  WALK: "🚶",
};

export const generateAnnotationGlyphString = (annotationIndex) => {
  if (annotationIndex === null || annotationIndex === undefined) {
    return "";
  }
  const keys = Object.keys(annotationIndex);
  let annotationString = "";

  for (let i = 0; i < keys.length; i++) {
    const annote = keys[i];
    if (annotationIndex[annote]) {
      annotationString += DEFAULT_ANNOTATIONS_GLYPHS[annote]
        ? DEFAULT_ANNOTATIONS_GLYPHS[annote]
        : annote + " ";
    }
  }

  return annotationString;
};

export const generateCalorieCurrentItem = (
  date = getTodayDate(),
  caloriesGoal,
  savedItems = null,
  addItem = () => {},
  calorieLog = null,
  goalPace = generatePaceGoal(0)
) => {
  const baseCalorieObject = {
    datetime: date.json,
    date: date.date,
    caloriesIn: 0, // Food
    caloriesOut: 0, // Physical
    caloriesGoal: caloriesGoal,
    foodItems: [],
    physicalItems: [],
    pace: goalPace,
  };
  const foodItems = savedItems?.food?.items;
  const physicalItems = savedItems?.physical?.items;

  // If no triggers were provided.
  if (
    (!foodItems && !physicalItems) ||
    (Object.keys(foodItems).length === 0 &&
      Object.keys(physicalItems).length === 0) ||
    date.date !== getTodayDate().date
  ) {
    return baseCalorieObject;
  } else {
    const processTriggers = (date, sourceTarget, savedTarget) => {
      const keys = Object.keys(sourceTarget);
      const today = getTodayDate();
      for (let index = 0; index < keys.length; index++) {
        const item = sourceTarget[keys[index]];
        const func = REPEAT_OPTIONS[item.repeatSetting];

        // Trigger fires, add respective item to current listing.
        // Only populate if current date is being looked at, to avoid filling into infinity dates.
        if (func(date, item.datetime) && date.date === today.date) {
          item.date = date.date;
          item.datetime = date.json;
          if (item.type === "foodItem") {
            savedTarget.caloriesIn += item.calories;
            savedTarget.foodItems.push(item);
          } else {
            // Physical
            savedTarget.caloriesOut += item.calories;
            savedTarget.physicalItems.push(item);
          }
        }
      }

      return savedTarget;
    };

    const baseCalorieObjectWithFoods = foodItems
      ? processTriggers(date, foodItems, baseCalorieObject)
      : baseCalorieObject;
    const baseCalorieObjectWithPhysicals = physicalItems
      ? processTriggers(date, physicalItems, baseCalorieObjectWithFoods)
      : baseCalorieObjectWithFoods;

    if (date.date === getTodayDate().date) {
      if (!calorieLog[date.year]) {
        calorieLog[date.year] = {};
      }
      if (!calorieLog[date.year][date.month]) {
        calorieLog[date.year][date.month] = {};
      }
      calorieLog[date.year][date.month][date.day] =
        baseCalorieObjectWithPhysicals;

      if (baseCalorieObjectWithPhysicals.foodItems.length > 0) {
        addItem("foodItem", baseCalorieObject.foodItems);
      }

      if (baseCalorieObjectWithPhysicals.physicalItems.length > 0) {
        addItem("foodItem", baseCalorieObject.physicalItems);
      }
    }

    return baseCalorieObjectWithPhysicals;
  }
};

export const DEFAULT_SAVED_ITEM = {
  calories: undefined,
  annotations: undefined,
  name: undefined,
  repeatSetting: DEFAULT_REPEAT_SETTING,
  key: undefined,
  type: undefined,
  datetime: undefined,
  date: undefined,
};

// Weight Tracker
export const generatePaceGoal = (pace, unit = POUNDS) => {
  return { unit: unit, value: pace, date: getTodayDate().date };
};

export const generateDailyCheckup = () => {
  return {
    poorSleep: false,
    irregularFluids: false,
    sick: false,
    irregularWeighin: false,
  };
};

export const generateWeightLogItem = (
  _weight = undefined,
  _type = "stub", // 'stub', 'user'
  _unit = POUNDS,
  _date = getTodayDate(),
  _prediction = undefined,
  _goal = undefined,
  _pace = undefined
) => {
  return {
    unit: _unit,
    value: _weight,
    type: _type,
    datetime: _date.json,
    date: _date.date,
    prediction: _prediction,
    goal: _goal,
    pace: _pace,
    checkup: generateDailyCheckup(),
  };
};

export const generateWeightGoal = (
  goal,
  direction = "nochange", // 'nochange', 'less', 'more'
  unit = POUNDS
) => {
  return {
    unit: unit,
    value: goal,
    direction: direction,
    date: getTodayDate().date,
  };
};

export const generateWeightPrediction = (
  bmr,
  caloriesIn = 0,
  caloriesOut = 0,
  lastWeight,
  weightGoal,
  goalPace,
  unit,
  date = getTodayDate()
) => {
  const caloricDelta = caloriesIn - bmr - caloriesOut;
  const weightChange = caloricDelta / CALORIES_IN_LB;
  const predictedWeight = lastWeight + weightChange;

  return {
    type: "prediction",
    predictedWeight: predictedWeight,
    bmr: bmr,
    caloricDelta: caloricDelta,
    lastWeight: lastWeight,
    weightDiff: predictedWeight - lastWeight,
    date: date.date,
    datetime: date.json,
    goal: weightGoal,
    pace: goalPace,
    unit: unit,
  };
};

// Workout Tracker
export const generateWorkoutItem = (planDefaults) => {
  return {
    isPrebuilt: false,
    prebuildLookup: null,
    name: null,
    // types: planDefaults?.types || generatePhysicalAnnotations(),
    instruction: "",
    order: planDefaults?.order || null,
    sets: planDefaults?.sets || 5,
    reps: planDefaults?.reps || 8,
    rest: planDefaults?.rest || 45,
    duration: null,
    calories: null,
    laps: planDefaults?.laps || 1,
    distancePerLap: planDefaults?.distancePerLap || 1,
    distanceUnit: planDefaults?.distanceUnit || KILOMETERS,
    totalDistance: null,
    speed: null,
    hasGoal: false,
  };
};

export const generateWorkoutPlan = (currentDefaults) => {
  return {
    id: null,
    name: null,
    types: generatePhysicalAnnotations(), // annotations
    schedule: DEFAULT_REPEAT_SETTING,
    nextDate: null,
    lastDate: null,
    creationDate: getTodayDate(),
    lastEditDate: null,
    description: "",
    calories: null,
    defaults: {
      duration: null,
      sets: currentDefaults?.sets || 5,
      reps: currentDefaults?.reps || 5,
      rest: currentDefaults?.rest || 90,
      laps: currentDefaults?.laps || 1,
      distancePerLap: currentDefaults?.distancePerLap || 1,
      distanceUnit: currentDefaults?.distanceUnit || KILOMETERS,
    },
  };
};

export const generateWorkoutPlanHistoryRecord = (sessionID) => {
  return {
    datestamp: getTodayDate().json,
    sessionID: sessionID,
    status: "prepped",
    duration: null,
    itemsCompleted: 0,
  };
};

export const generateWorkoutActiveItem = () => {
  return { item: 0, set: 0, reps: 0 };
};

export const generateWorkoutItemHistoryRecord = (sessionID) => {
  return {
    datestamp: getTodayDate().json,
    status: null,
    duration: null,
    setsGoal: null,
    setsCompleted: null,
    repsGoal: null,
    repsCompleted: null,
    sessionID: sessionID,
    weight: null,
  };
};

// Base States
export const BASE_INIT_STATE = {
  body: {
    height: null,
    age: null,
    sex: null,
    birthday: null,
    basalMetabolicRate: null,
  },
  workout: {
    cache: {
      preppedPlan: null,
      selectedPlan: null,
      activePlan: null,
      page: 0,
      skipped: false,
      completed: false,
      lastCompleted: null,
      manualPrep: false,
      selectedPlanHasExercises: false,
      lastCompletedItem: null,
    },
    settings: {
      weightUnit: POUNDS,
      distanceUnit: MILES,
      durationUnit: "second-short",
      progressionRateSet: 5,
      progressionRateDistance: 0.25,
      progressionRateDuration: 5,
      restDuration: 90,
    },
    planSizes: {},
  },
  weight: {
    settings: {
      unit: POUNDS,
      lastUserEntry: null,
    },
  },
  fast: {
    stats: {
      total: 0,
      successes: 0,
      avgDuration: 0,
      longest: 0,
    },
    current: {
      status: "inactive",
      start: null,
      end: null,
      duration: null,
      goal: 12,
      successful: false,
    },
  },
  calories: {
    savedItems: {
      physical: {
        graph: {},
        recent: [],
      },
      food: {
        graph: {},
        recent: [],
      },
    },
  },
  settings: {
    theme: LIGHT_THEME,
  },
  version: 2,
};

export const DEFAULT_USER = {
  height: { feet: 5, inches: 5 },
  age: {
    value: 18,
    date: getTodayDate().date,
  },
  weight: 160,
  weightUnit: POUNDS,
  birthday: getTodayDate().date,
  sex: "f",
  // goal: 155,
  pace: generatePaceGoal(0),
};
