import React from "react";

import {
  BASE_ACCENT,
  BASE_AQUA,
  BASE_GRAY,
  BASE_GREEN,
  BASE_ORANGE,
  BASE_PINK,
  BASE_RED,
  BASE_SELECTABLE_LIGHT,
  BASE_YELLOW,
  POUNDS,
  THEME_COLOR,
  THEME_GRAYSCALE,
  WEIGHT_MAX,
  WEIGHT_MIN,
} from "./Constants";
import {
  DEFAULT_ANNOTATIONS_GLYPHS,
  generateCalorieAnnotations,
  generatePhysicalAnnotations,
} from "./Templates";

import CalorieAddModal from "../Components/Modals/Calories/CalorieAddModal";
import EndFastModal from "../Components/Modals/Fast/EndFastModal";
import StartFastModal from "../Components/Modals/Fast/StartFastModal";
import ConfirmModal from "../Components/Modals/General/ConfirmModal";
import CustomInputModal from "../Components/Modals/General/CustomInputModal";

// import CameraModal from "../Components/Modals/Calories/CameraModal";
import CustomFastModal from "../Components/Modals/Fast/CustomFastModal";
import WorkoutSetupModal from "../Components/Modals/Workout/WorkoutSetupModal";
import PlanModal from "../Components/Modals/Workout/PlanModal";
import ViewPlanModal from "../Components/Modals/Workout/ViewPlanModal";

export const CALORIE_CONTEXT_BUTTONS = {
  format: "standard",
  buttons: [
    {
      btnName: "undo",
      funcKey: "CT_CNTX_UNDO",
      type: "custom",
      btnColor: (isDisabled) => {
        return isDisabled ? null : BASE_RED;
      },
      enabledCheck: {
        check: { latestItem: null },
        expectation: false,
      },
    },
    {
      btnName: "reduce",
      funcKey: "CT_CNTX_REDUCE",
      type: "custom",
      btnColor: (isDisabled) => {
        return isDisabled ? null : BASE_YELLOW;
      },
      enabledCheck: {
        check: { latestItem: null },
        expectation: false,
      },
    },
    {
      btnName: "more",
      funcKey: "CT_CNTX_MORE",
      type: "custom",
      btnColor: (isDisabled) => {
        return isDisabled ? null : BASE_GREEN;
      },
      enabledCheck: {
        check: { latestItem: null },
        expectation: false,
      },
    },
    {
      btnName: "information-short",
      funcKey: "CT_CNTX_INFO",
      type: "custom",
      btnColor: (isDisabled) => {
        return isDisabled ? null : BASE_AQUA;
      },
      enabledCheck: {
        check: { latestItem: null },
        expectation: false,
      },
    },
  ],
};

export const CT_FOOD_BUTTONS = {
  format: "standard",
  theme: THEME_COLOR,
  themeColor: BASE_ACCENT,
  buttons: [
    {
      btnName: "undo",
      funcKey: "UNDO",
      type: "custom",
      btnColor: (isDisabled) => {
        return isDisabled ? null : BASE_RED;
      },
      enabledCheck: {
        check: { latestItem: null },
        expectation: false,
      },
    },
    {
      btnName: "meal",
      btnColor: BASE_GREEN,
      type: "custom",
      funcKey: "CUSTOM_FOOD_CALORIES",
      modalSettings: {
        isFood: true,
        unitType: "calories",
        minVal: 0,
        maxVal: 4000, // Michael Phelps' Meal.
        color: BASE_ACCENT,
        colorScale: true,
      },
      modal: (params) => (
        <CalorieAddModal
          key={"modal-CUSTOM_FOOD_CALORIES"}
          modalVisible={params.btn.funcKey === params.modalVisible}
          setModalVisible={params.setModalVisible}
          func={params.modalFunc}
          modalSettings={params.btn.modalSettings}
          annotations={(customPersist) =>
            params._generateGenericButtons(
              params.props.interactions,
              params.customPersist
            )
          }
          setItemManagementTarget={params.props.setItemManagementTarget}
          setPercentItemTarget={params.props.setPercentItemTarget}
          userData={params.props.userData}
          itemGraph={params.props.itemGraph}
          hasNewCalorieItems={params.props.hasNewCalorieItems}
          setHasNewCalorieItems={params.props.setHasNewCalorieItems}
          activeDate={params.props.activeDate}
          changeDate={params.props.changeDate}
          previousDate={params.props.previousDate}
          nextDate={params.props.nextDate}
          setReturnFromCalToAdd={params.props.setReturnFromCalToAdd}
          foods={params.props.foods}
          exercises={params.props.exercises}
          savedFoodItems={params.props.savedFoodItems}
        />
      ),
    },
    // {
    //   btnName: "camera",
    //   btnColor: BASE_ORANGE,
    //   type: "custom",
    //   funcKey: "CUSTOM_FOOD_CAMERA",
    //   // modal: (params) => (
    //   //   <CameraModal
    //   //     key={"CUSTOM_FOOD_CAMERA"}
    //   //     modalVisible={params.btn.funcKey === params.modalVisible}
    //   //     setModalVisible={params.setModalVisible}
    //   //     func={params.modalFunc}
    //   //     modalSettings={params.btn.modalSettings}
    //   //     no={"cancel"}
    //   //     question={"scan-barcode-experimental"}
    //   //     userData={params.props.userData}
    //   //     itemGraph={params.props.itemGraph}
    //   //     isDarkTheme={params.isDarkTheme}
    //   //   />
    //   // ),
    //   modalSettings: {
    //     // isFood: true,
    //     // unitType: 'calories',
    //     // minVal: 0,
    //     // maxVal: 4000, // Michael Phelps' Meal.
    //     // color: BASE_ACCENT,
    //     // colorScale: true,
    //   },
    // },
    {
      btnName: "custom",
      btnColor: BASE_AQUA,
      type: "custom",
      funcKey: "CUSTOM_GENERIC_CALORIES",
      modalSettings: {
        isFood: true,
        unitType: "kcal",
        header: "calories",
        minVal: 0,
        maxVal: 4000, // Michael Phelps' Meal.
        color: BASE_ACCENT,
        colorScale: true,
      },
      modal: (params) => (
        <CustomInputModal
          key={"customInputModal-CUSTOM_GENERIC_CALORIES"}
          modalVisible={params.btn.funcKey === params.modalVisible}
          setModalVisible={params.setModalVisible}
          func={params.modalFunc}
          fastStatus={params.props.fastStatus}
          setActiveCardStates={params.props.setActiveCardStates}
          activeCardStates={params.props.activeCardStates}
          modalSettings={{
            isFood: true,
            unitType: "kcal",
            header: "calories",
            minVal: 0,
            maxVal: 4000, // Michael Phelps' Meal.
            color: BASE_ACCENT,
            colorScale: true,
          }}
          current={900} // Increased from 800 to 900 because CAMERA replaced by flat 800.
        />
      ),
    },
    { btnName: 50, btnColor: BASE_ACCENT },
    { btnName: 100, btnColor: BASE_ACCENT },
    { btnName: 200, btnColor: BASE_ACCENT },
    { btnName: 300, btnColor: BASE_ACCENT },
    { btnName: 400, btnColor: BASE_ACCENT },
    { btnName: 500, btnColor: BASE_ACCENT },
    { btnName: 600, btnColor: BASE_ACCENT },
    { btnName: 700, btnColor: BASE_ACCENT },
    { btnName: 800, btnColor: BASE_ACCENT },
    // { btnName: 900, btnColor: BASE_ACCENT },
    // { btnName: 1000, btnColor: BASE_ACCENT },
  ],
};

export const CT_PHYSICAL_BUTTONS = {
  format: "standard",
  theme: THEME_GRAYSCALE,
  themeColor: BASE_PINK,
  buttons: [
    {
      btnName: "undo",
      funcKey: "UNDO",
      type: "custom",
      btnColor: (isDisabled) => {
        return isDisabled ? null : BASE_RED;
      },
      enabledCheck: {
        check: { latestItem: null },
        expectation: false,
      },
    },
    {
      btnName: "activity",
      btnColor: BASE_GREEN,
      type: "custom",
      funcKey: "CUSTOM_PHYSICAL_CALORIES",
      modal: (params) => (
        <CalorieAddModal
          key={"modal-CUSTOM_PHYSICAL_CALORIES"}
          modalVisible={params.btn.funcKey === params.modalVisible}
          setModalVisible={params.setModalVisible}
          func={params.modalFunc}
          modalSettings={params.btn.modalSettings}
          annotations={(customPersist) =>
            params._generateGenericButtons(
              params.props.interactions,
              params.customPersist
            )
          }
          setItemManagementTarget={params.props.setItemManagementTarget}
          setPercentItemTarget={params.props.setPercentItemTarget}
          userData={params.props.userData}
          modalData={params.props.modalData}
          itemGraph={params.props.itemGraph}
          hasNewCalorieItems={params.props.hasNewCalorieItems}
          setHasNewCalorieItems={params.props.setHasNewCalorieItems}
          activeDate={params.props.activeDate}
          changeDate={params.props.changeDate}
          previousDate={params.props.previousDate}
          nextDate={params.props.nextDate}
          setReturnFromCalToAdd={params.props.setReturnFromCalToAdd}
          foods={params.props.foods}
          exercises={params.props.exercises}
          currentWeight={params.props.currentWeight}
          savedPhysicalItems={params.props.savedPhysicalItems}
        />
      ),
      modalSettings: {
        isFood: false,
        unitType: "calories",
        minVal: 0,
        maxVal: 2000, // Marathon calories.
        color: BASE_GRAY,
        colorScale: true,
      },
    },
    {
      btnName: "custom",
      btnColor: BASE_AQUA,
      type: "custom",
      funcKey: "CUSTOM_GENERIC_PHYSICAL_CALORIES",
      modalSettings: {
        isFood: false,
        unitType: "kcal",
        header: "calories",
        minVal: 0,
        maxVal: 2000, // marathon
        color: BASE_ACCENT,
        colorScale: true,
      },
      modal: (params) => (
        <CustomInputModal
          key={"customInputModal-CUSTOM_GENERIC_PHYSICAL_CALORIES"}
          modalVisible={params.btn.funcKey === params.modalVisible}
          setModalVisible={params.setModalVisible}
          func={params.modalFunc}
          modalSettings={{
            isFood: false,
            unitType: "kcal",
            header: "calories",
            minVal: 0,
            maxVal: 2000, // marathon
            color: BASE_ACCENT,
            colorScale: true,
          }}
          current={800}
        />
      ),
    },
    { btnName: 50, btnColor: BASE_PINK },
    { btnName: 100, btnColor: BASE_PINK },
    { btnName: 200, btnColor: BASE_PINK },
    { btnName: 300, btnColor: BASE_PINK },
    { btnName: 400, btnColor: BASE_PINK },
    { btnName: 500, btnColor: BASE_PINK },
    { btnName: 600, btnColor: BASE_PINK },
    { btnName: 700, btnColor: BASE_PINK },
    { btnName: 800, btnColor: BASE_PINK },
    // { btnName: 900, btnColor: BASE_PINK },
    // { btnName: 1000, btnColor: BASE_PINK },
  ],
};

const _genAnnotationButtons = (annotations) => {
  const keys = Object.keys(annotations);
  const annotationBtns = [];

  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
    annotationBtns.push({
      btnName: key,
      key: key,
      value: key,
      glyph: DEFAULT_ANNOTATIONS_GLYPHS[key],
      btnColor: (isDisabled) => {
        return isDisabled
          ? BASE_ACCENT
          : // : IS_DARK_THEME
            // ? BASE_SELECTABLE_DARK
            BASE_SELECTABLE_LIGHT;
      },
      enabledCheck: {
        check: { annotations: (obj) => obj[key] },
        checkType: "bool",
        expectation: true,
        neverDisable: true,
      },
    });
  }
  return annotationBtns;
};

export const CT_FOOD_ANNOTATIONS = {
  format: "standard",
  isColorScaled: false,
  buttons: _genAnnotationButtons(generateCalorieAnnotations()),
};

export const CT_PHYSICAL_ANNOTATIONS = {
  format: "standard",
  isColorScaled: false,
  buttons: _genAnnotationButtons(generatePhysicalAnnotations()),
};

export const START_AND_END_FAST_BUTTONS = {
  format: "standard",
  buttons: [
    {
      btnName: "start",
      btnColor: (isDisabled) => {
        return isDisabled ? null : BASE_GREEN;
      },
      type: "custom",
      funcKey: "STARTFAST",
      enabledCheck: {
        check: { fastStatus: "active" },
        expectation: false,
      },
      modal: (params) => (
        <StartFastModal
          key={"startFastModal-" + params.btn.funcKey}
          name={params.btn.btnName}
          modalVisible={params.btn.funcKey === params.modalVisible}
          setModalVisible={params.setModalVisible}
          func={params.modalFunc}
          endTarget={params.props.endTarget}
          fastGoal={params.props.fastGoal}
          modalSettings={params.btn.modalSettings}
        />
      ),
      modalSettings: {
        color: BASE_ACCENT,
      },
    },
    {
      btnName: "GOAL",
      btnColor: (isDisabled) => {
        return isDisabled ? null : BASE_PINK;
      },
      type: "custom",
      funcKey: "STARTGOAL",
      enabledCheck: {
        check: [{ fastDaysFromWeightGoal: 0 }, { fastStatus: "active" }],
        checkType: [">", "!=="],
        expectation: [true, true],
      },
      modal: (params) => (
        <StartFastModal
          key={"startGoalFastModal-" + params.btn.funcKey}
          name={params.btn.btnName}
          modalVisible={params.btn.funcKey === params.modalVisible}
          setModalVisible={params.setModalVisible}
          func={params.modalFunc}
          endTarget={params.props.endTarget}
          fastGoal={params.props.fastGoal}
          fastSpecialGoal={params.props.fastSpecialGoal}
          modalSettings={params.btn.modalSettings}
        />
      ),
      modalSettings: {
        color: BASE_ACCENT,
      },
    },
    {
      btnName: "custom",
      btnColor: (isDisabled) => {
        return isDisabled ? null : BASE_ORANGE;
      },
      type: "custom",
      funcKey: "CUSTOMFAST",
      enabledCheck: {
        check: { fastStatus: "active" },
        expectation: false,
      },
      modal: (params) => (
        <CustomFastModal
          key={"customFastModal-" + params.btn.funcKey}
          name={params.btn.btnName}
          modalVisible={params.btn.funcKey === params.modalVisible}
          setModalVisible={params.setModalVisible}
          func={params.modalFunc}
          endTarget={params.props.endTarget}
          fastGoal={params.props.fastGoal}
          customFastGoal={params.props.customFastGoal}
          setCustomFastGoal={params.props.setCustomFastGoal}
          modalSettings={params.btn.modalSettings}
          fastLog={params.props.fastLog}
        />
      ),
      modalSettings: {
        color: BASE_ACCENT,
      },
    },
    {
      btnName: "cancel",
      btnColor: (isDisabled) => {
        return isDisabled ? null : BASE_AQUA;
      },
      type: "custom",
      funcKey: "CANCELFAST",
      enabledCheck: {
        check: { fastStatus: "active" },
        expectation: true,
      },
      modal: (params) => (
        <ConfirmModal
          key={"ConfirmModal-" + params.btn.funcKey}
          name={params.btn.btnName}
          modalVisible={params.btn.funcKey === params.modalVisible}
          setModalVisible={params.setModalVisible}
          func={params.modalFunc}
          modalSettings={params.btn.modalSettings}
          userData={params.props.userData}
          question={"c-ftt-cancel-fast-q"}
          yes={"c-ftt-cancel-fast"}
          no={"back"}
        />
      ),
      modalSettings: {
        color: BASE_ACCENT,
      },
    },
    {
      btnName: "end",
      btnColor: (isDisabled) => {
        return isDisabled ? null : BASE_RED;
      },
      type: "custom",
      funcKey: "ENDFAST",
      enabledCheck: {
        check: { fastStatus: "active" },
        expectation: true,
      },
      modal: (params) => (
        <EndFastModal
          key={"endFastModal-" + params.btn.funcKey}
          name={params.btn.btnName}
          modalVisible={params.btn.funcKey === params.modalVisible}
          setModalVisible={params.setModalVisible}
          func={params.modalFunc}
          endTarget={params.props.endTarget}
          currentFast={params.props.currentFast}
          fastGoal={params.props.fastGoal}
          customFastGoal={params.props.fastGoal}
          modalSettings={params.btn.modalSettings}
        />
      ),
      modalSettings: {
        color: BASE_ACCENT,
      },
    },
  ],
};

export const ACTIVE_WORKOUT_CONTEXT_BUTTONS = {
  format: "standard",
  buttons: [
    {
      btnName: "goal",
      funcKey: "WO_CNTX_GOAL",
      type: "custom",
      btnColor: (isDisabled) => {
        return isDisabled ? null : BASE_GREEN;
      },
      enabledCheck: {
        check: { activeItemHasGoal: false },
        expectation: false,
      },
    },
    {
      btnName: "information-short",
      funcKey: "WO_CNTX_INFO",
      type: "custom",
      btnColor: (isDisabled) => {
        return isDisabled ? null : BASE_AQUA;
      },
      enabledCheck: {
        check: { activeItem: null },
        expectation: false,
      },
    },
    {
      btnName: "timer",
      funcKey: "WO_CNTX_TIMER",
      type: "custom",
      btnColor: (isDisabled) => {
        return isDisabled ? null : BASE_ORANGE;
      },
      enabledCheck: {
        check: { activeItemHasTimer: false },
        expectation: false,
      },
    },
    {
      btnName: "rest",
      funcKey: "WO_CNTX_REST",
      type: "custom",
      btnColor: (isDisabled) => {
        return isDisabled ? null : BASE_PINK;
      },
      enabledCheck: {
        check: { activeItem: null },
        expectation: false,
      },
    },
  ],
};

export const WORKOUT_PLAN_BUTTONS = {
  format: "standard",
  buttons: [
    {
      btnName: (props) => {
        return props.preppedPlan !== null &&
          props.selectedPlan !== null &&
          props.preppedPlan === props.selectedPlan
          ? "unprep"
          : "prep";
      },
      btnColor: (isDisabled) => {
        return isDisabled ? null : BASE_ACCENT;
      },
      type: "custom",
      funcKey: "PREP_OR_UNPREP",
      enabledCheck: {
        check: [{ selectedPlan: null }, { selectedPlanHasExercises: true }],
        checkType: ["===", "==="],
        expectation: [false, true],
      },
    },
    {
      btnName: "info",
      btnColor: (isDisabled) => {
        return isDisabled ? null : BASE_AQUA;
      },
      type: "custom",
      funcKey: "VIEWPLAN",
      enabledCheck: {
        check: { selectedPlan: null },
        expectation: false,
      },
      modal: (params) => (
        <ViewPlanModal
          key={"ViewPlanModal-" + params.btn.funcKey}
          modalVisible={params.btn.funcKey === params.modalVisible}
          setModalVisible={params.setModalVisible}
          func={params.modalFunc}
          isDarkTheme={params.isDarkTheme}
          exercises={params.props.exercises}
          question={params.props.plans[params.props.selectedPlan]?.name}
          no={"back"}
          isSelectedPrepped={params.props.isSelectedPrepped}
          prebuiltExerciseData={params.props.prebuiltExerciseData}
          viewedExercise={params.props.viewedExercise}
          setViewedExercise={params.props.setViewedExercise}
          setPlanModalType={params.props.setPlanModalType}
        />
      ),
      modalSettings: {
        color: BASE_ACCENT,
      },
    },
    {
      btnName: "new",
      btnColor: (isDisabled) => {
        return isDisabled ? null : BASE_GREEN;
      },
      type: "custom",
      funcKey: "NEWPLAN",
      enabledCheck: {
        check: true,
        expectation: true,
      },
      modal: (params) => (
        <PlanModal
          key={"PlanModal-" + params.btn.funcKey}
          funcKey={params.btn.funcKey}
          name={params.btn.btnName}
          modalVisible={params.btn.funcKey === params.modalVisible}
          setModalVisible={params.setModalVisible}
          func={params.modalFunc}
          modalSettings={params.btn.modalSettings}
          userData={params.props.userData}
          settings={params.props.settings}
          plans={params.props.plans}
          isDarkTheme={params.isDarkTheme}
          prebuiltExerciseData={params.props.prebuiltExerciseData}
          viewedExercise={params.props.viewedExercise}
          setViewedExercise={params.props.setViewedExercise}
          setPlanModalType={params.props.setPlanModalType}
        />
      ),
      modalSettings: {
        color: BASE_ACCENT,
      },
    },
    {
      btnName: "edit",
      btnColor: (isDisabled) => {
        return isDisabled ? null : BASE_ORANGE;
      },
      type: "custom",
      funcKey: "EDITPLAN",
      modal: (params) => (
        <PlanModal
          key={"PlanModal-" + params.btn.funcKey}
          funcKey={params.btn.funcKey}
          name={params.btn.btnName}
          modalVisible={params.btn.funcKey === params.modalVisible}
          setModalVisible={params.setModalVisible}
          func={params.modalFunc}
          modalSettings={params.btn.modalSettings}
          userData={params.props.userData}
          editTarget={params.props.editTarget}
          selectedPlanItems={params.props.selectedPlanItems}
          settings={params.props.settings}
          plans={params.props.plans}
          isDarkTheme={params.isDarkTheme}
          prebuiltExerciseData={params.props.prebuiltExerciseData}
          viewedExercise={params.props.viewedExercise}
          setViewedExercise={params.props.setViewedExercise}
          setPlanModalType={params.props.setPlanModalType}
          isEdit={true}
        />
      ),
      modalSettings: {},
      enabledCheck: {
        check: { selectedPlan: null },
        expectation: false,
      },
    },
    {
      btnName: "delete",
      btnColor: (isDisabled) => {
        return isDisabled ? null : BASE_RED;
      },
      type: "custom",
      funcKey: "DELETEPLAN",
      enabledCheck: {
        check: { selectedPlan: null },
        expectation: false,
      },
      modal: (params) => (
        <ConfirmModal
          key={"ConfirmModal-" + params.btn.funcKey}
          name={params.btn.btnName}
          modalVisible={params.btn.funcKey === params.modalVisible}
          setModalVisible={params.setModalVisible}
          func={params.modalFunc}
          modalSettings={params.btn.modalSettings}
          userData={params.props.userData}
          funcValue={params.props.selectedPlan}
          passedData={{ question: { varPlan: params.props.selectedPlanName } }}
          question={"m-wot-plan-delete-plan"}
          yes={"m-wot-plan-btn-delete"}
          no={"cancel"}
        />
      ),
      modalSettings: {
        color: BASE_ACCENT,
      },
    },
  ],
};

export const WORKOUT_INACTIVE_BUTTONS = {
  format: "standard",
  buttons: [
    {
      btnName: "start",
      btnColor: (isDisabled) => {
        return isDisabled ? null : BASE_ACCENT;
      },
      type: "custom",
      funcKey: "START_WORKOUT",
      enabledCheck: {
        check: [{ preppedPlan: null }, { activePlan: null }],
        checkType: ["!==", "!=="],
        expectation: [true, true],
      },
    },
    {
      btnName: "info",
      btnColor: (isDisabled) => {
        return isDisabled ? null : BASE_AQUA;
      },
      type: "custom",
      funcKey: "VIEWPLAN_INACTIVE",
      enabledCheck: {
        check: { preppedPlan: null },
        expectation: false,
      },
      modal: (params) => (
        <ViewPlanModal
          key={"ViewPlanModal-" + params.btn.funcKey}
          modalVisible={params.btn.funcKey === params.modalVisible}
          setModalVisible={params.setModalVisible}
          func={params.modalFunc}
          isDarkTheme={params.isDarkTheme}
          exercises={params.props.exercises}
          question={params.props.plans[params.props.preppedPlan]?.name}
          no={"back"}
          isSelectedPrepped={true}
          isFromInactive={true}
          prebuiltExerciseData={params.props.prebuiltExerciseData}
          viewedExercise={params.props.viewedExercise}
          setViewedExercise={params.props.setViewedExercise}
          setPlanModalType={params.props.setPlanModalType}
        />
      ),
      modalSettings: {
        color: BASE_ACCENT,
      },
    },
    {
      btnName: "setup",
      btnColor: (isDisabled) => {
        return isDisabled ? null : BASE_PINK;
      },
      type: "custom",
      funcKey: "WORKOUTSETUP",
      enabledCheck: {
        check: { selectedPlan: null },
        expectation: false,
      },
      modal: (params) => (
        <WorkoutSetupModal
          id={params.props.id}
          key={"WorkoutSetupModal-" + params.btn.funcKey}
          modalVisible={params.btn.funcKey === params.modalVisible}
          setModalVisible={params.setModalVisible}
          func={params.modalFunc}
          isDarkTheme={params.isDarkTheme}
          settings={params.props.settings}
        />
      ),
      modalSettings: {
        color: BASE_ACCENT,
      },
    },

    // {
    //   btnName: (props) => {
    //     return props.preppedPlan && props.skipped ? 'unskip' : 'skip';
    //   },
    //   btnColor: (isDisabled) => {
    //     return isDisabled ? null : BASE_YELLOW;
    //   },
    //   type: 'custom',
    //   funcKey: 'SKIP_OR_UNSKIP_WORKOUT',
    //   enabledCheck: {
    //     check: [{ preppedPlan: null }],
    //     checkType: ['!=='],
    //     expectation: [true],
    //   },
    // },
  ],
};

export const WORKOUT_ACTIVE_BUTTONS = {
  format: "standard",
  buttons: [
    {
      btnName: "UP",
      btnColor: (isDisabled) => {
        return isDisabled
          ? BASE_ACCENT
          : // : IS_DARK_THEME
            // ? BASE_SELECTABLE_DARK
            BASE_SELECTABLE_LIGHT;
      },
      type: "custom",
      funcKey: "UP_STATUS",
      enabledCheck: {
        check: { activeItemStatus: "UP" },
        expectation: false,
      },
    },
    {
      btnName: "STAY",
      btnColor: (isDisabled) => {
        return isDisabled
          ? BASE_ACCENT
          : // : IS_DARK_THEME
            // ? BASE_SELECTABLE_DARK
            BASE_SELECTABLE_LIGHT;
      },
      type: "custom",
      funcKey: "STAY_STATUS",
      enabledCheck: {
        check: { activeItemStatus: "STAY" },
        expectation: false,
      },
    },
    {
      btnName: "DOWN",
      btnColor: (isDisabled) => {
        return isDisabled
          ? BASE_ACCENT
          : // : IS_DARK_THEME
            // ? BASE_SELECTABLE_DARK
            BASE_SELECTABLE_LIGHT;
      },
      type: "custom",
      funcKey: "DOWN_STATUS",
      enabledCheck: {
        check: { activeItemStatus: "DOWN" },
        expectation: false,
      },
    },
    {
      btnName: "SKIP",
      btnColor: (isDisabled) => {
        return isDisabled
          ? BASE_ACCENT
          : // : IS_DARK_THEME
            // ? BASE_SELECTABLE_DARK
            BASE_SELECTABLE_LIGHT;
      },
      type: "custom",
      funcKey: "SKIP_STATUS",
      enabledCheck: {
        check: { activeItemStatus: "SKIP" },
        expectation: false,
      },
    },
    {
      btnName: "DNF",
      btnColor: (isDisabled) => {
        return isDisabled
          ? BASE_ACCENT
          : // : IS_DARK_THEME
            // ? BASE_SELECTABLE_DARK
            BASE_SELECTABLE_LIGHT;
      },
      type: "custom",
      funcKey: "DNF_STATUS",
      enabledCheck: {
        check: { activeItemStatus: "DNF" },
        expectation: false,
      },
    },
  ],
};

export const WORKOUT_STATS_TIMELINE_BUTTONS = {
  format: "standard",
  buttons: [
    {
      funcKey: "2W",
      btnName: "2W",
      btnColor: (isDisabled) => {
        return isDisabled
          ? BASE_ACCENT
          : // : IS_DARK_THEME
            // ? BASE_SELECTABLE_DARK
            BASE_SELECTABLE_LIGHT;
      },
      enabledCheck: {
        check: { statTimeline: "2W" },
        expectation: false,
      },
    },
    {
      funcKey: "1M",
      btnName: "1M",
      btnColor: (isDisabled) => {
        return isDisabled
          ? BASE_ACCENT
          : // : IS_DARK_THEME
            // ? BASE_SELECTABLE_DARK
            BASE_SELECTABLE_LIGHT;
      },
      enabledCheck: {
        check: { statTimeline: "1M" },
        expectation: false,
      },
    },
    {
      funcKey: "6M",
      btnName: "6M",
      btnColor: (isDisabled) => {
        return isDisabled
          ? BASE_ACCENT
          : // : IS_DARK_THEME
            // ? BASE_SELECTABLE_DARK
            BASE_SELECTABLE_LIGHT;
      },
      enabledCheck: {
        check: { statTimeline: "6M" },
        expectation: false,
      },
    },
    {
      funcKey: "1Y",
      btnName: "1Y",
      btnColor: (isDisabled) => {
        return isDisabled
          ? BASE_ACCENT
          : // : IS_DARK_THEME
            // ? BASE_SELECTABLE_DARK
            BASE_SELECTABLE_LIGHT;
      },
      enabledCheck: {
        check: { statTimeline: "1Y" },
        expectation: false,
      },
    },
    {
      funcKey: "3Y",
      btnName: "3Y",
      btnColor: (isDisabled) => {
        return isDisabled
          ? BASE_ACCENT
          : // : IS_DARK_THEME
            // ? BASE_SELECTABLE_DARK
            BASE_SELECTABLE_LIGHT;
      },
      enabledCheck: {
        check: { statTimeline: "3Y" },
        expectation: false,
      },
    },
    {
      funcKey: "BACK",
      btnName: "BACK",
      btnColor: () => {
        return BASE_RED;
      },
    },
  ],
};

export const WORKOUT_STATS_CONTEXT_BUTTONS = {
  format: "standard",
  buttons: [
    {
      funcKey: "SET",
      btnName: "SET",
      btnColor: (isDisabled) => {
        return isDisabled
          ? BASE_ACCENT
          : // : IS_DARK_THEME
            // ? BASE_SELECTABLE_DARK
            BASE_SELECTABLE_LIGHT;
      },
      enabledCheck: {
        check: { statContext: "SET" },
        expectation: false,
      },
      hiddenCheck: {
        check: { hasSetHistory: true },
        expectation: true,
      },
    },
    {
      funcKey: "DURATION",
      btnName: "DURATION",
      btnColor: (isDisabled) => {
        return isDisabled
          ? BASE_ACCENT
          : // : IS_DARK_THEME
            // ? BASE_SELECTABLE_DARK
            BASE_SELECTABLE_LIGHT;
      },
      enabledCheck: {
        check: { statContext: "DURATION" },
        expectation: false,
      },
      hiddenCheck: {
        check: { hasDurationHistory: true },
        expectation: true,
      },
    },
    {
      funcKey: "DISTANCE",
      btnName: "DISTANCE",
      btnColor: (isDisabled) => {
        return isDisabled
          ? BASE_ACCENT
          : // : IS_DARK_THEME
            // ? BASE_SELECTABLE_DARK
            BASE_SELECTABLE_LIGHT;
      },
      enabledCheck: {
        check: { statContext: "DISTANCE" },
        expectation: false,
      },
      hiddenCheck: {
        check: { hasDistanceHistory: true },
        expectation: true,
      },
    },
  ],
};

const _genPaces = (start = -1, end = 1, step = 0.5, weekLetter = "W") => {
  const paces = [];

  for (let index = start; index <= end; index += step) {
    paces.push({
      btnName: `${index > 0 ? "+" : ""}${index}/${weekLetter}`,
      value: index,
      btnColor: (isDisabled) => {
        return isDisabled
          ? BASE_ACCENT
          : // : IS_DARK_THEME
            // ? BASE_SELECTABLE_DARK
            BASE_SELECTABLE_LIGHT;
      },
      enabledCheck: {
        check: { paceGoalValue: index },
        expectation: false,
      },
    });
  }
  return paces;
};

export const generateWTPaceButtons = (weekLetter = "W") => {
  return {
    format: "standard",
    isColorScaled: false,
    step: 0.5,
    buttons: (step) =>
      step ? _genPaces(-step * 2, step * 2, step, weekLetter) : _genPaces(),
  };
};

export const WT_CHART_TIMELINE_BUTTONS = {
  format: "standard",
  buttons: [
    {
      funcKey: "2W",
      btnName: "2W",
      btnColor: (isDisabled) => {
        return isDisabled
          ? BASE_ACCENT
          : // : IS_DARK_THEME
            // ? BASE_SELECTABLE_DARK
            BASE_SELECTABLE_LIGHT;
      },
      enabledCheck: {
        check: { weightTimeline: "2W" },
        expectation: false,
      },
    },
    {
      funcKey: "1M",
      btnName: "1M",
      btnColor: (isDisabled) => {
        return isDisabled
          ? BASE_ACCENT
          : // : IS_DARK_THEME
            // ? BASE_SELECTABLE_DARK
            BASE_SELECTABLE_LIGHT;
      },
      enabledCheck: {
        check: { weightTimeline: "1M" },
        expectation: false,
      },
    },
    {
      funcKey: "6M",
      btnName: "6M",
      btnColor: (isDisabled) => {
        return isDisabled
          ? BASE_ACCENT
          : // : IS_DARK_THEME
            // ? BASE_SELECTABLE_DARK
            BASE_SELECTABLE_LIGHT;
      },
      enabledCheck: {
        check: { weightTimeline: "6M" },
        expectation: false,
      },
    },
    {
      funcKey: "1Y",
      btnName: "1Y",
      btnColor: (isDisabled) => {
        return isDisabled
          ? BASE_ACCENT
          : // : IS_DARK_THEME
            // ? BASE_SELECTABLE_DARK
            BASE_SELECTABLE_LIGHT;
      },
      enabledCheck: {
        check: { weightTimeline: "1Y" },
        expectation: false,
      },
    },
    {
      funcKey: "3Y",
      btnName: "3Y",
      btnColor: (isDisabled) => {
        return isDisabled
          ? BASE_ACCENT
          : // : IS_DARK_THEME
            // ? BASE_SELECTABLE_DARK
            BASE_SELECTABLE_LIGHT;
      },
      enabledCheck: {
        check: { weightTimeline: "3Y" },
        expectation: false,
      },
    },
    {
      funcKey: "GOAL",
      btnName: "GOAL",
      btnColor: (isDisabled) => {
        return isDisabled ? BASE_PINK : BASE_PINK;
      },
      enabledCheck: {
        check: [{ isGoal: true }],
        checkType: ["==="],
        expectation: [true],
      },
    },
  ],
};

export const WT_LOG_BUTTERFLY_BUTTONS = {
  format: "butterfly-weight",
  step: 0.5,
  theme: THEME_GRAYSCALE,
  defaultDelta: 200,
  buttons: {
    left: [
      { btnName: "190", value: 190, btnColor: BASE_GRAY },
      { btnName: "180", value: 180, btnColor: BASE_GRAY },
      { btnName: "170", value: 170, btnColor: BASE_GRAY },
      { btnName: "160", value: 160, btnColor: BASE_GRAY },
      { btnName: "150", value: 150, btnColor: BASE_GRAY },
      { btnName: "140", value: 140, btnColor: BASE_GRAY },
    ],
    center: [
      {
        btnName: "custom",
        btnColor: BASE_GREEN,
        type: "custom",
        funcKey: "CUSTOM_WEIGHT_LOG",
        modal: (params) => (
          <CustomInputModal
            key={"customInputModal-WeightLog"}
            modalVisible={params.btn.funcKey === params.modalVisible}
            setModalVisible={params.setModalVisible}
            func={params.modalFunc}
            modalSettings={params.btn.modalSettings}
            current={params.btn.current}
          />
        ),
        modalSettings: {
          unitType: POUNDS,
          minVal: 0,
          maxVal: 1400, // Heaviest person alive.
          color: BASE_GRAY,
          colorScale: true,
        },
      },
    ],
    right: [
      { btnName: "210", value: 210, btnColor: BASE_GRAY },
      { btnName: "220", value: 220, btnColor: BASE_GRAY },
      { btnName: "230", value: 230, btnColor: BASE_GRAY },
      { btnName: "240", value: 240, btnColor: BASE_GRAY },
      { btnName: "250", value: 250, btnColor: BASE_GRAY },
      { btnName: "260", value: 260, btnColor: BASE_GRAY },
    ],
  },
};

export const WT_GOAL_BUTTERFLY_BUTTONS = {
  format: "butterfly-goal",
  step: 5,
  defaultDelta: 200,
  theme: THEME_GRAYSCALE,
  buttons: {
    left: [
      { btnName: "190", value: 190, btnColor: BASE_GRAY },
      { btnName: "180", value: 180, btnColor: BASE_GRAY },
      { btnName: "170", value: 170, btnColor: BASE_GRAY },
      { btnName: "160", value: 160, btnColor: BASE_GRAY },
      { btnName: "150", value: 150, btnColor: BASE_GRAY },
      { btnName: "140", value: 140, btnColor: BASE_GRAY },
    ],
    center: [
      {
        btnName: "custom",
        btnColor: BASE_GREEN,
        type: "custom",
        funcKey: "CUSTOM_WEIGHT_GOAL",
        modal: (params) => (
          <CustomInputModal
            key={"customInputModal-WeightGoal"}
            modalVisible={params.btn.funcKey === params.modalVisible}
            setModalVisible={params.setModalVisible}
            func={params.modalFunc}
            modalSettings={params.btn.modalSettings}
            current={params.btn.current}
          />
        ),
        modalSettings: {
          unitType: POUNDS,
          minVal: WEIGHT_MIN,
          maxVal: WEIGHT_MAX,
          color: BASE_GRAY,
          colorScale: true,
        },
      },
    ],
    right: [
      { btnName: "210", value: 210, btnColor: BASE_GRAY },
      { btnName: "220", value: 220, btnColor: BASE_GRAY },
      { btnName: "230", value: 230, btnColor: BASE_GRAY },
      { btnName: "240", value: 240, btnColor: BASE_GRAY },
      { btnName: "250", value: 250, btnColor: BASE_GRAY },
      { btnName: "260", value: 260, btnColor: BASE_GRAY },
    ],
  },
};
