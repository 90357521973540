import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BASE_ACCENT,
  BASE_GRAY,
  KILOMETERS,
  LARGE_BTN_MAX_STRING,
  QUESTION_MAX_STRING,
} from "../../../Supports/Constants";
import {
  DEFAULT_REPEAT_SETTING,
  converterDistances,
  generateLapDistances,
  generateLapDurations,
  generateLaps,
  generateNumberRange,
  generateRestDurations,
  getNextScheduled,
  getTodayDate,
  presentDistances,
  shortenText,
} from "../../../Supports/Functions";
import {
  generateWorkoutItem,
  generateWorkoutPlan,
} from "../../../Supports/Templates";
import Icon from "@mdi/react";
import UnstyledSelectBasic from "../../../Components/Supports/UnstyledSelectBasic";
import {
  mdiCheck,
  mdiChevronLeft,
  mdiChevronRight,
  mdiContentSave,
  mdiDelete,
  mdiFileEdit,
  mdiInformationOutline,
  mdiKeyboardReturn,
  mdiPlus,
} from "@mdi/js";
import Modal from "react-modal";
import { InfoTooltip } from "../../Supports/InfoTooltip";

// Component to handle modal for custom workout plan management.
export default function PlanModal(props) {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const settings = props.settings;

  const prebuiltExerciseData = props.prebuiltExerciseData;
  const [baseSearchResults] = useState(prebuiltExerciseData.exerciseIndex);
  const [usedSearchResults, setUsedSearchResults] = useState(baseSearchResults);

  const [searchResultsPage, setSearchResultsPage] = useState(0);
  const [toBeAdded, setToBeAdded] = useState(null);

  const [toBeAddedGoal, setToBeAddedGoal] = useState(null);

  const [planItems, setPlanItems] = useState(
    props.selectedPlanItems ? Object.values(props.selectedPlanItems) : []
  );

  useEffect(() => {
    setPlanItems(
      props.selectedPlanItems ? Object.values(props.selectedPlanItems) : []
    );
  }, [props.selectedPlanItems]);

  const MAX_SEARCH_ITEMS_PER_PAGE = 5;

  // Generates template for fresh plan. Set default type to lift for all exercises.
  const genNewPlan = () => {
    const newPlan = generateWorkoutPlan(props.settings);
    newPlan.types.LIFT = true;
    newPlan.schedule = DEFAULT_REPEAT_SETTING;
    return newPlan;
  };

  const [plan, setPlan] = useState(genNewPlan());

  const updateState = (value) => {
    setPlan({ ...plan, ...value });
  };

  const [originalSchedule] = useState(plan.schedule);
  const [itemsPage, setItemsPage] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showNewItem, setShowNewItem] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [newItem, setNewItem] = useState(null);
  const [lastItem, setLastItem] = useState(null);

  const MAX_ITEMS_PER_PAGE = 8;

  const backPage = itemsPage > 0;
  const HIGHEST_USED_PAGE = planItems
    ? Math.floor(planItems.length / MAX_ITEMS_PER_PAGE) -
      (planItems.length % MAX_ITEMS_PER_PAGE === 0 ? 1 : 0)
    : 0; // 0;
  const forwardPage = itemsPage < HIGHEST_USED_PAGE;

  const updateNewItemState = (value) => {
    setNewItem({ ...newItem, ...value });
  };

  useEffect(() => {
    // If the edit button was the source of the modal, then populate the provided formatted plan.
    if (props.isEdit && props.editTarget) {
      setPlan(props.editTarget);
    }
  }, [props.isEdit, props.editTarget, setPlan, props.selectedPlanItems]);

  if (!props.userData) {
    return <></>;
  }

  const reset = () =>
    setPlan(props.editPlanTarget ? props.editPlanTarget : genNewPlan());

  const save = () => {
    if (plan.name && plan.id) {
      plan.name = plan.name.trim();
      plan.lastEditDate = getTodayDate(); // Update the last edit date tracker.

      // If the schedule was changed, use edit date to anchor scheduling, otherwise use creation date.
      // Determine the next time this plan is scheduled for.
      plan.nextDate = getNextScheduled(
        plan.schedule,
        plan.schedule !== originalSchedule
          ? plan.lastEditDate
          : plan.creationDate
      );

      plan.items = planItems;

      props.func(plan);
      reset();
      props.setModalVisible(false);
    }
  };

  const dismissWithoutInputFunc = () => {
    setNewItem(null);
    setEditOpen(false);
    setShowNewItem(false);
    setItemsPage(0);

    props.setModalVisible(!props.modalVisible);
  };

  const restRates = generateRestDurations(t);
  const lapDurations = generateLapDurations(t);
  const lapDistances = generateLapDistances(
    t,
    settings.distanceUnit,
    0,
    converterDistances(KILOMETERS, 43, settings.distanceUnit)
  );
  const laps = generateLaps();
  const setRates = generateNumberRange(1, 10);
  const repRates = generateNumberRange(1, 100);

  // Add FAIL Support
  repRates.unshift({
    value: t("fail").toUpperCase(),
    text: `${t("fail").toUpperCase()}`,
  });

  const availableOrders = generateNumberRange(
    1,
    planItems ? (editOpen ? planItems.length : planItems.length + 1) : 1
  );

  const getGoals = (category) => {
    switch (category) {
      case "strongman":
      case "powerlifting":
      case "olympic weightlifting":
      case "weighted bodyweight":
      case "assisted bodyweight":
        return ["set"];
      case "plyometrics":
      case "crossfit":
      case "stretching":
      case "strength":
        return ["set", "duration"];
      case "cardio":
        return ["duration", "distance"];
      default:
        return [];
    }
  };

  const generateSearchBar = () => {
    return (
      <div className="sectionItemRow">
        <div className="card-text-small">{`${t("search-bar")}:`}</div>

        <div className="endRow">
          <input
            type="search"
            placeholder={t("search-bar-info")}
            className="input-widest"
            value={searchQuery}
            onChange={(e) => {
              let text = e.target.value;
              setSearchResultsPage(0);
              setSearchQuery(text);

              const stlc = text.toLowerCase();
              const stlcTokens = stlc.split(" ");

              if (text === "" || !text || !text.replace(/\s/g, "").length) {
                setUsedSearchResults(baseSearchResults);
              } else {
                setUsedSearchResults(
                  baseSearchResults
                    .map((be) => {
                      const triggeredTokens = be.tokens.filter((token) =>
                        stlcTokens.some((inputToken) =>
                          token.toLowerCase().includes(inputToken)
                        )
                      );

                      return {
                        ...be,
                        ...{ tokensTriggered: triggeredTokens.length },
                      };
                    })
                    .filter((k) => {
                      const stlc = text.toLowerCase().trim();
                      const staticData =
                        prebuiltExerciseData.exercises[k.lookup];

                      return (
                        k.name.toLowerCase().includes(stlc) ||
                        (staticData.tokens &&
                          stlcTokens &&
                          staticData.tokens.some((token) =>
                            stlcTokens.includes(token.toLowerCase())
                          )) ||
                        (staticData.equipment &&
                          staticData.equipment.toLowerCase().includes(stlc)) ||
                        (staticData.category &&
                          staticData.category.toLowerCase().includes(stlc)) ||
                        (staticData.mechanic &&
                          staticData.mechanic.toLowerCase().includes(stlc)
                            .length > 0) ||
                        (
                          staticData.primaryMuscles &&
                          staticData.primaryMuscles.filter((m) =>
                            m.toLowerCase().includes(stlc)
                          )
                        ).length > 0 ||
                        (
                          staticData.secondaryMuscles &&
                          staticData.secondaryMuscles.filter((m) =>
                            m.toLowerCase().includes(stlc)
                          )
                        ).length > 0
                      );
                    })
                    .sort((a, b) => {
                      if (
                        !isNaN(a.tokensTriggered) &&
                        !isNaN(b.tokensTriggered) &&
                        a.tokensTriggered !== b.tokensTriggered
                      ) {
                        return b.tokensTriggered - a.tokensTriggered;
                      }
                      return a.name.length - b.name.length;
                    })
                );
              }
            }}
          />
          <InfoTooltip text={t("search-bar-info")} />
        </div>
      </div>
    );
  };

  const generateSearchItemTable = () => {
    const items = usedSearchResults;
    const backPage = searchResultsPage > 0;
    const forwardPage =
      searchResultsPage <
      Math.floor(items.length / MAX_SEARCH_ITEMS_PER_PAGE) -
        (items.length % MAX_SEARCH_ITEMS_PER_PAGE === 0 ? 1 : 0);

    const header = (
      <>
        <div className="TableArrowHeader">
          <button
            className="glyph-button"
            onClick={() =>
              backPage && setSearchResultsPage(searchResultsPage - 1)
            }
          >
            <Icon
              path={mdiChevronLeft}
              size={1}
              style={{
                color: backPage ? BASE_ACCENT : BASE_GRAY,
              }}
            />
          </button>
          <div className="card-header-text-small-centered">
            {shortenText(t("exercises"), 16, true, 0, true, true)}
          </div>
          <button
            className="glyph-button"
            onClick={() =>
              forwardPage && setSearchResultsPage(searchResultsPage + 1)
            }
          >
            <Icon
              path={mdiChevronRight}
              size={1}
              style={{
                color: forwardPage ? BASE_ACCENT : BASE_GRAY,
              }}
            />
          </button>
        </div>
      </>
    );

    const tableRows = [];

    if (items.length <= 0) {
      return (
        <div className="logTable">
          {header}
          <div className="logTableRowStub unselectedRow">
            <div className="logItemText logItemTextStubPlan">
              {t("no-exercises-found")}
            </div>
          </div>
        </div>
      );
    }

    tableRows.push(
      <div className="logTableHeaderRow" key="tableHeader">
        <div className="logHeaderText columnMax">{t("name")}</div>
        <div className="logHeaderText column20">{t("actions-i18n")}</div>
      </div>
    );

    for (
      let index = searchResultsPage * MAX_SEARCH_ITEMS_PER_PAGE;
      index < items.length &&
      index - searchResultsPage * MAX_SEARCH_ITEMS_PER_PAGE <
        MAX_SEARCH_ITEMS_PER_PAGE;
      index++
    ) {
      const item = items[index];
      // const isItemInTarget = baseSearchResults.find((i) => i.name === item.name);

      const row = (
        <div
          className="logTableRow unselectedRow"
          key={`tableRow-${index}`}
          onClick={() => {}}
        >
          <div className="logItemText columnMax unselectedRowText">{`${shortenText(
            item.name || t("unnamed"),
            45,
            true,
            0,
            false,
            false
          )}`}</div>
          <div className="button-container column20 nowrap noTopMargin noBottomMargin">
            <button
              className="row-glyph-button info"
              onClick={() => {
                props.setViewedExercise(item);
                props.setPlanModalType(props.funcKey);
                props.setModalVisible("WO_CNTX_INFO-INACTIVE");
              }}
            >
              <Icon path={mdiInformationOutline} size={1} />
            </button>
            <button
              className="row-glyph-button new"
              onClick={() => {
                const goalType =
                  getGoals(
                    prebuiltExerciseData.exercises[item.lookup].category
                  )[0] || null;
                setToBeAddedGoal(goalType);
                updateNewItemState({
                  isPrebuilt: true,
                  prebuildLookup: item.lookup,
                  name: item.name,
                  order: planItems?.length ? planItems.length + 1 : 1,
                  type: prebuiltExerciseData.exercises[item.lookup].category,
                  hasGoal: true,
                  goalType: goalType,
                });
                setToBeAdded(prebuiltExerciseData.exercises[item.lookup]);
              }}
            >
              <Icon path={mdiCheck} size={1} />
            </button>
          </div>
        </div>
      );
      tableRows.push(row);
    }

    return (
      <div className="logTable">
        {header}
        {tableRows}
      </div>
    );
  };

  const generatePostSelectSetup = () => {
    const generateGoalButtons = (goals) => {
      return goals.map((g) => {
        return (
          <button
            className={`small-rectangle ${
              toBeAddedGoal === g ? "accent" : "disabled"
            }`}
            key={"goalBtn-" + g}
            onClick={() => {
              setToBeAddedGoal(g);

              if (g === "distance") {
                updateNewItemState({
                  goalType: g,
                  durationPerLap: null,
                  distancePerLap: 1,
                  laps: 1,
                });
              } else if (g === "duration") {
                updateNewItemState({
                  goalType: g,
                  durationPerLap: 15,
                  distancePerLap: null,
                  laps: 1,
                });
              } else {
                updateNewItemState({
                  goalType: g,
                  durationPerLap: null,
                  distancePerLap: null,
                  laps: null,
                });
              }
            }}
          >
            <div className="card-button-text">{t(g)}</div>
          </button>
        );
      });
    };

    const rows = [
      { title: "name", content: toBeAdded.name, tooltip: "exercise-name-info" },
      // { title: 'category', content: toBeAdded.category, tooltip: 'c-wot-m-info-category-info' },
      // { title: 'equipment', content: toBeAdded.equipment, tooltip: 'c-wot-m-info-equipment-info' },
      // { title: 'mechanic', content: toBeAdded.mechanic, tooltip: 'c-wot-m-info-mechanic-info' },
      // { title: 'force', content: toBeAdded.force, tooltip: 'c-wot-m-info-force-info' },
      // {
      //   title: 'primary-muscles',
      //   content: toBeAdded.primaryMuscles,
      //   tooltip: 'c-wot-m-info-primary-info',
      // },
      // {
      //   title: 'secondary-muscles',
      //   content: toBeAdded.secondaryMuscles,
      //   tooltip: 'c-wot-m-info-secondary-info',
      // },
    ];

    const generateRows = (inRowData) => {
      return inRowData.map((d) => (
        <div className="sectionItemRow" key={"row-" + d.title}>
          <div className="card-text-small">{`${shortenText(
            t(d.title),
            QUESTION_MAX_STRING,
            true,
            1
          )}:`}</div>
          <div className="endRow">
            <div className="card-text-small">
              {Array.isArray(d.content)
                ? d.content.length <= 0
                  ? t("no-information-available-short")
                  : shortenText(
                      d.content.join(", "),
                      30,
                      true,
                      0,
                      false,
                      false,
                      true,
                      30,
                      3,
                      true,
                      true
                    )
                : shortenText(
                    d.content,
                    40,
                    true,
                    0,
                    false,
                    false,
                    false,
                    20,
                    1,
                    true,
                    true
                  ) || t("no-information-available-short")}
            </div>
            <InfoTooltip text={t(d.tooltip)} />
          </div>
        </div>
      ));
    };

    return (
      <>
        {generateRows(rows)}

        <div className="sectionItemRow">
          <div className="card-text-small">{`${shortenText(
            t("order"),
            QUESTION_MAX_STRING,
            true,
            1
          )}:`}</div>
          <div className="endRow">
            <UnstyledSelectBasic
              options={availableOrders}
              defaultOption={newItem.order}
              onChange={(_, v) => {
                updateNewItemState({ order: v });
              }}
            />
            <InfoTooltip text={t("exercise-order-info")} />
          </div>
        </div>

        {getGoals(toBeAdded.category).length > 0 && (
          <div className="sectionItemRow">
            <div className="card-text-small">{`${shortenText(
              t("goal"),
              QUESTION_MAX_STRING,
              true,
              1
            )}:`}</div>
            <div className="endRow">
              <div className="button-container nowrap noTopMargin noBottomMargin">
                {generateGoalButtons(getGoals(toBeAdded.category))}
              </div>
              <InfoTooltip text={t("c-wot-plan-goal-info")} />
            </div>
          </div>
        )}

        {toBeAddedGoal === "set" && toBeAdded !== "cardio" && (
          <div className="">
            <div className="sectionItemRow">
              <div className="card-text-small">{`${shortenText(
                t("sets"),
                QUESTION_MAX_STRING,
                true,
                1
              )}:`}</div>
              <div className="endRow">
                <UnstyledSelectBasic
                  options={setRates}
                  defaultOption={newItem.sets}
                  onChange={(_, v) => {
                    updateNewItemState({ sets: v });
                  }}
                />
                <InfoTooltip text={t("w-sets-info")} />
              </div>
            </div>
            <div className="sectionItemRow">
              <div className="card-text-small">{`${shortenText(
                t("repetitions-short"),
                QUESTION_MAX_STRING,
                true,
                1
              )}:`}</div>
              <div className="endRow">
                <UnstyledSelectBasic
                  options={repRates}
                  defaultOption={newItem.reps}
                  onChange={(_, v) => {
                    updateNewItemState({ reps: v });
                  }}
                />
                <InfoTooltip text={t("w-reps-info")} />
              </div>
            </div>
            <div className="sectionItemRow">
              <div className="card-text-small">{`${shortenText(
                t("rest-per-set"),
                QUESTION_MAX_STRING,
                true,
                1
              )}:`}</div>
              <div className="endRow">
                <UnstyledSelectBasic
                  options={restRates}
                  defaultOption={newItem.rest}
                  onChange={(_, v) => {
                    updateNewItemState({ rest: v });
                  }}
                />
                <InfoTooltip text={t("c-wot-rest-info")} />
              </div>
            </div>
          </div>
        )}

        {toBeAddedGoal === "set" && toBeAdded === "cardio" && (
          <div className="">
            <div className="sectionItemRow">
              <div className="card-text-small">{`${shortenText(
                `${t("laps")}`,
                QUESTION_MAX_STRING,
                true,
                1
              )}/${shortenText(
                `${t("repetitions-full")}`,
                QUESTION_MAX_STRING,
                true,
                1
              )}:`}</div>
              <div className="endRow">
                <UnstyledSelectBasic
                  options={laps}
                  defaultOption={newItem.laps}
                  onChange={(_, v) => {
                    updateNewItemState({
                      laps: v,
                      totalDistance: v * newItem.distancePerLap,
                    });
                  }}
                />
                <InfoTooltip text={t("c-lap-info")} />
              </div>
            </div>
            <div className="sectionItemRow">
              <div className="card-text-small">{`${shortenText(
                t("rest-per-lap"),
                QUESTION_MAX_STRING,
                true,
                1
              )}:`}</div>
              <div className="endRow">
                <UnstyledSelectBasic
                  options={restRates}
                  defaultOption={newItem.rest}
                  onChange={(_, v) => {
                    updateNewItemState({ rest: v });
                  }}
                />
                <InfoTooltip text={t("c-wot-rest-info")} />
              </div>
            </div>

            <div className="sectionItemRow">
              <div className="card-text-small">{`${shortenText(
                t("goal"),
                QUESTION_MAX_STRING,
                true,
                1
              )}:`}</div>
              <div className="endRow">
                <div className="button-container nowrap noTopMargin noBottomMargin">
                  <button
                    className={`small-rectangle ${
                      newItem.goal === "distance" ? "accent" : "disabled"
                    }`}
                    onClick={() => {
                      updateNewItemState({
                        goal: "distance",
                        durationPerLap: null,
                        distancePerLap: 1,
                      });
                    }}
                  >
                    <div className="card-button-text">{t("distance")}</div>
                  </button>
                  <button
                    className={`small-rectangle ${
                      newItem.goal === "duration" ? "accent" : "disabled"
                    }`}
                    onClick={() => {
                      updateNewItemState({
                        goal: "duration",
                        durationPerLap: 15,
                        distancePerLap: null,
                      });
                    }}
                  >
                    <div className="card-button-text">{t("duration-full")}</div>
                  </button>
                </div>
                <InfoTooltip text={t("exercise-goal-info")} />
              </div>
            </div>
          </div>
        )}

        {toBeAddedGoal === "duration" && (
          <div className="">
            <div className="sectionItemRow">
              <div className="card-text-small">{`${shortenText(
                `${t("laps")}`,
                QUESTION_MAX_STRING,
                true,
                1
              )}/${shortenText(
                `${t("repetitions-full")}`,
                QUESTION_MAX_STRING,
                true,
                1
              )}:`}</div>
              <div className="endRow">
                <UnstyledSelectBasic
                  options={laps}
                  defaultOption={newItem.laps}
                  onChange={(_, v) => {
                    updateNewItemState({
                      laps: v,
                      totalDistance: v * newItem.distancePerLap,
                    });
                  }}
                />
                <InfoTooltip text={t("c-lap-info")} />
              </div>
            </div>
            <div className="sectionItemRow">
              <div className="card-text-small">{`${shortenText(
                t("duration-full"),
                QUESTION_MAX_STRING,
                true,
                1
              )}:`}</div>
              <div className="endRow">
                <UnstyledSelectBasic
                  options={lapDurations}
                  defaultOption={newItem.durationPerLap}
                  onChange={(_, v) => {
                    updateNewItemState({
                      durationPerLap: v,
                      totalDuration: v * newItem.laps,
                    });
                  }}
                />
                <InfoTooltip text={t("c-lap-duration-info")} />
              </div>
            </div>

            <div className="sectionItemRow">
              <div className="card-text-small">{`${shortenText(
                t("rest"),
                QUESTION_MAX_STRING,
                true,
                1
              )}:`}</div>
              <div className="endRow">
                <UnstyledSelectBasic
                  options={restRates}
                  defaultOption={newItem.rest}
                  onChange={(_, v) => {
                    updateNewItemState({ rest: v });
                  }}
                />
                <InfoTooltip text={t("c-wot-rest-info")} />
              </div>
            </div>
          </div>
        )}

        {toBeAddedGoal === "distance" && (
          <div className="">
            <div className="sectionItemRow">
              <div className="card-text-small">{`${shortenText(
                `${t("laps")}`,
                QUESTION_MAX_STRING,
                true,
                1
              )}/${shortenText(
                `${t("repetitions-full")}`,
                QUESTION_MAX_STRING,
                true,
                1
              )}:`}</div>
              <div className="endRow">
                <UnstyledSelectBasic
                  options={laps}
                  defaultOption={newItem.laps}
                  onChange={(_, v) => {
                    updateNewItemState({
                      laps: v,
                      totalDistance: v * newItem.distancePerLap,
                    });
                  }}
                />
                <InfoTooltip text={t("c-lap-info")} />
              </div>
            </div>
            <div className="sectionItemRow">
              <div className="card-text-small">{`${shortenText(
                t("lap-distance"),
                QUESTION_MAX_STRING,
                true,
                1
              )}:`}</div>
              <div className="endRow">
                <UnstyledSelectBasic
                  options={lapDistances}
                  defaultOption={newItem.distancePerLap}
                  onChange={(_, v) => {
                    updateNewItemState({
                      distancePerLap: v,
                      totalDistance: v * newItem.laps,
                    });
                  }}
                />
                <InfoTooltip text={t("c-lap-distance-info")} />
              </div>
            </div>

            <div className="sectionItemRow">
              <div className="card-text-small">{`${shortenText(
                t("rest"),
                QUESTION_MAX_STRING,
                true,
                1
              )}:`}</div>
              <div className="endRow">
                <UnstyledSelectBasic
                  options={restRates}
                  defaultOption={newItem.rest}
                  onChange={(_, v) => {
                    updateNewItemState({ rest: v });
                  }}
                />
                <InfoTooltip text={t("c-wot-rest-info")} />
              </div>
            </div>
          </div>
        )}

        <div className="button-container nowrap noTopMargin noBottomMargin">
          {selectedItem === null && (
            <button
              className="large-rectangle danger"
              onClick={() => {
                setToBeAdded(null);
              }}
            >
              <Icon path={mdiKeyboardReturn} size={1} />
              <div className="card-button-text">
                {shortenText(
                  t("undo"),
                  LARGE_BTN_MAX_STRING,
                  true,
                  0,
                  true,
                  true
                )}
              </div>
            </button>
          )}

          {selectedItem !== null && (
            <button
              className="large-rectangle danger"
              onClick={() => {
                planItems.splice(selectedItem, 1);
                for (
                  let index = selectedItem;
                  index < planItems.length;
                  index++
                ) {
                  planItems[index].order--;
                }
                setShowNewItem(false);
                setEditOpen(false);
                setSelectedItem(null);
                setItemsPage(
                  Math.floor(planItems.length / MAX_ITEMS_PER_PAGE) -
                    (planItems.length % MAX_ITEMS_PER_PAGE === 0 ? 1 : 0)
                );
              }}
            >
              <Icon path={mdiDelete} size={1} />
              <div className="card-button-text">
                {shortenText(
                  t("m-wot-plan-s2-btn-delete-exercise"),
                  LARGE_BTN_MAX_STRING,
                  true,
                  0,
                  true,
                  true
                )}
              </div>
            </button>
          )}
          <button
            className={`large-rectangle ${newItem.name ? "new" : "disabled"}`}
            onClick={() => {
              const tempItem = newItem;
              const tempItems = planItems;
              if (editOpen) {
                // Was an edit rather then a fresh item

                // remove old item from array, and reduce the order of remaining items after that position.
                tempItems.splice(selectedItem, 1);

                for (
                  let index = selectedItem;
                  index < tempItems.length;
                  index++
                ) {
                  tempItems[index].order--;
                }

                // Add item back into array in whatever position it must be in. Bump up the order for any that follow.
                tempItems.splice(tempItem.order - 1, 0, tempItem);

                for (
                  let index = tempItem.order;
                  index < tempItems.length;
                  index++
                ) {
                  tempItems[index].order++;
                }

                updateState({
                  items: tempItems,
                });
                // Close the edit and new item windows, and a single edit.
                setEditOpen(false);
                setShowNewItem(false);
                setSelectedItem(null);
              } else {
                // Add item into array in whatever position it must be in. Bump up the order for any that follow.
                tempItems.splice(tempItem.order - 1, 0, tempItem);

                for (
                  let index = tempItem.order;
                  index < tempItems.length;
                  index++
                ) {
                  tempItems[index].order++;
                }

                // Clear the input for the next new item. Keep it open to allow for rapid new items.
                // Someone adding new items for the first time will likely be adding multiple.
                updateState({
                  items: tempItems,
                });

                setNewItem(
                  generateWorkoutItem({
                    order: tempItems.length + 1,
                    sets: newItem?.sets || plan.sets,
                    reps: newItem?.reps || plan.reps,
                    rest: newItem?.rest || plan.rest,
                  })
                );

                setSelectedItem(null);
                setShowNewItem(true);
                setToBeAdded(false);

                setLastItem(newItem);
                setItemsPage(
                  Math.floor(planItems.length / MAX_ITEMS_PER_PAGE) -
                    (planItems.length % MAX_ITEMS_PER_PAGE === 0 ? 1 : 0)
                );
              }
            }}
          >
            <Icon path={editOpen ? mdiFileEdit : mdiPlus} size={1} />
            <div className="card-button-text">
              {shortenText(
                editOpen
                  ? t("m-wot-plan-s2-btn-save-changes")
                  : t("m-wot-plan-s2-btn-add"),
                LARGE_BTN_MAX_STRING,
                true,
                0,
                true,
                true
              )}
            </div>
          </button>
        </div>
      </>
    );
  };

  const generateNewPresetItem = () => {
    return (
      <>
        <div className="card-header-text-small-centered">
          {newItem.name
            ? t("m-wot-plan-s2-new-header-edit")
            : t("m-wot-plan-s2-new-header-add")}
        </div>

        {!toBeAdded ? (
          <>
            {generateSearchBar()}
            {generateSearchItemTable()}
          </>
        ) : (
          generatePostSelectSetup()
        )}
      </>
    );
  };

  const generateItemTable = () => {
    const tableRows = [];

    if (!planItems || planItems.length <= 0) {
      return (
        <div className="logTableRowStub unselectedRow">
          <div className="logItemTextStub logItemTextStubPlan">
            {t("m-wot-plan-s2-stub")}
          </div>
        </div>
      );
    }

    const headerRowTextStyleSmall = "logHeaderText column15";
    const headerRowTextStyle = "logHeaderText column25";
    const headerRowTextStyleLarge = "logHeaderText column60";

    tableRows.push(
      <div className="logTableHeaderRow" key="tableHeader">
        <div className={headerRowTextStyleSmall}>{t("order")}</div>
        <div className={headerRowTextStyleLarge}>{t("name")}</div>
        <div className={headerRowTextStyle}>{t("information-short")}</div>
      </div>
    );

    for (
      let index = itemsPage * MAX_ITEMS_PER_PAGE;
      index < planItems.length &&
      index - itemsPage * MAX_ITEMS_PER_PAGE < MAX_ITEMS_PER_PAGE;
      index++
    ) {
      const planItem = planItems[index];
      const rowStyle = `logTableRow ${
        selectedItem === index ? "selectedRow" : "selectableRow"
      }`;

      const rowItemStyleSmall = `logItemText column15 ${
        selectedItem === index ? "selectedRowText" : "selectableRowText"
      }`;

      const rowItemStyle = `logItemText column25 ${
        selectedItem === index ? "selectedRowText" : "selectableRowText"
      }`;

      const rowItemStyleLarge = `logItemText column60 ${
        selectedItem === index ? "selectedRowText" : "selectableRowText"
      }`;

      const onClickRow = () => {
        setEditOpen(selectedItem !== index);
        setSelectedItem(selectedItem === index ? null : index);
        setToBeAdded(
          prebuiltExerciseData.exercises[planItems[index].prebuildLookup]
        );
        setToBeAddedGoal(planItems[index].goalType);
        setNewItem(planItems[index]);
        setShowNewItem(selectedItem !== index);
      };

      const generateInfo = (item) => {
        if (!item || !item.goalType) {
          return t("no-information-available-short");
        }

        try {
          switch (item.goalType) {
            case "set":
              return `${item.sets} x ${item.reps} ${t("repetitions-short")}`;
            case "duration":
              return `${item.laps} x ${
                Math.floor(item.durationPerLap / 60) > 0.5
                  ? Math.floor(item.durationPerLap / 60) +
                    `${t("minute-short")} `
                  : ""
              }${
                item.durationPerLap % 60 > 0
                  ? (item.durationPerLap % 60) + `${t("second-short")}`
                  : ""
              }`;
            case "distance":
              return `${item.laps} x ${planItem.distancePerLap} ${
                presentDistances(planItem.distanceUnit).short
              }`;
            default:
              return t("no-information-available-short");
          }
        } catch (_) {
          return t("no-information-available-short");
        }
      };

      const row = (
        <div
          className={rowStyle}
          key={`tableRow-${index}`}
          onClick={onClickRow}
        >
          <div className={rowItemStyleSmall}>{planItem.order}</div>
          <div className={rowItemStyleLarge}>{`${
            planItem.name.replace(/(.{35})..+/, "$1...") || t("unnamed")
          }`}</div>
          <div className={rowItemStyle}>{generateInfo(planItem)}</div>
        </div>
      );
      tableRows.push(row);
    }

    return tableRows;
  };

  return (
    <Modal
      isOpen={props.modalVisible}
      onRequestClose={dismissWithoutInputFunc}
      animationType="none"
      appElement={document.getElementById("root") || undefined}
    >
      <div className="modal-header-text-small">
        {props.editPlanTarget
          ? t("m-wot-plan-title-edit")
          : t("m-wot-plan-title-build")}
      </div>
      <div className="cardSectionContainer">
        <div className="modal-header-text-small">{t("information")}</div>
        <>
          <div className="sectionItemRow">
            <div className="card-text-small">{`${shortenText(
              t("name"),
              QUESTION_MAX_STRING,
              true,
              1
            )}:`}</div>
            <div className="endRow">
              <input
                className="input-wide"
                placeholder={t("required")}
                value={plan.name || ""}
                onChange={(e) => {
                  const text = e.target.value;
                  let tempID = plan.id;

                  if (!plan.id) {
                    const sorted = Object.keys(props.plans).sort((a, b) => {
                      return parseInt(a) - parseInt(b);
                    });

                    const freshID =
                      sorted.length <= 0
                        ? 0
                        : parseInt(sorted[sorted.length - 1], 10) + 1;
                    tempID = freshID;
                  }

                  updateState({ name: text, id: tempID.toString() });
                }}
              />
              <InfoTooltip text={t("plan-name-info")} />
            </div>
          </div>
          <div className="sectionItemRow">
            <div className="card-text-small">{`${shortenText(
              t("description"),
              QUESTION_MAX_STRING,
              true,
              1
            )}:`}</div>
            <div className="endRow">
              <input
                className="input-wide"
                placeholder={t("optional")}
                value={plan.description}
                onChange={(e) => {
                  const text = e.target.value;
                  updateState({ description: text });
                }}
              />
              <InfoTooltip text={t("plan-description-info")} />
            </div>
          </div>
          {/* <div className="sectionItemRow">
                    <div className=""
                      style={[
                        gs.cardTextSmall,
                        props.isDarkTheme ? gs.cardTextDark : gs.cardTextLight,
                      ]}
                    >{`${shortenText(t('schedule'), QUESTION_MAX_STRING, true, 1)}:`}</div>
                    <div className="endRow">
                      // <Picker
                      //   onChanged={(v) => updateState({ schedule: v })}
                      //   options={generateRepeatOptions(t)}
                      //   style={gs.numberPickerShorter}
                      //   value={plan.schedule}
                      // />
                      // <Tooltip text={t('plan-schedule-info')}>
                      //   <div className="">{infoIcon}</div>
                      // </Tooltip>
                    </div>
                  </div> */}
        </>
      </div>
      <div className="cardSectionContainer">
        <div className="TableArrowHeader">
          <button
            className="glyph-button"
            onClick={() => backPage && setItemsPage(itemsPage - 1)}
          >
            <Icon
              path={mdiChevronLeft}
              size={1}
              style={{
                color: backPage ? BASE_ACCENT : BASE_GRAY,
              }}
            />
          </button>
          <div className="card-header-text-small-centered">
            {`${planItems?.length || 0} ${t("m-wot-plan-s2-head")}`}
          </div>
          <button
            className="glyph-button"
            onClick={() => forwardPage && setItemsPage(itemsPage + 1)}
          >
            <Icon
              path={mdiChevronRight}
              size={1}
              style={{
                color: forwardPage ? BASE_ACCENT : BASE_GRAY,
              }}
            />
          </button>
        </div>
        {generateItemTable()}
        <div className="button-container nowrap noBottomMargin">
          {!showNewItem && (
            <button
              className="large-rectangle"
              onClick={() => {
                setSelectedItem(null);
                setToBeAdded(null);
                setNewItem(
                  generateWorkoutItem({
                    order: !planItems ? 1 : planItems.length + 1,
                    sets: lastItem?.sets || plan.defaults.sets,
                    reps: lastItem?.reps || plan.defaults.reps,
                    rest: lastItem?.rest || plan.defaults.rest,
                    // types: plan.defaults.types,
                    distanceUnit: plan.defaults.distanceUnit,
                  })
                );
                setShowNewItem(true);
                setEditOpen(false);
              }}
            >
              <Icon path={mdiPlus} size={1} />
              <div className="card-button-text">
                {shortenText(
                  t("m-wot-plan-s2-btn-add"),
                  LARGE_BTN_MAX_STRING,
                  true,
                  0,
                  true,
                  true
                )}
              </div>
            </button>
          )}
          {(selectedItem !== null || showNewItem) && (
            <button
              className="large-rectangle info"
              onClick={() => {
                setNewItem(false);
                setShowNewItem(false);
                setSelectedItem(null);
              }}
            >
              <Icon path={mdiKeyboardReturn} size={1} />
              <div className="card-button-text">
                {shortenText(
                  t("close"),
                  LARGE_BTN_MAX_STRING,
                  true,
                  0,
                  true,
                  true
                )}
              </div>
            </button>
          )}
        </div>
        {showNewItem && generateNewPresetItem()}
      </div>
      <div className="button-container nowrap noBottomMargin">
        <button
          className="large-rectangle info"
          onClick={dismissWithoutInputFunc}
        >
          <Icon path={mdiKeyboardReturn} size={1} />
          <div className="card-button-text">
            {shortenText(t("close"), LARGE_BTN_MAX_STRING, true, 0, true, true)}
          </div>
        </button>
        <button
          className={`large-rectangle ${
            !plan.name || !plan.id ? "disabled" : ""
          }`}
          onClick={save}
        >
          <Icon path={mdiContentSave} size={1} />
          <div className="card-button-text">
            {shortenText(
              t("m-wot-plan-btn-save"),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </div>
        </button>
      </div>
    </Modal>
  );
}
