import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BASE_ACCENT,
  BASE_GRAY,
  LARGE_BTN_MAX_STRING,
  QUESTION_MAX_STRING,
  TABLE_DOUBLE_ROW_HEADER,
} from "../../../Supports/Constants";
import { shortenText } from "../../../Supports/Functions";
import Icon from "@mdi/react";
import {
  mdiChevronLeft,
  mdiChevronRight,
  mdiHistory,
  mdiImage,
  mdiInformationOutline,
  mdiKeyboardReturn,
} from "@mdi/js";
import Modal from "react-modal";
import { InfoTooltip } from "../../Supports/InfoTooltip";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

export default function ViewExerciseModal(props) {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [displayToggle, setDisplayToggle] = useState(0);
  const item = props.modalSettings.viewedExercise;
  const plans = props.modalSettings.plans;

  const [exerciseHistory, setExerciseHistory] = useState([]);
  const [loadingHistory, setLoadingHistory] = useState(true);

  useEffect(() => {
    const prom = new Promise(async (res, _) => {
      const collectPlanItems = [];
      const planPromises = [];
      Object.values(plans).forEach((plan, inx) => {
        planPromises.push(
          getDocs(
            collection(
              getFirestore(),
              "UserData",
              getAuth().currentUser.uid,
              "WorkoutPlans",
              plan.id.toString(),
              "PlanItems"
            )
          ).then((itemQuery) => {
            if (!itemQuery) {
              return;
            }

            const collectItems = [];
            const inPromises = [];
            itemQuery.forEach((item, itemInx) => {
              const inItem = item.data();

              inPromises.push(
                getDocs(
                  collection(
                    getFirestore(),
                    "UserData",
                    getAuth().currentUser.uid,
                    "WorkoutPlans",
                    plan.id.toString(),
                    "PlanItems",
                    (inItem.order - 1).toString(),
                    "ItemHistory"
                  )
                )
                  .then((itemHistoryQuery) => {
                    if (itemHistoryQuery) {
                      const collectHistoryRecords = [];
                      itemHistoryQuery.forEach((itemHistoryRecord) => {
                        collectHistoryRecords.push(itemHistoryRecord.data());
                      });
                      inItem.history = collectHistoryRecords;
                    }
                  })
                  .then(collectItems.push(inItem))
                  .then(() => {
                    collectPlanItems[plan.id] = collectItems;
                  })
              );
            });

            return Promise.all(inPromises);
          })
        );
      });

      await Promise.all(planPromises);
      res(collectPlanItems);
    });

    prom
      .then((collectedPlanItems) => {
        if (!collectedPlanItems) {
          return;
        }

        const itemHistory = [];
        for (let i = 0; i < collectedPlanItems.length; i++) {
          for (let j = 0; j < collectedPlanItems[i].length; j++) {
            const inItem = collectedPlanItems[i][j];
            inItem.name === item.name &&
              inItem.history &&
              itemHistory.push(...inItem.history);
          }
        }

        if (itemHistory) {
          itemHistory.sort((a, b) => a.datestamp - b.datestamp);
          setExerciseHistory(itemHistory);
        }
      })
      .then(setLoadingHistory(false));
  }, [plans, item]);

  if (!item || loadingHistory) {
    return <></>;
  }

  const itemContext = item?.instructions
    ? item
    : props.modalSettings.prebuiltExerciseData.exercises[
        !isNaN(item.prebuildLookup) ? item.prebuildLookup : item.lookup
      ];

  const MAX_NUM_OF_LOGS = 5;
  const backPage = page > 0;
  const forwardPage =
    page <
    Math.floor(exerciseHistory.length / MAX_NUM_OF_LOGS) -
      (exerciseHistory.length % MAX_NUM_OF_LOGS === 0 ? 1 : 0);

  const dismissWithoutInputFunc = () => {
    props.modalSettings.setViewedExercise(null);

    props.setModalVisible(
      item?.origin
        ? item.origin
        : props.modalSettings?.planModalType
        ? props.modalSettings.planModalType
        : false
    );
    props.modalSettings.setPlanModalType &&
      props.modalSettings.setPlanModalType(null);
  };

  // const infoIcon = (
  //   <IconMaterial
  //     name="info-outline"
  //     size={20}
  //     color={props.isDarkTheme ? BASE_WHITE : BASE_ACCENT}
  //   />
  // );

  const emptyStub = (
    <div className="logTableRowStub unselectedRow" key={"tableRow-stub"}>
      <div className="logItemTextStub unselectedRowText">
        {t("m-wot-m-active-info-s2-stub")}
      </div>
    </div>
  );

  const headerStyle = `logHeaderText column15`;

  const tableRowHeader = (
    <div className="logTableHeaderRow" key="tableHeader">
      <div className={headerStyle}>
        {shortenText(t("weight"), TABLE_DOUBLE_ROW_HEADER, true, 0, true, true)}
      </div>
      <div className={headerStyle}>
        {shortenText(
          `${t("sets")}`,
          TABLE_DOUBLE_ROW_HEADER,
          true,
          0,
          true,
          true
        )}
      </div>
      <div className={headerStyle}>
        {shortenText(
          `${t("repetitions-short")}/${t("laps")}`,
          TABLE_DOUBLE_ROW_HEADER,
          true,
          0,
          true,
          true
        )}
      </div>
      <div className={headerStyle}>
        {shortenText(
          `${t("duration-full")}`,
          TABLE_DOUBLE_ROW_HEADER,
          true,
          0,
          true,
          true
        )}
      </div>
      <div className={headerStyle}>
        {shortenText(
          `${t("distance")}`,
          TABLE_DOUBLE_ROW_HEADER,
          true,
          0,
          true,
          true
        )}
      </div>
      <div className={headerStyle}>
        {shortenText(t("status"), TABLE_DOUBLE_ROW_HEADER, true, 0, true, true)}
      </div>
    </div>
  );

  const generateTableItems = (collection) => {
    const rowItemStyle = `logItemText column15 unselectedRowText`;

    const rows = [];
    for (
      let index = page * MAX_NUM_OF_LOGS;
      index < collection.length &&
      index - page * MAX_NUM_OF_LOGS < MAX_NUM_OF_LOGS;
      index++
    ) {
      const logItem = collection[index];

      rows.push(
        <div className="logTableRow unselectedRow" key={`tableRow-${index}`}>
          <div className={rowItemStyle}>
            {logItem.weight
              ? `${logItem.weight} ${logItem.unit}`
              : t("no-information-available-short")}
          </div>
          <div className={rowItemStyle}>
            {logItem.setsCompleted || t("no-information-available-short")}
          </div>
          <div className={rowItemStyle}>
            {logItem.repsCompleted
              ? logItem.repsCompleted === "FAIL"
                ? t("fail").toUpperCase()
                : logItem.repsCompleted || t("no-information-available-short")
              : logItem.lapsCompleted || t("no-information-available-short")}
          </div>
          <div className={rowItemStyle}>
            {`${
              Math.floor(logItem.durationCompleted / 60) > 0.5
                ? Math.floor(logItem.durationCompleted / 60) +
                  `${t("minute-short")} `
                : ""
            }${
              logItem.durationCompleted % 60 > 0
                ? (logItem.durationCompleted % 60) + `${t("second-short")}`
                : ""
            }` || t("no-information-available-short")}
          </div>
          <div className={rowItemStyle}>
            {logItem.distanceCompleted > 0
              ? `${logItem.distanceCompleted}${logItem.unit}`
              : t("no-information-available-short")}
          </div>
          <div className={rowItemStyle}>
            {logItem.status || t("no-information-available-short")}
          </div>
        </div>
      );
    }
    return rows;
  };

  const createHistoryTable = (itemHistory) => {
    return (
      <div className="logTable">
        {tableRowHeader}
        {itemHistory?.length > 0 ? generateTableItems(itemHistory) : emptyStub}
      </div>
    );
  };

  const generateHistory = () => {
    return (
      <>
        <div className="TableArrowHeader">
          <button
            className="glyph-button"
            onClick={() => backPage && setPage(page - 1)}
          >
            <Icon
              path={mdiChevronLeft}
              size={1}
              style={{
                color: backPage ? BASE_ACCENT : BASE_GRAY,
              }}
            />
          </button>
          <div className="card-header-text-small-centered">{t("history")}</div>
          <button
            className="glyph-button"
            onClick={() => forwardPage && setPage(page + 1)}
          >
            <Icon
              path={mdiChevronRight}
              size={1}
              style={{
                color: forwardPage ? BASE_ACCENT : BASE_GRAY,
              }}
            />
          </button>
        </div>
        {createHistoryTable(exerciseHistory)}
      </>
    );
  };

  const generateInstructions = () => {
    const uriBase = "https://weighzy.com/exerciseImages/";

    return (
      <>
        <div className="card-header-text-small-centered">
          {`${t("instructions")} - ${t("en")}`}
        </div>
        {itemContext.instructions.map((instr, i) => (
          <div className="instructionText card-text-small" key={`instru-${i}`}>
            {`${i + 1}. ${instr}`}
          </div>
        ))}
        <div className="imageRow">
          <img
            className="image imageLeft"
            alt=""
            src={`${uriBase}${
              isNaN(item.lookup) ? item.prebuildLookup : item.lookup
            }-0.jpg`}
          />
          <img
            className="image imageRight"
            alt=""
            src={`${uriBase}${
              isNaN(item.lookup) ? item.prebuildLookup : item.lookup
            }-1.jpg`}
          />
          {/* <Image
              style={[s.image, s.imageLeft]}
              source={{
                uri: `${uriBase}${
                  isNaN(item.lookup) ? item.prebuildLookup : item.lookup
                }-0.jpg`,
              }}
            /> */}
          {/* 
            <Image
              style={[s.image, s.imageRight]}
              source={{
                uri: `${uriBase}${
                  isNaN(item.lookup) ? item.prebuildLookup : item.lookup
                }-1.jpg`,
              }}
            /> */}
        </div>
      </>
    );
  };

  const generateInfo = () => {
    return (
      <>
        <div className="card-header-text-small-centered">
          {t("information")}
        </div>
        {generateRows(rows)}
      </>
    );
  };

  const rows = [
    { title: "name", content: itemContext.name, tooltip: "exercise-name-info" },
    {
      title: "category",
      content: itemContext.category,
      tooltip: "c-wot-m-info-category-info",
    },
    {
      title: "equipment",
      content: itemContext.equipment,
      tooltip: "c-wot-m-info-equipment-info",
    },
    {
      title: "mechanic",
      content: itemContext.mechanic,
      tooltip: "c-wot-m-info-mechanic-info",
    },
    {
      title: "force",
      content: itemContext.force,
      tooltip: "c-wot-m-info-force-info",
    },
    {
      title: "primary-muscles",
      content: itemContext.primaryMuscles,
      tooltip: "c-wot-m-info-primary-info",
    },
    {
      title: "secondary-muscles",
      content: itemContext.secondaryMuscles,
      tooltip: "c-wot-m-info-secondary-info",
    },
  ];

  const generateRows = (inRowData) => {
    return inRowData.map((d) => (
      <div className="sectionItemRow" key={"row-" + d.title}>
        <div className="card-text-small">{`${shortenText(
          t(d.title),
          QUESTION_MAX_STRING,
          true,
          1
        )}:`}</div>
        <div className="endRow">
          <div className="card-text-small">
            {Array.isArray(d.content)
              ? d.content.length <= 0
                ? t("no-information-available-short")
                : shortenText(
                    d.content.join(", "),
                    30,
                    true,
                    0,
                    false,
                    false,
                    true,
                    30,
                    3,
                    true,
                    true
                  )
              : shortenText(
                  d.content?.toString() || t("no-information-available-short"),
                  40,
                  true,
                  0,
                  false,
                  false,
                  false,
                  20,
                  1,
                  true,
                  true
                ) || t("no-information-available-short")}
          </div>
          <InfoTooltip text={t(d.tooltip)} />
        </div>
      </div>
    ));
  };

  return (
    <Modal
      isOpen={props.modalVisible}
      onRequestClose={dismissWithoutInputFunc}
      animationType="none"
      appElement={document.getElementById("root") || undefined}
    >
      <div className="modal-header-text-small">{item.name}</div>
      <div className="button-container nowrap noTopMargin">
        <button
          className={`large-rectangle ${
            displayToggle !== 0 ? "disabled" : "accent"
          }`}
          onClick={() => setDisplayToggle(0)}
        >
          <Icon path={mdiInformationOutline} size={1} />
          <div className="card-button-text">
            {shortenText(
              t("information-short"),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </div>
        </button>
        <button
          className={`large-rectangle ${
            displayToggle !== 1 ? "disabled" : "accent"
          }`}
          onClick={() => setDisplayToggle(1)}
        >
          <Icon path={mdiHistory} size={1} />
          <div className="card-button-text">
            {shortenText(
              t("history"),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </div>
        </button>
        <button
          className={`large-rectangle ${
            displayToggle !== 2 ? "disabled" : "accent"
          }`}
          onClick={() => setDisplayToggle(2)}
        >
          <Icon path={mdiImage} size={1} />
          <div className="card-button-text">
            {shortenText(
              t("instruct"),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </div>
        </button>
      </div>
      <>
        {displayToggle === 0
          ? generateInfo()
          : displayToggle === 1
          ? generateHistory()
          : generateInstructions()}
      </>
      <div className="button-container nowrap noTopMargin noBottomMargin">
        <button className="large-rectangle" onClick={dismissWithoutInputFunc}>
          <Icon path={mdiKeyboardReturn} size={1} />
          <div className="card-button-text">
            {shortenText(t("close"), LARGE_BTN_MAX_STRING, true, 0, true, true)}
          </div>
        </button>
      </div>
    </Modal>
  );
}
