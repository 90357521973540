import React from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { InfoRounded } from "@mui/icons-material";
import { BASE_ACCENT } from "../../Supports/Constants";

export const InfoTooltip = ({ text }) => {
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: BASE_ACCENT,
      color: "white",
      fontSize: theme.typography.pxToRem(12),
    },
  }));

  return (
    <HtmlTooltip title={text} placement="left">
      <IconButton size="small" className="infoIconButton">
        <InfoRounded className="infoIcon" />
      </IconButton>
    </HtmlTooltip>
  );
};
