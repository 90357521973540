import React from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { APP_NAME, LARGE_BTN_MAX_STRING } from "../../../Supports/Constants";
import { shortenText } from "../../../Supports/Functions";
import Icon from "@mdi/react";
import { mdiKeyboardReturn } from "@mdi/js";
import "./FurtherReadingModal.css";

// Modal for changing the date on the calorie tracker.
export default function FurtherReadingModal(props) {
  const { t } = useTranslation();

  const dismissWithoutInputFunc = () => props.setModalVisible(false);

  const links = [
    {
      url: "https://usetemper.com/learn/metabolic-step-by-step",
      title: "Metabolic Step-By-Step: Stages of Fasting In The First 72-Hours",
      author: "Dr T. Dalton Combs, PhD",
    },
    {
      url: "https://dralexisshields.com/guide-to-fasting",
      title: "A Comprehensive Guide to Fasting",
      author: "Dr. Alexis Shields",
    },
    {
      url: "https://www.healthline.com/nutrition/stages-of-fasting",
      title: "What Are the Different Stages of Fasting?",
      author: "Rachael Ajmera, MS, RD",
    },
    {
      url: "https://marathonhandbook.com/stages-of-water-fasting",
      title:
        "The 6 Stages Of Water Fasting: The Complete Water Fasting Timeline",
      author: "Amber Sayer",
    },
  ];

  const generateLinkRows = (links) => {
    const linkRows = [];
    for (let index = 0; index < links.length; index++) {
      const link = links[index];

      const row = (
        <div className="FurtherReadingRow" key={`link-row-${index}`}>
          <div
            onClick={() => window.open(link.url, "_blank")}
            className="card-text-small"
          >
            <div className="card-header-text-small-centered-black">
              {link.title}
            </div>

            <div>{link.author}</div>
            <div>{link.url}</div>
          </div>
        </div>
      );

      linkRows.push(row);
    }

    return linkRows;
  };

  return (
    <Modal
      isOpen={props.modalVisible}
      onRequestClose={dismissWithoutInputFunc}
      animationType="none"
      appElement={document.getElementById("root") || undefined}
    >
      <div className="modal-header-text-small">{t("fast-further-reading")}</div>
      <div className="card-header-text-small-centered">{t("warning")}</div>
      <div className="cardContextText">
        {t("fast-further-reading-warning", { varApp: APP_NAME })}
      </div>
      <div className="card-header-text-small-centered">
        {t("external-sources")}
      </div>
      {generateLinkRows(links)}
      <div className="button-container nowrap noTopMargin noBottomMargin">
        <button
          className="large-rectangle danger"
          onClick={dismissWithoutInputFunc}
        >
          <Icon size={1} path={mdiKeyboardReturn} />
          <div className="card-button-text">
            {shortenText(t("close"), LARGE_BTN_MAX_STRING, true, 0, true, true)}
          </div>
        </button>
      </div>
    </Modal>
  );
}
