import React from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { APP_NAME, LARGE_BTN_MAX_STRING } from "../../../Supports/Constants";
import { shortenText } from "../../../Supports/Functions";
import Icon from "@mdi/react";
import { mdiBook, mdiCheck } from "@mdi/js";
import "./GuideModal.css";
import welcome from "../../../Assets/WeighzyIconNoBackground-WhitePurple.png";

export default function WelcomeModal(props) {
  const { t } = useTranslation();

  const dismissWithoutInputFunc = () => {
    props.setModalVisible(false);
  };

  return (
    <Modal
      isOpen={props.modalVisible}
      onRequestClose={dismissWithoutInputFunc}
      animationType="none"
      appElement={document.getElementById("root") || undefined}
    >
      <div className="card-header-text-small-centered">
        {t("w-1", { varApp: APP_NAME })}
      </div>
      <div className="image-container">
        <img className="welcomeIcon" src={welcome} alt={"Welcome"} />
      </div>
      <div className="card-text-small">{t("w-2", { varApp: APP_NAME })}</div>
      <br />
      <div className="card-text-small">
        {t("w-3", { varApp: APP_NAME })}
      </div>{" "}
      <br />
      <div className="card-text-small">
        {t("w-4", { varApp: APP_NAME })}
      </div>{" "}
      <br />
      <div className="card-text-small">{t("w-5")}</div>
      <div className="button-container nowrap noTopMargin noBottomMargin">
        <button
          className="large-rectangle accent"
          onClick={dismissWithoutInputFunc}
        >
          <Icon path={mdiCheck} size={1} />
          <div className="card-button-text">
            {shortenText(
              t("e-w", { varApp: APP_NAME }),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </div>
        </button>
        <button
          className="large-rectangle accent"
          onClick={() => props.setModalVisible("GuideModal")}
        >
          <Icon path={mdiBook} size={1} />
          <div className="card-button-text">
            {shortenText(t("v-g"), LARGE_BTN_MAX_STRING, true, 0, true, true)}
          </div>
        </button>
      </div>
    </Modal>
  );
}
