export const userAgreementText = `Privacy Policy

The services provided by Weighzy enables you to gather, store, edit, track, and share your health information in one location.
To utilize the Weighzy service you must first register as a user.
You may register for a Weighzy account using your email in combination with a secure and private password, or use certain third-party login services such as Google and Apple.
If you register for Weighzy in any fashion, such as when using a third-party login, Weighzy may request information about you, or be provided access to information about you.
Any information requested by Weighzy would be required for the performance of its service during account setup.
Specifically, this information would include your email address, nutrition, fitness, and basic health information such as weight, height, age, and sex.
This information would be stored for use in the performance of Weighzy services, such as health calculations, assessments, and presentations.
This information will be removed along with stored account information if an account reset or deletion request is fulfilled.
Weighzy does not share the information acquired from third-party login and health information services provided by Google and Apple to third-party services.
The information you provide to register for a Weighzy account is subject to this Privacy Policy.
Please refer to the Weighzy User Agreement for terms and conditions of utilizing the Weighzy account and services.

Weighzy may collect your personal health information when you voluntarily enter information into personal health records within your account and/or as you use the Weighzy account and service.
The information is collected as part of the Weighzy service we are offering. Weighzy will collect information voluntarily entered by the user and may collect additional information about how the account and service are used from other sources where the user has consented. Information is collected pursuant to Part 3 of the Health Information Act and section 33 of the Freedom of Information and Protection of Privacy Act, as applicable.
If you choose to input information with your Weighzy account, your health information may be used by Weighzy for the management, operation, and continuity of your Weighzy account and the information within it. Whenever possible we will only use non-identifying aggregate information for the management and operation of the service.
The Personal Health Information Protection Act and other legislation governing Ontario, Canada may permit discretionary disclosure of your health information without your consent. However, notwithstanding this, Weighzy expressly agree not to disclose any personal information or health information that you have entered or caused to be entered into your account to any third party without your prior written consent or authorization, unless we are required to do so by law, including by court order or subpoena.

Terms and Conditions

These Terms and Conditions ("Terms", "Terms and Conditions") govern your relationship with Weighzy application (the "Service") operated by Weighzy.
Please read these Terms and Conditions carefully before using the Service.
Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.
By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the Service.

Jurisdiction

Any matter arising out of the use of this Service shall be governed by the laws of the Province of Ontario and the federal laws of Canada applicable therein, without reference to its conflict of laws principles.
You agree to submit to the non-exclusive jurisdiction of the courts of the Province of Ontario to determine any action or proceeding arising out of or in connection with this Site.

Availability, Errors, and Inaccuracies

We are constantly updating our offerings of features on the Service. The services available on our Service may be described inaccurately or unavailable, and we may experience delays in updating information on the Service and in our advertising on other web sites.
We cannot and do not guarantee the accuracy or completeness of any information, including prices, product images, specifications, availability, and services. We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.

Purchases

If you wish to purchase any product or service made available through the Service ("Purchase"), you may be asked to supply certain information relevant to your Purchase including, your credit card number, the expiration date of your credit card and CCV.
You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.
By submitting such information, you grant us the right to provide the information to third parties for purposes of facilitating the completion of Purchases.
We reserve the right to refuse or cancel your order at any time for certain reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons.
We reserve the right to refuse or cancel your order if fraud or an unauthorised or illegal transaction is suspected.

Monetization Changes

Weighzy, in its sole discretion and at any time, may modify its monetization strategy at any time. Any change will become effective at the end of the then-current Billing Cycle, if such a Billing Cycle is currently active within the application usage agreement.
Weighzy will provide you with a reasonable prior notice of any change in fee strategy to give you an opportunity to terminate any payment strategy before such change becomes effective.
Your continued use of the Service after a monetization change comes into effect constitutes your agreement to the adjusted changes, as presented by the application.

Refunds

Certain refund requests for charges related to a monetization strategy may be considered by Weighzy on a case-by-case basis and granted in sole discretion of Weighzy.

Accounts

When you create an account with us, you must provide us information that is accurate, complete, and current. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.
You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.
You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.

Intellectual Property

The Service and its original content, features and functionality are and will remain the exclusive property of Weighzy and its licensors.
The Service is protected by copyright, trademark, and other laws of both Canada and other countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Weighzy.

Links To Other Web Sites

Our Service may contain links to third-party web sites or services that are not owned or controlled by Weighzy.
Weighzy has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party web sites or services.
You further acknowledge and agree that Weighzy shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.
We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.

Termination

We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.

Limitation Of Liability

In no event shall Weighzy, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.

Medical Disclaimer

Information provided by or on behalf of Weighzy is intended for general information purposes only.
You agree and acknowledge that we are not providing the service to you for the purposes of giving you medical or health advice.
To be clear, any services provided by Weighzy (e.g., by providing the software or “software as a service”) does not recommend or endorse any specific tests, physicians, products, procedures, opinions, or other information that may be mentioned.
You should not rely on Weighzy to decide on a treatment plan, drug usage, or for any other medical or health advice.
You should consult with the appropriate health service provider in connection with all treatment options that may be available to you.
By accessing Weighzy, you acknowledge you may be receiving health information you have not yet discussed with your health services provider.
You will not use Weighzy if you know that you have a mental or physical health condition, such that your receipt of the type of information contained in Weighzy could reasonably cause harm to your mental or physical health or that of another individual.
Weighzy should not be considered as a replacement or substitute for the standard practices, professional judgment, skill, and expertise of the health service provider using them, but rather as a computer aid.
The health service provider responsible for treating a patient retains ultimate responsibility to all health decisions of the user, and treatment provided to the patient, including exercising all due skill and care and professional judgement.
Weighzy is not intended to be used as a medical device. The absence of an alert for a specific situation or a combination of specific situations should not be considered as an assurance that the specific situation or combinations of specific situations are without risk for you.
Weighzy is not intended for urgent matters or for use in emergencies.
If you have an emergency, call your health service provider(s) directly, go to the nearest emergency room, and/or call 911. Nothing contained in Weighzy is or should be considered, or used as a substitute for, individual health advice, diagnosis, or treatment.
The accuracy and completeness of information that you enter or cause to be entered into Weighzy from a source is solely within your control.

Use of Third-Party Google API Services

Weighzy's use and transfer of information received from Google APIs to any other app will adhere to Google API Services User Data Policy (https://developers.google.com/terms/api-services-user-data-policy), including the Limited Use requirements.

Indirect Collection of Limited Aggregate Personal Information for Analytics Purposes

Further, Weighzy uses analytics to understand usage patterns, and enhance product capability and performance. Weighzy may require limited aggregate personal information from third parties to meet the purposes.
By agreeing to these terms of use, and to the extent any of this personal information is not within the custody or under the control of either Weighzy you expressly authorize us to collect your personal information from the third parties to understand usage patterns and enhance product capability and performance.

Disclaimer

Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis.
The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.
Weighzy, its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.

Changes

We reserve the right, at our sole discretion, to modify or replace these Terms at any time.
If a revision is material, we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.

Contact Us

If you have any questions about these Terms, please contact us: help@weighzy.com`;
