import React, { useState } from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import {
  BASE_ACCENT,
  BASE_DARK_GRAY,
  BASE_GRAY,
  LARGE_BTN_MAX_STRING,
  QUESTION_MAX_STRING,
} from "../../../Supports/Constants";
import { shortenText } from "../../../Supports/Functions";
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiChevronRight, mdiKeyboardReturn } from "@mdi/js";
import { InfoTooltip } from "../../Supports/InfoTooltip";

export default function CalorieInfo(props) {
  const [itemsPage, setItemsPage] = useState(0);
  const [itemsPageDependers, setItemsPageDependers] = useState(0);
  const { t } = useTranslation();

  const itemGraph = props.modalSettings.itemGraph;
  const [item] = useState(() => {
    const tempItem = props.modalSettings.itemInfo;

    if (tempItem.isPreset) {
      tempItem.nutrients = {
        carbs: Math.round(
          (tempItem.nutrients.carbs / 100) *
            (tempItem.scalars?.find((s) => s.n === tempItem.servingUnit).g || 1)
        ),
        protein: Math.round(
          (tempItem.nutrients.protein / 100) *
            (tempItem.scalars?.find((s) => s.n === tempItem.servingUnit).g || 1)
        ),
        fat: Math.round(
          (tempItem.nutrients.fat / 100) *
            (tempItem.scalars?.find((s) => s.n === tempItem.servingUnit).g || 1)
        ),
        sugar: Math.round(
          (tempItem.nutrients.sugar / 100) *
            (tempItem.scalars?.find((s) => s.n === tempItem.servingUnit).g || 1)
        ),
      };
    }

    return tempItem;
  });

  const isFood = item.type === "foodItem";

  const MIN_G = 20;
  if (isFood && item.isPreset) {
    item.annotations = {
      CARB: item.nutrients.carbs >= MIN_G,
      PROT: item.nutrients.protein >= MIN_G,
      FAT: item.nutrients.fat >= MIN_G,
      SUGAR: item.nutrients.sugar >= MIN_G,
    };
  }

  const dependencies = item.dependencies;
  const dependers = Object.keys(itemGraph.getDependers(item.name));

  const MAX_ITEMS_PER_PAGE = 4;
  const backPage = !dependencies ? false : itemsPage > 0;
  const forwardPage = !dependencies
    ? false
    : itemsPage <
      Math.floor(dependencies.length / MAX_ITEMS_PER_PAGE) -
        (dependencies.length % MAX_ITEMS_PER_PAGE === 0 ? 1 : 0);

  const backPageDependers = !dependers ? false : itemsPage > 0;
  const forwardPageDependers = !dependers
    ? false
    : itemsPage <
      Math.floor(dependers.length / MAX_ITEMS_PER_PAGE) -
        (dependers.length % MAX_ITEMS_PER_PAGE === 0 ? 1 : 0);

  const generateSimpleAnnotations = (annotes) => {
    const buttons = [];
    const names = Object.keys(annotes);
    for (let i = 0; i < names.length; i++) {
      const name = names[i];

      // if (annotes[name]) {
      const buttonStructure = (
        <div
          className="annotation-item"
          key={`annotations-${item.name}-${name}`}
          style={{
            backgroundColor: annotes[name]
              ? BASE_ACCENT
              : props.isDarkTheme
              ? BASE_DARK_GRAY
              : BASE_GRAY,
          }}
        >
          <div className="card-button-text">{t(name.toLowerCase())}</div>
        </div>
      );

      buttons.push(buttonStructure);
      // }
    }

    return buttons;
  };

  const generateItemTable = (relations) => {
    const tableRows = [];

    const headerRowTextStyle = `logHeaderText column80`;
    const headerRowTextStyleSmall = `logHeaderText column20`;
    const rowStyle = `logTableRow unselectedRow`;
    const rowItemStyle = `logItemText column80 unselectedRowText`;
    const rowItemStyleSmall = `logItemText column20 unselectedRowText`;

    tableRows.push(
      <div className="logTableHeaderRow" key="tableHeader">
        <div className={headerRowTextStyle}>{t("name")}</div>
        <div className={headerRowTextStyleSmall}>{t("calories")}</div>
      </div>
    );

    for (
      let index = itemsPage * MAX_ITEMS_PER_PAGE;
      index < relations.length &&
      index - itemsPage * MAX_ITEMS_PER_PAGE < MAX_ITEMS_PER_PAGE;
      index++
    ) {
      const relation = relations[index];

      const row = (
        <div className={rowStyle} key={`tableRow-${index}`}>
          <div className={rowItemStyle}>{`${
            relation?.name.replace(/(.{45})..+/, "$1...") ||
            t("no-information-available-short")
          }`}</div>
          <div className={rowItemStyleSmall}>{`${
            `${relation?.calories}kcal` || t("no-information-available-short")
          }`}</div>
        </div>
      );
      tableRows.push(row);
    }

    return tableRows;
  };

  const dismissWithoutInputFunc = () => {
    if (props.modalSettings.returnTarget) {
      props.setModalVisible(props.modalSettings.returnTarget);
    } else {
      props.setModalVisible(false);
    }
  };

  return (
    <Modal
      isOpen={props.modalVisible}
      onRequestClose={dismissWithoutInputFunc}
      animationType="none"
      appElement={document.getElementById("root") || undefined}
    >
      <div className="card-header-text-small-centered">{t("information")}</div>

      <div className="sectionItemRow">
        <div className="card-text-small">{`${shortenText(
          t("name"),
          QUESTION_MAX_STRING,
          true,
          1
        )}:`}</div>
        <div className="endRow">
          <div className="card-text-small">
            {item.name || t("no-information-available")}
          </div>
          <InfoTooltip text={t("name-info")} />
        </div>
      </div>
      <div className="sectionItemRow">
        <div className="card-text-small">{`${shortenText(
          t("calories"),
          QUESTION_MAX_STRING,
          true,
          1
        )}:`}</div>
        <div className="endRow">
          <div className="card-text-small">
            {!isNaN(item.calories)
              ? `${item.calories}${"kcal"}`
              : t("no-information-available")}
          </div>
          <InfoTooltip
            text={
              isFood ? t("food-calories-info") : t("physical-calories-info")
            }
          />
        </div>
      </div>
      {!item.isPreset && (
        <>
          <div className="sectionItemRow">
            <div className="card-text-small">{`${shortenText(
              t("repeat-settings"),
              QUESTION_MAX_STRING,
              true,
              1
            )}:`}</div>
            <div className="endRow">
              <div className="card-text-small">
                {t(item.repeatSetting) || t("no-information-available")}
              </div>
              <InfoTooltip text={t("repeat-info")} />
            </div>
          </div>
          <div className="sectionItemRow">
            <div className="card-text-small">{`${shortenText(
              t("date-added-on"),
              QUESTION_MAX_STRING,
              true,
              1
            )}:`}</div>
            <div className="endRow">
              <div className="card-text-small">
                {item.date || t("no-information-available")}
              </div>
              <InfoTooltip text={t("date-added-on-info")} />
            </div>
          </div>
        </>
      )}
      {isFood && (
        <>
          <div className="sectionItemRow">
            <div className="card-text-small">{`${shortenText(
              t("serving-unit"),
              QUESTION_MAX_STRING,
              true,
              1
            )}:`}</div>
            <div className="endRow">
              <div className="card-text-small">
                {(item.servingUnit === "Meal"
                  ? t(item.servingUnit)[0].toUpperCase() +
                    t(item.servingUnit).slice(1)
                  : item.servingUnit) || t("no-information-available")}
              </div>
              <InfoTooltip text={t("serving-unit-info")} />
            </div>
          </div>
          <div className="sectionItemRow">
            <div className="card-text-small">{`${shortenText(
              t("serving-size"),
              QUESTION_MAX_STRING,
              true,
              1
            )}:`}</div>
            <div className="endRow">
              <div className="card-text-small">
                {item.servingSize || t("no-information-available")}
              </div>
              <InfoTooltip text={t("serving-size-info")} />
            </div>
          </div>
          <div className="sectionItemRow">
            <div className="card-text-small">{`${shortenText(
              t("carbohydrates"),
              QUESTION_MAX_STRING,
              true,
              1
            )}:`}</div>
            <div className="endRow">
              <div className="card-text-small">
                {item?.nutrients && !isNaN(item.nutrients.carbs)
                  ? `${item.nutrients.carbs}${"g"}`
                  : t("no-information-available")}
              </div>
              <InfoTooltip text={t("info-carbs")} />
            </div>
          </div>
          <div className="sectionItemRow">
            <div className="card-text-small">{`${shortenText(
              t("fat"),
              QUESTION_MAX_STRING,
              true,
              1
            )}:`}</div>
            <div className="endRow">
              <div className="card-text-small">
                {item?.nutrients && !isNaN(item.nutrients.fat)
                  ? `${item.nutrients.fat}${"g"}`
                  : t("no-information-available")}
              </div>
              <InfoTooltip text={t("info-fat")} />
            </div>
          </div>
          <div className="sectionItemRow">
            <div className="card-text-small">{`${shortenText(
              t("protein"),
              QUESTION_MAX_STRING,
              true,
              1
            )}:`}</div>
            <div className="endRow">
              <div className="card-text-small">
                {item?.nutrients && !isNaN(item.nutrients.protein)
                  ? `${item.nutrients.protein}${"g"}`
                  : t("no-information-available")}
              </div>
              <InfoTooltip text={t("info-protein")} />
            </div>
          </div>
          <div className="sectionItemRow">
            <div className="card-text-small">{`${shortenText(
              t("sugar"),
              QUESTION_MAX_STRING,
              true,
              1
            )}:`}</div>
            <div className="endRow">
              <div className="card-text-small">
                {item?.nutrients && !isNaN(item.nutrients.sugar)
                  ? `${item.nutrients.sugar}${"g"}`
                  : t("no-information-available")}
              </div>
              <InfoTooltip text={t("info-sugar")} />
            </div>
          </div>
        </>
      )}
      <div className="sectionItemRow">
        <div className="card-text-small">{`${shortenText(
          t("annotations"),
          QUESTION_MAX_STRING,
          true,
          1
        )}:`}</div>
        <div className="endRow">
          <div className="annotations">
            {generateSimpleAnnotations(item.annotations) ||
              t("no-information-available")}
          </div>
          <InfoTooltip text={t("annotations-info")} />
        </div>
      </div>
      {dependencies?.length > 0 && (
        <>
          <div className="TableArrowHeader">
            <button
              className="glyph-button"
              onClick={() => backPage && setItemsPage(itemsPage - 1)}
            >
              <Icon
                size={1}
                path={mdiChevronLeft}
                style={{ color: backPage ? BASE_ACCENT : BASE_GRAY }}
              />
            </button>
            <div className="card-header-text-small-centered">
              {t("contains-header-fixed", {
                varName: item.name,
              }) || t("no-information-available")}
            </div>
            <button
              className="glyph-button"
              onClick={() => forwardPage && setItemsPage(itemsPage + 1)}
            >
              <Icon
                size={1}
                path={mdiChevronRight}
                style={{ color: forwardPage ? BASE_ACCENT : BASE_GRAY }}
              />
            </button>
          </div>

          {generateItemTable(dependencies)}
        </>
      )}
      {dependers?.length > 0 && (
        <>
          <div className="tableArrowHeader">
            <button
              className="glyph-button"
              onClick={() =>
                backPageDependers &&
                setItemsPageDependers(itemsPageDependers - 1)
              }
            >
              <Icon
                size={1}
                path={mdiChevronLeft}
                style={{
                  color: backPageDependers ? BASE_ACCENT : BASE_GRAY,
                }}
              />
            </button>
            <div className="card-header-text-small-centered">
              {t("is-contained-in-header-fixed", {
                varName: item.name,
              })}
            </div>
            <button
              className="glyph-button"
              onClick={() =>
                forwardPageDependers &&
                setItemsPageDependers(itemsPageDependers + 1)
              }
            >
              <Icon
                size={1}
                path={mdiChevronRight}
                style={{
                  color: forwardPageDependers ? BASE_ACCENT : BASE_GRAY,
                }}
              />
            </button>
          </div>

          {generateItemTable(dependers)}
        </>
      )}
      <div className="button-container nowrap noTopMargin noBottomMargin">
        <button
          className="large-rectangle danger"
          onClick={dismissWithoutInputFunc}
        >
          <Icon size={1} path={mdiKeyboardReturn} />
          <div className="card-button-text">
            {shortenText(t("close"), LARGE_BTN_MAX_STRING, true, 0, true, true)}
          </div>
        </button>
      </div>
    </Modal>
  );
}
