import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import FastBlocker from "./FastBlocker";
import FastStats from "./FastStats";
import { START_AND_END_FAST_BUTTONS } from "../../../Supports/Buttons";
import Card from "../../Structural/Card/Card";
import CardContent from "../../Structural/Card/CardContent";
import {
  CALORIES_IN_LB,
  INFO_BANNER_MAX_STRING,
  POUNDS,
} from "../../../Supports/Constants";
import {
  converterUserWeight,
  getDate,
  getDateTimeDelta,
  getTodayDate,
  removeMillisecondsFromDateTime,
  shortenText,
  updateVal,
} from "../../../Supports/Functions";
import "./FastCard.css";
import { DataContext } from "../../../Supports/Contexts";
import { doc, getFirestore, setDoc } from "firebase/firestore";

export default function FastCard(props) {
  const { t } = useTranslation();
  const [goalHasChanged, setGoalHasChanged] = useState(false);
  const [restartTimer, setRestartTimer] = useState(0);
  const [fastGoal, setFastGoal] = useState(12);
  const [customFastGoal, setCustomFastGoal] = useState(null);
  const Data = useContext(DataContext);

  const userData = props.userData;
  const fast = userData.fast;
  const [fastLog, setFastLog] = useState(null);
  const [weightLog, setWeightLog] = useState(null);

  useEffect(() => {
    !Data.loadingFastLog && setFastLog(Data.fastLog);
  }, [Data.loadingFastLog, Data.fastLog]);

  useEffect(() => {
    !Data.loadingWeightLog && setWeightLog(Data.weightLog);
  }, [Data.loadingWeightLog, Data.weightLog]);

  const currentFast = fast.current;
  const fastStatus = currentFast.status;
  const currentFastGoal = currentFast.goal;
  const fastGoalDuration = currentFast.fastGoalDuration;
  const endTarget = currentFast.endTarget;

  const stats = fast.stats;
  const today = getTodayDate();

  useEffect(() => {
    const interval = setInterval(
      () =>
        fastStatus === "active" && setRestartTimer((prevKey) => prevKey + 1),
      1000 * 1
    ); // Once every 1s.
    return () => {
      clearInterval(interval);
    };
  }, [fastStatus]);

  const selectFastInfo = () => {
    const remainingTime = (endTarget - Date.now()) / 1000;
    const h = Math.floor(Math.abs(remainingTime / 3600));
    const m = Math.floor(Math.abs((remainingTime % 3600) / 60));
    const s = Math.floor(Math.abs(remainingTime % 60));

    const rem =
      h <= 0 && m <= 0
        ? `${s}${t("second-short")}`
        : h <= 0
        ? `${m}${t("minute-short")} ${s}${t("second-short")}`
        : `${h}${t("hour-short")} ${m}${t("minute-short")} ${s}${t(
            "second-short"
          )}`;

    const nonUnlimitedPostFix =
      fastGoal !== "unlimited"
        ? `| ${currentFast?.formattedRemaining?.timecount} ${t(
            currentFast?.formattedRemaining?.end
          )}`
        : "";

    let display = t("c-ftt-ban-error");

    if (fastStatus === "active") {
      display = `${currentFastGoal}${t("hour-short")} ${t("fast")} | ${
        remainingTime < 0 ? rem + ` ${t("over")}` : rem + ` ${t("left")}`
      }`;
    } else if (fastStatus === "complete" && !goalHasChanged) {
      display = `${t("ended")} ${
        goalHasChanged ? fastGoal : currentFastGoal
      }${t("hour-short")} ${t("fast")} ${nonUnlimitedPostFix}`;
    } else if (fastStatus === "cancelled" && !goalHasChanged) {
      display = `${t("cancelled")} ${
        goalHasChanged ? fastGoal : currentFastGoal
      }${t("hour-short")} ${t("fast")}`;
    } else if (fastStatus === "inactive" || goalHasChanged) {
      display = `${fastGoal}${t("hour-short")} ${t("fast")} | ${t(
        "c-ftt-ban-ready"
      )}`;
    }
    return shortenText(display, INFO_BANNER_MAX_STRING);
  };

  const fastInfo = selectFastInfo();

  const stub = (
    <Card
      name="fast"
      expandedCardHeight={props.expandedCardHeight}
      id="fast"
      header={t("ct-ftt")}
      info={fastInfo}
      leftButtonTitle={
        fastStatus === "active" ? t("cb-fst-l-end") : t("cb-fst-l-start")
      }
      rightButtonTitle={t("cb-fst-r")}
      setActiveCardStates={props.setActiveCardStates}
      activeCardStates={props.activeCardStates}
    />
  );

  const todayWeight = useMemo(
    () => weightLog && weightLog[`${today.year}-${today.month}-${today.day}`],
    [today.day, today.month, today.year, weightLog]
  );

  if (
    !userData ||
    !userData.body.age ||
    Data.loadingWeightLog ||
    Data.loadingFastLog
  ) {
    return stub;
  }

  const LONGEST_FAST = 480;
  let fastDaysFromWeightGoal;
  const todayGoal = todayWeight?.goal;

  const todayWeightInLBS = todayWeight
    ? converterUserWeight(todayWeight.unit, todayWeight.value, POUNDS)
    : 0;
  const todayGoalWeightInLBS = todayGoal
    ? converterUserWeight(todayGoal.unit, todayGoal.value, POUNDS)
    : 0;

  try {
    fastDaysFromWeightGoal =
      (((todayWeightInLBS - todayGoalWeightInLBS) * CALORIES_IN_LB) /
        props.userData.body.basalMetabolicRate) *
      24;
  } catch {
    fastDaysFromWeightGoal = null;
  }

  const selectCurrentFastDuration = () => {
    if (fastStatus === "inactive") {
      return 0;
    } else if (fastStatus !== "active") {
      return Math.floor(Math.abs(currentFast.duration / 3600));
    }

    return Math.abs(Date.now() - fastStart) / 3600000 || 0; // in hours
  };

  const startFast = (
    overrideLength = false,
    earlierVal = 0,
    isCustom = false
  ) => {
    const inFastGoal = overrideLength ? overrideLength : fastGoal;
    const today = getDate(false, false);
    let startDate = today;
    let startTime = today.time;

    if (earlierVal < 0) {
      const deltaDate = getDateTimeDelta(today.json, earlierVal * 1000); // seconds to milliseconds
      startDate = deltaDate;
      startTime = deltaDate.time;
    }

    const inFastGoalDuration = 3600000 * inFastGoal;
    const endFastTarget = startTime + inFastGoalDuration;

    setFastGoal(inFastGoal);

    const fastCurrent = {
      goal: inFastGoal,
      status: "active",
      start: startTime,
      startDate: startDate.date,
      startDatetime: startDate.json,
      fastGoalDuration: inFastGoalDuration / 1000,
      duration: null,
      formattedDuration: null,
      successful: null,
      endTarget: endFastTarget,
      endTargetDatetime: getDateTimeDelta(startDate.json, inFastGoalDuration),
      end: null,
      endDate: null,
      endDatetime: null,
      isCustom: isCustom,
      formattedRemaining: null,
    };

    props.setUserData({ ...userData, fast: { ...fast, current: fastCurrent } });

    return updateVal(
      {
        "fast.current": fastCurrent,
      },
      userData.id
    );
  };

  const endFast = (duration = 0, cancelled = false) => {
    setGoalHasChanged(false);
    let endTimeStamp;
    let remainingTime;
    let formattedDuration;
    const today = getDate(null, false);
    const successful = cancelled
      ? false
      : Math.floor(duration / 3600) >= fastGoal;

    const formattedRem = {};

    if (successful) {
      // successful fast
      endTimeStamp = fastStart + duration * 1000;
      remainingTime = (endTarget - (today.time - duration)) / 1000;

      const remH = Math.floor(Math.abs(remainingTime / 3600));
      const remM = Math.floor(Math.abs((remainingTime % 3600) / 60));
      const remS = Math.abs(42 - Math.floor(Math.abs(remainingTime % 60))); // FUTURE: What was the reason for the 42? Forgot. Must be something.

      formattedRem.timecount =
        remH <= 0 && remM <= 0
          ? `${remS}${t("second-short")}`
          : remH <= 0
          ? `${remM}${t("minute-short")} ${remS}${t("second-short")}`
          : `${remH}${t("hour-short")} ${remM}${t("minute-short")} ${remS}${t(
              "second-short"
            )}`;

      formattedRem.end = "over";

      const hdur = Math.floor(Math.abs(duration / 3600));
      const mdur = Math.floor(Math.abs((duration % 3600) / 60));
      const sdur = Math.floor(Math.abs(duration % 60));

      formattedDuration =
        hdur <= 0 && mdur <= 0
          ? `${sdur}${t("second-short")}`
          : hdur <= 0
          ? `${mdur}${t("minute-short")} ${sdur}${t("second-short")}`
          : `${hdur}${t("hour-short")} ${mdur}${t("minute-short")} ${sdur}${t(
              "second-short"
            )}`;
    } else {
      if (duration > 0) {
        // not yet successful, but not zero.
        endTimeStamp = fastStart + duration * 1000;
        remainingTime = currentFastGoal * 3600 - duration;
      } else {
        // zero length fast.
        endTimeStamp = today.time;
        remainingTime = currentFast.fastGoalDuration;
      }

      const remH = Math.floor(Math.abs(remainingTime / 3600));
      const remM = Math.floor(Math.abs((remainingTime % 3600) / 60));
      const remS = Math.floor(Math.abs(remainingTime % 60));

      formattedRem.timecount =
        remH <= 0 && remM <= 0
          ? `${Math.abs(remS)}${t("second-short")}`
          : remH <= 0
          ? `${Math.abs(remM)}${t("minute-short")} ${Math.abs(remS)}${t(
              "second-short"
            )}`
          : `${Math.abs(remH)}${t("hour-short")} ${Math.abs(remM)}${t(
              "minute-short"
            )} ${Math.abs(remS)}${t("second-short")}`;

      formattedRem.end = "left";

      const hdur = Math.floor(Math.abs(duration / 3600));
      const mdur = Math.floor(Math.abs((duration % 3600) / 60));
      const sdur = Math.floor(Math.abs(duration % 60));

      formattedDuration =
        hdur <= 0 && mdur <= 0
          ? `${sdur}${t("second-short")}`
          : hdur <= 0
          ? `${mdur}${t("minute-short")} ${sdur}${t("second-short")}`
          : `${hdur}${t("hour-short")} ${mdur}${t("minute-short")} ${sdur}${t(
              "second-short"
            )}`;
    }

    const completedFast = {
      ...currentFast,
      ...{
        status: cancelled ? "cancelled" : "complete",
        successful: successful,
        end: endTimeStamp,
        endDate: today.date,
        endDatetime: today.json,
        duration: duration, // seconds
        remaining: successful ? 0 : remainingTime,
        formattedRemaining: formattedRem,
        formattedDuration: formattedDuration,
      },
    };

    const newFastStats = {
      total: stats.total + 1,
      successes: stats.successes + (successful ? 1 : 0),
      avgDuration:
        stats.avgDuration === 0 || isNaN(stats.avgDuration)
          ? duration
          : (stats.avgDuration * stats.total + duration) / (stats.total + 1),
      longest:
        stats.longest === 0 || isNaN(stats.longest)
          ? duration
          : Math.max(stats.longest, duration),
    };

    const cleanedDate = removeMillisecondsFromDateTime(today.json);
    fastLog[cleanedDate] = completedFast;

    props.setUserData({
      ...userData,
      fast: {
        ...fast,
        current: completedFast,
        stats: cancelled ? fast.stats : newFastStats,
      },
    });

    return updateVal(
      {
        "fast.current": completedFast,
      },
      userData.id
    )
      .then(
        updateVal(
          { "fast.stats": cancelled ? fast.stats : newFastStats },
          userData.id
        )
      )
      .then(
        setDoc(
          doc(getFirestore(), `UserData/${userData.id}/FastLog`, cleanedDate),
          completedFast
        )
      );
  };

  const openEndFast = (params) => {
    props.setModalVisible("ENDFAST");
  };

  const fastStart = currentFast.start;
  const currentFastDuration = selectCurrentFastDuration();

  const visType = (internalProps) => {
    return <FastBlocker visualizationData={internalProps.visualizationData} />;
  };

  const fastStats = (internalProps) => {
    return <FastStats visualizationData={internalProps.visualizationData} />;
  };

  return (
    <Card
      name="fast"
      id="fast"
      header={t("ct-ftt")}
      info={fastInfo}
      leftButtonTitle={
        fastStatus === "active" ? t("cb-fst-l-end") : t("cb-fst-l-start")
      }
      rightButtonTitle={t("cb-fst-r")}
      setActiveCardStates={props.setActiveCardStates}
      activeCardStates={props.activeCardStates}
      externalTriggers={(params) => {
        switch (params.trigger) {
          case "END_FAST_FROM_FOOD":
            openEndFast();
            break;
          default:
            break;
        }
      }}
    >
      <CardContent
        headerTitle={t("c-ftt-l-head")}
        isContextActive={true}
        contextTitle={t("c-ftt-l-ctx-head")}
        contextInteractions={START_AND_END_FAST_BUTTONS}
        setModalVisible={props.setModalVisible}
        modalVisible={props.modalVisible}
        customContextFuncs={{
          STARTFAST: (v) => startFast(false, v),
          CUSTOMFAST: (v) => {
            setCustomFastGoal(v.length);
            startFast(v.length, v.backtime, true);
          },
          ENDFAST: (v) => endFast(v),
          CANCELFAST: () => endFast(0, "cancelled"),
          STARTGOAL: (v) =>
            startFast(
              Math.min(
                fastDaysFromWeightGoal - (fastDaysFromWeightGoal % 24),
                LONGEST_FAST
              ),
              v
            ),
        }}
        isVisualizationActive={true}
        visualizationType={visType}
        visualizationData={{
          endTarget: endTarget,
          fastGoal: fastGoal,
          customFastGoal,
          currentFastGoal: currentFastGoal,
          currentFastDuration: currentFastDuration,
          interactionFunc: (btn) => {
            setFastGoal(btn.value);
          },
          fastStatus: fastStatus,
          fastStart: fastStart,
          fastLog: fastLog,
          restartTimer: restartTimer,
          setRestartTimer: setRestartTimer,
          currentFast: currentFast,
          panel: "start",
          setGoalHasChanged: setGoalHasChanged,
          fastDaysFromWeightGoal: fastDaysFromWeightGoal,
          fastGoalDuration: fastGoalDuration,
        }}
        endTarget={endTarget} // required endfastmodal
        fastGoal={fastGoal} // required endfastmodal startfastmodal
        customFastGoal={customFastGoal}
        setCustomFastGoal={setCustomFastGoal}
        currentFast={currentFast}
        fastLog={fastLog}
        fastSpecialGoal={Math.min(
          fastDaysFromWeightGoal - (fastDaysFromWeightGoal % 24),
          LONGEST_FAST
        )} // required startfastmodal
        fastDaysFromWeightGoal={fastDaysFromWeightGoal} // required fast buttons.
        fastStatus={fastStatus} // required fast buttons
        interactionTitle={t("c-ftt-l-int-head", {
          varGoal: fastGoal + t("hour-short"),
        })}
      />
      <CardContent
        headerTitle={t("c-ftt-m-head")}
        contextTitle={t("Progress")}
        fastGoal={fastGoal}
        fastStatus={fastStatus}
        isVisualizationActive={true}
        visualizationType={visType}
        visualizationData={{
          isExpanded: props.isExpanded,
          endTarget: endTarget,
          fastGoal: fastGoal,
          currentFastGoal: currentFastGoal,
          currentFastDuration: currentFastDuration,
          interactionFunc: (btn) => {
            setFastGoal(btn.value);
          },
          fastStatus: fastStatus,
          fastStart: fastStart,
          fastLog: fastLog,
          restartTimer: restartTimer,
          setRestartTimer: setRestartTimer,
          currentFast: currentFast,
          panel: "view",
          setGoalHasChanged: setGoalHasChanged,
          fastDaysFromWeightGoal: fastDaysFromWeightGoal,
          fastGoalDuration: fastGoalDuration,
          setModalVisible: props.setModalVisible,
          modalVisible: props.modalVisible,
        }}
        isContextActive={false}
      />
      <CardContent
        headerTitle={t("c-ftt-r-head")}
        isVisualizationActive={true}
        visualizationType={fastStats}
        isContextActive={false}
        visualizationData={{
          fastLog: fastLog,
          stats: stats,
          setModalVisible: props.setModalVisible,
          modalVisible: props.modalVisible,
        }}
      />
    </Card>
  );
}
