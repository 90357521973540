import React, { useState } from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { LARGE_BTN_MAX_STRING } from "../../../Supports/Constants";
import { shortenText } from "../../../Supports/Functions";
import Icon from "@mdi/react";
import {
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
  mdiChevronLeft,
  mdiChevronRight,
  mdiKeyboardReturn,
  mdiTimer,
} from "@mdi/js";
import Slider from "@mui/material/Slider";

// Component to handle the confirmation of a fast start. Allows for slider/arrow adjustments to finesse.
export default function CustomFastModal(props) {
  const { t } = useTranslation();
  const MAX_BACKDATE_RANGE = 12;

  // Calculate last custom fast length. If not, use non-custom default start duration.
  const fastLog = props.fastLog;
  const fastLogKeys = Object.keys(fastLog);
  let lastCustom = false;

  for (let index = 0; index < fastLogKeys.length; index++) {
    const log = fastLog[fastLogKeys[index]];
    if (log?.isCustom) {
      lastCustom = log.goal;
      break;
    }
  }

  const [fastLength, setFastLength] = useState(
    lastCustom ? lastCustom : props.fastGoal
  );
  const minLength = 0;
  const maxLength = 480;

  const max = Math.min(MAX_BACKDATE_RANGE, fastLength) * 3600;
  const min = 0;
  const [sliderVal, setSliderVal] = useState(max);

  const translateDurationToHM = (dur) => {
    // invert to allow max to be far-left, for user display, while still aligning to min->max requirements for slider.
    const inDur = MAX_BACKDATE_RANGE * 3600 - dur;
    const remH = Math.floor(Math.abs(inDur / 3600));
    const remM = Math.floor(Math.abs((inDur % 3600) / 60));
    const remS = Math.floor(Math.abs(dur % 60));
    const remFormatted =
      remH <= 0 && remM <= 0
        ? `${remS}s`
        : remH <= 0
        ? `${remM}m ${remS}s`
        : `${remH}h ${remM}m ${remS}s`;

    return remFormatted;
  };

  const shiftTime = (isHour, isUpshift, delta = 1) => {
    const multiplier = 3600 * (isHour ? 1 : 1 / 60);
    const totalDelta = delta * multiplier;
    const newVal = sliderVal + (isUpshift ? totalDelta : -totalDelta);
    setSliderVal(newVal < 0 ? 0 : newVal > max ? max : newVal);
  };

  const dismissWithInputFunc = () => {
    props.func({
      backtime: -(MAX_BACKDATE_RANGE * 3600 - sliderVal),
      length: fastLength,
    });
    setSliderVal(max);
    setFastLength(
      props.name === "GOAL" ? props.fastSpecialGoal : props.fastGoal
    );
    props.setModalVisible(false);
  };

  const dismissWithoutInputFunc = () => {
    props.setCustomFastGoal(null);
    setSliderVal(max);
    setFastLength(
      props.name === "GOAL" ? props.fastSpecialGoal : props.fastGoal
    );
    props.setModalVisible(false);
  };

  return (
    <Modal
      isOpen={props.modalVisible}
      onRequestClose={dismissWithoutInputFunc}
      animationType="none"
      appElement={document.getElementById("root") || undefined}
    >
      <div className="modal-header-text-small">
        {t("m-ftt-start-title", {
          varLength: t("custom"),
          varUnit: "",
        })}
      </div>
      <div className="sliderRangeText">
        <div className="card-text-small">
          {`${minLength}${t("hour-short")}`}
        </div>
        <div className="sliderValuePairing card-text-small">
          {t("m-ftt-start-title", {
            varLength: fastLength,
            varUnit: t("hour-short"),
          })}
        </div>
        <div className="card-text-small">
          {`${maxLength}${t("hour-short")}`}
        </div>
      </div>
      <Slider
        aria-label="input"
        value={fastLength}
        onChange={(event) => setFastLength(Math.floor(event.target.value))}
        min={minLength}
        max={maxLength}
      />
      <div className="sliderGroup">
        <button
          className="glyph-button"
          onClick={() => {
            if (fastLength - 10 >= minLength) {
              setFastLength(fastLength - 10);
            }
          }}
        >
          <Icon size={1} path={mdiChevronLeft} />
        </button>
        <button
          className="glyph-button"
          onClick={() => {
            if (fastLength - 1 >= minLength) {
              setFastLength(fastLength - 1);
            }
          }}
        >
          <Icon size={1} path={mdiChevronDoubleLeft} />
        </button>
        <button
          className="glyph-button"
          onClick={() => {
            if (fastLength + 1 <= maxLength) {
              setFastLength(fastLength + 1);
            }
          }}
        >
          <Icon size={1} path={mdiChevronDoubleRight} />
        </button>
        <button
          className="glyph-button"
          onClick={() => {
            if (fastLength + 10 <= maxLength) {
              setFastLength(fastLength + 10);
            }
          }}
        >
          <Icon size={1} path={mdiChevronRight} />
        </button>
      </div>
      <div className="sliderRangeText">
        <div className="card-text-small">
          {t("m-ftt-start-time-ago", {
            varTime: MAX_BACKDATE_RANGE,
            varUnit: t("hour-short"),
          })}
        </div>
        <div className="sliderValuePairing card-text-small">
          {translateDurationToHM(sliderVal) === "0h"
            ? t("m-ftt-start-scroll-now")
            : t("m-ftt-start-scroll", {
                varPast: translateDurationToHM(sliderVal),
              })}
        </div>
        <div className="card-text-small">{t("now")}</div>
      </div>
      <Slider
        aria-label="input"
        value={sliderVal}
        onChange={(event) => setSliderVal(Math.floor(event.target.value))}
        min={minLength}
        max={maxLength}
      />
      <div className="sliderGroup">
        <button
          className="glyph-button"
          onClick={() => {
            if (sliderVal > min) {
              shiftTime(true, false);
            }
          }}
        >
          <Icon size={1} path={mdiChevronLeft} />
        </button>
        <button
          className="glyph-button"
          onClick={() => {
            if (sliderVal > min) {
              shiftTime(false, false);
            }
          }}
        >
          <Icon size={1} path={mdiChevronDoubleLeft} />
        </button>
        <button
          className="glyph-button"
          onClick={() => {
            if (sliderVal < max) {
              shiftTime(false, true);
            }
          }}
        >
          <Icon size={1} path={mdiChevronDoubleRight} />
        </button>
        <button
          className="glyph-button"
          onClick={() => {
            if (sliderVal < max) {
              shiftTime(true, true);
            }
          }}
        >
          <Icon size={1} path={mdiChevronRight} />
        </button>
      </div>
      <div className="button-container nowrap noTopMargin noBottomMargin">
        <button
          className="large-rectangle danger"
          onClick={dismissWithoutInputFunc}
        >
          <Icon size={1} path={mdiKeyboardReturn} />
          <div className="card-button-text">
            {shortenText(
              t("cancel"),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </div>
        </button>
        <button
          className="large-rectangle accent"
          onClick={dismissWithInputFunc}
        >
          <Icon size={1} path={mdiTimer} />
          <div className="card-button-text">
            {shortenText(
              t("m-ftt-start-btn-start"),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </div>
        </button>
      </div>
    </Modal>
  );
}
