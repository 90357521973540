import { useEffect, useState } from "react";
import "./App.css";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Login from "./Routes/Login";
import Main from "./Routes/Main";
import UserAgreement from "./Routes/UserAgreement";
import BasicInfo from "./Routes/BasicInfo";
import Registration from "./Routes/Registration";
import Screen from "./Components/Structural/Screen";
import {
  DataContextProvider,
  ModalContext,
  ThemeContext,
} from "./Supports/Contexts";
import "./Assets/Fonts/Bungee-Regular.ttf";
import "./Assets/Fonts/Oswald-Regular.ttf";
import { useTranslation } from "react-i18next";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import {
  DATABASE_USERS,
  DATABASE_USER_DATA,
  PUBLIC_PATHS,
} from "./Supports/Constants";
import Demo from "./Routes/Demo";
import Contact from "./Routes/Contact";

function App() {
  const firestore = getFirestore();
  const auth = getAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [theme, setTheme] = useState("light");
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [modal, setModal] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [activeCardContent, setActiveCardContent] = useState(1);
  const [userDataState, setUserData] = useState(null);

  useEffect(() => {
    document.body.classList.toggle("dark", theme === "dark");
  }, [theme]);

  // Initialize a connection to the firebase document, and maintain a subscription to the pre-authenticated user data.
  useEffect(() => {
    const backToLogin = () => {
      setUser(null);
      setUserData(null);
      navigate("/");
      setLoading(false);
    };

    const sub = auth.onAuthStateChanged((authedUser) => {
      // Either no auth'ed user or the user has been created but needs to confirm email.
      if (!authedUser || !authedUser?.emailVerified) {
        // if not in a valid "public" slug
        if (!PUBLIC_PATHS.includes(location.pathname)) {
          backToLogin();
        } else {
          setUser(null);
          setUserData(null);
          setLoading(false);
        }
        return;
      }

      // User was authenticated.
      getDoc(doc(firestore, DATABASE_USERS, authedUser.uid))
        .then((document) => {
          const userData = document.data();
          setUser(userData);
          if (
            userData.userAgreement &&
            !userData.userAgreement.hasAgreedToUserAgreement
          ) {
            setLoading(false);
            navigate("/useragreement");
          } else if (
            userData.userAgreement &&
            userData.userAgreement.hasAgreedToUserAgreement
          ) {
            getDoc(doc(firestore, DATABASE_USER_DATA, authedUser.uid))
              .then((document) => {
                const userDocumentData = document.data();
                setUserData(userDocumentData);
                setTheme(userDocumentData.settings.theme || "light");
                setLoading(false);
                navigate(
                  userDocumentData?.body?.age?.value ? "/main" : "/basicinfo"
                );
              })
              .catch(backToLogin);
          } else {
            backToLogin();
          }
        })
        .catch(backToLogin);
      return;
    });

    return () => sub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAuthErrors = (error) => {
    switch (error?.code) {
      case "auth/wrong-password":
        return t("account-warning-incorrect-password");
      case "auth/email-already-in-use":
        return t("account-warning-already-exists");
      case "auth/badly-formatted-email":
        return t("account-warning-badly-formatted");
      case "auth/invalid-name":
        return t("account-warning-invalid-name");
      case "auth/invalid-email":
        return t("account-warning-invalid-email");
      case "auth/invalid-password":
        return t("account-warning-invalid-password");
      case "auth/user-not-found":
        return t("account-warning-no-user");
      default:
        return t("account-warning-default");
    }
  };

  return loading ? (
    <></>
  ) : (
    <DataContextProvider>
      <ModalContext.Provider
        value={{ modal, setModal, modalData, setModalData }}
      >
        <ThemeContext.Provider value={{ theme, setTheme }}>
          <Screen>
            <Routes>
              <Route
                path="/"
                element={
                  <Login
                    handleAuthErrors={handleAuthErrors}
                    setUserFunc={setUser}
                  />
                }
              />
              <Route
                path="/main"
                element={
                  <Main
                    activeCardContent={activeCardContent}
                    setActiveCardContent={setActiveCardContent}
                    userDataState={userDataState}
                    setUserData={setUserData}
                    modal={modal}
                    setModal={setModal}
                    modalData={modalData}
                    setModalData={setModalData}
                  />
                }
              />
              <Route
                path="/register"
                element={
                  <Registration
                    handleAuthErrors={handleAuthErrors}
                    setUserFunc={setUser}
                  />
                }
              />
              <Route
                path="/useragreement"
                element={
                  <UserAgreement
                    userData={user}
                    userDocumentData={userDataState}
                  />
                }
              />
              <Route
                path="/basicinfo"
                element={
                  <BasicInfo
                    userData={user}
                    userDocumentData={userDataState}
                    setUserData={setUserData}
                  />
                }
              />
              <Route path="/demo" element={<Demo />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </Screen>
        </ThemeContext.Provider>
      </ModalContext.Provider>
    </DataContextProvider>
  );
}

export default App;
