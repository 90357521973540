import React from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { LARGE_BTN_MAX_STRING } from "../../../Supports/Constants";
import { shortenText } from "../../../Supports/Functions";
import Icon from "@mdi/react";
import { mdiCheck, mdiKeyboardReturn } from "@mdi/js";

// Simple yes/no double check modal component for deleting a plan.
export default function ConfirmModal(props) {
  const { t } = useTranslation();

  const dismissWithInputFunc = () => {
    props.setModalVisible(null);
    props.func && props.func(props.funcValue);
  };

  const dismissWithoutInputFunc = () => {
    props.setModalVisible(null);
    props.nofunc && props.nofunc(props.funcValue);
  };

  return (
    <Modal
      isOpen={props.modalVisible}
      onRequestClose={dismissWithoutInputFunc}
      animationType="none"
      appElement={document.getElementById("root") || undefined}
    >
      <div className="modal-header-text-small">
        {t(props.question, props.passedData?.question)}
      </div>
      <div className="button-container nowrap noTopMargin noBottomMargin">
        <button
          className="large-rectangle danger"
          onClick={dismissWithoutInputFunc}
        >
          <Icon size={1} path={mdiKeyboardReturn} />
          <div className="card-button-text">
            {shortenText(
              t(props.no, props.passedData?.no),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </div>
        </button>
        <button
          className="large-rectangle accent"
          onClick={dismissWithInputFunc}
        >
          <Icon size={1} path={mdiCheck} />
          <div className="card-button-text">
            {shortenText(
              t(props.yes, props.passedData?.yes),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </div>
        </button>
      </div>
    </Modal>
  );
}
