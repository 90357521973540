import { useState } from "react";
import validator from "validator";
import {
  Button,
  FormControl,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Nav from "../Components/Website/Nav";
import Foot from "../Components/Website/Foot";

import "./Contact.css";

const innertheme = createTheme({
  palette: {
    primary: {
      main: "#6614b3",
    },
  },
});

export default function Contact() {
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [type, setType] = useState("reset");
  const [email, setEmail] = useState("");

  const validateEmail = (e) => {
    setIsInvalidEmail(!validator.isEmail(e.target.value));
    setEmail(e.target.value);
  };

  const handleTypeChange = (_, newType) => newType !== null && setType(newType);

  return (
    <div className="page-wrapper">
      <main className="flex flex-col items-center scroll-smooth">
        <Nav playStoreSrc="../AppStore.png" />
        <ThemeProvider theme={innertheme}>
          <div className="contact-container bg-white rounded p-4 justify-center content-center text-center space-y-5 my-20 text-black">
            <h1>HAVE A QUESTION?</h1>
            <Button variant="contained" href={`mailto:help@weighzy.com`}>
              Email help@weighzy.com
            </Button>
            <h1>DATA REQUEST</h1>
            <FormControl className="contact-form">
              <div>
                {"I want to "}
                <ToggleButtonGroup
                  color="primary"
                  value={type}
                  exclusive
                  onChange={handleTypeChange}
                  aria-label="Platform"
                >
                  <ToggleButton variant="contained" value="reset">
                    Reset
                  </ToggleButton>
                  <ToggleButton value="delete">Delete</ToggleButton>
                </ToggleButtonGroup>
                {" my data."}
              </div>

              <TextField
                id="standard-basic"
                required
                helperText={
                  isInvalidEmail && email !== "" ? "Invalid Email" : "Required"
                }
                label="Email"
                variant="standard"
                error={isInvalidEmail && email !== ""}
                onChange={(e) => validateEmail(e)}
              />
              <br />
              <Button
                color={"primary"}
                variant="contained"
                disabled={isInvalidEmail || email === ""}
                href={`mailto:help@weighzy.com?subject=${type.toUpperCase()} Data Request: ${email}&body=Please send this email without changes. This request will result in an email being sent to the target address for complete confirmation. ${type.toUpperCase()} Data Request: ${email}`}
              >
                Submit Data Request
              </Button>
            </FormControl>
            <h1>An email will be sent to begin the confirmation process.</h1>
            <h1>Weighzy Privacy Policy</h1>
            <Button
              target="_blank"
              variant="contained"
              href={"../PrivacyPolicy.txt"}
            >
              Read Privacy Policy
            </Button>
          </div>
        </ThemeProvider>
        <Foot />
      </main>
    </div>
  );
}
