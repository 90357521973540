import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { BASE_ACCENT, BASE_GRAY } from "../../../Supports/Constants";
import { shortenText } from "../../../Supports/Functions";
import StatChart from "./StatChart";
import {
  mdiCheck,
  mdiChevronLeft,
  mdiChevronRight,
  mdiInformationOutline,
} from "@mdi/js";
import ViewExerciseModal from "../../Modals/Workout/ViewExerciseModal";
import { InfoTooltip } from "../../Supports/InfoTooltip";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

export default function WorkoutStats(props) {
  const { t } = useTranslation();
  const vd = props.visualizationData;
  const prebuiltExerciseData = vd.prebuiltExerciseData;

  // const [searchQuery, setSearchQuery] = useState("");
  const [baseSearchResults] = useState(prebuiltExerciseData.exerciseIndex);
  const [usedSearchResults, setUsedSearchResults] = useState(baseSearchResults);
  const [searchResultsPage, setSearchResultsPage] = useState(0);

  const [shownItems, setShownItems] = useState([]);

  useEffect(() => {
    setShownItems(() => {
      const shown = [];
      for (
        let index = searchResultsPage * MAX_SEARCH_ITEMS_PER_PAGE;
        index < usedSearchResults.length &&
        index - searchResultsPage * MAX_SEARCH_ITEMS_PER_PAGE <
          MAX_SEARCH_ITEMS_PER_PAGE;
        index++
      ) {
        shown.push(usedSearchResults[index]);
      }

      return shown;
    });
  }, [searchResultsPage, usedSearchResults]);

  const [exerciseHistory, setExerciseHistory] = useState({});

  useEffect(() => {
    const prom = new Promise(async (res, _) => {
      const collectPlanItems = [];
      const planPromises = [];
      Object.values(vd.plans).forEach((plan, inx) => {
        planPromises.push(
          getDocs(
            collection(
              getFirestore(),
              "UserData",
              getAuth().currentUser.uid,
              "WorkoutPlans",
              plan.id.toString(),
              "PlanItems"
            )
          ).then((itemQuery) => {
            if (!itemQuery) {
              return;
            }

            const collectItems = [];
            const inPromises = [];
            itemQuery.forEach((item) => {
              const inItem = item.data();

              inPromises.push(
                getDocs(
                  collection(
                    getFirestore(),
                    "UserData",
                    getAuth().currentUser.uid,
                    "WorkoutPlans",
                    plan.id.toString(),
                    "PlanItems",
                    (inItem.order - 1).toString(),
                    "ItemHistory"
                  )
                )
                  .then((itemHistoryQuery) => {
                    if (itemHistoryQuery) {
                      const collectHistoryRecords = [];
                      itemHistoryQuery.forEach((itemHistoryRecord) => {
                        collectHistoryRecords.push(itemHistoryRecord.data());
                      });
                      inItem.history = collectHistoryRecords;
                    }
                  })
                  .then(collectItems.push(inItem))
                  .then(() => {
                    collectPlanItems[plan.id] = collectItems;
                  })
              );
            });

            return Promise.all(inPromises);
          })
        );
      });

      await Promise.all(planPromises);
      res(collectPlanItems);
    });

    prom.then((collectedPlanItems) => {
      if (!collectedPlanItems) {
        return;
      }

      const itemHistory = {};
      for (let i = 0; i < collectedPlanItems.length; i++) {
        for (let j = 0; j < collectedPlanItems[i].length; j++) {
          const inItem = collectedPlanItems[i][j];
          if (
            shownItems.some((ele) => ele.name === inItem.name) &&
            inItem.history
          ) {
            itemHistory[inItem.name]
              ? itemHistory[inItem.name].push(...inItem.history)
              : (itemHistory[inItem.name] = inItem.history);
          }
        }
      }

      for (const [key, history] in Object.entries(itemHistory)) {
        if (!Object.hasOwn(itemHistory, key)) {
          continue;
        }

        itemHistory[key] = history.sort((a, b) => a.datestamp - b.datestamp);
      }

      setExerciseHistory(itemHistory);
    });
  }, [vd.plans, shownItems]);

  const activeStatExercise = vd.activeStatExercise;
  const setActiveStatExercise = vd.setActiveStatExercise;

  const MAX_SEARCH_ITEMS_PER_PAGE = 4;

  const infoIcon = <Icon path={mdiInformationOutline} size={1} />;

  const generateSearchBar = () => {
    return (
      <div className="sectionItemRow">
        <div className="card-text-small">{`${t("search-bar")}:`}</div>

        <div className="endRow" style={{ flex: 1, marginLeft: 10 }}>
          <input
            type="search"
            placeholder={t("search-bar-info")}
            className="input-flex-wide"
            onChange={(e) => {
              let text = e.target.value;
              setSearchResultsPage(0);
              // setSearchQuery(text);

              text = text.trim();
              const stlc = text.toLowerCase();
              const stlcTokens = stlc.split(" ");

              if (text === "" || !text || !text.replace(/\s/g, "").length) {
                setUsedSearchResults(baseSearchResults);
              } else {
                setUsedSearchResults(
                  baseSearchResults
                    .map((be) => {
                      const triggeredTokens = be.tokens.filter((token) =>
                        stlcTokens.some((inputToken) =>
                          token.toLowerCase().includes(inputToken)
                        )
                      );

                      return {
                        ...be,
                        ...{ tokensTriggered: triggeredTokens.length },
                      };
                    })
                    .filter((k) => {
                      const stlc = text.toLowerCase().trim();
                      const staticData =
                        prebuiltExerciseData.exercises[k.lookup];

                      return (
                        k.name.toLowerCase().includes(stlc) ||
                        (staticData.tokens &&
                          stlcTokens &&
                          staticData.tokens.some((token) =>
                            stlcTokens.includes(token.toLowerCase())
                          )) ||
                        (staticData.equipment &&
                          staticData.equipment.toLowerCase().includes(stlc)) ||
                        (staticData.category &&
                          staticData.category.toLowerCase().includes(stlc)) ||
                        (staticData.mechanic &&
                          staticData.mechanic.toLowerCase().includes(stlc)
                            .length > 0) ||
                        (
                          staticData.primaryMuscles &&
                          staticData.primaryMuscles.filter((m) =>
                            m.toLowerCase().includes(stlc)
                          )
                        ).length > 0 ||
                        (
                          staticData.secondaryMuscles &&
                          staticData.secondaryMuscles.filter((m) =>
                            m.toLowerCase().includes(stlc)
                          )
                        ).length > 0
                      );
                    })
                    .sort((a, b) => {
                      if (
                        !isNaN(a.tokensTriggered) &&
                        !isNaN(b.tokensTriggered) &&
                        a.tokensTriggered !== b.tokensTriggered
                      ) {
                        return b.tokensTriggered - a.tokensTriggered;
                      }
                      return a.name.length - b.name.length;
                    })
                );
              }
            }}
          />
          <InfoTooltip text={t("search-bar-info")} />
        </div>
      </div>
    );
  };

  const generateSearchItemTable = () => {
    const items = usedSearchResults;
    const backPage = searchResultsPage > 0;
    const forwardPage =
      searchResultsPage <
      Math.floor(items.length / MAX_SEARCH_ITEMS_PER_PAGE) -
        (items.length % MAX_SEARCH_ITEMS_PER_PAGE === 0 ? 1 : 0);

    const header = (
      <div className="TableArrowHeader">
        <button
          className="glyph-button"
          onClick={() =>
            backPage && setSearchResultsPage(searchResultsPage - 1)
          }
        >
          <Icon
            path={mdiChevronLeft}
            size={1}
            style={{
              color: backPage ? BASE_ACCENT : BASE_GRAY,
            }}
          />
        </button>
        <div className="card-header-text-small-centered">
          {shortenText(t("exercises"), 16, true, 0, true, true)}
        </div>
        <button
          className="glyph-button"
          onClick={() =>
            forwardPage && setSearchResultsPage(searchResultsPage + 1)
          }
        >
          <Icon
            path={mdiChevronRight}
            size={1}
            style={{
              color: forwardPage ? BASE_ACCENT : BASE_GRAY,
            }}
          />
        </button>
      </div>
    );

    const tableRows = [];

    if (items.length <= 0) {
      return (
        <div className="logTable">
          {header}
          <div className="logTableRowStub unselectedRow">
            <div className="logItemText logItemTextStubPlan">
              {t("no-exercises-found")}
            </div>
          </div>
        </div>
      );
    }

    tableRows.push(
      <div className="logTableHeaderRow" key="tableHeader">
        <div className="logHeaderText columnMax">{t("name")}</div>
        <div className="logHeaderText column20">{t("actions-i18n")}</div>
      </div>
    );

    shownItems.forEach((item) => {
      const itemHistory = exerciseHistory[item.name]
        ? exerciseHistory[item.name]
        : [];
      const hasSetHistory = itemHistory.some((item) => item.setsGoal);
      const hasDurationHistory = itemHistory.some((item) => item.durationGoal);
      const hasDistanceHistory = itemHistory.some((item) => item.distanceGoal);

      const row = (
        <div
          className="logTableRow unselectedRow"
          key={`tableRow-${item.name}`}
        >
          <div className="logItemText columnMax unselectedRowText">{`${shortenText(
            item.name || t("unnamed"),
            45,
            true,
            0,
            false,
            false
          )}`}</div>
          <div className="button-container column20 nowrap noTopMargin noBottomMargin">
            <button
              className={`row-glyph-button ${
                itemHistory.length > 0 ? "new" : "disabled"
              }`}
              onClick={() =>
                itemHistory.length > 0 &&
                setActiveStatExercise({
                  item: prebuiltExerciseData.exercises[item.lookup],
                  hasSetHistory: hasSetHistory,
                  hasDistanceHistory: hasDistanceHistory,
                  hasDurationHistory: hasDurationHistory,
                })
              }
            >
              <Icon path={mdiCheck} size={1} />
            </button>
            <button
              className="row-glyph-button accent"
              onClick={() => {
                vd.setViewedExercise(item);
                vd.setModalVisible("WO_CNTX_INFO-STATS");
              }}
            >
              {infoIcon}
            </button>
          </div>
        </div>
      );
      tableRows.push(row);
    });

    return (
      <div className="logTable">
        {header}
        {tableRows}
      </div>
    );
  };

  const generateSearchView = () => {
    return (
      <>
        {generateSearchBar()}
        {generateSearchItemTable()}
      </>
    );
  };

  const generateVisualizationView = () => {
    const itemHistory = exerciseHistory[activeStatExercise.item.name]
      ? exerciseHistory[activeStatExercise.item.name]
      : [];

    const setHistory = [];
    const durationHistory = [];
    const distanceHistory = [];

    itemHistory.forEach((h) => {
      if (h.setsGoal) {
        setHistory.push(h);
      } else if (h.durationGoal) {
        durationHistory.push(h);
      } else if (h.distanceGoal) {
        distanceHistory.push(h);
      }
    });

    const tempContext =
      setHistory.length > 0
        ? "SET"
        : durationHistory.length > 0
        ? "DURATION"
        : distanceHistory.length > 0
        ? "DISTANCE"
        : null;
    vd.setStatContext(tempContext);

    return (
      <StatChart
        visualizationData={{
          history:
            tempContext === "SET"
              ? setHistory
              : tempContext === "DURATION"
              ? durationHistory
              : distanceHistory,
          statContext: tempContext,
          displayUnit:
            vd.settings[
              tempContext === "SET"
                ? "weightUnit"
                : tempContext === "DURATION"
                ? "durationUnit"
                : "distanceUnit"
            ],
          isDarkTheme: vd.isDarkTheme,
          range: vd.statTimeline,
          settings: vd.settings,
        }}
      />
    );
  };

  return (
    <div className="settingsContainer">
      {activeStatExercise ? generateVisualizationView() : generateSearchView()}
      {vd.modalVisible === "WO_CNTX_INFO-STATS" && (
        <ViewExerciseModal
          key={"ViewExerciseModal-WO_CNTX_INFO-STATS"}
          modalVisible={vd.modalVisible === "WO_CNTX_INFO-STATS"}
          setModalVisible={vd.setModalVisible}
          modalSettings={{
            plans: vd.plans,
            prebuiltExerciseData: vd.prebuiltExerciseData,
            viewedExercise: vd.viewedExercise,
            setViewedExercise: vd.setViewedExercise,
            planModalType: vd.planModalType,
            setPlanModalType: vd.setPlanModalType,
          }}
        />
      )}
    </div>
  );
}
