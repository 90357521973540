import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import {
  BASE_ACCENT,
  BASE_DARK_GRAY,
  KILOGRAMS,
  KILOMETERS,
  LARGE_BTN_MAX_STRING,
  MILES,
  POUNDS,
  QUESTION_MAX_STRING,
} from "../../../Supports/Constants";
import {
  generateDistanceProgressionRates,
  generateDurationProgressionRates,
  generateRestDurations,
  generateSetProgressionRates,
  shortenText,
  updateVal,
} from "../../../Supports/Functions";
import Icon from "@mdi/react";
import UnstyledSelectBasic from "../../../Components/Supports/UnstyledSelectBasic";
import { ToggleButtonGroup } from "@mui/material";
import MuiToggleButton from "@mui/material/ToggleButton";
import { styled } from "@mui/material/styles";
import { mdiContentSave, mdiKeyboardReturn } from "@mdi/js";
import { InfoTooltip } from "../../Supports/InfoTooltip";

export default function WorkoutSetupModal(props) {
  const { t } = useTranslation();

  const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: BASE_ACCENT,
    },
    "&:not(.Mui-selected)": {
      color: "white",
      backgroundColor: BASE_DARK_GRAY,
    },
  }));

  const settings = props.settings;
  const weightUnit = settings.weightUnit;
  const distanceUnit = settings.distanceUnit;
  // const durationUnit = settings.durationUnit;
  const progressionRateSet = settings.progressionRateSet;
  const progressionRateDistance = settings.progressionRateDistance;
  const progressionRateDuration = settings.progressionRateDuration;
  const restDuration = settings.restDuration;

  const [warning, setWarning] = useState("");
  const [woSettings, setWOSettings] = useState({
    weightUnit: POUNDS,
    distanceUnit: MILES,
    durationUnit: "seconds-short",
    progressionRateSet: null,
    progressionRateDistance: null,
    progressionRateDuration: null,
    restDuration: null,
  });

  useEffect(() => {
    setWOSettings({
      weightUnit: weightUnit,
      distanceUnit: distanceUnit,
      progressionRateSet: progressionRateSet,
      progressionRateDistance: progressionRateDistance,
      progressionRateDuration: progressionRateDuration,
      restDuration: restDuration,
    });
  }, [
    weightUnit,
    distanceUnit,
    progressionRateSet,
    progressionRateDistance,
    progressionRateDuration,
    restDuration,
  ]);

  const hasStateChanged = (diff) => {
    const keys = Object.keys(diff);

    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      if (originalState.current[key] !== diff[key]) {
        return true;
      }
    }
    return false;
  };

  const updateState = (value) => {
    setWOSettings({ ...woSettings, ...value });
    setWarning(
      hasStateChanged({ ...woSettings, ...value })
        ? t("warning-changes-saved")
        : ""
    );
  };

  const originalState = useRef({
    weightUnit: weightUnit,
    distanceUnit: distanceUnit,
    progressionRateSet,
    progressionRateDistance,
    progressionRateDuration,
    restDuration: restDuration,
  });

  const setWorkoutSettings = (values) => {
    return updateVal(
      {
        "workout.settings": {
          ...settings,
          ...{
            weightUnit: values.weightUnit || weightUnit,
            distanceUnit: values.distanceUnit || distanceUnit,
            progressionRateSet: values.progressionRateSet || progressionRateSet,
            progressionRateDistance:
              values.progressionRateDistance || progressionRateDistance,
            progressionRateDuration:
              values.progressionRateDuration || progressionRateDuration,
            restDuration: values.restDuration || restDuration,
          },
        },
      },
      props.id
    ).catch((error) => {});
  };

  const restDurations = generateRestDurations(t);
  const setProgressionRates = generateSetProgressionRates(
    undefined,
    undefined,
    undefined,
    woSettings.weightUnit
  );

  const distanceProgressionRates = generateDistanceProgressionRates(
    undefined,
    undefined,
    undefined,
    woSettings.distanceUnit
  );

  const durationProgressionRates = generateDurationProgressionRates(
    t,
    undefined,
    undefined,
    undefined,
    woSettings.durationUnit
  );

  const settingSections = [
    {
      header: "general",
      content: (
        <div className="">
          <div className="sectionItemRow">
            <div className="card-text-small">{`${shortenText(
              t("rest-duration"),
              QUESTION_MAX_STRING,
              true,
              1
            )}:`}</div>
            <div className="endRow">
              <UnstyledSelectBasic
                options={restDurations}
                defaultOption={woSettings.restDuration}
                onChange={(_, v) => updateState({ restDuration: v })}
              />
              <InfoTooltip text={t("c-wot-rest-info")} />
            </div>
          </div>
        </div>
      ),
    },
    {
      header: "c-wot-setup-we",
      content: (
        <div className="">
          <div className="sectionItemRow">
            <div className="card-text-small">{`${shortenText(
              t("weight-unit"),
              QUESTION_MAX_STRING,
              true,
              1
            )}:`}</div>
            <div className="endRow">
              <ToggleButtonGroup
                className="row-content"
                value={woSettings.weightUnit}
                exclusive
                onChange={(_, newWeightUnit) =>
                  updateState({ weightUnit: newWeightUnit })
                }
                aria-label="weight unit"
              >
                <ToggleButton
                  className="large-rectangle-no-color"
                  value={POUNDS}
                  aria-label={POUNDS}
                >
                  {POUNDS}
                </ToggleButton>
                <ToggleButton
                  className="large-rectangle-no-color"
                  value={KILOGRAMS}
                  aria-label={KILOGRAMS}
                >
                  {KILOGRAMS}
                </ToggleButton>
              </ToggleButtonGroup>
              <InfoTooltip text={t("c-wot-r-q1-info")} />
            </div>
          </div>
          <div className="sectionItemRow">
            <div className="card-text-small">{`${shortenText(
              t("progression-rate"),
              QUESTION_MAX_STRING,
              true,
              1
            )}:`}</div>
            <div className="endRow">
              <UnstyledSelectBasic
                options={setProgressionRates}
                defaultOption={woSettings.progressionRateSet}
                onChange={(_, v) => updateState({ progressionRateSet: v })}
              />
              <InfoTooltip text={t("c-wot-r-q3-info")} />
            </div>
          </div>
        </div>
      ),
    },
    {
      header: "c-wot-setup-dise",
      content: (
        <>
          <div className="sectionItemRow">
            <div className="card-text-small">{`${shortenText(
              t("distance-unit"),
              QUESTION_MAX_STRING,
              true,
              1
            )}:`}</div>
            <div className="endRow">
              <ToggleButtonGroup
                className="row-content"
                value={woSettings.distanceUnit}
                exclusive
                onChange={(_, newDistanceUnit) =>
                  updateState({ distanceUnit: newDistanceUnit })
                }
                aria-label="distance unit"
              >
                <ToggleButton
                  className="large-rectangle-no-color"
                  value={MILES}
                  aria-label={MILES}
                >
                  {MILES}
                </ToggleButton>
                <ToggleButton
                  className="large-rectangle-no-color"
                  value={KILOMETERS}
                  aria-label={KILOMETERS}
                >
                  {KILOMETERS}
                </ToggleButton>
              </ToggleButtonGroup>
              <InfoTooltip text={t("c-wot-r-q2-info")} />
            </div>
          </div>
          <div className="sectionItemRow">
            <div className="card-text-small">{`${shortenText(
              t("progression-rate"),
              QUESTION_MAX_STRING,
              true,
              1
            )}:`}</div>
            <div className="endRow">
              <UnstyledSelectBasic
                options={distanceProgressionRates}
                defaultOption={woSettings.progressionRateDistance}
                onChange={(_, v) => updateState({ progressionRateDistance: v })}
              />
              <InfoTooltip text={t("c-wot-setup-dise-pr-info")} />
            </div>
          </div>
        </>
      ),
    },
    {
      header: "c-wot-setup-dure",
      content: (
        <div className="sectionItemRow">
          <div className="card-text-small">{`${shortenText(
            t("progression-rate"),
            QUESTION_MAX_STRING,
            true,
            1
          )}:`}</div>
          <div className="endRow">
            <UnstyledSelectBasic
              options={durationProgressionRates}
              defaultOption={woSettings.progressionRateDuration}
              onChange={(_, v) => updateState({ progressionRateDuration: v })}
            />
            <InfoTooltip text={t("c-wot-setup-dure-pr-info")} />
          </div>
        </div>
      ),
    },
  ];

  const buildSections = () => {
    const col = [];
    settingSections.forEach((section, i) => {
      col.push(
        <div key={"section" + i}>
          <div className="modal-header-text-small">{t(section.header)}</div>
          {section.content}
        </div>
      );
    });
    return col;
  };

  const dismissWithoutInputFunc = () => {
    props.setModalVisible(false);
  };

  const dismissWithInputFunc = () => {
    setWorkoutSettings(woSettings)
      .then(() => {
        originalState.current = woSettings;
        setWarning("");
      })
      .catch((error) => {});
    props.setModalVisible(false);
  };

  return (
    <Modal
      isOpen={props.modalVisible}
      onRequestClose={dismissWithoutInputFunc}
      animationType="none"
      appElement={document.getElementById("root") || undefined}
    >
      <div className="modal-header-text-small">{t("c-wot-r-title")}</div>
      {buildSections()}
      {warning !== "" && <div className="warningText">{warning}</div>}
      <div className="button-container nowrap noTopMargin noBottomMargin">
        <button className="large-rectangle" onClick={dismissWithoutInputFunc}>
          <Icon path={mdiKeyboardReturn} size={1} />
          <div className="card-button-text">
            {shortenText(
              t("cancel"),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </div>
        </button>
        <button className="large-rectangle new" onClick={dismissWithInputFunc}>
          <Icon path={mdiContentSave} size={1} />
          <div className="card-button-text">
            {shortenText(t("save"), LARGE_BTN_MAX_STRING, true, 0, true, true)}
          </div>
        </button>
      </div>
    </Modal>
  );
}
