import React, { useState } from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { LARGE_BTN_MAX_STRING } from "../../../Supports/Constants";
import { shortenText } from "../../../Supports/Functions";
import Icon from "@mdi/react";
import {
  mdiArrowLeftBold,
  mdiArrowRightBold,
  mdiFood,
  mdiHome,
  mdiKeyboardReturn,
  mdiRunFast,
  mdiScaleBathroom,
  mdiTimer,
} from "@mdi/js";
import "./GuideModal.css";

import ms2ss1 from "../../../Assets/GuideImages/l-ct_06.gif";
import ms2ss2 from "../../../Assets/GuideImages/log.png";

import ct1s1ss2 from "../../../Assets/GuideImages/food.png";
import ct1s1ss4 from "../../../Assets/GuideImages/active.png";
import ct1s1ss6 from "../../../Assets/GuideImages/l-ft_03.png";
import ct1s1ss7 from "../../../Assets/GuideImages/change-date-close.png";

import ct2s1ss1 from "../../../Assets/GuideImages/add-food-close.png";
import ct2s1ss2 from "../../../Assets/GuideImages/add-calorie-final-close.png";

import ct3s1ss1 from "../../../Assets/GuideImages/add-expended-close.png";

import ct4s1ss1 from "../../../Assets/GuideImages/l-ct_07.png";

import wt1s1ss2 from "../../../Assets/GuideImages/log.png";
import wt1s1ss4 from "../../../Assets/GuideImages/goals.png";
import wt1s1ss6 from "../../../Assets/GuideImages/l-ct_06.gif";

import wt2s1ss1 from "../../../Assets/GuideImages/add-weight-close.png";
import wt2s1ss2 from "../../../Assets/GuideImages/pick-weight-close.png";

import wt3s1ss1 from "../../../Assets/GuideImages/set-goal-close.png";

import wt4s1ss1 from "../../../Assets/GuideImages/l-wt_03.png";
import wt4s1ss2 from "../../../Assets/GuideImages/weight-details-close.png";

import wot1s1ss2 from "../../../Assets/GuideImages/plans.png";
import wot1s1ss4 from "../../../Assets/GuideImages/stats.png";
import wot1s1ss6 from "../../../Assets/GuideImages/l-ct_10.gif";

import wot2s1ss1 from "../../../Assets/GuideImages/select-workout-close.png";
import wot2s1ss2 from "../../../Assets/GuideImages/add-workout-close.png";

import wot3s1ss1 from "../../../Assets/GuideImages/exercise-prog-close.png";

import wot4s1ss1 from "../../../Assets/GuideImages/inactive-workout-close.png";
import wot4s1ss2 from "../../../Assets/GuideImages/l-wot_03.png";

import wot5s1ss1 from "../../../Assets/GuideImages/l-wot_03.png";

import wot6s1ss1 from "../../../Assets/GuideImages/l-wot_03.png";

import ft1s1ss2 from "../../../Assets/GuideImages/end.png";
import ft1s1ss4 from "../../../Assets/GuideImages/stats.png";
import ft1s1ss6 from "../../../Assets/GuideImages/l-ct_13.gif";

import ft2s1ss1 from "../../../Assets/GuideImages/set-fast-close.png";

import ft3s1ss1 from "../../../Assets/GuideImages/fast-history-close.png";

import ft4s1ss1 from "../../../Assets/GuideImages/fast-mid-close.png";
import ft4s1ss2 from "../../../Assets/GuideImages/fast-timer-close.png";

export default function GuideModal(props) {
  const { t } = useTranslation();
  const [position, setPosition] = useState(null);

  // Insert extras (images, view structure, etc.) indexing by key s-X-ss-Y,
  // where X is the section and Y the subsection.
  // FUTURE: If care enough, could automate default backs and next based on index +/- 1.
  // i.e. if stated, use stated, else if exists up or down, use up to down, else null therefore m.
  const pages = [
    {
      name: "m",
      back: null,
      next: null,
      extras: {
        "s2-ss1": (
          <div className="imageContainer">
            <img
              className="guideImageBorder guideWideImage"
              alt=""
              src={ms2ss1}
            />
          </div>
        ),
        "s2-ss2": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideSmallImage"
              src={ms2ss2}
            />
          </div>
        ),
        "s3-ss1": (
          <div className="">
            <div className="button-container nowrap noBottomMargin">
              <button
                className="large-rectangle"
                onClick={() => setPosition("ct-1")}
              >
                <Icon path={mdiFood} size={1} />
                <div className="card-button-text">
                  {shortenText(
                    t("ct-clt"),
                    LARGE_BTN_MAX_STRING,
                    true,
                    0,
                    true,
                    true
                  )}
                </div>
              </button>
              <button
                className="large-rectangle"
                onClick={() => setPosition("wt-1")}
              >
                <Icon path={mdiScaleBathroom} size={1} />
                <div className="card-button-text">
                  {shortenText(
                    t("ct-wtt"),
                    LARGE_BTN_MAX_STRING,
                    true,
                    0,
                    true,
                    true
                  )}
                </div>
              </button>
            </div>
            <div className="button-container nowrap">
              <button
                className="large-rectangle"
                onClick={() => setPosition("wot-1")}
              >
                <Icon path={mdiRunFast} size={1} />
                <div className="card-button-text">
                  {shortenText(
                    t("ct-wot"),
                    LARGE_BTN_MAX_STRING,
                    true,
                    0,
                    true,
                    true
                  )}
                </div>
              </button>
              <button
                className="large-rectangle"
                onClick={() => setPosition("ft-1")}
              >
                <Icon path={mdiTimer} size={1} />
                <div className="card-button-text">
                  {shortenText(
                    t("ct-ftt"),
                    LARGE_BTN_MAX_STRING,
                    true,
                    0,
                    true,
                    true
                  )}
                </div>
              </button>
            </div>
          </div>
        ),
      },
    },
    {
      name: "ct-1",
      back: null,
      next: "ct-2",
      extras: {
        "s1-ss2": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideSmallImage"
              src={ct1s1ss2}
            />
          </div>
        ),
        "s1-ss4": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideSmallImage"
              src={ct1s1ss4}
            />
          </div>
        ),
        "s1-ss6": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideWideImage"
              src={ct1s1ss6}
            />
          </div>
        ),
        "s1-ss7": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideWiderImage"
              src={ct1s1ss7}
            />
          </div>
        ),
      },
    },
    {
      name: "ct-2",
      back: "ct-1",
      next: "ct-3",
      extras: {
        "s1-ss1": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideShrunkLargeImage"
              src={ct2s1ss1}
            />
          </div>
        ),
        "s1-ss2": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideShrunkLargeNearWidestImage"
              src={ct2s1ss2}
            />
          </div>
        ),
      },
    },
    {
      name: "ct-3",
      back: "ct-2",
      next: "ct-4",
      extras: {
        "s1-ss1": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideLargeImage"
              src={ct3s1ss1}
            />
          </div>
        ),
      },
    },
    {
      name: "ct-4",
      back: "ct-3",
      next: null,
      extras: {
        "s1-ss1": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideLargeWideImage"
              src={ct4s1ss1}
            />
          </div>
        ),
      },
    },

    {
      name: "wt-1",
      back: null,
      next: "wt-2",
      extras: {
        "s1-ss2": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideSmallImage"
              src={wt1s1ss2}
            />
          </div>
        ),
        "s1-ss4": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideSmallImage"
              src={wt1s1ss4}
            />
          </div>
        ),
        "s1-ss6": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideWideImage"
              src={wt1s1ss6}
            />
          </div>
        ),
      },
    },
    {
      name: "wt-2",
      back: "wt-1",
      next: "wt-3",
      extras: {
        "s1-ss1": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideLargeSlightWiderImage"
              src={wt2s1ss1}
            />
          </div>
        ),
        "s1-ss2": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideShrunkLargeSlightWiderImage"
              src={wt2s1ss2}
            />
          </div>
        ),
      },
    },
    {
      name: "wt-3",
      back: "wt-2",
      next: "wt-4",
      extras: {
        "s1-ss1": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideLargeImage"
              src={wt3s1ss1}
            />
          </div>
        ),
      },
    },
    {
      name: "wt-4",
      back: "wt-3",
      next: null,
      extras: {
        "s1-ss1": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideLargeSlightTallerImage"
              src={wt4s1ss1}
            />
          </div>
        ),
        "s1-ss2": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideMediumImage"
              src={wt4s1ss2}
            />
          </div>
        ),
      },
    },

    {
      name: "wot-1",
      back: null,
      next: "wot-2",
      extras: {
        "s1-ss2": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideSmallImage"
              src={wot1s1ss2}
            />
          </div>
        ),
        "s1-ss4": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideSmallImage"
              src={wot1s1ss4}
            />
          </div>
        ),
        "s1-ss6": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideWideImage"
              src={wot1s1ss6}
            />
          </div>
        ),
      },
    },
    {
      name: "wot-2",
      back: "wot-1",
      next: "wot-3",
      extras: {
        "s1-ss1": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideLargeWiderImage"
              src={wot2s1ss1}
            />
          </div>
        ),
        "s1-ss2": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideLargeWidestImage"
              src={wot2s1ss2}
            />
          </div>
        ),
      },
    },
    {
      name: "wot-3",
      back: "wot-2",
      next: "wot-4",
      extras: {
        "s1-ss1": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideLargeSlightWiderImage"
              src={wot3s1ss1}
            />
          </div>
        ),
      },
    },
    {
      name: "wot-4",
      back: "wot-3",
      next: "wot-5",
      extras: {
        "s1-ss1": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideLargeSlightWidestImage"
              src={wot4s1ss1}
            />
          </div>
        ),
        "s1-ss2": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideLargeSlightWiderImage"
              src={wot4s1ss2}
            />
          </div>
        ),
      },
    },
    {
      name: "wot-5",
      back: "wot-4",
      next: "wot-6",
      extras: {
        "s1-ss1": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideLargeSlightWiderImage"
              src={wot5s1ss1}
            />
          </div>
        ),
      },
    },
    {
      name: "wot-6",
      back: "wot-5",
      next: null,
      extras: {
        "s1-ss1": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideLargeSlightWiderImage"
              src={wot6s1ss1}
            />
          </div>
        ),
      },
    },

    {
      name: "ft-1",
      back: null,
      next: "ft-2",
      extras: {
        "s1-ss2": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideSmallImage"
              src={ft1s1ss2}
            />
          </div>
        ),
        "s1-ss4": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideSmallImage"
              src={ft1s1ss4}
            />
          </div>
        ),
        "s1-ss6": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideWideImage"
              src={ft1s1ss6}
            />
          </div>
        ),
      },
    },
    {
      name: "ft-2",
      back: "ft-1",
      next: "ft-3",
      extras: {
        "s1-ss1": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideLargeBitWideImage"
              src={ft2s1ss1}
            />
          </div>
        ),
      },
    },
    {
      name: "ft-3",
      back: "ft-2",
      next: "ft-4",
      extras: {
        "s1-ss1": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideLargeImage"
              src={ft3s1ss1}
            />
          </div>
        ),
      },
    },
    {
      name: "ft-4",
      back: "ft-3",
      next: null,
      extras: {
        "s1-ss1": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideLargeNearWidestImage"
              src={ft4s1ss1}
            />
          </div>
        ),
        "s1-ss2": (
          <div className="imageContainer">
            <img
              alt=""
              className="guideImageBorder guideLargeSquareImage"
              src={ft4s1ss2}
            />
          </div>
        ),
      },
    },
  ];

  const getContent = (pos) => {
    // Automated using naming logic in i18n page. As long as text content
    // is inputted into translation in structured form, this will
    // build the structure using the expected values for any and all
    // sections and subsections for each page. Removes the need for
    // repeatative entry in code.

    // Default to main.
    const page =
      pages.find((p) => p.name === pos) || pages.find((p) => p.name === "m");

    // If no translation is found, it will return the query string back,
    // so break various checkpoints when translated string is the query.
    if (page) {
      const pageName = page?.name;
      const sections = [];
      let count = 1;
      let sectionChecking = true;
      while (sectionChecking) {
        if (t(`${pageName}-s${count}-h`) !== `${pageName}-s${count}-h`) {
          const subsections = [];
          let subsectionCount = 1;
          let subsectionChecking = true;
          while (subsectionChecking) {
            if (
              t(`${pageName}-s${count}-ss${subsectionCount}-h`) !==
              `${pageName}-s${count}-ss${subsectionCount}-h`
            ) {
              subsections.push({
                header: t(`${pageName}-s${count}-ss${subsectionCount}-h`),
                subsection: t(`${pageName}-s${count}-ss${subsectionCount}-s`),
                extra: page.extras[`s${count}-ss${subsectionCount}`],
              });
              subsectionCount++;
            } else {
              subsectionChecking = false;
            }
          }
          sections.push({
            header: t(`${pageName}-s${count}-h`),
            content: subsections,
          });
          count++;
        } else {
          sectionChecking = false;
        }
      }

      return {
        title:
          t(`${pageName}-t`) !== `${pageName}-t` ? t(`${pageName}-t`) : null,
        sections: sections,
        back: page?.back,
        next: page?.next,
      };
    }
  };

  const buildSections = (sections) => {
    const col = [];
    sections.forEach((section, i) => {
      const subsections = [];
      section.content.forEach((subsection, j) => {
        subsections.push(
          <div key={`guide-${i}-${j}`}>
            <label className="guide-text">
              <label className="guide-bold-text">
                {subsection.header + " "}
              </label>
              {subsection.subsection}
            </label>
            {subsection?.extra}
          </div>
        );
      });

      col.push(
        <div key={"section" + i}>
          <div className="card-header-text-small-centered">
            {section.header}
          </div>
          {subsections}
        </div>
      );
    });
    return col;
  };

  const pageObject = getContent(position);
  const sections = buildSections(pageObject.sections);

  const dismissWithoutInputFunc = () => props.setModalVisible(false);

  return (
    <Modal
      isOpen={props.modalVisible}
      onRequestClose={dismissWithoutInputFunc}
      animationType="none"
      appElement={document.getElementById("root") || undefined}
    >
      <div className="card-header-text-small-centered">{pageObject.title}</div>
      {sections}
      <div className="button-container nowrap noBottomMargin">
        {pageObject.back && (
          <button
            className={`large-rectangle ${!pageObject.back ? "disabled" : ""}`}
            disabled={!pageObject.back}
            onClick={() => pageObject.back && setPosition(pageObject.back)}
          >
            <Icon path={mdiArrowLeftBold} size={1} />
            <div className="card-button-text">
              {shortenText(
                t("back"),
                LARGE_BTN_MAX_STRING,
                true,
                0,
                true,
                true
              )}
            </div>
          </button>
        )}
        {!position ? (
          <button
            className="large-rectangle danger"
            onClick={() => {
              setPosition(null);
              dismissWithoutInputFunc();
            }}
          >
            <Icon path={mdiKeyboardReturn} size={1} />
            <div className="card-button-text">
              {shortenText(t("e-g"), LARGE_BTN_MAX_STRING, true, 0, true, true)}
            </div>
          </button>
        ) : (
          <button
            className="large-rectangle danger"
            onClick={() => setPosition(null)}
          >
            <Icon path={mdiHome} size={1} />
            <div className="card-button-text">
              {shortenText(t("b-s"), LARGE_BTN_MAX_STRING, true, 0, true, true)}
            </div>
          </button>
        )}
        {pageObject.next && (
          <button
            className={`large-rectangle ${!pageObject.next ? "disabled" : ""}`}
            disabled={!pageObject.next}
            onClick={() => pageObject.next && setPosition(pageObject.next)}
          >
            <div className="card-button-text">
              {shortenText(
                t("next"),
                LARGE_BTN_MAX_STRING,
                true,
                0,
                true,
                true
              )}
            </div>
            <Icon path={mdiArrowRightBold} size={1} />
          </button>
        )}
      </div>
    </Modal>
  );
}
