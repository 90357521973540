import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Footer from "../Components/Structural/Footer";
import Header from "../Components/Structural/Header";
import Section from "../Components/Structural/Section";
import { DATABASE_USERS, LARGE_BTN_MAX_STRING } from "../Supports/Constants";
import { getTodayDate, shortenText, updateVal } from "../Supports/Functions";
import { userAgreementText } from "../Assets/Documents/UserAgreement";
import { ModalContext } from "../Supports/Contexts";
import "./UserAgreement.css";

export default function UserAgreement({ userData, userDocumentData }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const modalContext = useContext(ModalContext);

  useEffect(() => {
    if (userDocumentData?.body?.age?.value) {
      navigate("/main");
    } else if (userData?.userAgreement?.hasAgreedToUserAgreement) {
      navigate("/basicinfo");
    }
  }, [
    userDocumentData?.body?.age?.value,
    userData?.userAgreement?.hasAgreedToUserAgreement,
    navigate,
  ]);

  const onAgreePress = () => {
    try {
      userData.userAgreement = {
        hasAgreedToUserAgreement: true,
        date: getTodayDate(),
      };
      return updateVal(userData, userData.id, DATABASE_USERS).then(() =>
        navigate("/basicinfo")
      );
    } catch (error) {}
  };

  const onDisagreePress = () => modalContext.setModal("LOGOUT_CONFIRM_MODAL");

  const onScrollTopPress = () => {
    const element = document.getElementById("useragreement");
    element.scrollTop = 0;
  };

  const onScrollBottomPress = () => {
    const element = document.getElementById("useragreement");
    element.scrollTop = element.scrollHeight;
  };

  const UserAgreement = (
    <div className="section-content grow-height">
      <div className="row-content space-between">
        <button className="large-rectangle" onClick={onScrollTopPress}>
          {shortenText(t("to-top"), LARGE_BTN_MAX_STRING, true, 0, true, true)}
        </button>
        <button className="large-rectangle" onClick={onScrollBottomPress}>
          {shortenText(
            t("to-bottom"),
            LARGE_BTN_MAX_STRING,
            true,
            0,
            true,
            true
          )}
        </button>
      </div>
      <div className={"user-agreement"}>
        <pre id={"useragreement"}>{userAgreementText}</pre>
      </div>
      <div className="row-content space-between">
        <button className="large-rectangle danger" onClick={onDisagreePress}>
          {shortenText(
            t("i-do-not-agree"),
            LARGE_BTN_MAX_STRING,
            true,
            0,
            true,
            true
          )}
        </button>
        <button className="large-rectangle new" onClick={onAgreePress}>
          {shortenText(t("i-agree"), LARGE_BTN_MAX_STRING, true, 0, true, true)}
        </button>
      </div>
    </div>
  );

  return (
    <div className="page-wrapper">
      <Header logout={true} />
      <main>
        <Section growHeight={true} header={t("user-agreement")}>
          {UserAgreement}
        </Section>
      </main>
      <Footer />
    </div>
  );
}
