import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import ConfirmModal from "../../Modals/General/ConfirmModal";
import { shortenText } from "../../../Supports/Functions";
import { TABLE_ROW_HEADER } from "../../../Supports/Constants";
import ViewExerciseModal from "../../Modals/Workout/ViewExerciseModal";
import { ModalContext } from "../../../Supports/Contexts";

export default function InactiveWorkout(props) {
  const { t } = useTranslation();
  const vd = props.visualizationData;
  const modalContext = useContext(ModalContext);

  const addCalories = () => {
    vd.prepPlan(vd.getNextPreppedPlan());
    vd.setActiveCardStates({ ...vd.activeCardStates, ...{ calorie: 2 } });
    modalContext.setModal("CUSTOM_GENERIC_PHYSICAL_CALORIES");
  };

  const doNotAddCalories = () => vd.prepPlan(vd.getNextPreppedPlan());

  const formatDuration = (dur) => {
    const hdur = Math.floor(dur / 3600);
    const mdur = Math.floor((dur % 3600) / 60);
    const sdur = Math.floor(dur % 60);
    return hdur <= 0 && mdur <= 0
      ? `${sdur}${t("second-short")}`
      : hdur <= 0
      ? `${mdur}${t("minute-short")} ${sdur}${t("second-short")}`
      : `${hdur}${t("hour-short")} ${mdur}${t("minute-short")}`;
  };

  const generateWorkoutRow = (workoutItem, prepped = true) => {
    const rowStyle = `logTableRow ${prepped ? "preppedRow" : "unselectedRow"}`;
    const rowItemStyle = `logItemText column25 ${
      prepped ? "selectedRowText" : "unselectedRowText"
    }`;

    const row = (
      <button
        key={`tableRow-${workoutItem?.id || "stub"}`}
        onClick={() => {}}
        className={rowStyle}
      >
        <div className={rowItemStyle}>{`${
          (workoutItem && workoutItem.name.replace(/(.{8})..+/, "$1...")) ||
          t("no-information-available-short")
        }`}</div>
        <div className={rowItemStyle}>{`${
          workoutItem && !Number.isNaN(vd.planSizes[workoutItem.id])
            ? vd.planSizes[workoutItem.id] || 0
            : t("no-information-available-short")
        }`}</div>
        <div className={rowItemStyle}>
          {(workoutItem &&
            workoutItem.duration &&
            formatDuration(workoutItem.duration)) ||
            t("no-information-available-short")}
        </div>
        <div className={rowItemStyle}>
          {(workoutItem && workoutItem.lastDate?.date) ||
            t("no-information-available-short")}
        </div>
        {/* <div className="" style={rowItemStyle}>
          {(workoutItem && workoutItem.nextDate?.date) || t('no-information-available-short')}
        </div> */}
      </button>
    );

    return row;
  };

  const previousWorkout = vd.lastCompletedPlan;
  const preppedWorkout = vd.preppedPlan;
  const headerRowTextStyle = "logHeaderText column25";

  const tableHeader = (isPrev = false) => (
    <div
      className="logTableHeaderRow"
      key={`tableHeader-${isPrev ? "prev" : "prepped"}`}
    >
      <div className={headerRowTextStyle}>
        {shortenText(t("name"), TABLE_ROW_HEADER, false, 0, true, true)}
      </div>
      <div className={headerRowTextStyle}>
        {shortenText(t("exercises"), TABLE_ROW_HEADER, false, 0, true, true)}
      </div>
      <div className={headerRowTextStyle}>
        {shortenText(
          t("duration-full"),
          TABLE_ROW_HEADER,
          false,
          0,
          true,
          true
        )}
      </div>
      <div className={headerRowTextStyle}>
        {shortenText(t("prior"), TABLE_ROW_HEADER, false, 0, true, true)}
      </div>
      {/* <div className="" style={headerRowTextStyle}>
        {shortenText(t('next'), TABLE_ROW_HEADER, false, 0, true, true)}
      </div> */}
    </div>
  );
  const previousWorkoutTable = [];
  previousWorkoutTable.push(generateWorkoutRow(previousWorkout));

  const preppedWorkoutTable = [];
  preppedWorkoutTable.push(generateWorkoutRow(preppedWorkout, true));

  return (
    <div className="inactiveWorkoutContainer">
      <>
        <div className="card-header-text-small-centered workoutHeader">
          {t("previous-full")}
        </div>
        {tableHeader(true)}
        {previousWorkout ? (
          <div className="logTable">{previousWorkoutTable}</div>
        ) : (
          <div className="logTableRowStub unselectedRow">
            <div className="logItemTextStub unselectedRowText">
              {t("c-wot-m-vis-s1-stub")}
            </div>
          </div>
        )}
        <div className="card-header-text-small-centered workoutHeader">
          {t("prepared-full")}
        </div>
        {tableHeader()}
        {preppedWorkout ? (
          <div className="logTable">{preppedWorkoutTable}</div>
        ) : (
          <div className="logTableRowStub unselectedRow">
            <div className="logItemTextStub unselectedRowText">
              {t("c-wot-m-vis-s2-stub")}
            </div>
          </div>
        )}
      </>
      {vd.modalVisible === "WO_ASK_CALORIE" && (
        <ConfirmModal
          key={"ConfirmModal-WO_ASK_CALORIE"}
          name={"WO_ASK_CALORIE"}
          modalVisible={vd.modalVisible === "WO_ASK_CALORIE"}
          setModalVisible={vd.setModalVisible}
          func={addCalories}
          nofunc={doNotAddCalories}
          funcValue={true}
          question={"m-wot-m-active-add-calories-title"}
          yes={"yes"}
          no={"not-yes"}
        />
      )}
      {vd.modalVisible === "WO_CNTX_INFO-INACTIVE_MID" && (
        <ViewExerciseModal
          key={"ViewExerciseModal-WO_CNTX_INFO-INACTIVE_MID"}
          modalVisible={vd.modalVisible === "WO_CNTX_INFO-INACTIVE_MID"}
          setModalVisible={vd.setModalVisible}
          modalSettings={{
            plans: vd.plans,
            prebuiltExerciseData: vd.prebuiltExerciseData,
            viewedExercise: vd.viewedExercise,
            setViewedExercise: vd.setViewedExercise,
            planModalType: vd.planModalType,
            setPlanModalType: vd.setPlanModalType,
          }}
        />
      )}
    </div>
  );
}
