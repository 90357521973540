import React from "react";
import SectionHeader from "./SectionHeader";

export default function Foot() {
  const sections = [
    // {
    //   href: "/",
    //   header: "Main ",
    //   description: "Return back to the main page.",
    // },
    {
      href: "https://play.google.com/store/apps/details?id=com.weighzy",
      header: "Play Store ",
      description: "Get Weighzy for Android devices on the Google Play Store.",
    },
    {
      href: "https://apps.apple.com/us/app/weighzy/id6469733588",
      header: "App Store ",
      description: "Get Weighzy for Apple devices on the Apple App Store.",
    },
    {
      href: "/PrivacyPolicy.txt",
      header: "Privacy Policy ",
      description: "Weighzy's User Agreement and Privacy Policy.",
    },
    {
      href: "/contact",
      header: "Contact ",
      description:
        "Contact Weighzy, as well as make data reset and deletion requests.",
    },
  ];

  const generateSections = (sections) => {
    return sections.map((s) => {
      return (
        <SectionHeader
          key={s.header}
          href={s.href}
          header={s.header}
          description={s.description}
        />
      );
    });
  };

  return (
    <div
      className={`mb-32 grid text-center lg:max-w-5xl lg:w-full mb-5 lg:grid-cols-4 lg:text-left`}
    >
      {generateSections(sections)}
    </div>
  );
}
