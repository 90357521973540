import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  reload,
} from "firebase/auth";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";
import Footer from "../Components/Structural/Footer";
import Header from "../Components/Structural/Header";
import Section from "../Components/Structural/Section";
import Mast from "../Components/Structural/Mast";
import {
  APP_NAME,
  LARGE_BTN_MAX_STRING,
  QUESTION_MAX_STRING,
} from "../Supports/Constants";
import { shortenText } from "../Supports/Functions";
import { BASE_INIT_STATE } from "../Supports/Templates";

export default function Registration({ setUserFunc, handleAuthErrors }) {
  const auth = getAuth();
  const firestore = getFirestore();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [responseStore, setResponseStore] = useState(null);
  const [verifyTrigger, setVerifyTrigger] = useState(false);
  const [warning, setWarning] = useState("");

  // const isVerificationActive = process.env.NODE_ENV === 'production';
  const isVerificationActive = true; // Firebase Emulator handles stub verification in console.

  const clearRegistration = () => {
    setVerifyTrigger(false);
    setEmail("");
    setPassword("");
    setPasswordConfirm("");
    setWarning("");

    document.getElementById("email").value = "";
    document.getElementById("password").value = "";
    document.getElementById("confirm-password").value = "";

    const currentUser = auth.currentUser;
    if (responseStore !== null && !currentUser.emailVerified) {
      currentUser.delete();
    }
    setResponseStore(null);
  };

  const updateEmail = (event) => setEmail(event.target.value);
  const updatePassword = (event) => setPassword(event.target.value);
  const updatePasswordConfirm = (event) =>
    setPasswordConfirm(event.target.value);

  const goToLogin = () => navigate("/");

  const emailVerificationActive = (response) => {
    setWarning(t("account-warning-check-email-for-verification"));
    setResponseStore(response);
    setVerifyTrigger(true);
    return sendEmailVerification(auth.currentUser);
  };

  const passVerification = () => {
    reload(auth.currentUser).then(() =>
      auth.currentUser.emailVerified
        ? buildUser(responseStore)
        : setWarning(t("account-warning-check-email-for-verification"))
    );
  };

  const buildUser = (response) => {
    const uid = response.user.uid;
    const data = {
      id: uid,
      email: response.user.email,
      userAgreement: {
        hasAgreedToUserAgreement: false,
        date: null,
      },
    };

    // Create reference to firebase login info document.
    const usersRef = collection(firestore, "Users");

    setDoc(doc(usersRef, uid), data)
      .then(() => {
        const userData = { ...BASE_INIT_STATE, id: uid };

        // Create reference to firebase user data document collection.
        const userDataRef = collection(firestore, "UserData");
        setDoc(doc(userDataRef, uid), userData).then(() => {
          setUserFunc(data);
          navigate("/useragreement");
        });
      })
      .catch((error) => setWarning(handleAuthErrors(error)));
  };

  const onRegisterPress = () => {
    if (!email && !password) {
      setWarning(t("account-warning-no-email-or-password"));
    } else if (!email) {
      setWarning(t("account-warning-no-email"));
    } else if (!password) {
      setWarning(t("account-warning-no-password"));
    } else if (!passwordConfirm) {
      setWarning(t("account-warning-second-password"));
    } else if (password !== passwordConfirm) {
      setWarning(t("account-warning-passwords-no-match"));
    } else {
      createUserWithEmailAndPassword(auth, email, password)
        .then((response) => {
          if (isVerificationActive) {
            return emailVerificationActive(response);
          } else {
            buildUser(response);
          }
        })
        .catch((error) => setWarning(handleAuthErrors(error)));
    }
  };

  const EmailPassword = (
    <div className="section-content">
      <div className="row-content space-between">
        <label className="large-label">{`${shortenText(
          t("email"),
          QUESTION_MAX_STRING,
          true,
          1
        )}:`}</label>
        <input
          id="email"
          type="email"
          placeholder={t("email")}
          onChange={updateEmail}
        />
      </div>
      <div className="row-content space-between">
        <label className="large-label">{`${shortenText(
          t("password"),
          QUESTION_MAX_STRING,
          true,
          1
        )}:`}</label>
        <input
          id="password"
          type="password"
          placeholder={t("password")}
          onChange={updatePassword}
        />
      </div>
      <div className="row-content space-between">
        <label className="large-label">{`${shortenText(
          t("confirm-password"),
          QUESTION_MAX_STRING,
          true,
          1
        )}:`}</label>
        <input
          id="confirm-password"
          type="password"
          placeholder={t("confirm-password")}
          onChange={updatePasswordConfirm}
        />
      </div>
      {warning && (
        <div className="row-content">
          <div className="warningText">{warning}</div>
        </div>
      )}
      <div className="row-content">
        {!verifyTrigger ? (
          <button className="large-rectangle" onClick={onRegisterPress}>
            {shortenText(
              t("signup-s2-btn"),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </button>
        ) : (
          <>
            <button
              className="large-rectangle danger"
              onClick={clearRegistration}
            >
              {shortenText(
                t("signup-clear-verification"),
                LARGE_BTN_MAX_STRING,
                true,
                0,
                true,
                true
              )}
            </button>
            <button className="large-rectangle new" onClick={passVerification}>
              {shortenText(
                t("signup-email-is-verified"),
                LARGE_BTN_MAX_STRING + 2,
                true,
                0,
                true,
                true
              )}
            </button>
          </>
        )}
      </div>
    </div>
  );

  const BackToLogin = (
    <div className="section-content">
      <div className="row-content">
        <button className="large-rectangle" onClick={goToLogin}>
          {shortenText(t("back"), LARGE_BTN_MAX_STRING, true, 0, true, true)}
        </button>
      </div>
    </div>
  );

  return (
    <div className="page-wrapper">
      <Header />
      <main className="menu-page">
        <Section header={t("mast-reasons-title", { varApp: APP_NAME })}>
          <Mast />
        </Section>
        <Section header={t("sign-up")}>{EmailPassword}</Section>
        <Section header={t("signup-back-head")}>{BackToLogin}</Section>
      </main>
      <Footer />
    </div>
  );
}
