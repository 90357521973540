import React from "react";

export default function SectionHeader(props) {
  return (
    <a
      href={props.href}
      className="group rounded-lg px-5 py-4"
      target="_self"
      rel="noopener noreferrer"
    >
      <h2 className={`mb-3 text-2xl font-semibold`}>
        {props.header}
        <span className="inline-block transition-transform group-hover:translate-x-1 motion-reduce:transform-none">
          -&gt;
        </span>
      </h2>
      <p className={`m-0 max-w-[30ch] text-sm opacity-50`}>
        {props.description}
      </p>
    </a>
  );
}
