import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BASE_ACCENT,
  BASE_LIGHT_GRAY,
  BASE_ORANGE,
  BASE_PINK,
  MAX_DISTANCE,
  MAX_DURATION,
  MAX_LIFT,
  MILES,
  MIN_DISTANCE,
  MIN_DURATION,
  MIN_LIFT,
  POUNDS,
} from "../../../Supports/Constants";
import {
  compareISODatetimes,
  converterDistances,
  converterUserWeight,
  getTodayDate,
  presentDistances,
  presentUserWeights,
  shortenText,
} from "../../../Supports/Functions";
import {
  generateWorkoutActiveItem,
  generateWorkoutItemHistoryRecord,
} from "../../../Supports/Templates";
import ConfirmModal from "../../Modals/General/ConfirmModal";
import CustomInputModal from "../../Modals/General/CustomInputModal";
import ChangeExerciseModal from "../../Modals/Workout/ChangeExerciseModal";
import Icon from "@mdi/react";
import {
  mdiCancel,
  mdiCheck,
  mdiChevronLeft,
  mdiChevronRight,
  mdiSwapHorizontal,
} from "@mdi/js";
import ViewExerciseModal from "../../Modals/Workout/ViewExerciseModal";
import "./ActiveWorkout.css";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

export default function ActiveWorkout(props) {
  const { t } = useTranslation();
  const vd = props.visualizationData;
  const itemData = vd.activeItem; // {item#, set#, reps#}
  const [inPlan] = useState({
    ...vd.activePlan,
    items: vd.activePlanItems,
    history: vd.activePlanHistory,
  });

  const [item, setItem] = useState(vd.activePlanItems[itemData.item]);

  useEffect(() => {
    inPlan.items = vd.activePlanItems;
    inPlan.history = vd.activePlanHistory;
  }, [inPlan, vd.activePlanItems, vd.activePlanHistory]);

  useEffect(() => {
    setItem(vd.activePlanItems[itemData.item]);
  }, [vd.activePlanItems, itemData.item]);

  const userWeight = vd.userWeight;
  // const userSex = vd.userSex;

  const setRest = vd.setRest;
  const rest = vd.rest;
  const setCardioTimer = vd.setCardioTimer;
  const cardioTimer = vd.cardioTimer;

  const itemStates = vd.itemStates;

  const newGoal = vd.activeWorkoutGoal;
  const distanceUnit = vd.settings.distanceUnit;

  useEffect(() => {
    item &&
      item?.goalType === "duration" &&
      setCardioTimer((newGoal && newGoal.value) || item.durationPerLap);
  }, [item, item?.durationPerLap, item?.goalType, newGoal, setCardioTimer]);

  if (!inPlan || !item) {
    return <></>;
  }

  const changeExercise = (exerciseIndex) => {
    const exercise = inPlan.items[exerciseIndex];

    itemStates[exerciseIndex] = false;

    vd.updateCache(
      {
        activeItemGoal: exercise.hasGoal ? vd.genGoal(exerciseIndex) : null,
        activeItemStatus: null,
        activeItem: { item: exerciseIndex, set: 0, reps: 0 },
        activeItemHasGoal: exercise.hasGoal,
        itemStates: itemStates,
      },
      true
    );
  };

  const isFirst = () => itemData.item === 0 && itemData.set === 0;

  const cancelSession = () => {
    const tempItems = [];

    for (let index = 0; index < inPlan.items.length; index++) {
      const tempItem = inPlan.items[index];
      tempItems.push(tempItem);
    }

    inPlan.items = tempItems;
    inPlan.history[inPlan.history.length - 1].status = "cancelled";

    vd.updateCache({
      activeItemStatus: null,
      activePlan: null,
      activeItem: generateWorkoutActiveItem(),
      completed: null,
      itemStates: null,
      activeItemGoal: null,
      activeItemHasGoal: null,
    })
      .then(
        setDoc(
          doc(
            getFirestore(),
            `UserData/${getAuth().currentUser.uid}/WorkoutPlans/${
              vd.cache.activePlan
            }/PlanHistory`,
            (inPlan.history.length - 1).toString()
          ),
          inPlan.history[inPlan.history.length - 1]
        )
      )
      .then(() => vd.prepPlan(vd.getNextPreppedPlan()));
  };

  const completeSession = () => {
    let count = 0;
    addHistoryRecord();

    let calorieCount = 0;
    for (let index = 0; index < inPlan.items.length; index++) {
      const tempItem = inPlan.items[index];
      if (
        tempItem.history.length > 0 &&
        tempItem.history[tempItem.history.length - 1].sessionID === vd.sessionID
      ) {
        const itemHistory = tempItem.history[tempItem.history.length - 1];
        const status = itemHistory.status;

        if (status !== "SKIP" && status !== "DNF") {
          count++;
          const userWeightInLBs = converterUserWeight(
            userWeight.unit,
            userWeight.value,
            POUNDS
          );

          if (tempItem.goalType === "set") {
            calorieCount += 1.5 * itemHistory.repsCompleted;
          } else if (tempItem.goalType === "duration") {
            const getMETCalc = (bodyLbs, metVal, seconds) => {
              return (bodyLbs / 2.20462) * metVal * 0.0175 * (seconds / 60);
            };

            const exercise =
              vd.prebuiltExerciseData.exercises[item.prebuildLookup];

            switch (exercise.category) {
              case "crossfit":
                calorieCount += getMETCalc(
                  userWeightInLBs,
                  5.6,
                  itemHistory.durationCompleted
                );
                break;
              case "stretching":
                calorieCount += getMETCalc(
                  userWeightInLBs,
                  2.3,
                  itemHistory.durationCompleted
                );
                break;
              case "plyometrics":
                calorieCount += getMETCalc(
                  userWeightInLBs,
                  8,
                  itemHistory.durationCompleted
                );
                break;
              case "cardio":
                calorieCount += getMETCalc(
                  userWeightInLBs,
                  8,
                  itemHistory.durationCompleted
                ); // FUTURE distinguish between different cardios.
                break;
              case "strength":
                calorieCount += getMETCalc(
                  userWeightInLBs,
                  6,
                  itemHistory.durationCompleted
                );
                break;
              default:
                calorieCount += getMETCalc(
                  userWeightInLBs,
                  3.5,
                  itemHistory.durationCompleted
                );
                break;
            }
          } else {
            // distance
            const getMETCalc = (bodyLbs, metVal, seconds) => {
              return (bodyLbs / 2.20462) * metVal * 0.0175 * (seconds / 60);
            };

            const exercise =
              vd.prebuiltExerciseData.exercises[item.prebuildLookup];

            switch (exercise.category) {
              case "cardio":
                calorieCount += getMETCalc(
                  userWeightInLBs,
                  8,
                  itemHistory.actualDuration
                );
                break;
              default:
                break;
            }
          }
        }
      }
    }

    const stamp = getTodayDate();
    inPlan.lastDate = stamp;

    const tempHistoryItem = inPlan.history.pop() || {};
    tempHistoryItem.calories = Math.round(calorieCount);
    tempHistoryItem.endTimeStamp = stamp;
    tempHistoryItem.status = "completed";
    tempHistoryItem.itemsCompleted = count;
    tempHistoryItem.duration =
      compareISODatetimes(
        tempHistoryItem.endTimeStamp.json,
        tempHistoryItem.startTimeStamp.json
      ) / 1000; // milliseconds to seconds

    inPlan.history.push(tempHistoryItem);

    const priorDurations = [];
    for (let index = 0; index < inPlan.history.length; index++) {
      const historyItem = inPlan.history[index];
      if (historyItem.duration) {
        priorDurations.push(historyItem.duration);
      }
    }

    inPlan.duration = tempHistoryItem.duration;

    vd.updatePlan(inPlan)
      .then(
        vd.updateCache({
          lastCompleted: inPlan,
          completed: inPlan,
          activePlan: null,
          activeItemStatus: null,
          skipped: false,
          itemStates: null,
          activeItemGoal: null,
        })
      )
      .then(vd.setModalVisible("WO_ASK_CALORIE"));
  };

  const addHistoryRecord = () => {
    const lastHistoryRec =
      inPlan.items[itemData.item]?.history[
        inPlan.items[itemData.item].history.length - 1
      ] || null;

    // If the last history record has the same session id as the current session,
    // then pop it off the end of the records and use that as the current record, otherwise create a new one.
    const itemHistory =
      lastHistoryRec?.sessionID === vd.sessionID
        ? inPlan.items[itemData.item].history.pop()
        : generateWorkoutItemHistoryRecord(vd.sessionID);

    itemHistory.status = vd.activeItemStatus || "SKIP";
    const lastSession = item.history[item.history.length - 1];

    if (item.goalType === "set") {
      itemHistory.repsGoal = item.reps * item.sets;
      itemHistory.repsCompleted =
        item.reps === "FAIL"
          ? null
          : vd.activeItemStatus === "DNF" || vd.activeItemStatus === "SKIP"
          ? 0
          : item.reps * item.sets;
      itemHistory.setsGoal = item.sets;
      itemHistory.setsCompleted =
        vd.activeItemStatus === "DNF" || vd.activeItemStatus === "SKIP"
          ? 0
          : item.sets;

      itemHistory.weight =
        vd.activeItemStatus === "DNF" || vd.activeItemStatus === "SKIP"
          ? 0
          : !isNaN(newGoal?.value)
          ? newGoal?.value
          : lastSession?.weight || 0;
      itemHistory.unit = newGoal?.unit
        ? newGoal.unit
        : lastSession?.unit || POUNDS;
    } else if (item.goalType === "distance") {
      itemHistory.lapsGoal = item.laps;
      itemHistory.lapsCompleted =
        vd.activeItemStatus === "DNF" || vd.activeItemStatus === "SKIP"
          ? 0
          : item.laps;

      itemHistory.distanceGoal = newGoal?.value || item.distancePerLap;
      itemHistory.distanceCompleted =
        vd.activeItemStatus === "DNF" || vd.activeItemStatus === "SKIP"
          ? 0
          : item.laps * item.distancePerLap;

      itemHistory.distance =
        vd.activeItemStatus === "DNF" || vd.activeItemStatus === "SKIP"
          ? 0
          : (newGoal?.value || item.distancePerLap) * item.laps || 0;
      itemHistory.unit = newGoal?.unit
        ? newGoal.unit
        : lastSession?.unit || MILES;
    } else {
      // duration
      itemHistory.lapsGoal = item.laps;
      itemHistory.lapsCompleted =
        vd.activeItemStatus === "DNF" || vd.activeItemStatus === "SKIP"
          ? 0
          : item.laps;

      itemHistory.durationGoal = newGoal?.value || item.durationPerLap;
      itemHistory.durationCompleted =
        vd.activeItemStatus === "DNF" || vd.activeItemStatus === "SKIP"
          ? 0
          : item.laps * item.durationPerLap;

      itemHistory.duration =
        vd.activeItemStatus === "DNF" || vd.activeItemStatus === "SKIP"
          ? 0
          : (newGoal?.value || item.durationPerLap) * item.laps || 0;
      itemHistory.unit = newGoal?.unit
        ? newGoal.unit
        : lastSession?.unit || "second-short";
    }

    if (vd.cache.lastCompletedItem) {
      itemHistory.startTimeStamp =
        inPlan.items[vd.cache.lastCompletedItem].history[
          inPlan.items[vd.cache.lastCompletedItem].history.length - 1
        ].endTimeStamp;
    } else {
      // first completed, so last completed timestamp is start of workout.
      // the start of workout is start of this exercise time
      itemHistory.startTimeStamp =
        inPlan.history[inPlan.history.length - 1].startTimeStamp;
    }

    itemHistory.endTimeStamp = getTodayDate();
    itemHistory.rest =
      inPlan.items[itemData.item].rest *
      (itemHistory.repsCompleted
        ? itemHistory.repsCompleted
        : itemHistory.lapsCompleted
        ? itemHistory.lapsCompleted
        : 1);

    itemHistory.actualDuration = Math.max(
      0,
      compareISODatetimes(
        itemHistory.endTimeStamp.json,
        itemHistory.startTimeStamp.json
      ) /
        1000 -
        itemHistory.rest
    );

    inPlan.items[itemData.item].history.push(itemHistory);

    vd.updateCache({
      activeItemGoal: null,
    });
  };

  const back = () => {
    if (isFirst()) {
      vd.setModalVisible("cancel-workout");
      return;
    }

    itemStates[itemData.item] = false;

    if (itemData.set <= 0) {
      itemData.item--;
      const lastItem = inPlan.items[itemData.item];
      itemData.set = lastItem.sets === 0 ? 0 : lastItem.sets - 1;
      itemData.reps = 0;

      const newStatus =
        lastItem.history[lastItem.history.length - 1]?.status || null;

      vd.updateCache(
        {
          activeItemStatus: newStatus,
          activeItem: itemData,
          activeItemHasGoal: lastItem.hasGoal,
          itemStates: itemStates,
          activeItemGoal: vd.genGoal(itemData.item),
        },
        true
      );
    } else {
      itemData.set--;

      vd.updateCache(
        {
          activeItem: itemData,
        },
        true
      );
    }

    setRest(0); // no reset rest.
  };

  const next = () => {
    const capper = item.goalType === "set" ? item.sets : item.laps;
    const noGoalSet = !(newGoal || newGoal === 0);
    const noStatus = !vd.activeItemStatus;

    if (item.hasGoal && noGoalSet) {
      return;
    } else if (itemStates.every((is) => is === true)) {
      vd.setModalVisible("complete-workout");
      return;
    }

    // If the exercise is done, but not the last in the workout.
    if (itemData.set + 1 >= capper) {
      // If this was the last set in the exercise, but no status has been reported.
      if (noStatus) {
        return;
      }

      itemStates[itemData.item] = true;

      if (itemStates.every((is) => is === true)) {
        vd.setModalVisible("complete-workout");
        return;
      }

      addHistoryRecord();

      const lastCompletedItem = itemData.item;

      // move the next target to be the next non-completed item. if the next is completed, move to the next, etc etc.
      let findNext = itemData.item + 1;

      while (itemStates[findNext] && findNext < itemStates.length - 2) {
        findNext++;
      }

      // if findNext triggered far enough to be at the number of exercise items, yet last one is completed, then reset to zero position,
      // and run it up once again to the end, to catch any stray exercises which were skipped at an early position.
      // if it hits the end again, that means every exercise is done, and next will be the end of the workout.
      if (itemStates[findNext] || findNext >= itemStates.length) {
        findNext = 0;
        while (itemStates[findNext] && findNext < itemStates.length - 2) {
          findNext++;

          // if still fully complete after the restart from zero, then end it.
          if (findNext === itemStates.length - 1) {
            break;
          }
        }
      }

      itemData.item = findNext;
      itemData.set = 0;
      itemData.reps = 0;

      const nextItem = inPlan.items[itemData.item];
      setItem(nextItem);

      vd.updateCache(
        {
          activeItemStatus: null,
          activeItem: itemData,
          activeItemHasGoal: nextItem.hasGoal,
          itemStates: itemStates,
          lastCompletedItem: lastCompletedItem,
          activeItemGoal: nextItem.hasGoal ? vd.genGoal(itemData.item) : null,
        },
        true
      );
    } else {
      // Still more sets/laps in the same exercise.
      itemData.set++;
      itemData.reps = 0;

      vd.updateCache(
        {
          activeItem: itemData,
        },
        true
      );
    }

    setRest(item.rest); // reset rest to its target.
  };

  const genArrow = (side) => {
    const isOnlyIndexNot = (a, i, target) => {
      let pass = true;
      for (let index = 0; index < a.length; index++) {
        const element = a[index];

        if (index !== i) {
          if (element === target) {
            pass = false;
            break;
          }
        }
      }
      return pass;
    };

    const isBookend =
      side === "left"
        ? isFirst()
        : (itemStates.every((is) => is === true) ||
            isOnlyIndexNot(itemStates, itemData.item, false)) &&
          itemData.set + 1 >=
            (item.goalType === "set"
              ? inPlan.items[itemData.item].sets
              : inPlan.items[itemData.item].laps);

    const capper = item.goalType === "set" ? item.sets : item.laps;
    const set = itemData.set + 1;

    let findNext = itemData.item + 1;

    while (itemStates[findNext] && findNext < itemStates.length - 2) {
      findNext++;
    }

    if (itemStates[findNext] || findNext >= itemStates.length) {
      findNext = 0;
      while (itemStates[findNext] && findNext < itemStates.length - 2) {
        findNext++;

        // if still fully complete after the restart from zero, then end it.
        if (findNext === itemStates.length - 1) {
          break;
        }
      }
    }

    const requiresSetGoal = item.hasGoal && !(newGoal || newGoal === 0);

    const nextDisallowed =
      side === "left" ||
      (capper >= set + 1 &&
        (!item.hasGoal || (item.hasGoal && (newGoal || newGoal === 0)))) ||
      (capper < set + 1 && vd.activeItemStatus);

    return (
      <div className="arrowContainer">
        <button
          className="glyph-button"
          onClick={() => (side === "left" ? back() : next())}
        >
          <div
            className={`arrowText ${
              side === "left" ? "arrowTextLeft" : "arrowTextRight"
            }`}
          >
            {side === "left" ? t("previous-short") : t("next-short")}
          </div>
          <Icon
            path={side === "left" ? mdiChevronLeft : mdiChevronRight}
            size={3}
            color={nextDisallowed ? BASE_ACCENT : BASE_LIGHT_GRAY}
          />
          <div
            className={`arrowText ${
              side === "left" ? "arrowTextLeft" : "arrowTextRight"
            }`}
          >
            {isBookend
              ? side === "left"
                ? `${t("cancel")}`
                : `${!vd.activeItemStatus ? `${t("status")} &\n` : ""}${
                    requiresSetGoal ? `${t("goal")} &\n` : ""
                  }${t("finish")}`
              : capper < set + 1 && side !== "left"
              ? `${!vd.activeItemStatus ? `${t("status")} &\n` : ""}${
                  requiresSetGoal ? `${t("goal")} &\n` : ""
                }${shortenText(
                  inPlan.items[findNext]?.name,
                  14,
                  true,
                  0,
                  false,
                  false,
                  false,
                  Math.ceil(9 / 3),
                  3
                )}`
              : set === 1 && side === "left"
              ? `${
                  requiresSetGoal && side !== "left" ? `${t("goal")} &\n` : ""
                }${shortenText(
                  inPlan.items[itemData.item - 1].name,
                  14,
                  true,
                  0,
                  false,
                  false,
                  false,
                  Math.ceil(9 / 3),
                  3
                )}`
              : `${
                  requiresSetGoal && side !== "left" ? `${t("goal")} &\n` : ""
                }${item.goalType === "set" ? t("set-wo") : t("lap")} ${
                  side === "left" ? set - 1 : set + 1
                }`}
          </div>
        </button>
      </div>
    );
  };

  const genTopButtons = () => {
    const pressableCancelSession = (
      <button
        className="glyph-button topButton"
        onClick={() => vd.setModalVisible("cancel-workout")}
      >
        <Icon path={mdiCancel} name="cancel" size={1} />
        <div className="card-header-text-smaller workoutNavLabel">
          {shortenText(t("wt-cancel-workout"), 9, true, 0, true, true)}
        </div>
      </button>
    );

    const pressableChangeExercise = (
      <button
        className="glyph-button topButton"
        onClick={() => vd.setModalVisible("change-exercise")}
      >
        <Icon path={mdiSwapHorizontal} size={1} />
        <div className="card-header-text-smaller workoutNavLabel">
          {shortenText(t("wt-change-exercise"), 9, true, 0, true, true)}
        </div>
      </button>
    );

    const pressableCompleteSession = (
      <button
        className="glyph-button topButton"
        onClick={() => vd.setModalVisible("complete-workout")}
      >
        <Icon path={mdiCheck} size={1} />
        <div className="card-header-text-smaller workoutNavLabel">
          {shortenText(t("wt-complete-workout"), 9, true, 0, true, true)}
        </div>
      </button>
    );

    return (
      <div className="topButtons">
        {pressableCancelSession}
        {pressableChangeExercise}
        {pressableCompleteSession}
      </div>
    );
  };

  const genInfo = () => {
    return <>{item.goalType === "set" ? genInfoLift() : genInfoCardio()}</>;
  };

  const genInfoLift = () => {
    const pressableGoal = (
      <button
        className="small-rectangle new"
        onClick={() => vd.setModalVisible("WO_CNTX_GOAL")}
      >
        <div className="card-button-text">{t("goal")}</div>
      </button>
    );

    return (
      <div className="restAndInfoBlock">
        <div
          className={`restTracker`}
          style={{ backgroundColor: rest <= 0 ? BASE_ACCENT : BASE_PINK }}
        >
          <div className="restTextLarge">
            {rest <= 0
              ? t("wt-rest-completed")
              : `${t("rest")} ${rest} ${t("seconds-full")}`}
          </div>
        </div>
        <div className="infoTracker">
          <div className="lineForSetAndRep">
            {!newGoal ? (
              pressableGoal
            ) : (
              <div className="infoTextLarge">{`${newGoal.value} ${
                presentUserWeights(newGoal.unit).short
              }`}</div>
            )}
            <div className="infoTextLarge">{` X ${item.reps} ${t(
              "repetitions-short"
            )}.`}</div>
          </div>
          <div className="infoTextSmall">{`${t("exercise-full")} ${
            itemData.item + 1
          } ${t("of")} ${inPlan.items.length} | ${t("set-wo")} ${
            itemData.set + 1
          } ${t("of")} ${item.sets}`}</div>
        </div>
      </div>
    );
  };

  const genInfoCardio = () => {
    let display;

    if (item.goalType === "distance") {
      const targetValue = newGoal ? newGoal.value : item.distancePerLap;
      const targetUnit = newGoal ? newGoal.unit : item.distanceUnit;
      const displayUnit = newGoal ? newGoal.unit : distanceUnit;

      const converted = converterDistances(
        targetUnit,
        targetValue,
        displayUnit,
        true,
        2
      );

      display = (
        <div className="infoTextLarge">{`${converted} ${
          presentDistances(displayUnit).short
          // converted === 1
          //   ? presentDistances(displayUnit).singular
          //   : presentDistances(displayUnit).full
        } | Lap ${itemData.set + 1}`}</div>
      );
    } else {
      // duration
      display = (
        <div
          className="restTracker"
          style={{
            backgroundColor: cardioTimer <= 0 ? BASE_ACCENT : BASE_ORANGE,
          }}
        >
          <div className="restTextLarge">
            {cardioTimer <= 0
              ? t(`${t("time")} ${t("completed")}`)
              : `${
                  Math.floor(cardioTimer / 60) > 0.5
                    ? Math.floor(cardioTimer / 60) + `${t("minute-short")} `
                    : ""
                }${
                  cardioTimer % 60 > 0
                    ? (cardioTimer % 60) + `${t("second-short")}`
                    : ""
                } ${t("left")}`}
          </div>
        </div>
      );
    }

    return (
      <div className="restAndInfoBlock">
        <div
          className="restTracker"
          style={{ backgroundColor: rest <= 0 ? BASE_ACCENT : BASE_PINK }}
        >
          <div className="restTextLarge">
            {rest <= 0
              ? t(`${t("rest")} ${t("completed")}`)
              : `${t("rest")} ${rest} ${t("seconds-full")}`}
          </div>
        </div>
        <div className="infoTracker">
          <div className="lineForSetAndRep">{display}</div>
          <div className="infoTextSmall">{`${t("exercise-full")} ${
            itemData.item + 1
          } ${t("of")} ${inPlan.items.length} | ${item.laps} ${
            item.laps !== 1 ? t("laps") : t("lap")
          }`}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="workoutContentContainer">
        {genTopButtons()}
        <div className="infoContainer">
          {genArrow("left")}
          {genInfo()}
          {genArrow("right")}
        </div>
      </div>
      {vd.modalVisible === "cancel-workout" && (
        <ConfirmModal
          key={"ConfirmModal-cancel"}
          name={"cancel"}
          modalVisible={vd.modalVisible === "cancel-workout"}
          setModalVisible={vd.setModalVisible}
          func={cancelSession}
          funcValue={true}
          question={"m-wot-m-active-cancel-title"}
          yes={"wt-cancel-workout"}
          no={"continue"}
        />
      )}
      {vd.modalVisible === "change-exercise" && (
        <ChangeExerciseModal
          key={"ViewExerciseModal-change-exercise"}
          modalVisible={vd.modalVisible === "change-exercise"}
          setModalVisible={vd.setModalVisible}
          exercises={vd.activePlanItems}
          sessionID={vd.cache.sessionID}
          activeItem={vd.cache.activeItem}
          func={changeExercise}
          funcValue={true}
          question={"wt-change-exercise-q"}
          yes={"select"}
          no={"cancel"}
        />
      )}
      {vd.modalVisible === "complete-workout" && (
        <ConfirmModal
          key={"ConfirmModal-complete"}
          name={"complete"}
          modalVisible={vd.modalVisible === "complete-workout"}
          setModalVisible={vd.setModalVisible}
          func={completeSession}
          funcValue={true}
          question={"m-wot-m-active-finish-title"}
          yes={"wt-complete-workout"}
          no={"cancel"}
        />
      )}
      {vd.modalVisible === "WO_CNTX_GOAL" && (
        <CustomInputModal
          key={"customInputModal-WO_CNTX_GOAL"}
          modalVisible={vd.modalVisible === "WO_CNTX_GOAL"}
          setModalVisible={vd.setModalVisible}
          func={(val) =>
            vd.updateCache({
              activeItemGoal: {
                value: val,
                unit:
                  item.goalType === "set"
                    ? vd.settings.weightUnit
                    : item.goalType === "distance"
                    ? item.distanceUnit
                    : "second-short",
              },
            })
          }
          modalSettings={
            item.goalType === "set"
              ? {
                  goalType: item.goalType,
                  unitType: vd.settings.weightUnit,
                  minVal: MIN_LIFT,
                  maxVal: MAX_LIFT,
                  color: BASE_ACCENT,
                  changeRate: 1,
                  changeRateBig: vd.progressionRateSet,
                }
              : item.goalType === "distance"
              ? {
                  goalType: item.goalType,
                  unitType: item.distanceUnit,
                  minVal: MIN_DISTANCE,
                  maxVal: MAX_DISTANCE,
                  color: BASE_ACCENT,
                  changeRate: 0.25,
                  changeRateBig: vd.progressionRateDistance,
                }
              : {
                  goalType: item.goalType,
                  unitType: "second-short",
                  minVal: MIN_DURATION,
                  maxVal: MAX_DURATION,
                  color: BASE_ACCENT,
                  changeRate: 5,
                  changeRateBig: vd.progressionRateDuration,
                }
          }
          current={
            item.goalType === "set"
              ? newGoal?.value || item.weight || 0
              : item.goalType === "distance"
              ? newGoal?.value || item.distancePerLap || 0
              : newGoal?.value || item.durationPerLap || 0
          }
        />
      )}
      {vd.modalVisible === "WO_CNTX_INFO-ACTIVE" && (
        <ViewExerciseModal
          key={"ViewExerciseModal-WO_CNTX_INFO-ACTIVE"}
          modalVisible={vd.modalVisible === "WO_CNTX_INFO-ACTIVE"}
          setModalVisible={vd.setModalVisible}
          modalSettings={{
            plans: vd.plans,
            prebuiltExerciseData: vd.prebuiltExerciseData,
            viewedExercise: vd.viewedExercise,
            setViewedExercise: vd.setViewedExercise,
          }}
        />
      )}
    </>
  );
}
