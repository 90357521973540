import { useEffect, useContext } from "react";
import Nav from "../Components/Website/Nav";
import Foot from "../Components/Website/Foot";
import { ThemeContext } from "../Supports/Contexts";

export default function Demo() {
  const theme = useContext(ThemeContext);

  const baseURL = "/screenshots";

  const cards = [
    {
      name: "Calorie Tracker",
      description:
        "Track caloric intake from food and drinks, and the burn from workouts and activities.",
      imageLight: `${baseURL}/l-ct.png`,
      imageDark: `${baseURL}/d-ct.png`,
      items: [
        "Quick add for calories and meals.",
        "Instant adjustment actions for existing calorie entries",
        "Expended calorie tracking from workouts and activities",
        "Custom meal creation, management, and usage",
        "Advanced meal building with meals-in-meals and custom unit descriptions",
        "Scheduled calories",
        "Dynamically calculated goal overlays based on personal description and weight tracker goals",
        "Easy navigation between days and calendar views",
        "Barcode nutrition scanning (experimental)",
      ],
    },
    {
      name: "Weight Tracker",
      description:
        "Set and track progress towards weight goals, whether to gain, lose, or maintain.",
      imageLight: `${baseURL}/l-wt.png`,
      imageDark: `${baseURL}/d-wt.png`,
      items: [
        "Quick add weight logging and custom entry",
        "Personalized weight goal calculations based on provided health insights",
        "Daily caloric impact assessment for weight results",
        "Dynamic weight goal types (gain, loss, and maintain)",
        "Scalable weight change rates",
        "Multiple interactive time range visualizations",
        "Predictive weight goal timeline",
      ],
    },
    {
      name: "Workout Tracker",
      description:
        "Plan and perform workouts, supporting both cardio and weight lifting exercises.",
      imageLight: `${baseURL}/l-wot.png`,
      imageDark: `${baseURL}/d-wot.png`,
      items: [
        "Live workouts supporting full spread of exercise types",
        "Expressive workout planner that supports any number of exercises and goals",
        "Supports full compliment of exercise goals, such as set/rep, time, distance, and to-fail.",
        "Dynamic and responsive live workout rest and pace timers",
        "Built-in session-to-session workout goal tracker and progression rates",
        "Track exercise performance and progress history",
        "Automatic post-workout calorie calculation.",
      ],
    },
    {
      name: "Fast Tracker",
      description:
        "Manage fasting sessions within a fully structured tracking environment.",
      imageLight: `${baseURL}/l-ft.png`,
      imageDark: `${baseURL}/d-ft.png`,
      items: [
        "Preset and customizable fast lengths",
        "Supports continuation beyond initial goal",
        "Adjustable fast management, allowing for alterations to reported start and end times",
        "Assistive fast length calculations to demostrate requirements to achieve described weight loss goals",
        "Contextual information within tracker based on current and upcoming fast stages",
        "Provides fast history and performance statistics",
      ],
    },
    {
      name: "Extra Features",
      description: "Amongst many other Weighzy features.",
      imageLight: `${baseURL}/l-set.png`,
      imageDark: `${baseURL}/d-set.png`,
      items: [
        "Completely free",
        "Continuously updated",
        "Email and Third-party account creation options",
        "Multi-language support (experimental): Danish, German, English, Spanish, Finnish, French, Italian, Dutch, Norwegian, Portuguese, and Swedish",
        "Light and dark themes",
        "Custom notification reminders and settings",
        "Easy, no-fuss account data reset and deletion",
      ],
    },
  ];

  const generateCard = (card, isDark = true) => {
    return (
      <div
        key={card.name}
        className="flex flex-col md:flex-row items-center justify-between md:columns-2 columns-1 mb-5 js-show-on-scroll"
      >
        <div className="max-w-xl">
          <div className="mb-3 font-bungee text-5xl">{card.name}</div>
          <div className="mb-3 text-md font-benchnine text-lg">
            {card.description}{" "}
          </div>
          <div className="mb-3 font-bungee text-3xl my-5">
            Feature Highlights
          </div>
          <ul className="list-disc list-inside">
            {card.items.map((l, count) => (
              <li
                key={`${card.name}-${count}`}
                className="mb-2 text-md font-benchnine text-lg"
              >{`${l}`}</li>
            ))}
          </ul>
        </div>
        <div className="ml-5">
          <img
            className="relative"
            src={theme.theme === "dark" ? card.imageDark : card.imageLight}
            alt={`${card.name} Screenshot`}
            width={500} // 537
            height={37}
            // priority
          />
        </div>
      </div>
    );
  };

  const callback = function (entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("animate-fadeIn");
        entry.target.classList.remove("animate-fadeOut");
      } else {
        entry.target.classList.remove("animate-fadeIn");
        entry.target.classList.add("animate-fadeOut");
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callback);
    const targets = document.querySelectorAll(".js-show-on-scroll");
    targets.forEach(function (target) {
      target.classList.add("opacity-0");
      observer.observe(target);
    });
  }, []);

  return (
    <div className="page-wrapper">
      <main
        className={`flex min-h-screen flex-col items-center justify-between scroll-smooth`}
      >
        <Nav playStoreSrc="./AppStore.png" />
        <div className="">{cards.map((c) => generateCard(c))}</div>
        <Foot />
      </main>
    </div>
  );
}
