import React from "react";
import { useTranslation } from "react-i18next";
import {
  BASE_ACCENT,
  BASE_AQUA,
  BASE_BLACK,
  BASE_DARK_GRAY,
  BASE_GREEN,
  BASE_PINK,
  BASE_RED,
} from "../../../Supports/Constants";
import { shortenText } from "../../../Supports/Functions";
import "./StatChart.css";

export default function ChartTooltip(props) {
  const { t } = useTranslation();
  const stat = props.activePoints[0];

  const stub = t("no-information-available-short");

  const makeBox = (title, value, type) => {
    const colorValueBackground = (datum) => {
      switch (datum) {
        case "goal":
          return BASE_PINK;
        case "log":
          return BASE_BLACK;
        case "pred":
          return BASE_AQUA;
        default:
          return BASE_DARK_GRAY;
      }
    };

    const timeConvert = (seconds) => {
      return `${
        Math.floor(seconds / 60) > 0.5
          ? Math.floor(seconds / 60) + `${t("minute-short")} `
          : ""
      }${seconds % 60 > 0 ? (seconds % 60) + `${t("second-short")}` : ""}`;
    };

    const inValue =
      stat.statContext === "DURATION" ? timeConvert(value) : value;

    return (
      <div className="tooltipBox">
        <div className="tooltipBoxTitle">
          {shortenText(title, 4, false, 0, false, false)}
        </div>
        <div
          className="tooltipBoxTextBackground"
          style={{ backgroundColor: colorValueBackground(type) }}
        >
          <div
            className="tooltipBoxValue"
            // style={{ backgroundColor: colorValueBackground(type) }}
          >
            {`${inValue || stub}${
              stat.statContext !== "DURATION"
                ? `${inValue !== stub ? stat.unit : ""}`
                : ""
            }`}
          </div>
        </div>
      </div>
    );
  };

  const colorBackground = (datum) => {
    if (!datum) {
      return BASE_ACCENT;
    }

    switch (datum.goalMet) {
      case true:
        return BASE_GREEN;
      case false:
        return BASE_RED;
      default:
        return BASE_ACCENT;
    }
  };

  return (
    <g style={{ pointerEvents: "none" }}>
      <foreignObject
        x={stat?._x < 8 ? "55%" : "20%"}
        y={5}
        width="150"
        height="100"
      >
        <div
          className="tooltipContainer"
          style={{
            backgroundColor: colorBackground(stat),
          }}
        >
          <div className="tooltipText">{stat.x}</div>
          <div className="boxRow">
            {makeBox(t("prior"), stat?.lastStat || stub, "log")}
            {makeBox(t("now"), stat?.y || stub, "log")}
            {makeBox(t("next"), stat?.nextStat || stub, "log")}
          </div>
        </div>
      </foreignObject>
    </g>
  );
}
