import { useState, useRef, createElement, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../Components/Structural/Footer";
import Header from "../Components/Structural/Header";
import WeightCard from "../Components/Features/Weight/WeightCard";
import FastCard from "../Components/Features/Fast/FastCard";
import CalorieCard from "../Components/Features/Calories/CalorieCard";
import WorkoutCard from "../Components/Features/Workout/WorkoutCard";
import { getTodayDate } from "../Supports/Functions";
// import Ad from "../Components/Features/Ad/Ad";

export default function Main({
  userDataState,
  setUserData,
  modal,
  setModal,
  modalData,
  setModalData,
  activeCardContent,
  setActiveCardContent,
}) {
  let location = useLocation();
  const homeRef = useRef();
  const [loading, setLoading] = useState(true);
  const [activeDate, setActiveDate] = useState(getTodayDate());
  const [activeCardStates, setActiveCardStates] = useState({});
  const [dateChangeRegistry, setDateChangeRegistry] = useState([]);

  useEffect(() => {
    userDataState?.body?.age?.value && setLoading(false);
    if (location.state?.isFirstTime) {
      setModal("WelcomeModal");
      location.state.isFirstTime = null;
    }
  }, [
    userDataState?.body?.age?.value,
    location.state?.isFirstTime,
    location.state,
    setModal,
  ]);

  if (loading) {
    return <></>;
  }

  // Load cards, and prepare with props. Done in such a fashion to dynamically adjust to number of Cards.
  const CARDS = [
    { card: CalorieCard, id: "calorie" },
    { card: WeightCard, id: "weight" },
    { card: WorkoutCard, id: "workout" },
    { card: FastCard, id: "fast" },
  ];

  const CARD_COMPONENTS = CARDS.map((item) => {
    return createElement(
      item.card,
      {
        key: item.card.name,
        userData: userDataState,
        setUserData: setUserData,
        modalVisible: modal,
        setModalVisible: setModal,
        modalData: modalData,
        setModalData: setModalData,
        activeDate: activeDate,
        setActiveDate: setActiveDate,
        dateChangeRegistry: dateChangeRegistry,
        setDateChangeRegistry: setDateChangeRegistry,
        homeRef: homeRef,
        activeCardStates: activeCardStates,
        setActiveCardStates: setActiveCardStates,
        activeCardContent: activeCardContent,
        setActiveCardContent: setActiveCardContent,
      },
      null
    );
  });

  return (
    <div className="page-wrapper">
      <Header
        logout={true}
        main={true}
        data={{
          userData: userDataState,
          setUserData: setUserData,
          activeDate: activeDate,
        }}
      />
      <main className="main">
        {CARD_COMPONENTS} {/*<Ad /> */}
      </main>
      <Footer />
    </div>
  );
}
