import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Footer from "../Components/Structural/Footer";
import Header from "../Components/Structural/Header";
import Section from "../Components/Structural/Section";
import Mast from "../Components/Structural/Mast";
import {
  APP_NAME,
  BASE_ACCENT,
  BASE_DARK_GRAY,
  DEFAULT_LANGUAGE,
  KILOGRAMS,
  LARGE_BTN_MAX_STRING,
  POUNDS,
  QUESTION_MAX_STRING,
  STABLE_LANGUAGES,
  STONES,
} from "../Supports/Constants";
import {
  converterUserWeight,
  generateAges,
  generateHeights,
  generateWeights,
  getBMR,
  getTodayDate,
  heightStringToObject,
  objectToHeightString,
  shortenText,
  updateVal,
} from "../Supports/Functions";
import {
  BASE_INIT_STATE,
  DEFAULT_USER,
  generateLanguages,
  generatePaceGoal,
  generateWeightGoal,
  generateWeightLogItem,
  generateWeightPrediction,
} from "../Supports/Templates";
import { ToggleButtonGroup } from "@mui/material";
import MuiToggleButton from "@mui/material/ToggleButton";
import { styled } from "@mui/material/styles";
import UnstyledSelectBasic from "../Components/Supports/UnstyledSelectBasic";
import i18n from "../i18n/config";
import { InfoTooltip } from "../Components/Supports/InfoTooltip";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { DataContext } from "../Supports/Contexts";

export default function BasicInfo({ userData, userDocumentData, setUserData }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const Data = useContext(DataContext);

  useEffect(() => {
    if (!location.state?.isReset && userDocumentData?.body?.age?.value) {
      navigate("/main");
    } else if (
      !location.state?.isReset &&
      !userData?.userAgreement?.hasAgreedToUserAgreement
    ) {
      navigate("/useragreement");
    }
  }, [
    userDocumentData?.body?.age?.value,
    userData?.userAgreement?.hasAgreedToUserAgreement,
    navigate,
    location.state?.isReset,
  ]);

  const [age, setAge] = useState(DEFAULT_USER.age.value);
  const [sex, setSex] = useState(DEFAULT_USER.sex === "f" ? "female" : "male");
  const [height, setHeight] = useState(
    objectToHeightString(DEFAULT_USER.height)
  );
  const [weight, setWeight] = useState(DEFAULT_USER.weight);
  const [weightUnit, setWeightUnit] = useState(DEFAULT_USER.weightUnit);
  const [language, setLanguage] = useState(DEFAULT_LANGUAGE);

  const ages = generateAges();
  const heights = generateHeights();
  const weights = generateWeights(weightUnit);
  const languages = generateLanguages(t);

  const onRegisterPress = () => {
    if (!sex) {
      return;
    }

    const inSex = sex === "male" ? "m" : "f";

    const today = getTodayDate();

    const newBMR = getBMR({
      age: age,
      height: height,
      sex: inSex,
      weight: converterUserWeight(weightUnit, weight, POUNDS),
    });

    const goalPace = generatePaceGoal(
      DEFAULT_USER.pace.value,
      DEFAULT_USER.pace.unit
    );

    const weightGoal = generateWeightGoal(
      weight,
      goalPace.value === 0 ? "nochange" : goalPace.value < 0 ? "less" : "more",
      weightUnit
    );

    const weightPrediction = generateWeightPrediction(
      newBMR,
      0,
      0,
      weight,
      weightGoal,
      goalPace,
      weightUnit
    );

    const current = generateWeightLogItem(
      weight,
      "user",
      weightUnit,
      today,
      weightPrediction,
      weightGoal,
      goalPace
    );

    const data = {
      ...BASE_INIT_STATE,
      ...{
        id: userData.id,
        settings: {
          theme: "light",
          language: language,
          notifications: {
            noCal: true,
            noWeight: true,
            fastEnd: true,
          },
        },
        body: {
          height: heightStringToObject(height),
          sex: inSex,
          age: { value: age, datetime: today.json, date: today.date },
          basalMetabolicRate: newBMR,
          birthday: null,
        },
        weight: {
          settings: {
            unit: weightUnit,
            lastUserEntry: {
              year: today.year,
              month: today.month,
              day: today.day,
            },
          },
        },
      },
    };
    setUserData(data);

    Data.weightLog[`${today.year}-${today.month}-${today.day}`] = current; // Force update for first load to have first weight.

    return updateVal(data, userData.id)
      .then(
        () =>
          setDoc(
            doc(
              getFirestore(),
              `UserData/${userData.id}/WeightLog`,
              `${today.year}-${today.month}-${today.day}`
            ),
            current
          ) // Create first weight log.
      )
      .then(navigate("/main", { state: { isFirstTime: true } }));
  };

  let warning = null;
  if (language && STABLE_LANGUAGES.findIndex((l) => l === language) === -1) {
    warning = t("m-dd-s-unstable-language-warning");
  }

  const handleAge = (_, age) => setAge(age);
  const handleSex = (_, newSex) => newSex && setSex(newSex);
  const handleHeight = (_, height) => setHeight(height);
  const handleWeightUnit = (_, newWeightUnit) =>
    newWeightUnit && setWeightUnit(newWeightUnit);
  const handleWeight = (_, weight) => setWeight(weight);
  const handleLanguage = (_, newLanguage) => {
    i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage);
  };

  const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: BASE_ACCENT,
    },
    "&:not(.Mui-selected)": {
      color: "white",
      backgroundColor: BASE_DARK_GRAY,
    },
  }));

  const Required = (
    <div className="section-content">
      <div className="row-content space-between">
        <label className="large-label">{`${shortenText(
          t("age"),
          QUESTION_MAX_STRING,
          true,
          1
        )}:`}</label>
        <div className="endRow">
          <UnstyledSelectBasic
            options={ages}
            defaultOption={age}
            onChange={handleAge}
          />
          <InfoTooltip text={t("age-info")} />
        </div>
      </div>
      <div className="row-content space-between no-margin-bottom">
        <label className="large-label">{`${shortenText(
          t("sex"),
          QUESTION_MAX_STRING,
          true,
          1
        )}:`}</label>
        <div className="endRow">
          <ToggleButtonGroup
            className="row-content"
            value={sex}
            exclusive
            onChange={handleSex}
            aria-label="sex"
          >
            <ToggleButton
              className="large-rectangle-no-color"
              value="female"
              aria-label="female"
            >
              {t("female")}
            </ToggleButton>
            <ToggleButton
              className="large-rectangle-no-color"
              value="male"
              aria-label="male"
            >
              {t("male")}
            </ToggleButton>
          </ToggleButtonGroup>
          <InfoTooltip text={t("sex-info")} />
        </div>
      </div>
      <div className="row-content space-between">
        <label className="large-label">{`${shortenText(
          t("height"),
          QUESTION_MAX_STRING,
          true,
          1
        )}:`}</label>
        <div className="endRow">
          <UnstyledSelectBasic
            options={heights}
            defaultOption={height}
            onChange={handleHeight}
          />
          <InfoTooltip text={t("height-info")} />
        </div>
      </div>
      <div className="row-content space-between no-margin-bottom">
        <label className="large-label">{`${shortenText(
          t("weight-unit"),
          QUESTION_MAX_STRING,
          true,
          1
        )}:`}</label>
        <div className="endRow">
          <ToggleButtonGroup
            className="row-content"
            value={weightUnit}
            exclusive
            onChange={handleWeightUnit}
            aria-label="weight unit"
          >
            <ToggleButton
              className="large-rectangle-no-color"
              value={POUNDS}
              aria-label={POUNDS}
            >
              {POUNDS}
            </ToggleButton>
            <ToggleButton
              className="large-rectangle-no-color"
              value={KILOGRAMS}
              aria-label={KILOGRAMS}
            >
              {KILOGRAMS}
            </ToggleButton>
            <ToggleButton
              className="large-rectangle-no-color"
              value={STONES}
              aria-label={STONES}
            >
              {STONES}
            </ToggleButton>
          </ToggleButtonGroup>
          <InfoTooltip text={t("weight-unit-info")} />
        </div>
      </div>
      <div className="row-content space-between">
        <label className="large-label">{`${shortenText(
          t("weight"),
          QUESTION_MAX_STRING,
          true,
          1
        )}:`}</label>
        <div className="endRow">
          <UnstyledSelectBasic
            options={weights}
            defaultOption={weight}
            onChange={handleWeight}
          />
          <InfoTooltip text={t("weight-unit-info")} />
        </div>
      </div>
      <div className="row-content space-between">
        <label className="large-label">{`${shortenText(
          t("language"),
          QUESTION_MAX_STRING,
          true,
          1
        )}:`}</label>
        <div className="endRow">
          <UnstyledSelectBasic
            options={languages}
            defaultOption={language}
            onChange={handleLanguage}
          />
          <InfoTooltip text={t("language-info", { varApp: APP_NAME })} />
        </div>
      </div>
      {warning && (
        <div className="row-content">
          <div className="warningText">{warning}</div>
        </div>
      )}
      <div className="row-content">
        <button className="large-rectangle" onClick={onRegisterPress}>
          {shortenText(t("confirm"), LARGE_BTN_MAX_STRING, true, 0, true, true)}
        </button>
      </div>
    </div>
  );

  return (
    <div className="page-wrapper">
      <Header logout={true} />
      <main className="menu-page">
        <Section header={t("mast-info-request-s1-head", { varApp: APP_NAME })}>
          <Mast type={"basicInfo"} />
        </Section>
        <Section header={t("mast-info-request-s2-head")}>{Required}</Section>
      </main>
      <Footer />
    </div>
  );
}
