import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ActiveWorkout from "./ActiveWorkout";
import InactiveWorkout from "./InactiveWorkout";
import WorkoutPlans from "./WorkoutPlans";
import WorkoutStats from "./WorkoutStats";
import {
  ACTIVE_WORKOUT_CONTEXT_BUTTONS,
  WORKOUT_ACTIVE_BUTTONS,
  WORKOUT_INACTIVE_BUTTONS,
  WORKOUT_PLAN_BUTTONS,
  WORKOUT_STATS_CONTEXT_BUTTONS,
  WORKOUT_STATS_TIMELINE_BUTTONS,
} from "../../../Supports/Buttons";
import Card from "../../Structural/Card/Card";
import CardContent from "../../Structural/Card/CardContent";
import { ARROW_SYM, INFO_BANNER_MAX_STRING } from "../../../Supports/Constants";
import {
  deleteCollection,
  getTodayDate,
  shortenText,
  updateVal,
} from "../../../Supports/Functions";
import {
  generateWorkoutActiveItem,
  generateWorkoutPlanHistoryRecord,
} from "../../../Supports/Templates";
import exercises from "../../../Assets/datasets/exercises.json";
import { DataContext, ThemeContext } from "../../../Supports/Contexts";
import {
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDocs,
  getFirestore,
  setDoc,
} from "firebase/firestore";

export default function WorkoutCard(props) {
  const { t } = useTranslation();
  const Data = useContext(DataContext);
  const theme = useContext(ThemeContext);
  const isDarkTheme = theme.theme !== "light";
  const workout = props.userData.workout;
  const weightLog = Data.weightLog;
  const activeDate = getTodayDate();
  const planSizes = useMemo(() => workout.planSizes, [workout.planSizes]);
  const [todayLog, setTodayLog] = useState(null);

  useEffect(() => {
    !Data.loadingWeightLog &&
      setTodayLog(
        weightLog[`${activeDate.year}-${activeDate.month}-${activeDate.day}`]
      );
  }, [
    weightLog,
    Data.loadingWeightLog,
    activeDate.year,
    activeDate.month,
    activeDate.day,
  ]);

  const [plans, setPlans] = useState(null);

  const [cache, setCache] = useState({
    page: workout?.cache?.page || 0,
    preppedPlan: workout?.cache?.preppedPlan || null,
    selectedPlan: workout?.cache?.selectedPlan || null,
    selectedPlanHasExercises: workout?.cache?.selectedPlanHasExercises,
    activePlan: workout?.cache?.activePlan,
    activeItem: workout?.cache?.activeItem || generateWorkoutActiveItem(),
    activeItemStatus: workout?.cache?.activeItemStatus || null,
    sessionID: workout?.cache?.sessionID || 0,
    skipped: workout?.cache?.skipped || false,
    completed: workout?.cache?.completed || null,
    lastCompleted: workout?.cache?.lastCompleted || null,
    manualPrepped: workout?.cache?.manualPrepped || false,
    activeItemHasGoal: workout?.cache?.activeItemHasGoal || false,
    itemStates: workout?.cache?.itemStates || null,
    activeItemGoal: workout?.cache?.activeItemGoal || null,
  });
  const [rest, setRest] = useState(0);
  const [cardioTimer, setCardioTimer] = useState(0);

  const [selectedPlanItems, setSelectedPlanItems] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedPlanHistory, setSelectedPlanHistory] = useState([]);
  const [loadingSelectedPlan, setLoadingSelectedPlan] = useState(true);

  useEffect(() => {
    if (cache.selectedPlan !== null && !Number.isNaN(cache.selectedPlan)) {
      getDocs(
        collection(
          getFirestore(),
          "UserData",
          props.userData.id,
          "WorkoutPlans",
          cache.selectedPlan,
          "PlanItems"
        )
      )
        .then((querySnapshot) => {
          if (!querySnapshot) {
            return;
          }

          const collect = [];
          const promises = [];
          querySnapshot.forEach((doc, inx) => {
            const item = doc.data();
            collect[doc.id] = item;
            promises.push(
              getDocs(
                collection(
                  getFirestore(),
                  "UserData",
                  props.userData.id,
                  "WorkoutPlans",
                  cache.selectedPlan,
                  "PlanItems",
                  (item.order - 1).toString(),
                  "ItemHistory"
                )
              ).then((itemHistoryQuery) => {
                if (itemHistoryQuery) {
                  const collectHistoryRecords = [];
                  itemHistoryQuery.forEach((itemHistoryRecord) => {
                    collectHistoryRecords.push(itemHistoryRecord.data());
                  });
                  collect[doc.id].history = collectHistoryRecords;
                }
              })
            );
          });

          return Promise.all(promises).then(
            setSelectedPlanItems(Object.values(collect))
          );
        })
        .then(
          getDocs(
            collection(
              getFirestore(),
              "UserData",
              props.userData.id,
              "WorkoutPlans",
              cache.selectedPlan,
              "PlanHistory"
            )
          ).then((querySnapshot) => {
            if (querySnapshot) {
              const collect = [];
              querySnapshot.forEach((doc) => {
                collect[doc.id] = doc.data();
              });

              setSelectedPlanHistory(Object.values(collect));
            }
          })
        )
        .then(setLoadingSelectedPlan(false));
    } else {
      setLoadingSelectedPlan(false);
    }
  }, [cache.selectedPlan, props.userData.id, loadingSelectedPlan]);

  const [preppedPlanItems, setPreppedPlanItems] = useState([]);
  const [preppedPlanHistory, setPreppedPlanHistory] = useState([]);
  const [loadingPreppedPlan, setLoadingPreppedPlan] = useState(true);

  useEffect(() => {
    if (cache.preppedPlan !== null && !Number.isNaN(cache.preppedPlan)) {
      getDocs(
        collection(
          getFirestore(),
          "UserData",
          props.userData.id,
          "WorkoutPlans",
          cache.preppedPlan,
          "PlanItems"
        )
      )
        .then((querySnapshot) => {
          if (!querySnapshot) {
            return;
          }

          const collect = [];
          const promises = [];
          querySnapshot.forEach((doc) => {
            const item = doc.data();
            collect[doc.id] = item;
            promises.push(
              getDocs(
                collection(
                  getFirestore(),
                  "UserData",
                  props.userData.id,
                  "WorkoutPlans",
                  cache.preppedPlan,
                  "PlanItems",
                  (item.order - 1).toString(),
                  "ItemHistory"
                )
              ).then((itemHistoryQuery) => {
                if (itemHistoryQuery) {
                  const collectHistoryRecords = [];
                  itemHistoryQuery.forEach((itemHistoryRecord) => {
                    collectHistoryRecords.push(itemHistoryRecord.data());
                  });
                  collect[doc.id].history = collectHistoryRecords;
                }
              })
            );
          });

          return Promise.all(promises).then(
            setPreppedPlanItems(Object.values(collect))
          );
        })
        .then(
          getDocs(
            collection(
              getFirestore(),
              "UserData",
              props.userData.id,
              "WorkoutPlans",
              cache.preppedPlan,
              "PlanHistory"
            )
          ).then((querySnapshot) => {
            if (querySnapshot) {
              const collect = [];
              querySnapshot.forEach((doc) => {
                collect[doc.id] = doc.data();
              });

              setPreppedPlanHistory(Object.values(collect));
            }
          })
        )
        .then(setLoadingPreppedPlan(false));
    } else {
      setLoadingPreppedPlan(false);
    }
  }, [cache.preppedPlan, props.userData.id, loadingPreppedPlan]);

  const [activePlanItems, setActivePlanItems] = useState([]);
  const [activePlanHistory, setActivePlanHistory] = useState([]);
  const [loadingActivePlan, setloadingActivePlan] = useState(true);

  useEffect(() => {
    if (cache.activePlan !== null && !Number.isNaN(cache.activePlan)) {
      getDocs(
        collection(
          getFirestore(),
          "UserData",
          props.userData.id,
          "WorkoutPlans",
          cache.activePlan,
          "PlanItems"
        )
      )
        .then((querySnapshot) => {
          if (!querySnapshot) {
            return;
          }

          const collect = [];
          const promises = [];
          querySnapshot.forEach((doc) => {
            const item = doc.data();
            collect[doc.id] = item;
            promises.push(
              getDocs(
                collection(
                  getFirestore(),
                  "UserData",
                  props.userData.id,
                  "WorkoutPlans",
                  cache.activePlan,
                  "PlanItems",
                  (item.order - 1).toString(),
                  "ItemHistory"
                )
              ).then((itemHistoryQuery) => {
                if (itemHistoryQuery) {
                  const collectHistoryRecords = [];
                  itemHistoryQuery.forEach((itemHistoryRecord) => {
                    collectHistoryRecords.push(itemHistoryRecord.data());
                  });
                  collect[doc.id].history = collectHistoryRecords;
                }
              })
            );
          });

          return Promise.all(promises).then(
            setActivePlanItems(Object.values(collect))
          );
        })
        .then(
          getDocs(
            collection(
              getFirestore(),
              "UserData",
              props.userData.id,
              "WorkoutPlans",
              cache.activePlan,
              "PlanHistory"
            )
          ).then((querySnapshot) => {
            if (querySnapshot) {
              const collect = [];
              querySnapshot.forEach((doc) => {
                collect[doc.id] = doc.data();
              });

              setActivePlanHistory(Object.values(collect));
            }
          })
        )
        .then(setloadingActivePlan(false));
    } else {
      setloadingActivePlan(false);
    }
  }, [cache.activePlan, props.userData.id, loadingActivePlan]);

  useEffect(() => {
    if (!Data.loadingWorkoutPlans && !loadingActivePlan) {
      setPlans(Data.workoutPlans);
      setRest(
        (cache.activePlan &&
          cache.activeItem &&
          Data.workoutPlans[cache.activePlan] !== undefined &&
          activePlanItems &&
          activePlanItems[cache.activeItem.item]?.rest) ||
          0
      );
      setCardioTimer(
        (cache.activePlan &&
          cache.activeItem &&
          Data.workoutPlans[cache.activePlan] !== undefined &&
          activePlanItems &&
          activePlanItems[cache.activeItem.item]?.durationPerLap) ||
          0
      );
    }
  }, [
    Data.loadingWorkoutPlans,
    Data.workoutPlans,
    activePlanItems,
    cache.activeItem,
    cache.activePlan,
    loadingActivePlan,
    plans,
  ]);

  const userSex = props.userData.body.sex;
  const settings = workout?.settings;

  const [viewedExercise, setViewedExercise] = useState(null);
  const [planModalType, setPlanModalType] = useState(null);

  const [activeStatExercise, setActiveStatExercise] = useState(null);
  const [statTimeline, setStatTimeline] = useState("2W");
  const [statContext, setStatContext] = useState(null);

  const categories = [
    "cardio",
    "olympic weightlifting",
    "plyometrics",
    "powerlifting",
    "strength",
    "stretching",
    "strongman",
  ];

  const equipment = [
    "bands",
    "barbell",
    "body only",
    "cable",
    "dumbbell",
    "e-z curl bar",
    "exercise ball",
    "foam roll",
    "kettlebells",
    "machine",
    "medicine ball",
    null,
    "other",
  ];

  const mechanics = ["compound", "isolation", null];

  const primaryMuscles = [
    "abdominals",
    "abductors",
    "adductors",
    "biceps",
    "calves",
    "chest",
    "forearms",
    "glutes",
    "hamstrings",
    "lats",
    "lower back",
    "middle back",
    "neck",
    "quadriceps",
    "shoulders",
    "traps",
    "triceps",
  ];

  const secondaryMuscles = [
    "abdominals",
    "abductors",
    "adductors",
    "biceps",
    "calves",
    "chest",
    "forearms",
    "glutes",
    "hamstrings",
    "lats",
    "lower back",
    "middle back",
    "neck",
    "quadriceps",
    "shoulders",
    "traps",
    "triceps",
  ];

  const [exerciseIndex] = useState(
    [
      ...new Set(
        exercises.exercises.map((item, index) => {
          return {
            name: item.name,
            lookup: index,
            tokens: item.name
              .replace(/[&/\\#,+()$~%.'":*?<>{}-]/g, " ")
              .split(" "),
          };
        })
      ),
    ].sort()
  );

  const prebuiltExerciseData = {
    exercises: exercises.exercises,
    categories: categories,
    equipment: equipment,
    mechanics: mechanics,
    primaryMuscles: primaryMuscles,
    secondaryMuscles: secondaryMuscles,
    exerciseIndex: exerciseIndex,
  };

  // useEffect(() => {
  //   const categories = [...new Set(exercises.exercises.map((item) => item.category))].sort();
  //   const equipment = [...new Set(exercises.exercises.map((item) => item.equipment))].sort();
  //   const names = [...new Set(exercises.exercises.map((item) => item.name))].sort();
  //   const mechanics = [...new Set(exercises.exercises.map((item) => item.mechanic))].sort();

  //   const primaryMuscles = [
  //     ...new Set(exercises.exercises.map((item) => item.primaryMuscles).flat(Infinity)),
  //   ].sort();
  //   const secondaryMuscles = [
  //     ...new Set(exercises.exercises.map((item) => item.secondaryMuscles).flat(Infinity)),
  //   ].sort();
  // }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      rest > 0 && setRest(rest - 1);
    }, 1000 * 1); // Once every second, tick rest down, stopping at zero. Will start up again if rest is topped off.

    return () => {
      clearInterval(interval);
    };
  }, [rest, setRest]);

  useEffect(() => {
    const interval = setInterval(() => {
      rest <= 0 && cardioTimer > 0 && setCardioTimer(cardioTimer - 1);
    }, 1000 * 1); // Once every second, tick cardiotimer down, stopping at zero. Will start up again if cardiotimer is topped off. Won't tick if rest isn't zero.

    return () => {
      clearInterval(interval);
    };
  }, [cardioTimer, rest, setCardioTimer]);

  if (
    !props.userData ||
    !props.userData.body.age ||
    Data.loadingWorkoutPlans ||
    Data.loadingWeightLog ||
    loadingActivePlan ||
    loadingSelectedPlan ||
    loadingPreppedPlan ||
    !plans
  ) {
    return <></>;
  }

  const genGoal = (target, items = activePlanItems) => {
    if (!items) {
      return null;
    }

    const targetItem = items[target];

    const lastHistoryRec = targetItem.history
      ? targetItem?.history[
          targetItem?.history.findLastIndex((item) => {
            return item.status !== "SKIP";
          })
        ]
      : null; // Finds the last item that wasn't SKIP.

    if (!lastHistoryRec && targetItem.goalType === "set") {
      return null;
    }

    if (targetItem.hasGoal) {
      const lastStatus = lastHistoryRec?.status;

      const lastValue =
        targetItem.goalType === "set"
          ? lastHistoryRec.weight
          : targetItem.goalType === "distance"
          ? lastHistoryRec?.distanceGoal || targetItem.distancePerLap
          : lastHistoryRec?.durationGoal || targetItem.durationPerLap;

      const progRate =
        targetItem.goalType === "set"
          ? settings.progressionRateSet
          : targetItem.goalType === "distance"
          ? settings.progressionRateDistance
          : settings.progressionRateDuration;

      const valueUnit =
        targetItem.goalType === "set"
          ? lastHistoryRec.unit
          : targetItem.goalType === "distance"
          ? lastHistoryRec?.unit || targetItem.distanceUnit
          : lastHistoryRec?.unit || targetItem.durationUnit;

      if (lastStatus === "UP") {
        return {
          value: lastValue + progRate,
          unit: valueUnit,
        };
      } else if (lastStatus === "DOWN") {
        return {
          value: lastValue - progRate,
          unit: valueUnit,
        };
      } else {
        return { value: lastValue, unit: valueUnit };
      }
    }
  };

  const updatePlan = (newPlan, isDeletePlan = false) => {
    if (!newPlan) {
      return;
    }

    if (isDeletePlan) {
      delete plans[newPlan.id];
      delete planSizes[newPlan.id];

      return deleteDoc(
        doc(
          getFirestore(),
          `UserData/${props.userData.id}/WorkoutPlans`,
          newPlan.id
        )
      )
        .then(() => {
          const promises = [];
          for (let i = 0; i < Object.keys(selectedPlanItems).length; i++) {
            promises.push(
              deleteCollection(
                getFirestore(),
                `UserData/${props.userData.id}/WorkoutPlans/${
                  newPlan.id
                }/PlanItems/${i.toString()}/ItemHistory`
              )
            );
          }

          return Promise.all(promises);
        })
        .then(
          deleteCollection(
            getFirestore(),
            `UserData/${props.userData.id}/WorkoutPlans/${newPlan.id}/PlanItems`
          )
        )
        .then(
          deleteCollection(
            getFirestore(),
            `UserData/${props.userData.id}/WorkoutPlans/${newPlan.id}/PlanHistory`
          )
        )
        .then(
          updateVal(
            {
              [`workout.planSizes.${newPlan.id}`]: deleteField(),
            },
            props.userData.id
          )
        )
        .then(() => {
          const firstPlanId = plans[Object.keys(plans)[0]]?.id?.toString();
          updateCache({
            selectedPlan: firstPlanId || null,
            selectedPlanHasExercises: planSizes[firstPlanId] > 0 || false,
            preppedPlan:
              cache.preppedPlan === newPlan.id ? null : cache.preppedPlan,
          });
        });
    } else {
      const tempItems = newPlan.items;
      const tempHistory = newPlan.history;
      planSizes[newPlan.id] = Object.keys(tempItems)?.length || 0;
      delete newPlan.items;
      delete newPlan.history;
      plans[newPlan.id] = newPlan;

      return setDoc(
        doc(
          getFirestore(),
          `UserData/${props.userData.id}/WorkoutPlans`,
          newPlan.id
        ),
        plans[newPlan.id]
      )
        .then(
          updateVal(
            {
              [`workout.planSizes.${newPlan.id}`]: planSizes[newPlan.id],
            },
            props.userData.id
          )
        )
        .then(() => {
          if (tempHistory?.length > 0) {
            const promises = [];
            tempHistory.forEach((item, inx) => {
              promises.push(
                setDoc(
                  // FUTURE: Do we need to redo every history, or can we just add the latest?
                  doc(
                    getFirestore(),
                    `UserData/${props.userData.id}/WorkoutPlans/${newPlan.id}/PlanHistory`,
                    inx.toString()
                  ),
                  item
                )
              );
            });

            return Promise.all(promises);
          }
        })
        .then(() => {
          if (tempItems?.length > 0) {
            const promises = [];
            tempItems.forEach((item, inx) => {
              const tempItemHistory = item.history;
              const index = inx.toString();
              delete item.history;

              promises.push(
                setDoc(
                  doc(
                    getFirestore(),
                    `UserData/${props.userData.id}/WorkoutPlans/${newPlan.id}/PlanItems`,
                    index
                  ),
                  item
                ).then(
                  tempItemHistory &&
                    tempItemHistory.length > 0 &&
                    setDoc(
                      doc(
                        getFirestore(),
                        `UserData/${props.userData.id}/WorkoutPlans/${newPlan.id}/PlanItems/${index}/ItemHistory`,
                        (tempItemHistory.length - 1).toString()
                      ),
                      tempItemHistory[tempItemHistory.length - 1]
                    )
                )
              );
            });

            return Promise.all(promises).then(setLoadingSelectedPlan(true));
          }
        })
        .then(() => {
          if (
            tempItems?.length &&
            Object.keys(selectedPlanItems).length > tempItems.length
          ) {
            const promises = [];
            // if there used to be more items in the plan, but now there's less. the extras need to be manually removed from the subcollection.
            for (
              let i = tempItems.length;
              i < Object.keys(selectedPlanItems).length;
              i++
            ) {
              promises.push(
                deleteCollection(
                  getFirestore(),
                  `UserData/${props.userData.id}/WorkoutPlans/${
                    newPlan.id
                  }/PlanItems/${i.toString()}/ItemHistory`
                ).then(
                  deleteDoc(
                    doc(
                      getFirestore(),
                      `UserData/${props.userData.id}/WorkoutPlans/${newPlan.id}/PlanItems`,
                      i.toString()
                    )
                  )
                )
              );
            }

            return Promise.all(promises).then(setLoadingSelectedPlan(true));
          }
        });
    }
  };

  const updateCache = (newCacheItems, updateDB = true) => {
    setCache({
      ...cache,
      ...newCacheItems,
    });

    workout.cache = {
      ...cache,
      ...newCacheItems,
    };

    if (updateDB) {
      return updateVal(
        {
          "workout.cache": workout.cache,
        },
        props.userData.id
      );
    }
    return;
  };

  const prepPlan = (target, manual = false) => {
    const targetString = target?.toString();
    if (!targetString) {
      updateCache({
        selectedPlanHasExercises: selectedPlanItems.length > 0 || false,
        sessionID: null,
        preppedPlan: null,
        manualPrepped: manual,
        activePlan: null,
        activeItemStatus: null,
        skipped: false,
        itemStates: null,
      });
    } else {
      getDocs(
        collection(
          getFirestore(),
          "UserData",
          props.userData.id,
          "WorkoutPlans",
          targetString,
          "PlanItems"
        )
      ).then((planItemsSnapshot) => {
        if (!planItemsSnapshot) {
          return;
        }

        const planItems = [];
        planItemsSnapshot.forEach((doc) => {
          planItems[doc.id] = doc.data();
        });

        getDocs(
          collection(
            getFirestore(),
            "UserData",
            props.userData.id,
            "WorkoutPlans",
            targetString,
            "PlanHistory"
          )
        ).then((planHistorySnapshot) => {
          const planHistory = [];
          planHistorySnapshot.forEach((doc) => {
            planHistory[doc.id] = doc.data();
          });

          const sessionID = planHistory.length;

          setDoc(
            doc(
              getFirestore(),
              `UserData/${props.userData.id}/WorkoutPlans/${targetString}/PlanHistory`,
              planHistory.length.toString()
            ),
            generateWorkoutPlanHistoryRecord(sessionID)
          )
            .then(setLoadingSelectedPlan(true))
            .then(
              updateCache({
                sessionID: sessionID,
                preppedPlan: targetString,
                manualPrepped: manual,
                activePlan: null,
                activeItemStatus: null,
                skipped: false,
                itemStates: new Array(planItems.length).fill(false),
                selectedPlanHasExercises: planItems.length > 0,
              })
            )
            .then(setLoadingPreppedPlan(true));
        });
      });
    }
  };

  const getNextPreppedPlan = () => {
    const keys = Object.keys(plans);
    let next = null;
    for (let planInd = 0; planInd < keys.length; planInd++) {
      const plan = plans[keys[planInd]];

      if (
        !next &&
        plan.nextDate?.json < next?.nextDate?.json &&
        plan.lastDate.date !== getTodayDate().date &&
        planSizes[plan.id] > 0
      ) {
        next = plan;
      }
    }
    return next ? next.id : null;
  };

  const formatDuration = (dur) => {
    const hdur = Math.floor(dur / 3600);
    const mdur = Math.floor((dur % 3600) / 60);
    const sdur = Math.floor(dur % 60);
    return hdur <= 0 && mdur <= 0
      ? `${sdur}${t("second-short")}`
      : hdur <= 0
      ? `${mdur}${t("minute-short")} ${sdur}${t("second-short")}`
      : `${hdur}${t("hour-short")} ${mdur}${t("minute-short")}`;
  };

  const generateInfo = () => {
    const processInfo = (status, data) => {
      return `${status} ${
        data?.duration ? "| " + formatDuration(data.duration) + " " : ""
      }| ${
        data?.name.replace(/(.{8})..+/, "$1...") ||
        t("no-information-available-short")
      }`;
    };

    let display = t("no-information-available");
    if (cache.activePlan) {
      display = processInfo(t("active"), plans[cache.activePlan]);
    } else if (cache.preppedPlan) {
      display = processInfo(
        cache.skipped ? t("skipped") : t("prepared-full"),
        plans[cache.preppedPlan]
      );
    } else if (cache.completed) {
      display = processInfo(t("completed"), cache.completed);
    } else if (Object.keys(plans).length <= 0) {
      display = shortenText(t("c-wot-ban-noplan", { aroSym: ARROW_SYM }), 30);
    } else if (!cache.selectedPlan) {
      display = t("c-wot-ban-noselect", { aroSym: ARROW_SYM });
    } else if (cache.selectedPlan) {
      if (selectedPlanItems.length > 0) {
        display = t("c-wot-ban-noprep", { aroSym: ARROW_SYM });
      } else {
        display = t("c-wot-ban-noitems", { aroSym: ARROW_SYM });
      }
    }

    return shortenText(display, INFO_BANNER_MAX_STRING - 6);
  };

  const visPlan = (internalProps) => (
    <WorkoutPlans visualizationData={internalProps.visualizationData} />
  );

  const visSettings = (internalProps) => (
    <WorkoutStats visualizationData={internalProps.visualizationData} />
  );

  const visWorkout = (internalProps) =>
    cache.activePlan && plans && plans[cache.activePlan] ? (
      <ActiveWorkout visualizationData={internalProps.visualizationData} />
    ) : (
      <InactiveWorkout visualizationData={internalProps.visualizationData} />
    );

  const isWorkoutActive =
    cache.activePlan &&
    plans &&
    plans[cache.activePlan] &&
    activePlanItems.length > 0;
  // const isWorkoutPrepared = cache.preppedPlan && plans && plans[cache.preppedPlan];

  return (
    <Card
      name="workout"
      id="workout"
      header={t("ct-wot")}
      info={generateInfo()}
      leftButtonTitle={t("cb-wot-l")}
      rightButtonTitle={t("cb-fst-r")} // NOTE: Using same as fast 'stats'.
      setActiveCardStates={props.setActiveCardStates}
      activeCardStates={props.activeCardStates}
    >
      <CardContent
        isVisualizationActive={true}
        visualizationType={visPlan}
        visualizationData={{
          id: props.userData.id,
          plans: plans,
          cache: cache,
          updatePlan: updatePlan,
          updateCache: updateCache,
          isDarkTheme: isDarkTheme,
          viewedExercise: viewedExercise,
          setViewedExercise: setViewedExercise,
          modalVisible: props.modalVisible,
          setModalVisible: props.setModalVisible,
          prebuiltExerciseData: prebuiltExerciseData,
          planModalType: planModalType,
          setPlanModalType: setPlanModalType,
          selectedPlanItems: selectedPlanItems,
          activePlanItems: activePlanItems,
          preppedPlanItems: preppedPlanItems,
          planSizes: planSizes,
        }}
        settings={settings}
        setModalVisible={props.setModalVisible}
        modalVisible={props.modalVisible}
        interactionTitle={t("c-wot-l-int-head")}
        interactions={WORKOUT_PLAN_BUTTONS}
        preppedPlan={cache.preppedPlan}
        selectedPlan={cache.selectedPlan}
        isSelectedPrepped={cache.preppedPlan === cache.selectedPlan}
        selectedPlanName={plans[cache.selectedPlan]?.name}
        exercises={selectedPlanItems}
        userData={props.userData}
        editTarget={plans[cache.selectedPlan]}
        plans={plans}
        selectedPlanItems={selectedPlanItems}
        prebuiltExerciseData={prebuiltExerciseData}
        viewedExercise={viewedExercise}
        setViewedExercise={setViewedExercise}
        selectedPlanHasExercises={cache.selectedPlanHasExercises}
        planModalType={planModalType}
        setPlanModalType={setPlanModalType}
        customInteractionFuncs={{
          PREP_OR_UNPREP: () => {
            if (
              cache.selectedPlan === cache.preppedPlan &&
              plans[cache.preppedPlan]
            ) {
              const tempPlan = plans[cache.preppedPlan];
              try {
                preppedPlanHistory.pop();
                tempPlan.history = preppedPlanHistory;
              } catch (e) {
                console.warn(e);
              }
              tempPlan.items = preppedPlanItems;

              updatePlan(tempPlan).then(
                updateCache(
                  {
                    sessionID: null,
                    preppedPlan: null,
                    manualPrepped: false,
                    skipped: false,
                  },
                  false
                )
              );
            } else {
              prepPlan(cache.selectedPlan, true);
            }
          },
          VIEWPLAN: () => {
            // because in view plan, you can prep/unprep things.
            if (
              cache.selectedPlan === cache.preppedPlan &&
              plans[cache.preppedPlan]
            ) {
              const tempPlan = plans[cache.preppedPlan];
              try {
                preppedPlanHistory.pop();
                tempPlan.history = preppedPlanHistory;
              } catch (e) {
                console.warn(e);
              }
              tempPlan.items = preppedPlanItems;
              updatePlan(tempPlan).then(
                updateCache(
                  {
                    sessionID: null,
                    preppedPlan: null,
                    manualPrepped: false,
                    skipped: false,
                  },
                  false
                )
              );
            } else {
              prepPlan(cache.selectedPlan, true);
            }
          },
          NEWPLAN: (v) => updatePlan(v).then(prepPlan(getNextPreppedPlan())),
          EDITPLAN: (v) => updatePlan(v).then(prepPlan(getNextPreppedPlan())),
          DELETEPLAN: (v) => {
            if (cache.selectedPlan) {
              updatePlan(plans[v], true).then(
                updateCache({
                  selectedPlan: null,
                  selectedPlanHasExercises: false,
                  preppedPlan:
                    cache.preppedPlan === v.toString()
                      ? null
                      : cache.preppedPlan,
                  page: 0,
                  activePlan:
                    cache.activePlan === v.toString() ? null : cache.activePlan,
                })
              );
            }
          },
        }}
      />
      <CardContent
        id={props.userData.id}
        headerTitle="Workout"
        isVisualizationActive={true}
        visualizationType={visWorkout}
        preppedPlan={cache.preppedPlan}
        plans={plans}
        selectedPlanItems={selectedPlanItems}
        activePlanItems={activePlanItems}
        preppedPlanItems={preppedPlanItems}
        exercises={selectedPlanItems}
        prebuiltExerciseData={prebuiltExerciseData}
        viewedExercise={viewedExercise}
        setViewedExercise={setViewedExercise}
        selectedPlanHasExercises={cache.selectedPlanHasExercises}
        planModalType={planModalType}
        setPlanModalType={setPlanModalType}
        setModalVisible={props.setModalVisible}
        modalVisible={props.modalVisible}
        skipped={cache.skipped}
        activeItemStatus={cache.activeItemStatus}
        isContextActive={cache.activePlan}
        contextTitle={
          activePlanItems && isWorkoutActive
            ? `${activePlanItems[cache.activeItem.item].name}`
            : null
        }
        contextInteractions={isWorkoutActive && ACTIVE_WORKOUT_CONTEXT_BUTTONS}
        customContextFuncs={{
          WO_CNTX_GOAL: () => props.setModalVisible("WO_CNTX_GOAL"),
          WO_CNTX_INFO: () => {
            setViewedExercise(activePlanItems[cache.activeItem.item]);
            props.setModalVisible("WO_CNTX_INFO-ACTIVE");
          },
          WO_CNTX_TIMER: () => {
            setRest(0);
            setCardioTimer(
              cache.activeItemGoal.value ||
                activePlanItems[cache.activeItem.item].durationPerLap
            );
          },
          WO_CNTX_REST: () => {
            setRest(activePlanItems[cache.activeItem.item].rest || 30);
            activePlanItems[cache.activeItem.item].goalType === "duration" &&
              setCardioTimer(
                cache.activeItemGoal.value ||
                  activePlanItems[cache.activeItem.item].durationPerLap
              );
          },
        }}
        interactionTitle={() =>
          isWorkoutActive
            ? `${t("c-wot-m-interaction-head-active")}: ${
                cache.activeItemStatus === null
                  ? t("required")
                  : cache.activeItemStatus
              }`
            : t("c-wot-inactive-interaction-title")
        }
        interactions={
          isWorkoutActive ? WORKOUT_ACTIVE_BUTTONS : WORKOUT_INACTIVE_BUTTONS
        }
        // interactionsDisabledCheck={{
        //   check: () => !(isWorkoutActive || (!isWorkoutActive && isWorkoutPrepared)),
        //   disabledMessage: t('c-wot-m-int-stub'),
        // }}
        customInteractionFuncs={{
          // Inactive Workout
          START_WORKOUT: (v) => {
            let inPreppedPlanHistory = preppedPlanHistory.pop();
            if (!inPreppedPlanHistory) {
              inPreppedPlanHistory = generateWorkoutPlanHistoryRecord(
                cache.sessionID
              );
            }

            inPreppedPlanHistory.status = "activated";
            inPreppedPlanHistory.startTimeStamp = getTodayDate();
            preppedPlanHistory.push(inPreppedPlanHistory);
            const tempPlan = plans[cache.preppedPlan];
            tempPlan.items = preppedPlanItems; // Temp insert
            tempPlan.history = preppedPlanHistory; // Temp insert

            updatePlan(tempPlan)
              .then(
                updateCache({
                  activePlan: cache.preppedPlan,
                  activeItemHasGoal: preppedPlanItems[0]?.hasGoal,
                  completed: false,
                  activeItem: generateWorkoutActiveItem(),
                  lastCompletedItem: null,
                  activeItemGoal: genGoal(0, preppedPlanItems),
                  itemStates: new Array(preppedPlanItems.length).fill(false),
                })
              )
              .then(setLoadingPreppedPlan(true))
              .then(setloadingActivePlan(true));
          },
          VIEWPLAN_INACTIVE: () => {
            // because in view plan, you can prep / unprep things.
            if (plans[cache.preppedPlan]) {
              const tempPlan = plans[cache.preppedPlan];
              try {
                preppedPlanHistory.pop();
                tempPlan.history = preppedPlanHistory; // Temp insert.
              } catch (e) {
                console.warn(e);
              }
              tempPlan.items = preppedPlanItems;

              updatePlan(tempPlan).then(
                updateCache(
                  {
                    sessionID: null,
                    preppedPlan: null,
                    manualPrepped: false,
                    skipped: false,
                  },
                  false
                )
              );
            } else {
              prepPlan(cache.selectedPlan, true);
            }
          },
          // Active Workout
          STAY_STATUS: () => updateCache({ activeItemStatus: "STAY" }, false),
          UP_STATUS: () => updateCache({ activeItemStatus: "UP" }, false),
          SKIP_STATUS: () => updateCache({ activeItemStatus: "SKIP" }, false),
          DOWN_STATUS: () => updateCache({ activeItemStatus: "DOWN" }, false),
          DNF_STATUS: () => updateCache({ activeItemStatus: "DNF" }, false),
        }}
        rest={rest}
        activeItemHasGoal={cache.activeItemHasGoal}
        activeItemHasTimer={
          activePlanItems[cache.activeItem.item]?.goalType === "duration"
        }
        settings={settings}
        visualizationData={{
          settings: settings,
          id: props.userData.id,
          plans: plans,
          activePlanItems: activePlanItems,
          preppedPlanItems: preppedPlanItems,
          activePlanHistory: activePlanHistory,
          setActivePlanHistory: setActivePlanHistory,
          cache: cache,
          preppedPlan: plans[cache.preppedPlan],
          activePlan: plans[cache.activePlan],
          activeItem: cache.activeItem,
          activeItemHasTimer:
            activePlanItems[cache.activeItem.item]?.goalType === "duration",
          activeItemStatus: cache.activeItemStatus,
          lastCompletedPlan: cache.lastCompleted,
          setRest: setRest,
          rest: rest,
          cardioTimer: cardioTimer,
          setCardioTimer: setCardioTimer,
          updateCache: updateCache,
          updatePlan: updatePlan,
          sessionID: cache.sessionID,
          setModalVisible: props.setModalVisible,
          modalVisible: props.modalVisible,
          getNextPreppedPlan: getNextPreppedPlan,
          prepPlan: prepPlan,
          manualPrepped: cache.manualPrepped,
          setExpanded: props.setExpanded,
          activeWorkoutGoal: cache.activeItemGoal,
          genGoal: genGoal,
          itemStates: cache.itemStates,
          isDarkTheme: isDarkTheme,
          progressionRateSet: settings.progressionRateSet,
          progressionRateDistance: settings.progressionRateDistance,
          progressionRateDuration: settings.progressionRateDuration,
          userWeight: todayLog,
          userSex: userSex,
          viewedExercise: viewedExercise,
          setViewedExercise: setViewedExercise,
          prebuiltExerciseData: prebuiltExerciseData,
          planModalType: planModalType,
          setPlanModalType: setPlanModalType,
          setActiveCardStates: props.setActiveCardStates,
          activeCardStates: props.activeCardStates,
          planSizes: planSizes,
        }}
      />
      <CardContent
        isVisualizationActive={true}
        visualizationType={visSettings}
        prebuiltExerciseData={prebuiltExerciseData}
        viewedExercise={viewedExercise}
        setViewedExercise={setViewedExercise}
        statContext={statContext}
        hasSetHistory={
          (activeStatExercise && activeStatExercise.hasSetHistory) || false
        }
        hasDistanceHistory={
          (activeStatExercise && activeStatExercise.hasDistanceHistory) || false
        }
        hasDurationHistory={
          (activeStatExercise && activeStatExercise.hasDurationHistory) || false
        }
        isContextActive={activeStatExercise}
        contextTitle={activeStatExercise && `${t(statContext)}`}
        contextInteractions={
          activeStatExercise && WORKOUT_STATS_CONTEXT_BUTTONS
        }
        contextFunc={(btn) => {
          setStatContext(btn.btnName);
        }}
        interactionTitle={
          activeStatExercise &&
          `${t("exercise")}: ${activeStatExercise?.item.name}`
        }
        interactions={activeStatExercise && WORKOUT_STATS_TIMELINE_BUTTONS}
        interactionFunc={(btn) => setStatTimeline(btn.btnName)}
        customInteractionFuncs={{
          BACK: () => {
            setStatTimeline("2W");
            setActiveStatExercise(null);
            setStatContext(null);
          },
        }}
        statTimeline={statTimeline}
        visualizationData={{
          settings: settings,
          id: props.userData.id,
          isDarkTheme: isDarkTheme,
          prebuiltExerciseData: prebuiltExerciseData,
          viewedExercise: viewedExercise,
          setViewedExercise: setViewedExercise,
          activeStatExercise: activeStatExercise,
          setActiveStatExercise: setActiveStatExercise,
          statContext: statContext,
          setStatContext: setStatContext,
          plans: plans,
          statTimeline: statTimeline,
          setModalVisible: props.setModalVisible,
          modalVisible: props.modalVisible,
          planModalType: planModalType,
          setPlanModalType: setPlanModalType,
        }}
      />
    </Card>
  );
}
