import React from "react";
import { useTranslation } from "react-i18next";
import {
  BASE_ACCENT,
  BASE_AQUA,
  BASE_DARK_GRAY,
  BASE_GREEN,
  BASE_PINK,
  BASE_RED,
  BASE_WHITE,
} from "../../../Supports/Constants";
import { shortenText } from "../../../Supports/Functions";
import "./WeightChart.css";

export default function ChartTooltip(props) {
  const { t } = useTranslation();
  const goal = props.activePoints[0];
  const pred = props.activePoints[1];
  const log = props.activePoints[2];
  const stub = t("no-information-available-short");

  const hasContexts = goal?.isCheckUped || goal?.isWorkedOut || goal?.isFasted;
  const contexts = [
    { title: t("m-cu-title"), check: goal?.isCheckUped },
    { title: t("workout"), check: goal?.isWorkedOut },
    { title: t("fast"), check: goal?.isFasted },
  ];

  const makeBox = (title, value, type) => {
    const colorValueBackground = (datum) => {
      switch (datum) {
        case "goal":
          return BASE_PINK;
        case "log":
          return BASE_WHITE;
        case "pred":
          return BASE_AQUA;
        default:
          return BASE_DARK_GRAY;
      }
    };

    return (
      <div className="tooltip-box">
        <div>
          <div className="tooltip-box-title">
            {shortenText(title, 4, false, 0, true, true)}
          </div>
        </div>
        <div
          className="tooltip-box-text-background"
          style={{ backgroundColor: colorValueBackground(type) }}
        >
          <div
            className="tooltip-box-value"
            style={{ backgroundColor: colorValueBackground(type) }}
          >
            {value}
          </div>
        </div>
      </div>
    );
  };

  const makeContextTag = (item) => {
    if (!item.check) {
      return;
    }

    return (
      <div key={`tag-${item.title}`} className="tag-box">
        <div className="tag-title">{item.title.toUpperCase()}</div>
      </div>
    );
  };

  const colorBackground = (datum) => {
    if (!datum) {
      return BASE_ACCENT;
    }

    switch (datum.goalMet) {
      case undefined:
        return BASE_ACCENT;
      case true:
        return BASE_GREEN;
      case false:
        return BASE_RED;
      default:
        return BASE_ACCENT;
    }
  };

  const calorieGoalText = (datum) => {
    if (!datum || datum.goalMet === undefined) {
      return null;
    }

    return datum.goalTargetIsMoreCalories
      ? datum.goalMet
        ? t("c-wtt-m-vis-pop-passed-goal")
        : t("c-wtt-m-vis-pop-did-not-reach-goal")
      : datum.goalMet
      ? t("c-wtt-m-vis-pop-stayed-below")
      : t("c-wtt-m-vis-pop-went-below");
  };

  return (
    <g style={{ pointerEvents: "none" }}>
      <foreignObject
        x={log?._x < 8 || pred?._x < 8 ? "55%" : "20%"}
        y={5}
        width="150"
        height="300"
      >
        <div
          className="tooltip-container"
          style={{
            backgroundColor: colorBackground(log),
          }}
        >
          <div>
            <div className="tooltip-text">{goal.x}</div>
            {calorieGoalText(log) && (
              <div className="tooltip-text">{calorieGoalText(log)}</div>
            )}
          </div>
          <div className="box-row">
            {makeBox(
              t("c-wtt-m-vis-pop-weigh-in-goal"),
              goal?.y || stub,
              "goal"
            )}
            {makeBox(t("c-wtt-m-vis-pop-predicted"), pred?.y || stub, "pred")}
            {makeBox(t("c-wtt-m-vis-pop-weigh-in-log"), log?.y || stub, "log")}
          </div>
          {hasContexts && (
            <div className="tag-container">
              {contexts.map((item) => makeContextTag(item))}
            </div>
          )}
        </div>
      </foreignObject>
    </g>
  );
}
