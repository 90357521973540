import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BASE_ACCENT,
  BASE_GRAY,
  LARGE_BTN_MAX_STRING,
} from "../../../Supports/Constants";
import {
  presentDistances,
  presentUserWeights,
  shortenText,
} from "../../../Supports/Functions";
import Icon from "@mdi/react";
import {
  mdiCancel,
  mdiCheck,
  mdiChevronLeft,
  mdiChevronRight,
  mdiInformationOutline,
  mdiKeyboardReturn,
} from "@mdi/js";
import Modal from "react-modal";

export default function ViewPlanModal(props) {
  const { t } = useTranslation();

  const isSelectedPrepped = props.isSelectedPrepped;
  const isFromInactive = props.isFromInactive;
  const [itemsPage, setItemsPage] = useState(0);

  if (!props.exercises) {
    return <></>;
  }

  const exercises = Object.values(props.exercises);

  const MAX_ITEMS_PER_PAGE = 10;

  const backPage = itemsPage > 0;
  const HIGHEST_USED_PAGE =
    Math.floor(exercises.length / MAX_ITEMS_PER_PAGE) -
    (exercises.length % MAX_ITEMS_PER_PAGE === 0 ? 1 : 0); // 0;
  const forwardPage = itemsPage < HIGHEST_USED_PAGE;

  const dismissWithInputFunc = () => {
    props.func();
    props.setModalVisible(false);
  };

  const dismissWithoutInputFunc = () => {
    props.setModalVisible(false);
  };

  const generateItemTable = () => {
    const tableRows = [];
    if (exercises.length <= 0) {
      return (
        <div className="logTableRowStub unselectedRow">
          <div className="logItemTextStub logItemTextStubPlan">
            {t("m-wot-plan-s2-stub")}
          </div>
        </div>
      );
    }
    const headerRowTextStyleSmall = `logHeaderText column10`;
    const headerRowTextStyle = `logHeaderText column15`;
    const headerRowTextStyleLarge = `logHeaderText column25`;
    const headerRowTextStyleLargest = `logHeaderText column30`;

    tableRows.push(
      <div className="logTableHeaderRow" key="tableHeader">
        <div className={headerRowTextStyleSmall}>{t("#")}</div>
        <div className={headerRowTextStyleLargest}>{t("name")}</div>
        <div className={headerRowTextStyleLarge}>{t("prior")}</div>
        <div className={headerRowTextStyle}>{t("information-short")}</div>
        <div className={headerRowTextStyleSmall}>
          {/*t("actions-i18n")*/ ""}
        </div>
      </div>
    ); // Note: Empty one is a holder for the actions.

    for (
      let index = itemsPage * MAX_ITEMS_PER_PAGE;
      index < exercises.length &&
      index - itemsPage * MAX_ITEMS_PER_PAGE < MAX_ITEMS_PER_PAGE;
      index++
    ) {
      const planItem = exercises[index];
      const lastHistory =
        planItem.history && planItem.history[planItem.history.length - 1];

      const rowStyle = `logTableRow selectableRow`;
      const rowItemStyleSmall = `logItemText column10 selectableRowText`;
      const rowItemStyle = `logItemText column15 selectableRowText`;
      const rowItemStyleLarge = `logItemText column25 selectableRowText`;
      const rowItemStyleLargest = `logItemText column30 selectableRowText`;

      const generateInfo = (item) => {
        if (!item || !item.goalType) {
          return t("no-information-available-short");
        }

        try {
          switch (item.goalType) {
            case "set":
              return `${item.sets} x ${item.reps} ${t("repetitions-short")}`;
            case "duration":
              return `${item.laps} x ${
                Math.floor(item.durationPerLap / 60) > 0.5
                  ? Math.floor(item.durationPerLap / 60) +
                    `${t("minute-short")} `
                  : ""
              }${
                item.durationPerLap % 60 > 0
                  ? (item.durationPerLap % 60) + `${t("second-short")}`
                  : ""
              }`;
            case "distance":
              return `${item.laps} x ${planItem.distancePerLap} ${
                presentDistances(planItem.distanceUnit).short
              }`;
            default:
              return t("no-information-available-short");
          }
        } catch (_) {
          return t("no-information-available-short");
        }
      };

      const generatePriorInfo = (item) => {
        if (!item || !item.goalType || !lastHistory) {
          return t("no-information-available-short");
        }
        try {
          switch (item.goalType) {
            case "set":
              return `${lastHistory.weight}${
                presentUserWeights(lastHistory.unit).short
              } | ${lastHistory.status}`;
            case "duration":
              return `${
                Math.floor(lastHistory.duration / 60) > 0.5
                  ? Math.floor(lastHistory.duration / 60) +
                    `${t("minute-short")} `
                  : ""
              }${
                lastHistory.duration % 60 > 0
                  ? (lastHistory.duration % 60) + `${t("second-short")}`
                  : ""
              }`;
            case "distance":
              return `${lastHistory.distancePerLap} ${
                presentDistances(lastHistory.distanceUnit).short
              } | ${lastHistory.status}`;
            default:
              return t("no-information-available-short");
          }
        } catch (_) {
          return t("no-information-available-short");
        }
      };

      const row = (
        <div key={`tableRow-${index}`} className={rowStyle}>
          <div className={rowItemStyleSmall}>{planItem.order}</div>
          <div className={rowItemStyleLargest}>{`${
            planItem.name.replace(/(.{21})..+/, "$1...") || t("unnamed")
          }`}</div>
          <div className={rowItemStyleLarge}>{generatePriorInfo(planItem)}</div>
          <div className={rowItemStyle}>{generateInfo(planItem)}</div>

          <div className={rowItemStyleSmall}>
            <div className="itemButtons">
              <button
                className="row-glyph-button info"
                onClick={() => {
                  props.setViewedExercise(planItem);
                  props.setPlanModalType(
                    isFromInactive ? "VIEWPLAN_INACTIVE" : "VIEWPLAN"
                  );
                  props.setModalVisible(
                    isFromInactive
                      ? "WO_CNTX_INFO-INACTIVE_MID"
                      : "WO_CNTX_INFO-INACTIVE"
                  );
                }}
              >
                <Icon path={mdiInformationOutline} size={1} />
              </button>
            </div>
          </div>
        </div>
      );
      tableRows.push(row);
    }

    return tableRows;
  };

  return (
    <Modal
      isOpen={props.modalVisible}
      onRequestClose={dismissWithoutInputFunc}
      animationType="none"
      appElement={document.getElementById("root") || undefined}
    >
      <div className="modal-header-text-small">{t(props.question)}</div>
      <div className="TableArrowHeader">
        <button
          className="glyph-button"
          onClick={() => backPage && setItemsPage(itemsPage - 1)}
        >
          <Icon
            path={mdiChevronLeft}
            size={1}
            style={{
              color: backPage ? BASE_ACCENT : BASE_GRAY,
            }}
          />
        </button>
        <div className="card-header-text-small-centered">
          {t("m-wot-plan-s2-head")}
        </div>
        <button
          className="glyph-button"
          onClick={() => forwardPage && setItemsPage(itemsPage + 1)}
        >
          <Icon
            path={mdiChevronRight}
            size={1}
            style={{
              color: forwardPage ? BASE_ACCENT : BASE_GRAY,
            }}
          />
        </button>
      </div>
      {generateItemTable()}
      <div className="button-container nowrap noBottomMargin">
        <button
          className="large-rectangle info"
          onClick={dismissWithoutInputFunc}
        >
          <Icon path={mdiKeyboardReturn} size={1} />
          <div className="card-button-text">
            {shortenText(
              t(props.no),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </div>
        </button>
        <button
          className={`large-rectangle ${exercises.length <= 0 && "disabled"}`}
          onClick={() => exercises.length > 0 && dismissWithInputFunc()}
        >
          <Icon path={isSelectedPrepped ? mdiCancel : mdiCheck} size={1} />
          <div className="card-button-text">
            {shortenText(
              t(isSelectedPrepped ? "unprep" : "prep"),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </div>
        </button>
      </div>
    </Modal>
  );
}
