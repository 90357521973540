import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import {
  KILOGRAMS,
  LARGE_BTN_MAX_STRING,
  POUNDS,
} from "../../../Supports/Constants";
import { presentUserWeights, shortenText } from "../../../Supports/Functions";
import Icon from "@mdi/react";
import {
  mdiCheck,
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
  mdiChevronLeft,
  mdiChevronRight,
  mdiKeyboard,
  mdiKeyboardReturn,
} from "@mdi/js";
import "./CustomInputModal.css";
import Slider from "@mui/material/Slider";

// Component handles custom slider entry of values. Originally used for multiple slider values, however
// as needs expanded for some, slowly reduced the usage of this component for anything beyond the weight slider.
export default function CustomInputModal(props) {
  const { t } = useTranslation();
  const type = props.modalSettings.unitType;
  const modalData = props.modalData;
  const header = props.modalSettings.header || type;
  const min = props.modalSettings.minVal;
  const max = props.modalSettings.maxVal;
  const current =
    modalData?.calories || props.modalSettings.starting || props.current || min;
  const changeRate = props.modalSettings.changeRate || 0.1;
  const changeRateBig = props.modalSettings.changeRateBig || 1;

  const isNotWeight =
    (type !== KILOGRAMS && type !== POUNDS) ||
    (props.modalSettings.goalType && props.modalSettings.goalType !== "set");

  const [sliderVal, setSliderVal] = useState(current);
  const [tempSliderVal, setTempSliderVal] = useState(sliderVal);
  const [showInputVisual, setShowInputVisual] = useState(true);
  const [currentText, setCurrentText] = useState("");

  useEffect(() => {
    setShowInputVisual(true);
    setSliderVal(current);
    setTempSliderVal(current);
  }, [current, setSliderVal, setTempSliderVal, setShowInputVisual]);

  const dismissWithInputFunc = () => {
    const val = parseFloat(currentText.trimEnd(type));
    const target = !isNaN(val) ? val : current;

    if (!isNaN(target)) {
      setSliderVal(target);
      setTempSliderVal(target);
    }

    const returnTarget =
      props.fastStatus === "active"
        ? "END_FAST_FROM_FOOD"
        : !props.modalSettings.percentItemTarget ||
          !props.modalSettings.percentItemTarget.item
        ? false
        : props.modalSettings.percentItemTarget.origin
        ? props.modalSettings.percentItemTarget.origin
        : props.modalSettings.percentItemTarget.item.type === "foodItem"
        ? "CUSTOM_FOOD_CALORIES"
        : "CT_GENERIC_CUSTOM_FOR_WORKOUT_END";

    if (returnTarget === "END_FAST_FROM_FOOD") {
      props.setActiveCardStates({ ...props.activeCardStates, ...{ fast: 0 } });
    }

    props.func(target);
    props.setModalVisible(returnTarget);
  };

  const dismissWithoutInputFunc = () => {
    setSliderVal(current);
    setTempSliderVal(current);

    const returnTarget =
      !props.modalSettings.percentItemTarget ||
      !props.modalSettings.percentItemTarget.item
        ? null
        : props.modalSettings.percentItemTarget.origin
        ? props.modalSettings.percentItemTarget.origin
        : props.modalSettings.percentItemTarget.item.type === "foodItem"
        ? "CUSTOM_FOOD_CALORIES"
        : "CT_GENERIC_CUSTOM_FOR_WORKOUT_END";

    props.cancelFunc && props.cancelFunc(props);
    props.setModalVisible(returnTarget);
  };

  return (
    <Modal
      isOpen={props.modalVisible}
      onRequestClose={dismissWithoutInputFunc}
      animationType="none"
      appElement={document.getElementById("root") || undefined}
      onShow={() => {
        this.textInput.focus();
      }}
    >
      <div className="modal-header-text-small">
        {`${t("set")} ${t(
          isNotWeight ? header : presentUserWeights(header)?.full
        )}`}
      </div>
      <div className="sliderRangeText">
        <div className="card-text-small">{`${min}${
          isNotWeight ? t(type) : presentUserWeights(type)?.short
        }`}</div>
        <div className="sliderValuePairing card-text-small">
          {`${sliderVal}${
            isNotWeight ? t(type) : presentUserWeights(type)?.short
          }`}
        </div>
        <div className="card-text-small">{`${max}${
          isNotWeight ? t(type) : presentUserWeights(type)?.short
        }`}</div>
      </div>
      <Slider
        aria-label="input"
        value={sliderVal}
        onChange={(event) => {
          setTempSliderVal(Math.floor(event.target.value));
          setSliderVal(Math.floor(event.target.value));
        }}
        min={min}
        max={max}
      />
      <div className="sliderGroup">
        <button
          className="glyph-button"
          onClick={() => {
            if (sliderVal - changeRateBig >= min) {
              setTempSliderVal(
                Math.round((sliderVal - changeRateBig) * 100) / 100
              );
              setSliderVal(Math.round((sliderVal - changeRateBig) * 100) / 100);
            }
          }}
        >
          <Icon size={1} path={mdiChevronLeft} />
        </button>
        <button
          className="glyph-button"
          onClick={() => {
            if (sliderVal - changeRate >= min) {
              setTempSliderVal(
                Math.round((sliderVal - changeRate) * 100) / 100
              );
              setSliderVal(Math.round((sliderVal - changeRate) * 100) / 100);
            }
          }}
        >
          <Icon size={1} path={mdiChevronDoubleLeft} />
        </button>
        <button
          className="glyph-button"
          onClick={() => setShowInputVisual(!showInputVisual)}
        >
          <Icon size={1} path={mdiKeyboard} />
        </button>
        {showInputVisual && (
          <input
            className="input-smallest cardTextSmallRaised"
            type="number"
            placeholder={`${tempSliderVal}`}
            autoFocus={true}
            onKeyUp={(e) => e.key === "Enter" && dismissWithInputFunc()}
            onChange={(event) => {
              setCurrentText(event.target.value);
              const val = parseFloat(event.target.value);
              val && setSliderVal(val >= max ? max : val <= min ? min : val);
            }}
          />
        )}
        <button
          className="glyph-button"
          onClick={() => {
            if (sliderVal + changeRate <= max) {
              setTempSliderVal(
                Math.round((sliderVal + changeRate) * 100) / 100
              );
              setSliderVal(Math.round((sliderVal + changeRate) * 100) / 100);
            }
          }}
        >
          <Icon size={1} path={mdiChevronDoubleRight} />
        </button>
        <button
          className="glyph-button"
          onClick={() => {
            if (sliderVal + changeRate <= max) {
              setTempSliderVal(
                Math.round((sliderVal + changeRateBig) * 100) / 100
              );
              setSliderVal(Math.round((sliderVal + changeRateBig) * 100) / 100);
            }
          }}
        >
          <Icon size={1} path={mdiChevronRight} />
        </button>
      </div>
      <div className="button-container nowrap noTopMargin noBottomMargin">
        <button
          className="large-rectangle danger"
          onClick={dismissWithoutInputFunc}
        >
          <Icon size={1} path={mdiKeyboardReturn} />
          <div className="card-button-text">
            {shortenText(t("close"), LARGE_BTN_MAX_STRING, true, 0, true, true)}
          </div>
        </button>
        <button
          className="large-rectangle accent"
          onClick={dismissWithInputFunc}
        >
          <Icon size={1} path={mdiCheck} />
          <div className="card-button-text">
            {shortenText(
              t("confirm"),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </div>
        </button>
      </div>
    </Modal>
  );
}
