import React, { createContext, useEffect, useState } from "react";

import { collection, getDocs, getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

export const ThemeContext = createContext({
  theme: "",
  setTheme: (_) => {},
});

export const ModalContext = createContext({
  modal: null,
  setModal: (_) => {},
  modalData: null,
  setModalData: (_) => {},
});

export const DataContext = createContext();

export const DataContextProvider = ({ children }) => {
  const auth = getAuth();
  const firestore = getFirestore();
  const uid = auth.currentUser?.uid;

  const [loadingWeightLog, setLoadingWeightLog] = useState(false);
  const [weightLog, setWeightLog] = useState(null);

  const [loadingCalorieLog, setLoadingCalorieLog] = useState(false);
  const [calorieLog, setCalorieLog] = useState(null);
  const [loadingFoodItems, setLoadingFoodItems] = useState(false);
  const [foodItems, setFoodItems] = useState(null);
  const [loadingPhysicalItems, setLoadingPhysicalItems] = useState(false);
  const [physicalItems, setPhysicalItems] = useState(null);

  const [reloadCalorieItems, setReloadCalorieItems] = useState(true);

  const [loadingWorkoutPlans, setLoadingWorkoutPlans] = useState(false);
  const [workoutPlans, setWorkoutPlans] = useState(null);
  const [reloadWorkoutPlans, setReloadWorkoutPlans] = useState(true);

  const [loadingFastLog, setLoadingFastLog] = useState(false);
  const [fastLog, setFastLog] = useState(null);

  const [syncWeightLog, setSyncWeightLog] = useState(false);

  useEffect(() => {
    if (!uid) return;

    setLoadingWeightLog(true);
    getDocs(collection(firestore, "UserData", uid, "WeightLog")).then(
      (querySnapshot) => {
        if (querySnapshot) {
          const collect = {};
          querySnapshot.forEach((doc) => {
            collect[doc.id] = doc.data();
          });

          setWeightLog(collect);
        }
        setLoadingWeightLog(false);
      }
    );
  }, [firestore, uid]);

  useEffect(() => {
    if (!uid) return;

    setLoadingCalorieLog(true);
    getDocs(collection(firestore, "UserData", uid, "CalorieLog")).then(
      (querySnapshot) => {
        if (querySnapshot) {
          const collect = {};
          querySnapshot.forEach((doc) => {
            collect[doc.id] = doc.data();
          });

          setCalorieLog(collect);
        }

        setLoadingCalorieLog(false);
      }
    );
  }, [firestore, uid]);

  useEffect(() => {
    if (!uid || !reloadCalorieItems) return;

    setLoadingFoodItems(true);
    getDocs(collection(firestore, "UserData", uid, "FoodItems")).then(
      (querySnapshot) => {
        if (querySnapshot) {
          const collect = {};
          querySnapshot.forEach((doc) => {
            collect[doc.id] = doc.data();
          });

          setFoodItems(collect);
        }
        setLoadingFoodItems(false);
        setReloadCalorieItems(false);
      }
    );
  }, [firestore, uid, reloadCalorieItems]);

  useEffect(() => {
    if (!uid || !reloadCalorieItems) return;

    setLoadingPhysicalItems(true);
    getDocs(collection(firestore, "UserData", uid, "PhysicalItems")).then(
      (querySnapshot) => {
        if (querySnapshot) {
          const collect = {};
          querySnapshot.forEach((doc) => {
            collect[doc.id] = doc.data();
          });

          setPhysicalItems(collect);
        }
        setLoadingPhysicalItems(false);
        setReloadCalorieItems(false);
      }
    );
  }, [firestore, uid, reloadCalorieItems]);

  useEffect(() => {
    if (!uid || !reloadWorkoutPlans) return;

    setLoadingWorkoutPlans(true);
    getDocs(collection(firestore, "UserData", uid, "WorkoutPlans")).then(
      (querySnapshot) => {
        if (querySnapshot) {
          const collect = {};

          querySnapshot.forEach((doc) => {
            collect[doc.id] = doc.data();
          });

          setWorkoutPlans(collect);
        }

        setReloadWorkoutPlans(false);
        setLoadingWorkoutPlans(false);
      }
    );
  }, [firestore, uid, reloadWorkoutPlans]);

  useEffect(() => {
    if (!uid) return;

    setLoadingFastLog(true);
    getDocs(collection(firestore, "UserData", uid, "FastLog")).then(
      (querySnapshot) => {
        if (querySnapshot) {
          const collect = {};
          querySnapshot.forEach((doc) => {
            collect[doc.id] = doc.data();
          });

          setFastLog(collect);
        }

        setLoadingFastLog(false);
      }
    );
  }, [firestore, uid]);

  return (
    <DataContext.Provider
      value={{
        loading:
          loadingWeightLog ||
          loadingCalorieLog ||
          loadingWorkoutPlans ||
          loadingFastLog ||
          loadingFoodItems ||
          loadingPhysicalItems,
        loadingWeightLog,
        loadingCalorieLog,
        loadingFoodItems,
        loadingPhysicalItems,
        loadingWorkoutPlans,
        loadingFastLog,
        weightLog,
        calorieLog,
        foodItems,
        physicalItems,
        workoutPlans,
        fastLog,
        syncWeightLog,
        setSyncWeightLog,
        setReloadCalorieItems,
        setReloadWorkoutPlans,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
