import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BASE_ACCENT,
  BASE_GRAY,
  LARGE_BTN_MAX_STRING,
} from "../../../Supports/Constants";
import {
  presentDistances,
  presentUserWeights,
  shortenText,
} from "../../../Supports/Functions";
import Icon from "@mdi/react";
import {
  mdiChevronLeft,
  mdiChevronRight,
  mdiKeyboardReturn,
  mdiSwapHorizontal,
} from "@mdi/js";
import Modal from "react-modal";

export default function ChangeExerciseModal(props) {
  const { t } = useTranslation();

  const exercises = props.exercises;

  const [itemsPage, setItemsPage] = useState(0);
  const [selectedItem, setSelectedItem] = useState(props.activeItem.item);

  const MAX_ITEMS_PER_PAGE = 10;

  const backPage = itemsPage > 0;
  const HIGHEST_USED_PAGE =
    Math.floor(exercises.length / MAX_ITEMS_PER_PAGE) -
    (exercises.length % MAX_ITEMS_PER_PAGE === 0 ? 1 : 0); // 0;
  const forwardPage = itemsPage < HIGHEST_USED_PAGE;

  const dismissWithInputFunc = () => {
    props.func(selectedItem);
    props.setModalVisible(false);
  };

  const dismissWithoutInputFunc = () => {
    props.setModalVisible(false);
  };

  const generateItemTable = () => {
    const tableRows = [];
    if (exercises.length <= 0) {
      return (
        <div className="logTableRowStub unselectedRow">
          <div className="logItemTextStub logItemTextStubPlan">
            {t("m-wot-plan-s2-stub")}
          </div>
        </div>
      );
    }

    const headerRowTextStyle = `logHeaderText column20`;
    const headerRowTextStyleLarge = `logHeaderText column25`;
    const headerRowTextStyleLargest = `logHeaderText column30`;
    const headerRowTextStyleSmall = `logHeaderText column15`;
    const headerRowTextStyleSmallest = `logHeaderText column10`;

    tableRows.push(
      <div className="logTableHeaderRow" key="tableHeader">
        <div className={headerRowTextStyleSmallest}>{t("#")}</div>
        <div className={headerRowTextStyleLargest}>{t("name")}</div>
        <div className={headerRowTextStyleLarge}>{t("prior")}</div>
        <div className={headerRowTextStyle}>{t("information-short")}</div>
        <div className={headerRowTextStyleSmall}>{t("status")}</div>
      </div>
    );

    for (
      let index = itemsPage * MAX_ITEMS_PER_PAGE;
      index < exercises.length &&
      index - itemsPage * MAX_ITEMS_PER_PAGE < MAX_ITEMS_PER_PAGE;
      index++
    ) {
      const planItem = exercises[index];
      const lastHistory = planItem.history[planItem.history.length - 1];

      const rowStyle = `logTableRow ${
        selectedItem === index ? "preppedRow" : "selectableRow"
      }`;

      const rowItemStyleSmallest = `logItemText column10 ${
        selectedItem === index ? "selectedRowText" : "selectableRowText"
      }`;

      const rowItemStyleSmall = `logItemText column15 ${
        selectedItem === index ? "selectedRowText" : "selectableRowText"
      }`;

      const rowItemStyle = `logItemText column20 ${
        selectedItem === index ? "selectedRowText" : "selectableRowText"
      }`;

      const rowItemStyleLarge = `logItemText column25 ${
        selectedItem === index ? "selectedRowText" : "selectableRowText"
      }`;

      const rowItemStyleLargest = `logItemText column30 ${
        selectedItem === index ? "selectedRowText" : "selectableRowText"
      }`;

      const generateInfo = (item) => {
        if (!item || !item.goalType) {
          return t("no-information-available-short");
        }

        try {
          switch (item.goalType) {
            case "set":
              return `${item.sets} x ${item.reps} ${t("repetitions-short")}`;
            case "duration":
              return `${item.laps} ${
                item.laps === 1 ? t("repetition-short") : t("repetitions-short")
              }`;
            case "distance":
              return `${item.laps} ${
                item.laps === 1 ? t("repetition-short") : t("repetitions-short")
              }`;
            default:
              return t("no-information-available-short");
          }
        } catch (_) {
          return t("no-information-available-short");
        }
      };

      const generatePriorInfo = (item) => {
        if (!item || !item.goalType || !lastHistory) {
          return t("no-information-available-short");
        }

        try {
          switch (item.goalType) {
            case "set":
              return `${lastHistory.weight}${
                presentUserWeights(lastHistory.unit).short
              } | ${lastHistory.status}`;
            case "duration":
              return `${
                Math.floor(lastHistory.durationGoal / 60) > 0.5
                  ? Math.floor(lastHistory.durationGoal / 60) +
                    `${t("minute-short")} `
                  : ""
              }${
                lastHistory.durationGoal % 60 > 0
                  ? (lastHistory.durationGoal % 60) + `${t("second-short")}`
                  : ""
              } | ${lastHistory.status}`;
            case "distance":
              return `${lastHistory.distanceGoal} ${
                presentDistances(lastHistory.unit).short
              } | ${lastHistory.status}`;
            default:
              return t("no-information-available-short");
          }
        } catch (_) {
          return t("no-information-available-short");
        }
      };

      const row = (
        <div
          className={rowStyle}
          key={`tableRow-${index}`}
          onClick={() => {
            setSelectedItem(selectedItem === index ? null : index);
          }}
        >
          <div className={rowItemStyleSmallest}>{planItem.order}</div>
          <div className={rowItemStyleLargest}>{`${
            planItem.name.replace(/(.{21})..+/, "$1...") || t("unnamed")
          }`}</div>
          <div className={rowItemStyleLarge}>{generatePriorInfo(planItem)}</div>
          <div className={rowItemStyle}>{generateInfo(planItem)}</div>
          <div className={rowItemStyleSmall}>{`${
            lastHistory && props.sessionID === lastHistory.sessionID
              ? t(lastHistory.status)
              : t("no-information-available-short")
          }`}</div>
        </div>
      );
      tableRows.push(row);
    }

    return tableRows;
  };

  return (
    <Modal
      isOpen={props.modalVisible}
      onRequestClose={dismissWithoutInputFunc}
      animationType="none"
      appElement={document.getElementById("root") || undefined}
    >
      <div className="modal-header-text-small">{t(props.question)}</div>
      <div className="TableArrowHeader">
        <button
          className="glyph-button"
          onClick={() => backPage && setItemsPage(itemsPage - 1)}
        >
          <Icon
            path={mdiChevronLeft}
            size={1}
            style={{
              color: backPage ? BASE_ACCENT : BASE_GRAY,
            }}
          />
        </button>
        <div className="card-header-text-small-centered">
          {t("m-wot-plan-s2-head")}
        </div>
        <button
          className="glyph-button"
          onClick={() => forwardPage && setItemsPage(itemsPage + 1)}
        >
          <Icon
            path={mdiChevronRight}
            size={1}
            style={{
              color: forwardPage ? BASE_ACCENT : BASE_GRAY,
            }}
          />
        </button>
      </div>
      {generateItemTable()}
      {selectedItem !== props.activeItem.item && (
        <div className="warningText">{t("wt-change-exercise-warning")}</div>
      )}
      <div className="button-container nowrap noTopMargin noBottomMargin">
        <button
          className="large-rectangle warning"
          onClick={dismissWithoutInputFunc}
        >
          <Icon path={mdiKeyboardReturn} size={1} />
          <div className="card-button-text">
            {shortenText(
              t(props.no),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </div>
        </button>
        <button
          className={`large-rectangle ${
            !selectedItem && selectedItem !== 0
              ? null
              : !isNaN(selectedItem) && selectedItem !== props.activeItem.item
              ? ""
              : "disabled"
          }`}
          onClick={
            !selectedItem && selectedItem !== 0
              ? null
              : !isNaN(selectedItem) && selectedItem !== props.activeItem.item
              ? dismissWithInputFunc
              : null
          }
        >
          <Icon path={mdiSwapHorizontal} size={1} />
          <div className="card-button-text">
            {shortenText(
              t(props.yes),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </div>
        </button>
      </div>
    </Modal>
  );
}
