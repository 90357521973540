import React, { useState } from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { LARGE_BTN_MAX_STRING } from "../../../Supports/Constants";
import { shortenText } from "../../../Supports/Functions";
import Icon from "@mdi/react";
import {
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
  mdiChevronLeft,
  mdiChevronRight,
  mdiKeyboardReturn,
  mdiTimer,
} from "@mdi/js";
import Slider from "@mui/material/Slider";

// Component to handle the confirmation of a fast start. Allows for slider/arrow adjustments to finesse.
export default function StartFastModal(props) {
  const { t } = useTranslation();
  const MAX_BACKDATE_RANGE = 12;
  const fastLength =
    props.name === "GOAL" ? props.fastSpecialGoal : props.fastGoal;
  const max = Math.min(MAX_BACKDATE_RANGE, fastLength) * 3600;
  const min = 0;
  const [sliderVal, setSliderVal] = useState(max);

  const translateDurationToHM = (dur) => {
    // invert to allow max to be far-left, for user display, while still aligning to min->max requirements for slider.
    const inDur = MAX_BACKDATE_RANGE * 3600 - dur;
    const remH = Math.floor(Math.abs(inDur / 3600));
    const remM = Math.floor(Math.abs((inDur % 3600) / 60));
    const remS = Math.floor(Math.abs(dur % 60));
    const remFormatted =
      remH <= 0 && remM <= 0
        ? `${remS}${t("second-short")}`
        : remH <= 0
        ? `${remM}${t("minute-short")} ${remS}${t("second-short")}`
        : `${remH}${t("hour-short")} ${remM}${t("minute-short")} ${remS}${t(
            "second-short"
          )}`;

    return remFormatted;
  };

  const shiftTime = (isHour, isUpshift, delta = 1) => {
    const multiplier = 3600 * (isHour ? 1 : 1 / 60);
    const totalDelta = delta * multiplier;
    const newVal = sliderVal + (isUpshift ? totalDelta : -totalDelta);
    setSliderVal(newVal < 0 ? 0 : newVal > max ? max : newVal);
  };

  const dismissWithInputFunc = () => {
    props.func(-(MAX_BACKDATE_RANGE * 3600 - sliderVal));
    setSliderVal(max);
    props.setModalVisible(false);
  };

  const dismissWithoutInputFunc = () => {
    setSliderVal(max);
    props.setModalVisible(false);
  };

  return (
    <Modal
      isOpen={props.modalVisible}
      onRequestClose={dismissWithoutInputFunc}
      animationType="none"
      appElement={document.getElementById("root") || undefined}
    >
      <div className="modal-header-text-small">
        {t("m-ftt-start-title", {
          varLength: fastLength,
          varUnit: t("hour-short"),
        })}
      </div>
      <div className="sliderRangeText">
        <div className="card-text-small">
          {t("m-ftt-start-time-ago", {
            varTime: MAX_BACKDATE_RANGE,
            varUnit: t("hour-short"),
          })}
        </div>
        <div className="sliderValuePairing card-text-small">
          {translateDurationToHM(sliderVal) === "0h"
            ? t("m-ftt-start-scroll-now")
            : t("m-ftt-start-scroll", {
                varPast: translateDurationToHM(sliderVal),
              })}
        </div>
        <div className="card-text-small">{t("now")}</div>
      </div>
      <Slider
        aria-label="input"
        value={sliderVal}
        onChange={(event) => setSliderVal(Math.floor(event.target.value))}
        min={min}
        max={max}
      />
      <div className="sliderGroup">
        <button
          className="glyph-button"
          onClick={() => sliderVal > min && shiftTime(true, false)}
        >
          <Icon size={1} path={mdiChevronLeft} />
        </button>
        <button
          className="glyph-button"
          onClick={() => sliderVal > min && shiftTime(false, false)}
        >
          <Icon size={1} path={mdiChevronDoubleLeft} />
        </button>
        <button
          className="glyph-button"
          onClick={() => sliderVal < max && shiftTime(false, true)}
        >
          <Icon size={1} path={mdiChevronDoubleRight} />
        </button>
        <button
          className="glyph-button"
          onClick={() => sliderVal < max && shiftTime(true, true)}
        >
          <Icon size={1} path={mdiChevronRight} />
        </button>
      </div>
      <div className="button-container nowrap noTopMargin noBottomMargin">
        <button
          className="large-rectangle danger"
          onClick={dismissWithoutInputFunc}
        >
          <Icon size={1} path={mdiKeyboardReturn} />
          <div className="card-button-text">
            {shortenText(
              t("cancel"),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </div>
        </button>
        <button
          className="large-rectangle accent"
          onClick={dismissWithInputFunc}
        >
          <Icon size={1} path={mdiTimer} />
          <div className="card-button-text">
            {shortenText(
              t("m-ftt-start-btn-start"),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </div>
        </button>
      </div>
    </Modal>
  );
}
