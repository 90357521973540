import React from "react";

import { useTranslation } from "react-i18next";
import "./Mast.css";
import { APP_NAME, LARGE_BTN_MAX_STRING } from "../../Supports/Constants";
import { shortenText } from "../../Supports/Functions";
import { useNavigate } from "react-router-dom";
import Section from "./Section";

// Component outlining the mast content.
export default function Mast({ type = "frontPage" }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToSupport = () => navigate("/contact");
  const goToDemo = () => navigate("/demo");

  const DemoAndSupport = (
    <div className="row-content">
      <button className="large-rectangle" onClick={goToDemo}>
        {shortenText(t("demo"), LARGE_BTN_MAX_STRING, true, 0, true, true)}
      </button>
      <button className="large-rectangle" onClick={goToSupport}>
        {shortenText(t("support"), LARGE_BTN_MAX_STRING, true, 0, true, true)}
      </button>
    </div>
  );

  const frontMast = (
    <>
      <label className="cardMastText">
        <label className="boldText">{t("mast-reasons-r1-p1")}</label>
        {t("mast-reasons-r1-p2")}
        <label className="boldText">{t("mast-reasons-r1-p3")}</label>
        {t("mast-reasons-r1-p4")}
      </label>
      <label className="cardMastText">
        <label className="boldText">{t("mast-reasons-r2-p1")}</label>
        {t("mast-reasons-r2-p2")}
      </label>
      <label className="cardMastText">
        <label className="boldText">{t("mast-reasons-r3-p1")}</label>
        {t("mast-reasons-r3-p2")}
      </label>
      <label className="cardMastText">
        <label className="boldText">{t("mast-reasons-r4-p1")}</label>
        {t("mast-reasons-r4-p2")}
      </label>

      {DemoAndSupport}
    </>
  );

  const setupRequest = (
    <>
      <label className="cardMastText">
        <label className="boldText">{t("mast-info-request-r1-p1")}</label>
        {t("mast-info-request-r1-p2", { varApp: APP_NAME })}
      </label>
      <label className="cardMastText">
        <label className="boldText">{t("mast-info-request-r2-p1")}</label>
        {t("mast-info-request-r2-p2")}
      </label>
      <label className="cardMastText">
        <label className="boldText">{t("mast-info-request-r3-p1")}</label>
        {t("mast-info-request-r3-p2")}
      </label>
      <label className="cardMastText">
        <label className="boldText">{t("mast-info-request-r4-p1")}</label>
        {t("mast-info-request-r4-p2")}
      </label>
    </>
  );

  return (
    <div className="mastContainer">
      {type === "frontPage" ? frontMast : setupRequest}
    </div>
  );
}
