import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./i18n/config";
import FirebaseWrapper from "./Supports/FirebaseWrapper";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));

// https://console.cloud.google.com/apis/credentials?project=weighter-b67fd&supportedpurview=project
const googleOAuthID =
  "582632124808-9p8853v9iui1d315v1t8jh6lrs5m8s4e.apps.googleusercontent.com";

root.render(
  <GoogleOAuthProvider clientId={googleOAuthID}>
    <React.StrictMode>
      <BrowserRouter>
        <FirebaseWrapper />
      </BrowserRouter>
    </React.StrictMode>
  </GoogleOAuthProvider>
);
