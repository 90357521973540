import React, { useState } from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import {
  // BASE_ACCENT,
  // BASE_PINK,
  LARGE_BTN_MAX_STRING,
} from "../../../Supports/Constants";
import { shortenText } from "../../../Supports/Functions";
import Icon from "@mdi/react";
import { mdiCalendarCursor, mdiKeyboardReturn } from "@mdi/js";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import "./CalendarModal.css";

// Modal for changing the date on the calorie tracker.
export default function CalendarModal(props) {
  const { t } = useTranslation();
  const locale = props.language || "en";
  const [selected, setSelected] = useState(new Date(props.activeDate.json));

  const dismissWithInputFunc = () => {
    props.func(selected.toISOString());
    props.setModalVisible(props.returnFromCalToAdd);
    props.setReturnFromCalToAdd(false);
  };

  const dismissWithoutInputFunc = () => {
    props.setModalVisible(props.returnFromCalToAdd);
    props.setReturnFromCalToAdd(false);
  };

  // FUTURE: new calendar doesn't mark dates, so no dots for calories/activities.

  // const markedDates = {};

  // const years = Object.keys(props.calorieLog);
  // for (let i = 0; i < years.length; i++) {
  //   const months = Object.keys(props.calorieLog[years[i]]);
  //   for (let j = 0; j < months.length; j++) {
  //     // double deep sucks but will never be more then 12.
  //     const days = Object.keys(props.calorieLog[years[i]][months[j]]);
  //     for (let k = 0; k < days.length; k++) {
  //       // triple deep sucks but will never be more then 31.
  //       const log = props.calorieLog[years[i]][months[j]][days[k]];

  //       const [day, month, year] = log.date.split("/");
  //       const newDate = `${year}-${month > 9 ? month : "0" + month}-${
  //         day > 9 ? day : "0" + day
  //       }`;

  //       const dots = [];
  //       if (log.caloriesIn > 0) {
  //         dots.push({
  //           key: "caloriesIn",
  //           color: BASE_ACCENT,
  //           selectedDotColor: BASE_ACCENT,
  //         });
  //       }

  //       if (log.caloriesOut > 0) {
  //         dots.push({
  //           key: "caloriesOut",
  //           color: BASE_PINK,
  //           selectedDotColor: BASE_PINK,
  //         });
  //       }

  //       markedDates[newDate] = {
  //         marked: true,
  //         dots: dots,
  //       };
  //     }
  //   }
  // }

  // markedDates[selected] = {
  //   ...markedDates[selected],
  //   selected: true,
  //   disableTouchEvent: true,
  // };

  const [curDay, curMonth, curYear] = props.activeDate.date.split("/");
  const minDate = new Date(
    `${curYear - 1}-${curMonth > 9 ? curMonth : "0" + curMonth}-${
      curDay > 9 ? curDay : "0" + curDay
    }`
  );

  return (
    <Modal
      isOpen={props.modalVisible}
      onRequestClose={dismissWithoutInputFunc}
      animationType="none"
      appElement={document.getElementById("root") || undefined}
    >
      <div className="card-header-text-small-centered">{t(props.question)}</div>
      <Calendar
        locale={locale}
        onChange={(val) => setSelected(val)}
        value={selected}
        minDate={minDate}
      />
      <div className="button-container nowrap noTopMargin noBottomMargin">
        <button
          className="large-rectangle danger"
          onClick={dismissWithoutInputFunc}
        >
          <Icon size={1} path={mdiKeyboardReturn} />
          <div className="card-button-text">
            {shortenText(
              t(props.no, props.passedData?.no),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </div>
        </button>
        <button
          className="large-rectangle accent"
          onClick={dismissWithInputFunc}
        >
          <Icon size={1} path={mdiCalendarCursor} />
          <div className="card-button-text">
            {shortenText(
              t(props.yes, props.passedData?.yes),
              LARGE_BTN_MAX_STRING,
              true,
              0,
              true,
              true
            )}
          </div>
        </button>
      </div>
    </Modal>
  );
}
