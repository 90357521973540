import { getAuth, deleteUser } from "firebase/auth";
import {
  getFirestore,
  doc,
  deleteDoc,
  updateDoc,
  collection,
  orderBy,
  limit,
  query,
  writeBatch,
  getDocs,
} from "firebase/firestore";

import tinycolor from "tinycolor2";

import {
  AGE_MAX,
  AGE_MIN,
  DATABASE_PROVIDER,
  DATABASE_USER_DATA,
  // CARD_AREA_HEIGHT,
  // CARD_MARGIN,
  // COLLAPSED_HEIGHT,
  // DATABASE_PROVIDER,
  // DATABASE_USER_DATA,
  HEIGHT_FEET_MAX,
  HEIGHT_FEET_MIN,
  KILOGRAMS,
  KILOGRAMS_TO_POUNDS,
  KILOGRAMS_TO_STONES,
  KILOMETERS,
  KILOMETERS_TO_MILES,
  MILES,
  MILES_TO_KILOMETERS,
  MILLISECONDS_IN_A_DAY,
  POUNDS,
  POUNDS_TO_KILOGRAMS,
  POUNDS_TO_STONES,
  PROG_DISTANCE_MAX,
  PROG_DISTANCE_MIN,
  PROG_DURATION_MAX,
  PROG_DURATION_MIN,
  PROG_MAX,
  PROG_MIN,
  REST_MAX,
  REST_MIN,
  STONES,
  STONES_TO_KILOGRAMS,
  STONES_TO_POUNDS,
  THEME_GRAYSCALE,
  WEIGHT_MAX,
  WEIGHT_MIN,
  // WINDOW_WIDTH,
} from "./Constants";
import BMR from "./BMR";

export const getExpandedCardHeight = (numOfCards) => {
  return 1000;
  // return CARD_AREA_HEIGHT - (COLLAPSED_HEIGHT + CARD_MARGIN) * numOfCards;
};

// export const getExpandedCardWidth = (numOfCards) => {
//   return WINDOW_WIDTH;
// };

export const shortenText = (
  string,
  maxLength = 6,
  useTrail = true,
  postSave = 0,
  perWord = false,
  insertNewLine = false,
  innerNewLine = false,
  innerSplitLength = Math.ceil(maxLength / 3),
  maxVerticalLength = 3,
  autoCapitalizeFirst = true,
  autoCapitalizeEach = true
) => {
  const shorterInner = (str, ml, ut, ps) => {
    return str
      ?.substring(0, ml - ps) // postSave allows you to preserve punctuations, e.g. "Will you help me?" --> "Will you help...?" vs. "Will you help..."
      .trim()
      .concat(str.length > ml && ut ? "..." : "")
      .concat(str?.substring(str.length - ps, str.length));
  };

  const chunkString = (str, len) => {
    const size = Math.ceil(str.length / len);
    const r = Array(size);
    let offset = 0;

    for (let i = 0; i < size; i++) {
      r[i] = str.substr(offset, len);
      offset += len;
    }

    return r;
  };

  const capitalizeFilter = (str) => {
    if (!autoCapitalizeFirst) {
      return str;
    }

    if (!autoCapitalizeEach) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return str
      .split(/(\s+)/)
      .map((w) => {
        return w !== " " ? w.charAt(0).toUpperCase() + w.slice(1) : w;
      })
      .join("");
  };

  if (string && string.length <= maxLength) {
    return capitalizeFilter(string);
  }

  const words = perWord ? string.split(" ") : [string];
  const cleaned = words.map((w) =>
    shorterInner(w, maxLength, useTrail, postSave)
  );

  const formed = [];
  let buildUp = [];
  let runningLength = 0;

  if (innerNewLine) {
    for (let index = 0; index < words.length; index++) {
      const adj =
        words[index].length / innerSplitLength > maxVerticalLength || // if the vertical spread is hitting the max
        words[index].length % innerSplitLength === 1 // or if there is a final line that is a single letter,
          ? 1 // Try to go a bit wider so it can fit in cleaner.
          : 0;
      formed.push(
        ...chunkString(words[index], innerSplitLength + adj).map(
          (c) => c + "\n"
        )
      );
    }
  } else {
    for (let index = 0; index < cleaned.length; index++) {
      const cleanedWord = cleaned[index];

      if (cleanedWord) {
        if (cleanedWord.length > maxLength) {
          formed.push(buildUp.join(" "));
          formed.push(cleanedWord);
        } else {
          if (runningLength + cleanedWord.length <= maxLength) {
            // could've just calculated prior to if, but would needlessly add N compute, where N is the number of currently build up words.
            runningLength += cleanedWord.length;
            buildUp.push(cleanedWord);
          } else {
            formed.push(buildUp.join(" "));
            buildUp = [cleanedWord];
            runningLength = cleanedWord.length;
          }

          if (index === cleaned.length - 1) {
            formed.push(buildUp.join(" "));
          }
        }
      }
    }
  }

  return capitalizeFilter(formed.join(insertNewLine ? "\n" : " ").trim());
};

// From https://www.30secondsofcode.org/js/s/to-hsl-object
const toHSLObject = (hslStr) => {
  const [hue, saturation, lightness] = hslStr.match(/\d+/g).map(Number);
  return { hue, saturation, lightness };
};

const scaleLightnessOfHSL = ([color, rangeSize, positionInSet]) => {
  const tempHSLInfo = toHSLObject(color);
  const split = tempHSLInfo.lightness / rangeSize;
  const lightness = tempHSLInfo.lightness - split * positionInSet;
  return `hsl(${tempHSLInfo.hue}, ${tempHSLInfo.saturation}%, ${lightness}%)`;
};

// Altered from public source https://stackoverflow.com/a/65891557
export const scaleValue = (
  value,
  sourceRangeMin,
  sourceRangeMax,
  targetRangeMin,
  targetRangeMax
) => {
  const targetRange = targetRangeMax - targetRangeMin;
  const sourceRange = sourceRangeMax - sourceRangeMin;
  return (
    ((value - sourceRangeMin) * targetRange) / sourceRange + targetRangeMin
  );
};

const lightenColor = ([color, rangeSize, positionInSet]) => {
  const val = scaleValue(rangeSize - positionInSet, 0, rangeSize, -15, 20);
  return tinycolor(color).brighten(val).toString();
};

export const scaleTheme = (theme, ...props) => {
  if (theme === THEME_GRAYSCALE) {
    return scaleLightnessOfHSL(props);
  } else {
    return lightenColor(props);
  }
};

// Conversation functions.
// F' I" -> {feet: F, inches: I}
export const heightStringToObject = (str) => {
  return {
    feet: Number(str.substring(0, str.indexOf("'"))),
    inches: Number(str.substring(str.indexOf(" ") + 1, str.indexOf('"'))),
  };
};

// obj:{feet: F, inches: I} -> F' I"
export const objectToHeightString = (obj) => {
  return obj ? `${obj.feet}' ${obj.inches}"` : undefined;
};

export const metersToFeetInches = (inMeters, round = true) => {
  let feet = 0;
  let inches = 0;

  const flatConvertInFeet = inMeters * 3.281;
  feet = Math.floor(flatConvertInFeet);
  inches = (flatConvertInFeet - feet) * 12;

  inches = round ? inches.toFixed(0) : inches;

  return {
    feet: feet,
    inches: inches,
    text: `${feet > 0 ? feet + "' " : ""}${inches}"`,
  };
};

export const inchesToFeetInches = (inInches, round = true) => {
  const inches = round ? inInches.toFixed(0) : inInches;
  const feet = Math.floor(inches / 12);
  const remainingInches = (inches / 12 - feet) * 12;

  return {
    feet: feet,
    inches: round ? remainingInches.toFixed(0) : remainingInches,
    text: `${feet > 0 ? feet + "' " : ""}${
      round ? remainingInches.toFixed(0) : remainingInches
    }"`,
  };
};

// export const getFieldPath = (...pathLine) => {
//   const fieldPath = new firestore.FieldPath(...pathLine);
//   return fieldPath;
// };

// Firebase functions
export const updateVal = (update, id, db = DATABASE_USER_DATA) => {
  switch (DATABASE_PROVIDER) {
    case "Firestore":
      return updateFirestore(update, id, db);
    default:
      return updateFirestore(update, id, db);
  }
};

// export const wipeData = (target, id) => {
//   switch (DATABASE_PROVIDER) {
//     case "Firestore":
//       return wipeDataFirestore(target, id);
//     default:
//       return wipeDataFirestore(target, id);
//   }
// };

export const deleteAccount = (id) => {
  switch (DATABASE_PROVIDER) {
    case "Firestore":
      return deleteAccountFirestore(id);
    default:
      return deleteAccountFirestore(id);
  }
};

const updateFirestore = (update, _, db = DATABASE_USER_DATA) => {
  const auth = getAuth();
  const firestore = getFirestore();
  return updateDoc(doc(firestore, db, auth.currentUser.uid), update);
};

// const wipeDataFirestore = (target, id) => {
//   return firestore()
//     .collection("UserData")
//     .doc(id)
//     .update({
//       [target]: firestore.FieldValue.delete(),
//     });
// };

const deleteAccountFirestore = (_) => {
  const firestore = getFirestore();
  const auth = getAuth();

  return deleteUser(auth.currentUser)
    .then(deleteDoc(doc(firestore, "UserData", auth.currentUser.uid)))
    .then(deleteDoc(doc(firestore, "Users", auth.currentUser.uid)));
};

export async function deleteCollection(db, collectionPath) {
  const toDelete = await getDocs(query(collection(db, collectionPath)));
  toDelete.forEach((item) => deleteDoc(doc(db, collectionPath, item.id)));
}

// FUTURE: Batching does not work. Snapshot inconsistent, usually zero docs grabbed.
//cloud.google.com/firestore/docs/samples/firestore-data-delete-collection
export async function deleteCollectionBatch(
  db,
  collectionPath,
  batchSize = 100
) {
  // https://stackoverflow.com/questions/67262661/what-is-the-recommended-batch-size-when-deleting-firestore-documents-in-a-collec
  const collectionRef = collection(db, collectionPath);
  const q = query(collectionRef, orderBy("name"), limit(batchSize));

  return new Promise((resolve, reject) => {
    deleteQueryBatch(db, q, resolve).catch(reject);
  });
}

// FUTURE: Batching does not work. Snapshot inconsistent, usually zero docs grabbed.
export async function deleteQueryBatch(db, q, resolve) {
  const snapshot = await getDocs(q);
  const batchSize = snapshot.size;

  if (batchSize === 0) {
    // When there are no documents left, we are done
    resolve();
    return;
  }

  // Delete documents in a batch
  const batch = writeBatch(db);
  snapshot.forEach((doc) => {
    batch.delete(doc.ref);
  });
  await batch.commit();

  // Recurse on the next process tick, to avoid
  // exploding the stack.
  requestAnimationFrame(() => {
    deleteQueryBatch(db, q, resolve);
  });
}

// Connect to support library.
export const getBMR = (obj) => {
  // eslint-disable-next-line new-cap
  return BMR({
    bwLbs: obj.weight,
    bhFI:
      typeof obj.height === "string"
        ? heightStringToObject(obj.height)
        : obj.height,
    age: obj.age,
    sex: obj.sex,
    roundedDown: true,
  });
};

// unit converters
export const converterUserWeight = (
  inUnit,
  inValue,
  outUnit,
  shortened = false,
  shortenedVal = 0
) => {
  if (inUnit === outUnit) {
    return inValue;
  }
  let convertedVal;
  if (inUnit === POUNDS) {
    if (outUnit === KILOGRAMS) {
      convertedVal = inValue * POUNDS_TO_KILOGRAMS;
    } else if (outUnit === STONES) {
      convertedVal = inValue * POUNDS_TO_STONES;
    }
  } else if (inUnit === KILOGRAMS) {
    if (outUnit === POUNDS) {
      convertedVal = inValue * KILOGRAMS_TO_POUNDS;
    } else if (outUnit === STONES) {
      convertedVal = inValue * KILOGRAMS_TO_STONES;
    }
  } else if (inUnit === STONES) {
    if (outUnit === POUNDS) {
      convertedVal = inValue * STONES_TO_POUNDS;
    } else if (outUnit === KILOGRAMS) {
      convertedVal = inValue * STONES_TO_KILOGRAMS;
    }
  } else {
    return undefined;
  }

  if (shortened) {
    convertedVal = Number.parseFloat(convertedVal).toFixed(shortenedVal);
  }

  return convertedVal;
};

export const converterDistances = (
  inUnit,
  inValue,
  outUnit,
  shortened = false,
  shortenedVal = 0
) => {
  if (inUnit === outUnit) {
    return inValue;
  }
  let convertedVal;
  if (inUnit === MILES) {
    if (outUnit === KILOMETERS) {
      convertedVal = inValue * MILES_TO_KILOMETERS;
    }
  } else if (inUnit === KILOMETERS) {
    if (outUnit === MILES) {
      convertedVal = inValue * KILOMETERS_TO_MILES;
    }
  } else {
    return undefined;
  }

  if (shortened) {
    convertedVal = parseFloat(convertedVal.toFixed(shortenedVal));
  }

  return convertedVal;
};

export const presentUserWeights = (inUnit) => {
  if (inUnit === POUNDS) {
    return { short: "lbs", full: "pounds", sinuglar: "pound" };
  } else if (inUnit === KILOGRAMS) {
    return { short: "kg", full: "kilograms", singular: "kilogram" };
  } else if (inUnit === STONES) {
    return { short: "st", full: "stone", singular: "stone" };
  }

  return undefined;
};

export const presentDistances = (inUnit) => {
  if (inUnit === MILES) {
    return { short: "mi.", full: "miles", singular: "mile" };
  } else if (inUnit === KILOMETERS) {
    return { short: "km.", full: "kilometers", singular: "kilometer" };
  }

  return undefined;
};

// Generate value ranges.
export const generateAges = (start = AGE_MIN, end = AGE_MAX) => {
  const ages = Array.from({ length: end - start }, (x, i) => {
    const count = i + start;
    return { value: count, text: count.toString() };
  }); // 18 -> 128
  return ages;
};

export const generateLaps = (start = 1, end = 100) => {
  const ages = Array.from({ length: end - start }, (x, i) => {
    const count = i + start;
    return { value: count, text: count.toString() };
  }); // 18 -> 128
  return ages;
};

export const generateLapDistances = (
  translator,
  unit = KILOMETERS,
  start = 0,
  end = 43, // marathon length == 42.195
  step = 0.5
) => {
  const distances = [];

  for (let distanceStep = start; distanceStep <= end; distanceStep += step) {
    distances.push({
      value: distanceStep,
      text: `${distanceStep.toString()} ${translator(
        presentDistances(unit).short
      )}`,
    });
  }
  return distances;
};

export const generateLapDurations = (
  translator,
  start = 0,
  end = 30, // 30 minutes per lap?
  step = 0.25
) => {
  const durations = [];

  for (let durationStep = start; durationStep <= end; durationStep += step) {
    const seconds = durationStep * 60;
    const convertedMinutes = Math.floor(seconds / 60);
    const convertedSeconds = seconds % 60;

    const strVal = `${
      convertedMinutes > 0.5
        ? convertedMinutes + `${translator("minute-short")} `
        : ""
    }${
      convertedSeconds > 0
        ? convertedSeconds + `${translator("second-short")}`
        : ""
    }`;

    durations.push({
      value: seconds,
      text: strVal,
    });
  }
  return durations;
};

export const generateHeights = (
  startFeet = HEIGHT_FEET_MIN,
  endFeet = HEIGHT_FEET_MAX,
  step = 1
) => {
  const heights = [];

  for (let feetVal = startFeet; feetVal < endFeet; feetVal += step) {
    for (
      let inchVal = feetVal === startFeet ? endFeet : 0;
      inchVal < 12;
      inchVal += step
    ) {
      heights.push({
        value: `${feetVal}' ${inchVal}"`,
        text: `${feetVal}' ${inchVal}"`,
      });
    }
  } // 1' 9" -> 8' 11" Shortest person ever to tallest person ever.
  return heights;
};

export const generateWeights = (
  unit = POUNDS,
  start = WEIGHT_MIN,
  end = WEIGHT_MAX
) => {
  const weights = [];
  let inStep = 0.5;
  let inStart;
  let inEnd;
  if (unit === POUNDS) {
    inStep = 0.5;
    inStart = start;
    inEnd = end;
  } else if (unit === KILOGRAMS) {
    inStep = 0.2;
    inStart = Math.floor(converterUserWeight(POUNDS, start, KILOGRAMS));
    inEnd = Math.ceil(converterUserWeight(POUNDS, end, KILOGRAMS));
  } else if (unit === STONES) {
    inStep = 0.1;
    inStart = Math.floor(converterUserWeight(POUNDS, start, STONES));
    inEnd = Math.ceil(converterUserWeight(POUNDS, end, STONES));
  }

  for (let weightStep = inStart; weightStep <= inEnd; weightStep += inStep) {
    weights.push({
      value: parseFloat(weightStep.toFixed(1)),
      text: `${weightStep.toFixed(1)} ${presentUserWeights(unit).short}`,
    });
  }
  return weights;
};

export const generateSetProgressionRates = (
  start = PROG_MIN,
  end = PROG_MAX,
  step = 0.5,
  unit = POUNDS
) => {
  const progressions = [];
  for (let progStep = start; progStep <= end; progStep += step) {
    progressions.push({
      value: progStep,
      text: `${progStep.toString()} ${presentUserWeights(unit).short}`,
    });
  }
  return progressions;
};

export const generateDistanceProgressionRates = (
  start = PROG_DISTANCE_MIN,
  end = PROG_DISTANCE_MAX,
  step = 0.25,
  unit = MILES
) => {
  const progressions = [];
  for (let progStep = start; progStep <= end; progStep += step) {
    progressions.push({
      value: progStep,
      text: `${progStep.toString()} ${presentDistances(unit).short}`,
    });
  }
  return progressions;
};

export const generateDurationProgressionRates = (
  translator,
  start = PROG_DURATION_MIN,
  end = PROG_DURATION_MAX,
  step = 5,
  unit = "second-short"
) => {
  const translateDurationToHM = (t, dur) => {
    // invert to allow max to be far-left, for user display, while still aligning to min->max requirements for slider.
    const inDur = dur;
    const remH = Math.floor(Math.abs(inDur / 3600));
    const remM = Math.floor(Math.abs((inDur % 3600) / 60));
    const remS = Math.floor(Math.abs(dur % 60));
    const remFormatted =
      remH <= 0 && remM <= 0
        ? `${remS}${t("second-short")}`
        : remH <= 0
        ? `${remM}${t("minute-short")} ${remS}${t("second-short")}`
        : `${remH}${t("hour-short")} ${remM}${t("minute-short")} ${remS}${t(
            "second-short"
          )}`;

    return remFormatted;
  };

  const progressions = [];
  for (let progStep = start; progStep <= end; progStep += step) {
    progressions.push({
      value: progStep,
      text: `${translateDurationToHM(translator, progStep)}`,
    });
  }
  return progressions;
};

export const generateRestDurations = (
  translator,
  start = REST_MIN,
  end = REST_MAX,
  step = 15
) => {
  const durations = [];
  for (let progStep = start; progStep <= end; progStep += step) {
    durations.push({
      value: progStep,
      text: `${progStep.toString()}${translator("second-short")}`,
    });
  }
  return durations;
};

export const generateNumberRange = (
  start = 0,
  end = 100,
  step = 1,
  postfix = null,
  noneVal = false,
  spaceBetween = true
) => {
  const entries = [];

  for (let index = start; index <= end; index += step) {
    entries.push({
      value: index,
      text:
        index === 0 && noneVal
          ? noneVal
          : `${index}${spaceBetween ? " " : ""}${postfix ? postfix : ""}`,
      key: "range-" + index,
    });
  }

  return entries;
};

// Time and date functions for scheduling.
export const getDate = (providedISO = false, isNormalized = true) => {
  let base;
  if (providedISO) {
    base = new Date(providedISO);
  } else {
    base = new Date();
  }
  let json;
  let day = base.getDate();
  let month = base.getMonth() + 1;
  let year = base.getFullYear();

  if (isNormalized) {
    const baseNormalized = new Date(
      base.getTime() - Math.abs(base.getTimezoneOffset() * 60000)
    );
    json = baseNormalized.toJSON();
    day = baseNormalized.getDate();
    month = baseNormalized.getMonth() + 1;
    year = baseNormalized.getFullYear();
  } else {
    json = base.toJSON();
    day = base.getDate();
    month = base.getMonth() + 1;
    year = base.getFullYear();
  }

  const date = [day, month, year].join("/");

  return {
    ios: base,
    json: json,
    date: date,
    time: base.getTime(),
    day: day,
    month: month,
    year: year,
  }; // NOTE: using normalize time seems to break things in fast.
};

export const getTodayDate = (isNormalized = true) => {
  return getDate(false, isNormalized);
};

export const DEFAULT_REPEAT_SETTING = "doesnotrepeat";

export const REPEAT_OPTIONS = {
  doesnotrepeat: () => false,
  everyday: () => true,
  everyotherday: (dateISO, dateISOOriginal) =>
    isSpacedDays(dateISO, dateISOOriginal, 1),
  everyothertwodays: (dateISO, dateISOOriginal) =>
    isSpacedDays(dateISO, dateISOOriginal, 2),
  onlyweekdays: (dateISO, _) => isWeekday(dateISO),
  onlyweekends: (dateISO, _) => isWeekend(dateISO),
  mondays: (_, __) => isDayOfWeek("Monday"),
  tuesdays: (dateISO, _) => isDayOfWeek(dateISO, "Tuesday"),
  wednesdays: (dateISO, _) => isDayOfWeek(dateISO, "Wednesday"),
  thursdays: (dateISO, _) => isDayOfWeek(dateISO, "Thursday"),
  fridays: (dateISO, _) => isDayOfWeek(dateISO, "Friday"),
  saturdays: (dateISO, _) => isDayOfWeek(dateISO, "Saturday"),
  sundays: (dateISO, _) => isDayOfWeek(dateISO, "Sunday"),
};

export const getNextScheduled = (opt, anchorDate) => {
  // Assumes default is 'no repeat'.
  if (opt === DEFAULT_REPEAT_SETTING) {
    return null;
  }

  // Code note: Technically open ended loop, but will always unfold/expand out to trigger within the weekly cycle.
  // Could while(true) and remove the trigger variable, and just check the REPEAT_OPT... line, returning targetDate.
  let isTriggered = false;
  let dateDelta = 0; // 0 is 'today'. Runs out day-by-day until trigger is hit.
  while (!isTriggered) {
    const targetDate = getLaterDate(getTodayDate().json, dateDelta);
    isTriggered = REPEAT_OPTIONS[opt](targetDate.json, anchorDate);
    if (isTriggered) {
      return targetDate;
    }
    dateDelta++;
  }
};

export const getNowTime = (isNormalized = true) => {
  const now = new Date();
  if (isNormalized) {
    const baseNormalized = new Date(
      now.getTime() - Math.abs(now.getTimezoneOffset() * 60000)
    );
    return baseNormalized.getTime();
  } else {
    return now.getTime();
  }
};

export const getDateDelta = (datetime, delta, normalized = false) => {
  const workingDate = new Date(datetime);
  const deltaDate = new Date(
    workingDate.setDate(workingDate.getDate() + delta)
  );
  return getDate(deltaDate, normalized);
};

export const removeMillisecondsFromDateTime = (datetime) => {
  return datetime.split(".")[0] + "Z";
};

export const getDateTimeDelta = (datetime, delta, normalized = false) => {
  const workingDate = new Date(datetime);
  const deltaDate = new Date(workingDate.getTime() + delta);
  return getDate(deltaDate, normalized);
};

export const getYesterdayDate = () => {
  return getDateDelta(getTodayDate().json, -1);
};

export const getTomorrowDate = () => {
  return getDateDelta(getTodayDate().json, 1);
};

export const getLaterDate = (datetime, delta = 1) => {
  return getDateDelta(datetime, delta);
};

export const getEarlierDate = (datetime, delta = 1) => {
  return getDateDelta(datetime, -delta);
};

export const getRangeOfDates = (
  startDateTime,
  endDateTime,
  includeEndPoints = true,
  isNormalized = true
) => {
  const startObj = getDate(startDateTime, isNormalized);
  const endObj = getDate(endDateTime, isNormalized);
  const dates = [];

  if (areDatetimesOnDifferentDates(startDateTime, endDateTime)) {
    includeEndPoints && dates.push(startObj);
    let cursor = startObj;
    const compareRangeEnds = compareISODatetimes(startDateTime, endDateTime);

    if (compareRangeEnds > 0) {
      // Start is a "larger" date then End, thus is a later date, therefore generate backwards.
      while (cursor.date !== endObj.date) {
        const earlier = getEarlierDate(cursor.json);
        if (earlier.date !== endObj.date || includeEndPoints) {
          dates.push(earlier);
        }
        cursor = earlier;
      }
      // reverse dates before sending, so its in cronological timeline order.
      return dates.reverse();
    } else {
      // End is the "larger" date then end, thus is a later date, generated in cronological time.
      while (cursor.date !== endObj.date) {
        const later = getLaterDate(cursor.json);
        if (later.date !== endObj.date || includeEndPoints) {
          dates.push(later);
        }
        cursor = later;
      }

      return dates;
    }
  }

  includeEndPoints && dates.push(endObj);
  return dates;
};

export const isLeapYearUsingDate = (year) => {
  const leapDate = new Date(year, 1, 29);
  return leapDate.getMonth() === 1 && leapDate.getDate() === 29;
};

export const compareISODatetimes = (dateA, dateB) => {
  return new Date(dateA).valueOf() - new Date(dateB).valueOf();
};

export const getTimeUntil = (
  targetTime = {
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  },
  isNormalized = true
) => {
  let d = new Date();
  d.setHours(
    targetTime.hour,
    targetTime.minute,
    targetTime.second,
    targetTime.millisecond
  );

  if (isNormalized) {
    d = new Date(d.getTime() - Math.abs(d.getTimezoneOffset() * 60000));
  }

  const delta = compareISODatetimes(d, getTodayDate(isNormalized).json);
  return { milliseconds: delta, days: delta / MILLISECONDS_IN_A_DAY };
};

export const getTimeUntilMidnight = (isNormalized = true) => {
  return getTimeUntil(
    { hour: 24, minute: 0, second: 0, millisecond: 0 },
    isNormalized
  );
};

export const areDatetimesOnDifferentDates = (datetimeA, datetimeB) => {
  return datetimeA.split("T")[0] !== datetimeB.split("T")[0];
};

export const isDayOfWeek = (dateISO = getTodayDate().json, targetDay) => {
  const activeDate = new Date(dateISO);
  const currentDOW = activeDate.getDay(); // 0->6;
  const triggerDOW = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  return triggerDOW[currentDOW] === targetDay;
};

export const isWeekend = (dateISO = getTodayDate().json) => {
  return isDayOfWeek(dateISO, "Saturday") || isDayOfWeek(dateISO, "Sunday");
};

export const isWeekday = (dateISO = getTodayDate().json) => {
  return !isWeekend(dateISO);
};

export const isSpacedDays = (dateISO, dateISOOriginal, numOfDaysBetween) => {
  const activeDate = new Date(dateISO);
  const originalDate = new Date(dateISOOriginal);
  const diffInMilliseconds = activeDate.getTime() - originalDate.getTime();
  const diffInDays = diffInMilliseconds / MILLISECONDS_IN_A_DAY;
  return Math.floor(diffInDays) % (numOfDaysBetween + 1) === 0;
};
