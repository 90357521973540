import React, { useContext, useMemo, useCallback } from "react";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import ChartTooltip from "./ChartTooltip";
import {
  BASE_ACCENT,
  BASE_AQUA,
  BASE_BLACK,
  BASE_GREEN,
  BASE_PINK,
  BASE_RED,
  BASE_WHITE,
  BASE_WHITE_DULLED,
} from "../../../Supports/Constants";
import {
  compareISODatetimes,
  converterUserWeight,
  getEarlierDate,
  getLaterDate,
  getRangeOfDates,
  getTodayDate,
} from "../../../Supports/Functions";
import {
  DEFAULT_CALORIE_ANNOTATIONS,
  DEFAULT_PHYSICAL_ANNOTATIONS,
  generateWeightLogItem,
  generateWeightPrediction,
} from "../../../Supports/Templates";
import "./WeightChart.css";
import { ThemeContext } from "../../../Supports/Contexts";

// Standard weight tracker charting with Victory.
export default function WeightChart(props) {
  const theme = useContext(ThemeContext);
  const isDarkTheme = theme.theme === "dark";

  const vd = props.visualizationData;

  const MAX_DATAPOINTS = 15; // The maximum number of datapoints displayed in the chart at onetime.
  // If the number of datapoints in the range is larger then the MAX_DATAPOINTS, then slim down set at regular intervals
  // to get general feel of changes over that span, attempting to maintain value to viewer while not overloading visuals.

  const blackList = [
    "trendLine-weight",
    // 'trendLine-prediction',
    // 'trendLine-goal',
  ];

  const DOMAIN_PADDING = 2;

  const filterRange = useCallback(
    (preFilteredData, range, maxNumOfItems = MAX_DATAPOINTS) => {
      let rangeBack;
      switch (range) {
        case "2W":
          rangeBack = 14;
          break;
        case "1M":
          rangeBack = 30; // Note: Some months are 29, 30, 31 days. Though we aren't saying just current month. Generic month.
          break;
        case "6M":
          rangeBack = 30 * 6; // Note: Some months are 29, 30, 31 days. "...". Generic 6 months.
          break;
        case "1Y":
          rangeBack = 365;
          break;
        case "3Y":
          rangeBack = 365 * 3;
          break;
        case "ALL":
          rangeBack = null;
          break;
        default:
          rangeBack = 14;
          break;
      }

      if (rangeBack === null || !preFilteredData) {
        return preFilteredData;
      }

      const today = getTodayDate();

      const backDate = getEarlierDate(today.json, rangeBack - 1);
      const dateRange = getRangeOfDates(today.json, backDate.json, true, false);

      const filteredData = dateRange
        .map((dr) =>
          preFilteredData[`${dr.year}-${dr.month}-${dr.day}`]
            ? preFilteredData[`${dr.year}-${dr.month}-${dr.day}`]
            : undefined
        )
        .filter((dr) => dr !== undefined)
        .sort((a, b) => compareISODatetimes(a.datetime, b.datetime));

      if (filteredData.length <= 0) {
        return;
      }

      let currentCursorDateData = filteredData.shift();
      const filteredAndFilledData = [currentCursorDateData]; // current day is already populated.
      let lastDateData = currentCursorDateData; // filteredData.pop();

      for (let i = 0; i < dateRange.length; i++) {
        if (dateRange[i].date === currentCursorDateData?.date) {
          lastDateData = currentCursorDateData;
          filteredAndFilledData.push(currentCursorDateData);
          currentCursorDateData = filteredData.shift();
        } else {
          // stub
          let calIn = 0;
          let calOut = 0;

          try {
            calIn =
              vd.calorieLog[
                `${lastDateData.year}-${lastDateData.month}-${lastDateData.day}`
              ].caloriesIn;
            calOut =
              vd.calorieLog[
                `${lastDateData.year}-${lastDateData.month}-${lastDateData.day}`
              ].caloriesOut;
          } catch (error) {}

          const prediction = generateWeightPrediction(
            lastDateData.prediction.bmr,
            calIn,
            calOut,
            lastDateData.value,
            lastDateData.goal,
            lastDateData.pace,
            lastDateData.unit,
            dateRange[i]
          );

          filteredAndFilledData.push(
            generateWeightLogItem(
              lastDateData.value,
              "stub",
              lastDateData.unit,
              dateRange[i],
              prediction,
              lastDateData.goal
            )
          );
        }
      }

      const finalData = filteredAndFilledData;
      const savedToday = finalData.shift();

      if (finalData.length <= maxNumOfItems) {
        finalData.push(savedToday);
        return finalData;
      }

      let cullSpread = Math.ceil(finalData.length / maxNumOfItems);
      const culledData = [savedToday];
      let count = 0;

      if (cullSpread < 3) {
        // when the spread hits a poor rounding at 2, give it a bump down to a tighter cull to produce more datapoints.
        cullSpread = 1;
      }

      for (let i = 0; i < finalData.length; i++) {
        if (count < cullSpread) {
          count++;
        } else {
          finalData[i].date !== savedToday.date &&
            culledData.push(finalData[i]);
          count = 0;
        }
      }

      return culledData.sort((a, b) =>
        compareISODatetimes(a.datetime, b.datetime)
      );
    },
    [vd.calorieLog]
  );

  const buildGoalTimeline = useCallback(() => {
    const weightGoal = [];
    const weight = [];
    const predictions = [];

    // Pad range around goal line for minimum and maximum.
    let maxWeight = -1;
    let minWeight = 1000;

    const today = getTodayDate();
    const todayLog = vd.weightLog[`${today.year}-${today.month}-${today.day}`];
    const todayWeight = converterUserWeight(
      todayLog.unit,
      todayLog.value,
      vd.weightUnitSetting,
      true,
      1
    );

    const todayGoalWeight = converterUserWeight(
      vd.goalWeight.unit,
      vd.goalWeight.value,
      vd.weightUnitSetting,
      true,
      1
    );

    const todayGoalPacePerWeek =
      converterUserWeight(
        vd.goalPace.unit,
        vd.goalPace.value,
        vd.weightUnitSetting,
        true,
        1
      ) / 7;

    const weightDelta = todayWeight - todayGoalWeight;
    const maxDays = Math.abs(weightDelta / todayGoalPacePerWeek);

    const forwardDate = getLaterDate(today.json, maxDays);

    const dateRangePreCull = getRangeOfDates(today.json, forwardDate.json); // maxDays + current date.
    let dateRange;

    if (dateRangePreCull.length - 1 > MAX_DATAPOINTS) {
      const savedToday = dateRangePreCull.shift();

      const cullSpread = Math.round(
        (dateRangePreCull.length - 1) / MAX_DATAPOINTS
      );

      const culledData = [];
      let count = 0;

      for (let i = 0; i < dateRangePreCull.length; i++) {
        if (count < cullSpread) {
          count++;
        } else {
          const attachOriginal = dateRangePreCull[i];
          attachOriginal.originalIndex = i;
          culledData.push(attachOriginal);
          count = 0;
        }
      }

      dateRange = [savedToday, ...culledData];
    } else {
      dateRange = dateRangePreCull;
    }

    for (let index = 0; index < dateRange.length; index++) {
      const date = dateRange[index];
      if (date.date === today.date) {
        // current day, load up full day info

        if (maxWeight < todayLog.value) {
          maxWeight = todayLog.value;
        }

        if (minWeight > todayLog.value) {
          minWeight = todayLog.value;
        }

        weight.push({
          key: `weight-${index}`,
          y: parseFloat(todayLog.value.toFixed(1)),
          x: todayLog.date,
          type: todayLog.type,
          labelStore: `▫️`,
        });
      } else {
        if (
          maxWeight <
          todayWeight + todayGoalPacePerWeek * (date?.originalIndex || index)
        ) {
          maxWeight =
            todayWeight + todayGoalPacePerWeek * (date?.originalIndex || index);
        }

        if (
          minWeight >
          todayWeight + todayGoalPacePerWeek * (date?.originalIndex || index)
        ) {
          minWeight =
            todayWeight + todayGoalPacePerWeek * (date?.originalIndex || index);
        }

        weight.push({
          key: `weight-${index}`,
          y: parseFloat(
            (
              todayWeight +
              todayGoalPacePerWeek * (date?.originalIndex || index)
            ).toFixed(1)
          ),
          x: date.date,
          type: "pred",
          labelStore: `▫️`,
        });
      }

      weightGoal.push({
        key: `weightGoal-${index}`,
        y: parseFloat(todayGoalWeight.toFixed(1)),
        x: date.date,
        type: "goal",
        isDarkTheme: isDarkTheme,
        labelStore: "",
      });

      if (maxWeight < todayGoalWeight) {
        maxWeight = todayGoalWeight;
      }

      if (minWeight > todayGoalWeight) {
        minWeight = todayGoalWeight;
      }
    }

    return {
      weight: weight,
      weightGoal: weightGoal,
      predictions: predictions,
      maxWeight: maxWeight,
      minWeight: minWeight,
    };
  }, [
    isDarkTheme,
    vd.goalPace?.unit,
    vd.goalPace?.value,
    vd.goalWeight?.unit,
    vd.goalWeight?.value,
    vd.weightLog,
    vd.weightUnitSetting,
  ]);

  const buildWeightTimeline = useCallback(() => {
    const weightGoal = [];
    const weight = [];
    const predictions = [];

    // Pad range around goal line for minimum and maximum.
    let maxWeight = -1;
    let minWeight = 1000;

    const filtered = filterRange(vd.weightLog, vd.range);

    if (!filtered?.length) {
      return (
        <div className="empty-text">
          No weights are logged within this time range.
        </div>
      );
    }

    const filteredPredictions = filtered
      .filter(
        (item) => item.type !== "stub" || item.date === getTodayDate().date
      )
      .map((item) => item.prediction);

    if (filteredPredictions) {
      for (let index = 0; index < filteredPredictions.length; index++) {
        const pred = filteredPredictions[index];
        if (pred.predictedWeight) {
          if (maxWeight < pred.predictedWeight) {
            maxWeight = pred.predictedWeight;
          }

          if (minWeight > pred.predictedWeight) {
            minWeight = pred.predictedWeight;
          }

          predictions.push({
            key: `pred-${index}`,
            y: parseFloat(pred.predictedWeight.toFixed(1)),
            x: pred.date,
            labelStore: "",
          });
        }
      }
    }

    for (let index = 0; index < filtered.length; index++) {
      const log = filtered[index];

      if (maxWeight < log.value) {
        maxWeight = log.value;
      }

      if (maxWeight < log.goal.value) {
        maxWeight = log.goal.value;
      }

      if (minWeight > log.value) {
        minWeight = log.value;
      }

      if (minWeight > log.goal.value) {
        minWeight = log.goal.value;
      }

      // Build and associate annotation labels.
      const annoteStore = {
        ...DEFAULT_CALORIE_ANNOTATIONS,
        ...DEFAULT_PHYSICAL_ANNOTATIONS,
      };

      let targetDate = null;
      let goalTrigger; // True if whatever the calories prior, was under its caloric goal,
      // false if it wasn't, undefined if no insight available.

      let thisDay = null;

      try {
        thisDay = vd.calorieLog[`${log.year}-${log.month}-${log.day}`];
      } catch (e) {}

      // if no food logged this day, or if there is food logged, but it's after weight, then try yesterday.
      // if not food logged yesterday, then no annotes.

      if (thisDay) {
        const comboLogTimes = [
          ...thisDay.foodItems,
          ...thisDay.physicalItems,
        ].map((i) => i.datetime);

        const weightWasLoggedBeforeNewCalories = comboLogTimes.every(
          (cur) => cur > log.datetime
        );

        // If the active weight of the day was logged before the calories stored for that same day, then that means
        // the user logged their weight before doing anything on the day, a.k.a. first thing after waking.
        // This means that the calories of the prior day, and in turn their annotations, were the direct cause for the weight result.
        // Thus, the prior day's intake/physical stats should be associated with this weight.
        if (weightWasLoggedBeforeNewCalories) {
          const dayBefore = getEarlierDate(log.datetime);
          // If there are logs for yesterday, then use yesterday
          if (
            vd.calorieLog[
              `${dayBefore.year}-${dayBefore.month}-${dayBefore.day}`
            ]
          ) {
            targetDate = dayBefore;
          }
        } else {
          targetDate = log;
        }
      } else {
        const dayBefore = getEarlierDate(log.datetime);
        // If there are logs for yesterday, then use yesterday
        if (
          vd.calorieLog[`${dayBefore.year}-${dayBefore.month}-${dayBefore.day}`]
        ) {
          targetDate = dayBefore;
        }
      }

      let workoutTrigger = false;
      let fastTrigger = false;
      let checkupTriggered = false;

      for (const [, v] of Object.entries(log.checkup)) {
        checkupTriggered = checkupTriggered || v;
      }

      if (targetDate) {
        const finalizedTargetDay =
          vd.calorieLog[
            `${targetDate.year}-${targetDate.month}-${targetDate.day}`
          ];
        if (finalizedTargetDay) {
          const calorieDelta =
            finalizedTargetDay.caloriesIn - finalizedTargetDay.caloriesOut;
          const caloriesGoal = finalizedTargetDay.caloriesGoal;

          if (finalizedTargetDay.caloriesOut > 0) {
            workoutTrigger = true;
          }

          // FUTURE: Arbitrary cut off for "fast check" calorie level. Could be zero, but perhaps someone uses "coffee" in their fast.
          if (finalizedTargetDay.caloriesIn < 100) {
            fastTrigger = true;
          }

          // NOTE: While below is the most "accurate" check, the nochange goal will never in practice be exactly 0, so that
          // means for this to be put into practice, would need to derive a fuzzy check that bounds a little looser.

          goalTrigger =
            log.goal.direction === "nochange"
              ? undefined
              : log.goal.direction === "more"
              ? calorieDelta > caloriesGoal
              : calorieDelta < caloriesGoal;

          const comboItemsFinal = [
            ...finalizedTargetDay.foodItems,
            ...finalizedTargetDay.physicalItems,
          ];
          const comboAnnotesFinal = comboItemsFinal.map((i) => i.annotations);

          // Reduce array of annotations on the day into a single collection of annotations.
          for (let items = 0; items < comboAnnotesFinal.length; items++) {
            const item = comboAnnotesFinal[items];
            const itemKeys = Object.keys(item);

            for (let j = 0; j < itemKeys.length; j++) {
              const key = itemKeys[j];
              if (item[key]) {
                annoteStore[key] = true;
              }
            }
          }
        }
      }

      weightGoal.push({
        key: `weightGoal-${index}`,
        y: parseFloat(log.goal.value.toFixed(1)),
        x: log.date,
        type: log.type,
        goalMet: goalTrigger,
        goalTargetIsMoreCalories: log.goal.direction === "more",
        isDarkTheme: isDarkTheme,
        labelStore: "",
        isFasted: fastTrigger,
        isCheckUped: checkupTriggered,
        isWorkedOut: workoutTrigger,
      });

      weight.push({
        key: `weight-${index}`,
        y: parseFloat(log.value.toFixed(1)),
        x: log.date,
        type: log.type,
        goalMet: goalTrigger,
        goalTargetIsMoreCalories: log.goal.direction === "more",
        isDarkTheme: isDarkTheme,
        labelStore: ` `, // There needs to be something inserted, empty character.
      });
    }

    return {
      weight: weight,
      weightGoal: weightGoal,
      predictions: predictions,
      maxWeight: maxWeight,
      minWeight: minWeight,
    };
  }, [filterRange, isDarkTheme, vd.calorieLog, vd.range, vd.weightLog]);

  const visibleDataPoints = useMemo(() => {
    return vd.isGoal && vd.range === "GOAL"
      ? buildGoalTimeline()
      : buildWeightTimeline();
  }, [vd.isGoal, vd.range, buildGoalTimeline, buildWeightTimeline]);

  return !vd.weightLog || Object.keys(vd.weightLog).length === 0 ? (
    <div className="empty-text">No weights have been logged.</div>
  ) : (
    <div className="chart">
      <VictoryChart
        containerComponent={
          <VictoryVoronoiContainer
            labels={({ datum }) => `${datum.labelStore}`}
            voronoiDimension="x"
            voronoiBlacklist={blackList}
            labelComponent={
              <VictoryTooltip flyoutComponent={<ChartTooltip />} />
            }
          />
        }
        padding={{
          top: 30,
          bottom: 25,
          left: 30,
          right: 20,
        }}
        minDomain={{ y: visibleDataPoints.minWeight - DOMAIN_PADDING }}
        maxDomain={{ y: visibleDataPoints.maxWeight + DOMAIN_PADDING }}
        height={200}
      >
        <VictoryAxis
          style={{
            axis: { stroke: isDarkTheme ? BASE_WHITE_DULLED : BASE_BLACK },
          }}
          tickFormat={(tf) => {
            try {
              const day = tf.split("/")[0];
              const month = tf.split("/")[1];
              // const year = tf.split('/')[2].slice(-2);
              return `${day}\n${month}`;
            } catch (error) {
              return "";
            }
          }}
          tickLabelComponent={
            <VictoryLabel
              angle={0}
              dy={-5}
              dx={1}
              textAnchor={"middle"}
              style={{
                fill: isDarkTheme ? BASE_WHITE : BASE_BLACK,
                fontSize: 10,
                fontFamily: "Oswald-Regular",
              }}
            />
          }
        />
        <VictoryAxis
          style={{
            axis: { stroke: isDarkTheme ? BASE_WHITE_DULLED : BASE_BLACK },
          }}
          dependentAxis
          tickFormat={(tf) => tf.toFixed(1)}
          tickCount={3}
          tickLabelComponent={
            <VictoryLabel
              dx={8}
              textAnchor={"end"}
              style={{
                fill: isDarkTheme ? BASE_WHITE : BASE_BLACK,
                fontSize: 10,
                fontFamily: "Oswald-Regular",
              }}
            />
          }
        />
        {visibleDataPoints.weightGoal && (
          <VictoryLine
            name="trendLine-goal"
            // interpolation="natural"
            data={visibleDataPoints.weightGoal}
            style={{
              data: {
                stroke: BASE_PINK,
                strokeWidth: 2,
                strokeDasharray: "1111",
              },
            }}
          />
        )}
        {visibleDataPoints.predictions && (
          <VictoryLine
            name="trendLine-prediction"
            data={visibleDataPoints.predictions}
            style={{ data: { stroke: BASE_AQUA, strokeWidth: 1 } }}
          />
        )}
        {visibleDataPoints.weight && (
          <VictoryLine
            name="trendLine-weight"
            data={visibleDataPoints.weight}
            style={{
              data: {
                stroke: isDarkTheme ? BASE_WHITE : BASE_BLACK,
                strokeWidth: isDarkTheme ? 1 : 2,
                strokeDasharray: "1 2 1 2",
              },
            }}
          />
        )}

        <VictoryScatter
          data={visibleDataPoints.weight}
          size={({ datum }) =>
            datum.type === "user" ? 4 : isDarkTheme ? 3 : 2
          }
          style={{
            data: {
              fill: ({ datum }) => {
                switch (datum.goalMet) {
                  case true:
                    return BASE_GREEN;
                  case false:
                    return BASE_RED;
                  default:
                    return BASE_ACCENT;
                }
              },
              stroke: isDarkTheme ? BASE_WHITE : BASE_BLACK,
              strokeWidth: isDarkTheme ? 1 : 2,
            },
          }}
        />
      </VictoryChart>
    </div>
  );
}
